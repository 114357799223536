import React, { useState } from "react";
import { useEffect } from "react";
import { BsChevronDown } from "react-icons/bs";
import { transfers } from "../api/data";
import TransferHistoryTable from "../components/TransferHistoryTable";
import { transferTableData } from "./TransferHistoryData";
import OverlayLoader from "../components/OverlayLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";

const TransferHistory = ({ title, listing = null }) => {
  const options = [
    { label: "All", data: null },
    { label: "Last 3 Months", data: "3_MONTHS" },
    { label: "Last Week", data: "LAST_WEEK" },
  ];
  // DEPOSIT FILTER
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(options[0]);
  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const [page, setPage] = useState(50);
  const [transferList, setTransferList] = useState([]);

  useEffect(() => {
    setLoading(true);
    transfers({ range: selected.data, type: listing ? listing : "ALL_TRANSACTIONS", page: page })
      .then((response) => {
        setTransferList(response);
        setLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setLoading(false);
        toast("Unable to load documents successfully", {
          type: "error",
        });
      });
  }, [selected]);

  useEffect(() => {
    transfers({ range: selected.data, type: listing ? listing : "ALL_TRANSACTIONS", page: page })
      .then((response) => {
        if (!response) return null;
        setTransferList([...transferList, ...response]);
      })
      .catch((err) => console.error(err));
  }, [page]);

  const handleScroll = (e) => {
    const bottom = Math.floor(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight;
    console.log({
      first: e.target.scrollHeight - e.target.scrollTop,
      second: e.target.clientHeight
    });
    if (bottom) {
      setPage(page + 50);
    }
  }

  return (
    <div className="relative">
      {loading ? (
        <div className="absolute z-50 flex items-center justify-center w-full h-full">
          <FadeLoader color={color} loading={loading} size={80} />
        </div>
      ) : null}
      <div className="deposit-page-box-title">
        <div className="color-box" style={{ background: "#141416" }}></div>
        <span>{title}</span>
        {/*--------------- Dropdown filter ---------- */}
        <div class="deposit-filter">
          <div
            className="df-btn"
            onClick={(e) => {
              setIsActive(!isActive);
            }}
          >
            {selected.label}
            <span>
              <BsChevronDown></BsChevronDown>
            </span>
          </div>
          {isActive && (
            <div className="df-content">
              {options.map((option) => (
                <div
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className="df-item"
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
        {/*--------------- Dropdown filter ---------- */}
      </div>
      <TransferHistoryTable onScroll={handleScroll} data={transferList}></TransferHistoryTable>
      <a
        href="/transfers-list"
        target="_blank"
        rel="noopener noreferrer"
        className="underline blue"
      >
        <button className="smkit-btn">
          Download Report
        </button>
      </a>
    </div>
  );
};

export default TransferHistory;
