import React, { useState } from "react";

// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import AdminTable from "../../components/admin/AdminTable";

import TradingHoursChart from "../../components/admin/TradingHoursChart";
import { data, colNames } from "../../components/admin/ProfitRankingData";
import { lossdata, losscolNames } from "../../components/admin/LossRankingData";
import {
  volrankdata,
  volrankcolNames,
} from "../../components/admin/VolRankingData";
import {
  symbolrankdata,
  symbolcolNames,
} from "../../components/admin/SymbolRankingData";
import {
  balancerankdata,
  balancecolNames,
} from "../../components/admin/BalanceRankingData";
import {
  payoutrankdata,
  payoutcolNames,
} from "../../components/admin/PayoutRankingData";
import {
  affiliaterankdata,
  affiliatecolNames,
} from "../../components/admin/AffiliateRankingData";

import adminboxblue from "../../images/icons/adminboxblue.svg";

import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxorange2 from "../../images/icons/adminboxorange2.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";

const BrokerAnalytics = ({ showAdminNav }) => {
  showAdminNav(true);

  return (
    <div className="dashboard-page">
      <AdminBar title="Broker Analytics"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Profitable Traders"
              img={adminboxblue}
              data="120"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Losing Traders"
              img={adminboxorange}
              data="120"
              info="Last 24 Hours +2.43%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total Volume"
              img={adminboxgreen}
              data="500"
              info="Last 24 Hours +3.00%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Symbol"
              img={adminboxblack}
              data="2.77"
              info="Last 24 Hours -0.12%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Best Trading Hours"
              img={adminboxblue}
              data="12:00"
              data2="Am(EST)"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Best Trading Day"
              img={adminboxblue}
              data="Sunday"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="A-Book Traders"
              img={adminboxblue}
              data="250"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="B-Book Traders"
              img={adminboxorange2}
              data="120"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        {/* CHARTS */}
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Best Trading Hours</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          {/* <div className="chart">
            <TradingHoursChart></TradingHoursChart>
          </div> */}
        </div>

        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Best Trading Day</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          {/* <div className="chart">
            <TradingHoursChart></TradingHoursChart>
          </div> */}
        </div>
        {/* tables */}

        <div className="overview-tables ot-grid">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Profit Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={data}
              colNames={colNames}
              color="blue"
              color2="green"
              color3="green"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Loss Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={lossdata}
              colNames={losscolNames}
              color="blue"
              color2="red"
              color3="red"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Volume Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={volrankdata}
              colNames={volrankcolNames}
              color="blue"
              color2="black"
              color3="green"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Symbol Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={symbolrankdata}
              colNames={symbolcolNames}
              color="blue"
              color2="black"
              color3="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Balance Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={balancerankdata}
              colNames={balancecolNames}
              color="blue"
              color2="black"
              color3="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Net Deposit Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={payoutrankdata}
              colNames={payoutcolNames}
              color="blue"
              color2="black"
              color3="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Payout Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={payoutrankdata}
              colNames={payoutcolNames}
              color="blue"
              color2="black"
              color3="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Affiliate Ranking</div>
              <div className="os-right">
                <TableSearch></TableSearch>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={affiliaterankdata}
              colNames={affiliatecolNames}
              color="blue"
              color2="black"
              color3="blue"
            ></AdminTable>
            <button>Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BrokerAnalytics;
