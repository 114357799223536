import React, { useState } from "react";

import { BsChevronDown } from "react-icons/bs";

const AdminFilter = ({ classprop, name, setOption, option, list, defaultValue = "This Month" }) => {
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(defaultValue || option);
  const options = list? list : ["This Month", "Last Week"];

  return (
    <div className={`admin-filter ${classprop}`}>
      <div
        className="df-btn"
        onClick={(e) => {
          setIsActive(!isActive);
        }}
      >
        {selected}
        <span>
          <BsChevronDown></BsChevronDown>
        </span>
      </div>
      {isActive && (
        <div className="df-content">
          {options.map((option, i) => (
            <div
              key={name+'-options-'+i}
              onClick={(e) => {
                setSelected(e.target.textContent);
                setIsActive(false);
                setOption(e.target.textContent);
              }}
              className="df-item"
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AdminFilter;
