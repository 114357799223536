import React from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import TableBtn from "../../components/admin/TableBtn";
import AdminTable from "../../components/admin/AdminTable";
import {
  invoice_data,
  invoice_datacolNames,
} from "../../components/admin/InvoicingData";

import adminboxblueinv from "../../images/icons/adminboxblueinv.svg";
import adminboxorange3 from "../../images/icons/adminboxorange3.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack2 from "../../images/icons/adminboxblack2.svg";

const Invoicing = ({ showAdminNav }) => {
  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Invoicing"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Invoices"
              img={adminboxblueinv}
              data="200"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Paid"
              img={adminboxorange3}
              data="1224"
              info="Last 24 Hours -0.21% "
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Unpaid"
              img={adminboxblack2}
              data="50"
              info="Adrito Rafsan"
              info2="By"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Partially Paid"
              img={adminboxgreen}
              data="05"
              info="Adrito Rafsan"
              info2="By"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Invoicing</div>
              <div className="os-right">
                <TableBtn text="Create invoice"></TableBtn>
                <TableSearch classprop="long"></TableSearch>
                <TableFilter></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={invoice_data}
              colNames={invoice_datacolNames}
              color="green"
              color2="blue"
              color3="green"
              color4="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Invoicing;
