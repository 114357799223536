export const volrankdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.3",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.3",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.3",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.3",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "11.3",
    gain: "-2.54%",
  },
];

export const volrankcolNames = [
  "No",
  "Login Id",
  "Name",
  "Volume (Lot)",
  "Change",
];
