import React from "react";
import styled from "styled-components";
import "./ImportantNotice.css";
import { useNavigate } from "react-router-dom";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const ImportantNotice = ({ setShow }) => {
  const navigate = useNavigate();
  return (
    <ModalContainer>
      <div className="notice-modal">
        <div className="notice-top">
          <h1>Important Notice</h1>
        </div>
        <div className="notice-body">
          <p>
            <b>
              The following documents are required for verification of your Apex
              Markets account for you to have the best experience:
            </b>
          </p>
          <p>
            <span>1.</span>A CLEAR picture of the front and back of your valid
            Government-Issued Photo ID which can be any of the following:
            Passport, Drivers License, or National ID.
          </p>
          <p>
            <span>2.</span> A CLEAR picture of you holding your
            Government-Issued ID Beside your face.
          </p>
          <p>
            <span>3.</span> A SIGNED Customer Agreement.
          </p>

          <h4>
            <span className="green">Tips:</span>
          </h4>
          <p>
            <span>1.</span> Ensure all pictures are taken clearly and in good
            lighting.
          </p>
          <p>
            <span>2.</span> Ensure your name is correct and corresponds to the
            Government Issued Identification.
          </p>
          <p>
            <span>3.</span> Ensure your Government Issued Identification number
            such as Tax Registration Number (TRN), Passport Number, or Driver's
            License Number is entered as it appears on your Government Issued
            Identification document.
          </p>
          <p>
            <span>4.</span> Ensure the signature signed on the Customer
            Agreement document is drawn exactly as it appears on your
            Government-issued Identification document.
          </p>
          <p>
            <span>5.</span> Ensure the information on your Government Issued
            Identification document is entered correctly in the Customer
            Agreement and corresponds to your Government Issued Identification
            document. In short, do not upload any expired documents or incorrect
            information. All information MUST match.
          </p>
          <div>
            If you have any questions, check out our{" "}
            <span className="blue pointer" onClick={() => navigate("/faq")}>
              FAQ page
            </span>{" "}
            or reach out to our 24/7 Support team via email at{" "}
            <span className="green">
              <a href="mailto:support@apexmarkets.io">support@apexmarkets.io</a>
            </span>
            .
          </div>
          <p>
            Verifying your Apex Markets account is quick and easy! So what are
            you waiting for? <br /> Start the verification process now and enjoy
            full access to the Apex Markets platform!
          </p>
          <button onClick={() => setShow(false)}>Verify My Account</button>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ImportantNotice;
