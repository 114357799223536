import ky from "ky";
import axios from "axios";

import { toast } from "react-toastify";

export const api = ky.extend({
  timeout: false,
  credentials: "include",
  withCredentials: true,
  prefixUrl: process.env.REACT_APP_API_BASE_URL,
  throwHttpErrors: false,
  hooks: {
    afterResponse: [
      async (_request, _options, response) => {
        const body = await response.json();
        if (response.status === 401) {
          window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/login`;
        }
        else if (
          body.errors != null &&
          Array.isArray(body.errors) &&
          response.status != 200
        ) {
          for (const error of body.errors) {
            toast(error, { type: "error" });
          }
        }
        console.log("@@@ response");
      },
    ],
  },
});

export const SECURE_API = (contentType = null, responseType = null) => {
  return axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    withCredentials: true,
    responseType: responseType ?? "json",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": contentType ?? "application/json"
    },
  });
};
