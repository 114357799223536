import { React, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { getDocumentUrl } from "../api/document";
// react-signature-pad
// https://www.npmjs.com/package/react-signature-pad
import SignaturePad from "react-signature-pad";

// react-signature-canvas
// https://github.com/agilgur5/react-signature-canvas
// import SignatureCanvas from "react-signature-canvas";

import deleteIcon from "../images/icons/delete.svg";
import "./SignatureModal.css";
import { getWireTransferUrl, updateWireTransfer } from "../api/wireTransfer";
import CurrencyInput from "react-currency-input-field";
import { BsChevronDown } from "react-icons/bs";
import { toast } from "react-toastify";
import "../pages/WireTransferModal.css";
const ModalContainer = styled.div`
  position: fixed;
  top: 100px;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const ManageWireTransferModal = ({ closeModal, wireTransfer, refresh }) => {
  const iframeRef = useRef();
  const documentUrl = getWireTransferUrl(wireTransfer);
  const [amount, setAmount] = useState(wireTransfer?.Amount);
  const updateWireTransferRequest = async (status) => {
    const response = await updateWireTransfer(
      {
        status,
        amount,
      },
      wireTransfer.ID
    );
    if (response.errors == null) {
      toast("Successfully update wire transfer", { type: "success" });
      refresh();
      closeModal(false);
    }
  };
  useEffect(() => { });

  return (
    wireTransfer != null && (
      <ModalContainer>
        <div className="cpm">
          <div className="signature-modal big">
            <div className="cpm-container">
              {documentUrl && (
                <iframe
                  style={{ margin: "0px auto", display: "block" }}
                  src={documentUrl}
                  ref={iframeRef}
                  height={500}
                  width={500}
                />
              )}
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  style={{ width: "500" }}
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={amount}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt) => setAmount(valueAmt)}
                />
                <div className="select-currency-btn currency-type">
                  USD
                  <BsChevronDown />
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>
              <div className="buttons">
                <button
                  className="verification-continue-btn complete"
                  onClick={() => {
                    closeModal(false);
                  }}
                >
                  Close
                </button>
                {wireTransfer?.Status === "PENDING" && (
                  <>
                    <button
                      className="verification-continue-btn complete"
                      onClick={() => {
                        updateWireTransferRequest("APPROVED");
                      }}
                    >
                      Approve
                    </button>
                    <button
                      className="verification-continue-btn complete"
                      onClick={() => {
                        updateWireTransferRequest("REJECTED");
                      }}
                    >
                      Reject
                    </button>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    )
  );
};

export default ManageWireTransferModal;
