// src/store.js
import { create } from "zustand";

export const useStore = create((set) => ({
  docFront: "",
  docBack: "",
  selfie: "",
  ocrData: {},
  signature: "",

  setDocFront: (docFront) => set(() => ({ docFront })),
  setDocBack: (docBack) => set(() => ({ docBack })),
  setSelfie: (selfie) => set(() => ({ selfie })),
  setSignature: (signature) => set(() => ({ signature })),
  setOcrData: (ocrData) => set(() => ({ ocrData })),
}));
