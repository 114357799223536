import React from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import bluetick from "../images/icons/bluetick.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const WireSubmitted = ({ closeModal, valueAmt }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="delete-card">
              <img src={bluetick} alt="" />
              <p>Thanks for Submit</p>
            </div>
            <div className="delete-card-info">
              <p>
                our team will verify and add funds to your account once we
                receive it. Wire transfer deposits usually take between 1-5
                working days to reflect.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default WireSubmitted;
