import { api } from "./index";
export const getCurrentProfile = async () => {
  const response = await api.get("profile/me").json();
  if (response != null && response.customer != null) {
    return response.customer;
  }
  return null;
};

export const saveProfile = async (data) => {
  const response = await api
    .put("profile/me", {
      json: data,
    })
    .json();
  if (response != null && response.customer != null) {
    return response.customer;
  }
  return null;
};

export const save2Fa = async (data) => {
  return await api
    .put("profile/twoFa", {
      json: JSON.parse(JSON.stringify(data)),
    })
    .json();
};

export const userActivityTest = async () => {
  return await api
    .get("profile/userClicked")
    .json();
};

