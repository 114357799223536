import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { getKycDocuments } from "../../api/document";
import AdminVerificationReview from "./AdminVerificationReview";
import TableSearch from "../../components/admin/TableSearch";
import Paginator from "../Paginator";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import moment from "moment";

const AdminKycTable = () => {
  const allIDTypes = ["National ID", "Drivers License", "Passport"];
  const [signatureStatus, setSignatureStatus] = useState();
  const [selfieStatus, setSelfieStatus] = useState();
  const [idCardStatus, setIdCardStatus] = useState();
  const [docSignature, setDocSignature] = useState();
  const [docSelfie, setDocSelfie] = useState();
  const [docIDFront, setDocID] = useState();
  const [docForm, setDocForm] = useState();
  const [statusFilter, setStatusFilter] = useState("");
  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [customers, setCustomers] = useState([]);
  const [filter, setFilter] = useState();
  const [limit, setLimit] = useState(10);
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#008ffd");
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [showManageModal, setShowManageModal] = useState(false);

  const loadDocuments = async () => {
    setLoading(true);
    try {
      const { customers, pages } = await getKycDocuments({
        ...(filter != null && {
          filter,
        }),
        status: statusFilter,
        limit: currentPage * numRows,
        numRows: numRows,
      });
      setCustomers(customers);
      setNumPages(pages > 0 ? pages : 1);
      if (currentPage > pages) {
        setCurrentPage(1);
      }
      setLoading(false);
      return customers;
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      return [];
    }
  };

  useEffect(() => {
    selectedCustomer?.kyc_documents?.forEach((doc) => {
      const typeName = doc?.kyc_document_type?.Type_Name;

      if (typeName === "Signed Signature") {
        setDocSignature(doc);
        setSignatureStatus(getDocStatus(doc));
      } else if (typeName === "Selfie") {
        setDocSelfie(doc);
        setSelfieStatus(getDocStatus(doc));
      } else if (doc.name.includes("front") && allIDTypes.includes(typeName)) {
        setDocID(doc);
        setIdCardStatus(getDocStatus(doc));
      } else if (doc.name.includes("Form")) {
        setDocForm(doc);
      }
    });
  }, [selectedCustomer]);

  const manageCustomer = async (customer) => {
    setDocSignature();
    setSignatureStatus();
    setDocSelfie();
    setSelfieStatus();
    setDocID();
    setIdCardStatus();
    setDocForm();
    setSelectedCustomer(customer);
    setShowManageModal(true);
  };

  useEffect(() => {
    loadDocuments();
  }, [filter]);

  const refresh = async () => {
    const customers = await loadDocuments();
    if (selectedCustomer != null) {
      for (const customer of customers) {
        if (
          customer.customer.Customer_ID ===
          selectedCustomer.customer.Customer_ID
        ) {
          setSelectedCustomer({
            ...customer,
          });
        }
      }
    }
  };
  useEffect(() => {
    refresh();
  }, [currentPage, statusFilter, filter]);

  const getDocStatus = (doc) => {
    return doc?.resubmit_count > 0
      ? doc?.resubmit_status_id === 1
        ? "PENDING"
        : doc?.resubmit_status_id === 3
        ? "VERIFIED"
        : "REJECTED"
      : doc?.kyc_status?.Status_Name;
  };

  const getStatusColor = (Status) => {
    return Status === "REJECTED"
      ? "text-red-600"
      : Status === "PENDING"
      ? "text-yellow-600"
      : "text-green-600";
  };
  return (
    <>
      <div className="overview-section-top">
        <div className="os-left">KYC Customer Verification</div>
        <select
          style={{ display: "block" }}
          onChange={(e) => {
            setCurrentPage(1);
            setNumPages(1);
            setStatusFilter(e.target.value);
          }}
        >
          <option value={""}>All</option>
          <option value={"REJECTED"}>REJECTED</option>
          <option value={"VERIFIED"}>VERIFIED</option>
          <option value={"PENDING"}>PENDING</option>
          <option value={"BLOCKED"}>BLOCKED</option>
          <option value={"MIGRATED"}>MIGRATED</option>
        </select>
        <div className="os-right">
          <TableSearch
            classprop="long"
            setFilter={(val) => {
              setFilter(val);
            }}
            filter={filter}
          />
        </div>
      </div>
      <div
        className="relative admin-table-container"
        style={{ height: "100vh" }}
      >
        <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
          <FadeLoader color={color} loading={loading} size={80} />
        </div>
        {showManageModal && (
          <>
            <AdminVerificationReview
              docForm={docForm}
              signatureStatus={signatureStatus}
              selfieStatus={selfieStatus}
              idCardStatus={idCardStatus}
              docSignature={docSignature}
              docSelfie={docSelfie}
              docIDFront={docIDFront}
              allIDTypes={allIDTypes}
              getDocStatus={getDocStatus}
              customer={selectedCustomer}
              closeModal={setShowManageModal}
              refresh={refresh}
            />
          </>
        )}
        <div className="horizontal-line"></div>
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Status</th>
              <th>Email</th>
              <td>ID</td>
              <td>Selfie</td>
              <td>Signed Signature</td>
              <td>Agreement Form</td>
              <td>Verified By</td>
              <td>Verified Date</td>
              <td>Migrated</td>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, index) => {
              const SelfieStatus = getDocStatus(
                customer?.kyc_documents?.find(
                  (doc) => doc?.kyc_document_type?.Type_Name === "Selfie"
                )
              );
              const SignatureStatus = getDocStatus(
                customer?.kyc_documents?.find(
                  (doc) =>
                    doc?.kyc_document_type?.Type_Name === "Signed Signature"
                )
              );
              const DcoumentStatus = getDocStatus(
                customer?.kyc_documents?.find(
                  (doc) =>
                    doc.name.includes("front") &&
                    allIDTypes.includes(doc?.kyc_document_type?.Type_Name)
                )
              );
              const FormStatus = getDocStatus(
                customer?.kyc_documents?.find(
                  (doc) => doc?.kyc_document_type?.Type_Name === "Form"
                )
              );
              return (
                <tr
                  key={index}
                  onClick={() => {
                    manageCustomer(customer);
                  }}
                >
                  <td>{`${customer?.customer?.Firstname} ${customer?.customer?.Lastname}`}</td>
                  <td
                    className={`${getStatusColor(customer?.customer?.Status)}`}
                  >{`${customer?.customer?.Status}`}</td>
                  <td>{`${customer?.customer?.Email}`}</td>
                  <td className={`${getStatusColor(DcoumentStatus)}`}>
                    {DcoumentStatus}
                  </td>
                  <td className={`${getStatusColor(SelfieStatus)}`}>
                    {SelfieStatus}
                  </td>
                  <td className={`${getStatusColor(SignatureStatus)}`}>
                    {SignatureStatus}
                  </td>
                  <td className={`${getStatusColor(FormStatus)}`}>
                    {FormStatus}
                  </td>
                  <td>
                    {`${
                      customer?.customer?.Verified_By_admin?.Customer
                        ?.Firstname ?? "-"
                    } 
                    ${
                      customer?.customer?.Verified_By_admin?.Customer
                        ?.Lastname ?? "-"
                    }`}
                  </td>
                  <td>{`${
                    customer?.customer?.Verified_Date
                      ? moment(customer?.customer?.Verified_Date)?.format(
                          "DD/MM/YY HH:mm:ss A"
                        )
                      : "-"
                  }`}</td>
                  <td>{`${
                    customer?.customer?.migrated == 1 ? true : false
                  }`}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <Paginator
          totalPages={numPages}
          currentPage={currentPage}
          onPageChange={(page) => {
            if (page > numPages) {
              setCurrentPage(numPages);
            } else if (page < 1) {
              setCurrentPage(1);
            } else {
              setCurrentPage(page);
            }
          }}
        />
        <button
          onClick={() => {
            setLimit(limit + 50);
            refresh();
          }}
        >
          Load More
        </button>
      </div>
    </>
  );
};

export default AdminKycTable;
