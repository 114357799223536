import { api } from "./index";

function filterParams(params) {
  const newParams = {};

  for (const [key, val] of Object.entries(params)) {
    if (!val || val === "null") {
      continue;
    }

    newParams[key] = val;
  }

  return newParams;
}

export const transfers = async (params) => {
  const response = await api
    .get("data/transfers", { searchParams: filterParams(params) })
    .json();

  return response?.records.length >= 1 ? response?.records : null;
};

export const transfersDownload = async (params) => {
  const response = await api
    .get("data/transfers-download", { searchParams: filterParams(params) })
    .json();
  return response?.records.length >= 1 ? response?.records : null;
};

export const getDeposits = async (params) => {
  const response = await api
    .get("data/transfers/v2", { searchParams: filterParams(params) })
    .json();
  return response;
};

export const getDepositsOverview = async (params) => {
  const response = await api
    .get("data/deposits-overview", { searchParams: filterParams(params) })
    .json();
  return response;
};

export const getWalletTransactions = async (params) => {
  const response = await api
    .get("data/wallet-transactions", { searchParams: filterParams(params) })
    .json();

  return response;
};

export const getWalletStats = async (params) => {
  const response = await api
    .get("data/wallet-stats", { searchParams: filterParams(params) })
    .json();
  return response;
};

export const getWithdrawalRequests = async (params) => {
  const response = await api
    .get("data/withdrawal-requests", { searchParams: filterParams(params) })
    .json();

  return response;
};

export const getWithdrawalOverview = async (params) => {
  const response = await api
    .get("data/withdrawal-requests-overview", { searchParams: filterParams(params) })
    // .get("data/withdrawal-requests-overview")
    .json();

  return response;
};


export const getFailedAudits = async (params) => {
  const response = await api
    .get("data/failed-audits", { searchParams: filterParams(params) })
    .json();

  return response;
};

export const getFailedAuditsStats = async () => {
  const response = await api
    .get("data/failed-audits-stats")
    .json();

  return response;
};

export const createAppCreds = async (appName, urlList) => {
  const response = await api
    .post("auth/generate-app-credentials", {
      json:{
        appName,
        urlList
      }
    })
    .json();

  return response;
};


