import React from "react";
import { Outlet } from "react-router-dom";
import TransactionHistory from "../components/TransactionHistory";
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";

const TransactionHistoryPage = ({ showNav, noNav }) => {
  showNav(true);
  noNav(false);
  return (
    <>
      <AppBar title="Accounts"></AppBar>
      <AccountsSelector></AccountsSelector>
      <Outlet></Outlet>
      <div className="dashboard-container ">
        <div class="deposit-history ">
          <TransactionHistory title="Transaction History"></TransactionHistory>
        </div>
      </div>
    </>
  );
};

export default TransactionHistoryPage;
