import React from "react";

// components
import AdminBar from "../../components/admin/AdminBar";

import "./Overview.css";

const Overview = ({ showAdminNav }) => {
  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Overview"></AdminBar>
      <div className="dashboard-container"></div>
    </div>
  );
};

export default Overview;
