import React from "react";
import styled from "styled-components";

import trash from "../images/icons/trashcan.svg";
import deleteIcon from "../images/icons/delete.svg";

import "./DeleteCardInfo.css";
import "./DepositForm.css";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const DeleteCardInfo = ({ closeModal }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="delete-card">
              <img src={trash} alt="" />
              <p>Delete Card Info</p>
            </div>
            <div className="delete-card-info">
              <p>Are you sure you want to delete this card info</p>
              <span>(**** - 4534)</span> ?
            </div>
            <div className="card-delete-btn">Delete</div>
            <div className="cancel">Cancel</div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DeleteCardInfo;
