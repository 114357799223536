import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";

import ClosedAdminNav from "./ClosedAdminNav";
import logo from "../../images/apex-markets-logo.svg";
import closenav from "../../images/icons/navclose.svg";

import { AdminSidebarData } from "./AdminSidebarData";

const AdminSidebar = ({ activeNav, setActiveNav }) => {
  // const [active, setActive] = useState(true);
  return (
    <div className="navbar">
      <ClosedAdminNav
        setActive={setActiveNav}
        active={activeNav}
      ></ClosedAdminNav>
      <div className={`sidebar ${activeNav ? "" : "inactive"}`}>
        <div className="logo-row">
          <div className="logo">
            <Link to="#">
              <img src={logo} alt="Apex Markets Logo" />
            </Link>
          </div>
          <div className="close-nav">
            <img
              src={closenav}
              alt=""
              onClick={() => setActiveNav(!activeNav)}
            />
          </div>
        </div>

        <div className="menu-heading">
          <p>Menu</p>
          <div className="menu-heading-underline"></div>
        </div>
        <div className="sidebar-link-container">
          <ul className="sidebar-list">
            {AdminSidebarData.map((val, key) => {
              return (
                <li
                  key={key}
                  className={val.cName}
                  // onClick={() => setActive(!active)}
                >
                  <NavLink to={val.link} className="row">
                    <div id="icon">
                      <img src={val.icon} alt="" />
                    </div>
                    <div id="title">{val.title}</div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AdminSidebar;
