import React from "react";

const DocumentPreviewModal = ({
  onClose,
  modalRef,
  frontPreview,
  backPreview,
  selfiePreview,
  index,
}) => {

  return (
    <div className="modal" ref={modalRef}>
      <div className="space-y-2 modal-content">
        {frontPreview && (
          <>
            <p className="text-2xl text-center">Front</p>
            <div className="p-2 mx-auto border border-black rounded-md img-box">
              <img
                src={
                  typeof frontPreview == "string" &&
                  frontPreview.startsWith("http")
                    ? frontPreview
                    : URL.createObjectURL(frontPreview)
                }
                alt="File Preview"
              />
            </div>
          </>
        )}

        {backPreview && (
          <>
            <p className="text-2xl text-center">Back</p>
            <div className="p-2 mx-auto border border-black rounded-md img-box">
              <img
                src={
                  typeof backPreview == "string" &&
                  backPreview.startsWith("http")
                    ? backPreview
                    : URL.createObjectURL(backPreview)
                }
                alt="File Preview"
              />
            </div>
          </>
        )}
        {selfiePreview && index === 4 && (
          <>
            <p className="text-2xl text-center">Front</p>
            <div className="p-2 mx-auto border border-black rounded-md img-box">
              <img
                src={
                  typeof selfiePreview == "string" &&
                  selfiePreview.startsWith("http")
                    ? selfiePreview
                    : URL.createObjectURL(selfiePreview)
                }
                alt="File Preview"
              />
            </div>
          </>
        )}

        <button
          className="flex items-center justify-center mx-auto pt-5"
          onClick={onClose}
        >
          Close
        </button>
      </div>
    </div>
  );
};

export default DocumentPreviewModal;
