import { React, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import moment from "moment";
import { getDocumentUrl } from "../api/document";
// react-signature-pad
// https://www.npmjs.com/package/react-signature-pad
import SignaturePad from "react-signature-pad";

// react-signature-canvas
// https://github.com/agilgur5/react-signature-canvas
// import SignatureCanvas from "react-signature-canvas";

import deleteIcon from "../images/icons/delete.svg";
import "./SignatureModal.css";
import {
  getWireTransferUrl,
  updateWireTransfer,
  updateWireTransferWithdrawalRequest,
} from "../api/wireTransfer";
import CurrencyInput from "react-currency-input-field";
import { BsChevronDown } from "react-icons/bs";
import { toast } from "react-toastify";
import "../pages/WireTransferModal.css";
import { updateCryptoWithdrawalRequest } from "../api/crypto";
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1055;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ManageWithdrawRequestModal = ({
  closeModal,
  withdrawRequest,
  refresh,
}) => {
  const [isCrypto, setIsCrypto] = useState(withdrawRequest?.CryptoType != undefined);
  const [amount, setAmount] = useState(withdrawRequest?.Amount);

  const updateWithdrawalRequestFunc = async (status) => {
    if (!isCrypto) {
      const response = await updateWireTransferWithdrawalRequest(
        {
          status,
        },
        withdrawRequest.ID
      );
      if (response.errors == null) {
        toast("Successfully update wire transfer", { type: "success" });
        refresh();
        closeModal(false);
      }
    } else {
      const response = await updateCryptoWithdrawalRequest(
        {
          status,
          amount,
        },
        withdrawRequest.ID
      );
      if (response.errors == null) {
        toast("Successfully update crypto withdrawal", { type: "success" });
        refresh();
        closeModal(false);
      }
    }
  };

  useEffect(() => { });

  return (
    withdrawRequest != null && (
      <ModalContainer>
        <div className="signature-modal full-width">
          <div className="cpm-container">
            <div className="cpm-top">
              <h2>Payout Request Details</h2>
            </div>
            <div className="modal-container">
              <div className="row-container">
                <div className="column">
                  <h3 className="sub-heading">Customer Details</h3>
                  <p>Name: <span>{withdrawRequest.customer.Firstname + " " + withdrawRequest.customer.Lastname}</span></p>
                  <p>Email Address: <span>{withdrawRequest.customer.Email}</span></p>
                  <p>Contact Number: <span>{withdrawRequest.customer.Telephone_Cell}</span></p>
                </div>
                <div className="column">
                  <h3 className="sub-heading">Withdrawal Details</h3>
                  <p>Date Created: <span>{moment(withdrawRequest.Date_Created).format("YYYY-MM-DD HH:mm:ss a")}</span></p>
                  <p>Withdrawal Method: <span>{withdrawRequest?.CryptoType == undefined ? "WIRE TRANSFER" : "CRYPTO"}</span></p>
                  <p>Wallet Balance: <span>USD${withdrawRequest.customer?.wallet?.Balance}</span></p>
                  <p>Amount: <span>USD${withdrawRequest.Amount}</span></p>
                  <p>Status: <span>{withdrawRequest.Status}</span></p>

                  {withdrawRequest.Type == "WIRETRANSFER" ?
                    (<>
                      <p>Proccessed By : <span>{withdrawRequest?.wire_transfer_details?.Process_By_admin?.Firstname || "-"} {withdrawRequest?.wire_transfer_details?.Process_By_admin?.Lastname || ""}</span></p>
                      <p>Proccessed Date : <span>{withdrawRequest?.wire_transfer_details?.Process_Date ? moment(withdrawRequest?.wire_transfer_details?.Process_Date).format("DD/MM/YY") : "-"}</span></p>
                    </>
                    ) : null
                  }
                </div>
              </div>

              {!isCrypto && (
                <>
                  <h3 className="sub-heading">Beneficiary Bank</h3>
                  <div className="row-container">
                    <div className="column">
                      <p>Beneficiary Bank Name: <span>{withdrawRequest.Beneficiary_Bank_Name || 'N/A'}</span></p>
                      <p>Beneficiary Bank Address: <span>{withdrawRequest.Beneficiary_Bank_Address || 'N/A'}</span></p>
                      <p>Beneficiary Bank Account Number: <span>{withdrawRequest.Beneficiary_Bank_Account_Number || 'N/A'}</span></p>
                      <p>Beneficiary Bank Code: <span>{withdrawRequest.Beneficiary_Bank_Code || 'N/A'}</span></p>
                      <p>Beneficiary Bank Routing Code: <span>{withdrawRequest.Beneficiary_Bank_Routing_Code || 'N/A'}</span></p>
                      <p>Beneficiary Address On Bank Account: <span>{withdrawRequest.Beneficiary_Address || 'N/A'}</span></p>
                    </div>
                  </div>
                  <br />
                  <h3 className="sub-heading">Intermediary Bank</h3>
                  <div className="row-container">
                    <div className="column">
                      <p>Intermediary Bank Name: <span>{withdrawRequest.Intermediary_Bank_Name || 'N/A'}</span></p>
                      <p>Intermediary Bank Address: <span>{withdrawRequest.Intermediary_Bank_Address || 'N/A'}</span></p>
                      <p>Intermediary Bank Code: <span>{withdrawRequest.Intermediary_Bank_Code || 'N/A'}</span></p>
                      <p>Intermediary Bank Routing Code: <span>{withdrawRequest.Intermediary_Bank_Routing_Code || 'N/A'}</span></p>
                    </div>
                  </div>
                </>
              )}

              {isCrypto && withdrawRequest?.Status === "PENDING" && (
                <div className="amount-input">
                  {/* <input type="text" placeholder="$0.00" /> */}
                  <CurrencyInput
                    style={{ width: "500" }}
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "$0.00")}
                    id="input-example"
                    name="input-name"
                    placeholder="$0.00"
                    defaultValue={amount}
                    prefix="$"
                    decimalsLimit={2}
                    onValueChange={(valueAmt) => setAmount(valueAmt)}
                  />
                  <div className="select-currency-btn currency-type">
                    USD
                    <BsChevronDown />
                    <div className="sc-content"></div>
                  </div>
                  {/* <button>USD</button> */}
                </div>
              )}
            </div>
            <div className="buttons">
              <button
                className="verification-continue-btn complete"
                onClick={() => {
                  closeModal(false);
                }}
              >
                Close
              </button>
              {withdrawRequest?.Status === "PENDING" && (
                <>
                  <button
                    className="verification-continue-btn complete"
                    onClick={() => {
                      updateWithdrawalRequestFunc("APPROVED");
                    }}
                  >
                    Approve
                  </button>
                  <button
                    className="verification-continue-btn complete"
                    onClick={() => {
                      updateWithdrawalRequestFunc("REJECTED");
                    }}
                  >
                    Reject
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalContainer>
    )
  );
};

export default ManageWithdrawRequestModal;
