import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

import UserProfileModal from "../components/UserProfileModal";
import DownloadMetaTrader from "./DownloadMetaTrader";

// css
import "./AppBar.css";

// images icons
import { BsChevronDown } from "react-icons/bs";
import bell from "../images/icons/bell.svg";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import profile from "../images/profile-icon.png";
import Avatar from "react-avatar";
// ----------------------TOP APP BAR------------------
const AppBar = ({ title }) => {
  const customer = useSelector(selectCurrentCustomer);
  const [openModal, setOpenModal] = useState(false);
  const [openDownload, setOpenDownload] = useState(false);

  return (
    customer != null && (
      <>
        {openModal && (
          <UserProfileModal closeModal={setOpenModal}></UserProfileModal>
        )}

        {openDownload && (
          <DownloadMetaTrader closeModal={setOpenDownload}></DownloadMetaTrader>
        )}
        <div className="app-bar-container">
          <div className="app-bar-title">
            <h1>{title}</h1>
          </div>
          <div className="app-bar-right">
            {customer?.Status.toUpperCase() === "VERIFIED" ? (
              <Link to="/deposit">
                <div className="deposit-btn">Deposit Funds</div>
              </Link>
            ) : (
              <></>
            )}

            <div
              className="download-btn"
              onClick={() => {
                setOpenDownload(!openDownload);
              }}
            >
              <div className="divider"></div>
              <span>Download App</span> <BsChevronDown></BsChevronDown>
            </div>
            <div className="divider"></div>
            <div className="notification-bell">
              <img src={bell} alt="" />
            </div>
            <div className="divider"></div>
            <div
              className="profile-icon"
              onClick={() => {
                setOpenModal(true);
              }}
            >
              <Avatar
                name={customer.Firstname + " " + customer.Lastname}
                round={true}
                size={40}
              />
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default AppBar;
