import React, { useEffect, useState } from "react";

// css used
import "./Dashboard.css";

// components
import AppBar from "../components/AppBar";
import VerificationBar from "../components/VerificationWarning";
import TransferHistory from "../components/TransferHistory";
import TransactionHistory from "../components/TransactionHistory";

import ChallengeModal from "../components/ChallengeModal";

import { Link, useNavigate } from "react-router-dom";

// React Circular Progressbar
// https://www.npmjs.com/package/react-circular-progressbar
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// React Elastic Carousel
import Carousel from "react-elastic-carousel";

// images / icons

import deposit from "../images/icons/deposit-black.svg";
import transfer from "../images/icons/transfer-black.svg";
import withdraw from "../images/icons/withdraw-black.svg";
import greenArrow from "../images/icons/db-green-arrow.svg";
import blueArrow from "../images/icons/db-blue-arrow.svg";
import contestimg from "../images/contest.png";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { getAccounts } from "../api/trading";

import { IoEyeOutline } from "react-icons/io5";
import { IoEyeOffOutline } from "react-icons/io5";
import { FadeLoader } from "react-spinners";
import { getCurrentProfile } from "../api/profile";

const Dashboard = ({ showNav, noNav }) => {
  const customer = useSelector(selectCurrentCustomer);
  noNav(false);
  showNav(true);
  const navigate = useNavigate();

  // get from local storage
  const initialHideBalance =
    JSON.parse(localStorage.getItem("hideBalance")) || false;

  const [openModal, setOpenModal] = useState(false);
  const [hideBalance, setHideBalance] = useState(initialHideBalance);
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dob, setDob] = useState("");
  const [phone, setPhone] = useState("");
  const [gender, setGender] = useState("");
  const [street1, setStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const loadLiveAccounts = async (limit, archive = null) => {
    setLoading(true);
    setLiveAccounts([]);
    const accounts = await getAccounts(limit, 0, archive, "LIVE");
    if (accounts != null) {
      setLiveAccounts(accounts);
    }
    setLoading(false);
  };

  useEffect(() => {
    // Save to local storage
    localStorage.setItem("hideBalance", JSON.stringify(hideBalance));
  }, [hideBalance]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    if (customer.Status == "VERIFIED") {
      loadLiveAccounts(3);
    }
    getCustomerInformation();
  }, []);

  const getCustomerInformation = async () => {
    const customer = await getCurrentProfile();
    if (customer != null) {
      setFirstName(customer.Firstname);
      setLastName(customer.Lastname);
      setPostalCode(customer.Postal_Code);
      setPhone(customer.Telephone_Cell);
      setGender(customer.gender);
      setDob(customer.DOB);
    }
    if (customer.customer_address.length) {
      const {
        city_town_district,
        country,
        state_parish_pronvince,
        line1,
        line2,
      } = customer.customer_address[0];
      setStreet1(line1);
      setStreet2(line2);
      setCity(city_town_district);
      setState(state_parish_pronvince);
      setCountry(country);
    }
  };

  return (
    customer != null && (
      <>
        <div className="dashboard-page">
          <AppBar title="Dashboard"></AppBar>
          {(!firstName ||
            !lastName ||
            !dob ||
            !phone ||
            !gender ||
            !street1 ||
            !street2 ||
            !city ||
            !state ||
            !postalCode ||
            !country) && <VerificationBar personalInformation />}
          {customer?.Status !== "VERIFIED" && (
            <>
              <VerificationBar></VerificationBar>
            </>
          )}
          {customer?.Status == "VERIFIED" && (
            <>
              <div className="dashboard-container">
                <div className="main-dashboard-grid">
                  {/*--------------- Current Balance------------------------ */}
                  <div className="current-balance">
                    <div className="cb-row1">
                      <div className="cb-row-1-left">
                        <h1 className="dashboard-grid-title">
                          Current Balance
                        </h1>
                        <div
                          className="hide-balance-btn"
                          onClick={() => setHideBalance(!hideBalance)}
                        >
                          <span className="flex items-center gap-1">
                            {hideBalance ? (
                              <IoEyeOutline className="text-lg" />
                            ) : (
                              <IoEyeOffOutline className="text-lg" />
                            )}
                            {hideBalance ? "Show Balance" : "Hide Balance"}
                          </span>
                        </div>
                      </div>
                      <div
                        className="arrow"
                        onClick={() => navigate("/accounts/wallet-account")}
                      >
                        <img src={blueArrow} alt="" />
                      </div>
                    </div>
                    <div className="cb-row2">
                      <div className="current-balance-amt">
                        {hideBalance ? (
                          <>
                            {Array.from(
                              { length: `${customer?.wallet?.Balance}`.length },
                              (_, index) => "*"
                            ).join("")}
                          </>
                        ) : (
                          <>$ {customer?.wallet?.Balance ?? 0} </>
                        )}
                      </div>
                      <div className="currency-type">USD</div>
                    </div>
                    <div className="cb-row3">
                      <div
                        className="cb-btn"
                        id="cb-deposit-btn"
                        onClick={() => navigate("/deposit")}
                      >
                        Deposit Funds
                        <span>
                          <img src={deposit} alt="" />
                        </span>
                      </div>

                      <div
                        className="cb-btn"
                        id="cb-withdraw-btn"
                        onClick={() => navigate("/withdraw")}
                      >
                        Withdraw Funds
                        <span>
                          <img src={withdraw} alt="" />
                        </span>
                      </div>

                      <div
                        className="cb-btn"
                        id="cb-transfer-btn"
                        onClick={() => navigate("/transfer")}
                      >
                        Transfer Funds
                        <span>
                          <img src={transfer} alt="" />
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="live-accounts">
                    <div className="cb-row1">
                      <div className="cb-row-1-right">
                        <h1 className="dashboard-grid-title">Live Accounts</h1>
                      </div>
                      <div className="arrow">
                        <img
                          src={blueArrow}
                          alt=""
                          onClick={() => navigate("/accounts/live-accounts")}
                        />
                      </div>
                    </div>
                    {/* <div className="info">
                <p>You dont have any Live Account</p>
              </div> */}
                    <div className="dashboard-accounts">
                      {loading ? (
                        <FadeLoader
                          color="#008ffd"
                          loading={loading}
                          size={80}
                        />
                      ) : (
                        <Carousel itemsToShow={1} showArrows={false}>
                          {liveAccounts.map((account) => {
                            return (
                              <div className="dashboard-account">
                                <div className="flex items-center gap-2 da-top">
                                  <div
                                    className="color-box"
                                    style={{ background: "#141416" }}
                                  ></div>
                                  <p>MT5 ID - {account.trading_account_id}</p>
                                </div>
                                <div className="da-info">
                                  <p>Current Balance</p>
                                  <div className="da-amt">
                                    ${account.balance} USD
                                  </div>
                                  <span className="green"></span>
                                </div>
                              </div>
                            );
                          })}
                        </Carousel>
                      )}
                    </div>
                    <Link to="/accounts/live-accounts" className="cb-btn">
                      <div>Open Live Account</div>
                    </Link>
                  </div>
                  <div className="dashboard-th-container">
                    <TransactionHistory title="Transaction History"></TransactionHistory>
                  </div>
                  {/*  */}
                </div>
              </div>
            </>
          )}
        </div>
      </>
    )
  );
};

export default Dashboard;
