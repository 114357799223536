import React, { useEffect, useState } from "react";

// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import AdminTable from "../../components/admin/AdminTable";

import adminboxblueppl from "../../images/icons/adminboxblueppl.svg";
import adminboxgreentrend from "../../images/icons/adminboxgreentrend.svg";
import adminboxorangedowntrend from "../../images/icons/adminboxorangedowntrend.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxgreenplus from "../../images/icons/adminboxgreenplus.svg";
import adminboxorangeminus from "../../images/icons/adminboxorangeminus.svg";
import adminboxblackdeposit from "../../images/icons/adminboxblackdeposit.svg";
import adminboxbluetransfer from "../../images/icons/adminboxbluetransfer.svg";
//import { getTraderList } from "../../api/trading";
import Paginator from "../../components/Paginator";

const Traders = ({ showAdminNav }) => {
  const [data, setData] = useState(null);
  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  // const fetchTradersList = async () => {
  //   const { traders, pages }= await getTraderList({
  //     limit: currentPage * numRows,
  //     numRows: numRows,
  //   });
  //   setNumPages(pages > 0 ? pages : 1);
  //   if (currentPage > pages) {
  //     setCurrentPage(1);
  //   }
  //   setData(traders);
  // }

  useEffect(() => {
   // fetchTradersList();
  }, [currentPage])


  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Traders"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Traders"
              img={adminboxblueppl}
              data="12050"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Profitable Traders"
              img={adminboxgreentrend}
              data="200"
              info="Last 24 Hours -0.21%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Losing Traders"
              img={adminboxorangedowntrend}
              data="100"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Live Traders"
              img={adminboxgreen}
              data="130"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="A-Book"
              img={adminboxgreenplus}
              data="112"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="B-Book"
              img={adminboxorangeminus}
              data="232"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Demo"
              img={adminboxblackdeposit}
              data="765"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Trades"
              img={adminboxbluetransfer}
              data="130"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Traders Details</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={data}
              color6="blue"
            ></AdminTable>
            <Paginator
              totalPages={numPages}
              currentPage={currentPage}
              onPageChange={(page) => {
                if (page > numPages) {
                  setCurrentPage(numPages);
                } else if (page < 1) {
                  setCurrentPage(1);
                } else {
                  setCurrentPage(page);
                }
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Traders;
