import { React, useEffect, useState } from "react";
import styled from "styled-components";
// react-signature-pad
// https://www.npmjs.com/package/react-signature-pad
import SignaturePad from "react-signature-pad";
import FullscreenModal from "./FullscreenModal";

// react-signature-canvas
// https://github.com/agilgur5/react-signature-canvas
// import SignatureCanvas from "react-signature-canvas";

import deleteIcon from "../images/icons/delete.svg";
import "./SignatureModal.css";
import { isEmpty } from "lodash";
import toast, { Toaster } from "react-hot-toast";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const SignatureModal = ({
  closeModal,
  fullname,
  firstname,
  middlename,
  lastname,
  setfullname,
  setSigImage,
  signature,
  sigImage,
  signaturePad,
  save,
  clear,
  isEmpty,
  upload,
  setSigImageBase64,
  sigImageBase64,
  acceptedFileTypes,
  sigImageRef,
  maxSize,
  maxSizeInMB,
}) => {
  const [isMarkOnSignaturePad, setIsMarkOnSignaturePad] = useState(false);
  const [uploadSig, setUploadSig] = useState(false);
  const [draw, setDraw] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeSigModal = () => {
    setIsModalOpen(false);
  };
  const [fileError, setFileError] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = async (e, isBack) => {
    const file = e.target.files[0];
    debugger;
    if (file && file.size > maxSize) {
      setFileError(true);
      toast.error(
        `File size exceeds the limit (${maxSizeInMB} MB). Please choose a smaller file.`
      );
    } else {
      setFileError(false);
      setSigImageBase64(await toBase64(file));
      clear();
      setIsMarkOnSignaturePad(false);
    }
  };

  const resetFileUploadWidget = async () => {
    setSigImageBase64(null);
    setIsMarkOnSignaturePad(true);
  };

  const markOnSignaturePadDetected = async () => {
    if (!isMarkOnSignaturePad) {
      resetFileUploadWidget();
    }
  };

  const isMobile = () => {
    const mobileBreakpoint = 500; // Adjust this value according to your needs
    return window.innerWidth < mobileBreakpoint;
  };

  const notify = () =>
    toast((t) => (
      <span>
        Please Rotate Your Device!
        <button
          onClick={() => toast.dismiss(t.id)}
          className="p-2 ml-4 text-sm border border-gray-400 rounded-md bg-gray-500/20"
        >
          Dismiss
        </button>
      </span>
    ));

  return (
    <>
      {isMobile && (
        <div className="block opacity-1 sm:opacity-0">
          <Toaster
            toastOptions={{
              // Define default options
              className: "",
              duration: 10000,
              style: {
                background: "#363636",
                color: "#fff",
              },
            }}
          />
        </div>
      )}

      {isModalOpen && (
        <FullscreenModal onClose={closeSigModal}>
          <div className="sig-input md:w-[80%] sm:w-[90%] w-full mx-auto">
            <p>
              Draw your Signature <span onClick={clear}>Clear</span>
            </p>
            <div
              className="sigpad"
              onMouseDown={() => markOnSignaturePadDetected()}
            >
              {/* <SignatureCanvas
                penColor="black"
                canvasProps={{
                  className: "sigCanvas",
                }}
                backgroundColor="white"
              /> */}
              <SignaturePad ref={signaturePad}></SignaturePad>
            </div>
            <div className="flex gap-2">
              <button
                class="bg-white mt-4 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow "
                onClick={() => {
                  closeSigModal();
                  save();
                }}
              >
                Save Signature
              </button>
              <button
                class="bg-white mt-4 hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow "
                onClick={() => {
                  closeSigModal();
                }}
              >
                Close
              </button>
            </div>
            {sigImage && (
              <img
                src={sigImage}
                alt="my signiture"
                style={{
                  display: "block",
                  height: "100px",
                }}
                className="my-4"
              />
            )}
          </div>
        </FullscreenModal>
      )}
      <ModalContainer>
        <div className="cpm">
          <div className="signature-modal">
            <div className="cpm-container">
              <div className="cpm-top">
                <p className="text-sm sm:text-xl lg:w-full w-[80%]">
                  Confirm Your Full Name And Signature
                </p>
                <div className="close-cpm" onClick={() => closeModal(false)}>
                  <img src={deleteIcon} alt="" />
                </div>
              </div>
              <div className="sig-input">
                <p>Full Name</p>

                <input
                  type="text"
                  value={firstname + " " + lastname}
                  // onChange={(e) => setfullname(e.target.value)}
                />
              </div>
              <div className="flex w-full gap-4">
                <div
                  class="cursor-pointer flex-1 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-300 rounded-md shadow flex items-center gap-2  md:text-base text-sm"
                  onClick={() => {
                    notify();
                    setDraw(true);
                    setUploadSig(false);
                    openModal();
                  }}
                >
                  <div
                    className={`w-5 h-5 border flex-shrink-0 border-gray-300 rounded-full  ${
                      draw && "bg-[#008ffd]"
                    } `}
                  ></div>{" "}
                  Draw your Signature
                </div>
                <div
                  class="cursor-pointer flex-1 bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border flex items-center gap-2 border-gray-300 rounded-md shadow md:text-base text-sm"
                  onClick={() => {
                    setDraw(false);
                    setUploadSig(true);
                  }}
                >
                  <div
                    className={`w-5 h-5 border border-gray-300 flex-shrink-0 rounded-full  ${
                      uploadSig && "bg-[#008ffd]"
                    } `}
                  ></div>{" "}
                  Upload Signature
                </div>
              </div>
              {uploadSig && (
                <>
                  <div className="sig-input">
                    <p>
                      Sign your signature on a clear blank piece of paper as
                      seen on ID, take a picture and upload it.
                    </p>
                    {fileError && (
                      <p className="px-2 pb-2 text-center text-red-500">
                        File size exceeds the limit {maxSizeInMB} MB. Please
                        choose a smaller file.
                      </p>
                    )}
                    <input
                      type={"file"}
                      hidden
                      ref={sigImageRef}
                      multiple={false}
                      accept={acceptedFileTypes}
                      onChange={(e) => {
                        handleFileChange(e, false);
                      }}
                    />
                    <div
                      className="document-upload-box"
                      onClick={() => sigImageRef.current.click()}
                      // style={{
                      //   ...(sigImageBase64 != null &&
                      //     sigImageBase64.startsWith("data:image") && {
                      //       backgroundImage: `url("${sigImageBase64}")`,
                      //       backgroundRepeat: "no-repeat",
                      //       backgroundSize: "contain",
                      //     }),
                      // }}
                    >
                      <img src={upload} alt="" />
                      <div className="document-upload-box-title">
                        Upload Signature Here
                      </div>
                      <div className="document-upload-box-text">
                        Upload photo of your signature <br />
                        Supports:JPG, PNG
                      </div>
                      <div className="document-upload-box-btn">
                        Choose a File
                      </div>

                      {sigImageBase64 != null &&
                        sigImageBase64.startsWith("data:image") && (
                          <div className="flex justify-center pt-5">
                            <img
                              src={sigImageBase64}
                              alt=""
                              className="h-[200px] w-[300px] object-contain"
                            />
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="sig-info">
                    <p>
                      By selecting Adopt and Sign, I agree that the signature
                      and initials will be the electronic representation of my
                      signature and initials for all purposes when I (or my
                      agent) use them on documents, including legally binding
                      contracts - just the same as a pen-and-paper signature or
                      initial.
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      closeModal(false);
                      save();
                    }}
                  >
                    Adopt and Sign
                  </button>
                </>
              )}
              {draw && (
                <>
                  {sigImage && (
                    <img
                      src={sigImage}
                      alt="my signiture"
                      style={{
                        display: "block",
                        height: "100px",
                      }}
                      className="my-4"
                    />
                  )}
                  <div className="pt-4 sig-info">
                    <p>
                      By selecting Adopt and Sign, I agree that the signature
                      and initials will be the electronic representation of my
                      signature and initials for all purposes when I (or my
                      agent) use them on documents, including legally binding
                      contracts - just the same as a pen-and-paper signature or
                      initial.
                    </p>
                  </div>
                  <button
                    onClick={() => {
                      closeModal(false);
                      save();
                    }}
                  >
                    Adopt and Sign
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default SignatureModal;
