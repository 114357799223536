import React, { useRef, useState } from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import hide from "../images/icons/hide-grey.svg";
import copy from "../images/icons/copy.svg";
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";
import info from "../images/icons/info2.svg";
import proof from "../images/icons/uploadpaymentproof.svg";

// css in
import "./DepositForm.css";
import { depositWireTransfer } from "../api/wireTransfer";
import { toast } from "react-toastify";
import OverlayLoader from "./OverlayLoader";
import { FadeLoader } from "react-spinners";
import { useEffect } from "react";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const WireTransferModal = ({
  closeModal,
  openModal,
  valueAmt,
  method = null,
}) => {
  const acceptedFileTypes = "image/png, image/jpeg, application/pdf";
  const proofRef = useRef();
  const [proof, setProof] = useState();
  const [amount, setAmount] = useState(valueAmt);
  const [processing, setProcessing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const toBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setProof(file);
  };

  const submit = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setIsDisabled(true);
    setProcessing(true);

    const base64 = await toBase64(proof);
    const data = {
      proof: base64,
      amount,
    };
    const response = await depositWireTransfer(data);
    if (response.errors == null) {
      toast("Successfully submitted wire transfer request", {
        type: "success",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 3000);
    } else {
      setIsDisabled(false);
      setProcessing(false);
    }
  };

  return (
    <>
      <ModalContainer>
        <OverlayLoader loading={processing}></OverlayLoader>
        <div className="cpm">
          <div className="change-password-modal">
            <div className="cpm-container deposit-form">
              <div className="cpm-top">
                <h2>Transfer Money to Proceed</h2>
                <div className="close-cpm" onClick={() => closeModal(false)}>
                  <img src={deleteIcon} alt="" />
                </div>
              </div>
              <p>
                To make a deposit to your Apex Markets account, please follow
                the instructions below:
              </p>
              <div className="bank-info">
                <p>
                  To make a Bank Wire Transfer payment to Apex Markets, please
                  pay the ($1000 USD) plus any additional fees to the bank
                  account details below. Include your reference code so our team
                  can track your transaction.{" "}
                </p>
                <p>
                  The sender name and banking details MUST correspond with your
                  Apex Markets account profile. Wire Transfers can take 1-5
                  working days to be processed.{" "}
                </p>
                <p>Thank you for your cooperation!</p>
              </div>

              <div className="enter-amount">
                <div className="enter-amt-top-row">
                  <p>Amount</p>
                </div>
                <div className="amount-input">
                  {/* <input type="text" placeholder="$0.00" /> */}
                  <CurrencyInput
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "$0.00")}
                    id="input-example"
                    name="input-name"
                    placeholder="$0.00"
                    defaultValue={valueAmt}
                    prefix="$"
                    decimalsLimit={2}
                    onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                  />
                  <div className="select-currency-btn currency-type">
                    USD<BsChevronDown></BsChevronDown>
                    <div className="sc-content"></div>
                  </div>
                  {/* <button>USD</button> */}
                </div>
              </div>
              <div className="la-info">
                <div className="la-info-row bank-info-top">
                  <span>Apex Markets Bank Account Details</span>
                  <div className="la-info-details">
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="horizontal-line"></div>

                <div className="la-info-row">
                  <p>Bank Name:</p>
                  <div className="la-info-details">
                    The Currency Cloud Limited
                    <img src={copy} alt="" />
                  </div>
                </div>

                <div className="la-info-row">
                  <p>Bank Address:</p>
                  <div className="la-info-details">
                    12 Steward Street,
                    <br /> The Steward Building, <br /> London, E1 6FQ, GB
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Account Name:</p>
                  <div className="la-info-details">
                    APEX E-COMMERCE SERVICES LLC
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Account Address:</p>
                  <div className="la-info-details">
                    43260 GARFIELD RD,
                    <br />
                    MI CLINTON TWP 48038
                    <img src={copy} alt="" />
                  </div>
                </div>

                <div className="la-info-row">
                  <p>Account number: </p>
                  <div className="la-info-details">
                    GB91TCCL04140484734447
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Routing number:</p>
                  <div className="la-info-details">
                    TCCLGB3L
                    <img src={copy} alt="" />
                  </div>
                </div>

                <div className="la-info-row">
                  <p>Account Currency:</p>
                  <div className="la-info-details">
                    USD
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Purpose:</p>
                  <div className="la-info-details">
                    Payment for online software services
                    <img src={copy} alt="" />
                  </div>
                </div>

                <div className="la-info">
                  <div className="la-info-row bank-info-top">
                    <span>Payment Complete ?</span>
                  </div>
                  <div className="horizontal-line"></div>
                </div>
                <div
                  className="upload-payment-proof"
                  onClick={() => proofRef.current.click()}
                >
                  <img src={proof} alt="" />
                  <p>Upload payment proof. Supports: JPG, PNG, PDF</p>
                </div>
                <input
                  type={"file"}
                  hidden
                  ref={proofRef}
                  multiple={false}
                  accept={acceptedFileTypes}
                  onChange={handleFileChange}
                />
                <button
                  className="verification-continue-btn complete"
                  disabled={amount == null || proof == null || isDisabled}
                  onClick={submit}
                >
                  Submit Payment Proof
                </button>
              </div>
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default WireTransferModal;
