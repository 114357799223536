import React from "react";

const InputField = ({ label, value, onChange, required, name, type }) => {
  return (
    <div style={{ marginTop: "0.5rem", marginBottom: "1rem" }}>
      {label}: <br />
      <input
        type={type}
        value={value}
        name={name}
        onChange={onChange}
        required={required}
        className="p-[5px] w-[200px] border border-solid text-black rounded-lg border-[#ff6838]"
      />
    </div>
  );
};

export default InputField;
