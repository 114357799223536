import React, { useState } from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler,
} from "chart.js";

ChartJS.register(
  Title,
  Tooltip,
  LineElement,
  Legend,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);

const TradingHoursChart = () => {
  const [chartdata, setChartData] = useState({
    labels: [
      "12am",
      "1am",
      "2am",
      "3am",
      "4am",
      "5am",
      "6am",
      "7am",
      "8am",
      "9am",
      "10am",
      "11am",
      "12pm",
      "1pm",
      "2pm",
      "3pm",
      "4pm",
      "5pm",
      "6pm",
      "7pm",
      "8pm",
      "9pm",
      "10pm",
      "11pm",
    ],
    datasets: [
      {
        label: "",
        data: [
          10, 20, 30, 40, 30, 450, 800, 0, 10, 20, 10, 50, 60, 10, 50, 60, 20,
          10, 50, 60, 10, 50, 60, 10,
        ],
        backgroundColor: "#86cbff",
        borderColor: "#008FFD",
        tension: 0.4,
        fill: true,
        pointStyle: "circ",
        pointBorderColor: "blue",
        pointBackgroundColor: "#fff",
        showLine: true,
      },
    ],
  });
  return <Line data={chartdata}>Hello</Line>;
};

export default TradingHoursChart;
