import React from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const EditBankInfo = ({ closeModal }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Edit Bank info</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div className="bank-info-top">
              <span className="text-xl font-bold">Beneficiary Banking Details</span>
            </div>
            <div className="horizontal-line"></div>
            <div className="form-label-warning">
              <label htmlFor="CardHolde Name">Bank Account Holder Name</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                id="CardHolderName"
                name="CardHolderName"
                placeholder="Zesan Howlader"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Beneficiary Bank Name</label>
            </div>
            <div className="hide-pw">
              <input type="text" placeholder="Enter beneficiary bank name" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Beneficiary Bank Address</label>
            </div>
            <div className="hide-pw">
              <input type="text" placeholder="Enter beneficiary bank address" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">
                Beneficiary Bank BIC/SWIFT Code
              </label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter beneficiary bank BIC/SWIFT code"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">
                Beneficiary Bank Account Number
              </label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter beneficiary bank account number"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">
                Beneficiary Address On Bank Account
              </label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter beneficiary address on bank account"
              />
            </div>

            {/* ------------------------------- */}

            <div className="bank-info-top">
              <span className="text-xl font-bold">Intermediary Information (Optional)</span>
            </div>
            <div className="horizontal-line"></div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Intermediary Bank Name</label>
            </div>
            <div className="hide-pw">
              <input type="text" placeholder="Enter intermediary bank name" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Intermediary Bank Details</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter intermediary bank details"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Intermediary Bank Address</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter intermediary bank address"
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Intermediary Swift Code</label>
            </div>
            <div className="hide-pw">
              <input type="text" placeholder="Enter intermediary swift code" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Intermediary Routing/Sort Code</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                placeholder="Enter intermediary routing/sort code"
              />
            </div>

            <div className="df-save-card">
              <input type="checkbox" />
              <span className="label"></span>
              <p>Save Card info for further payment</p>
            </div>

            <button className="verification-continue-btn">Next</button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default EditBankInfo;
