import { clean } from "../util";
import { api } from "./index";
export const openAccount = async (data) => {
  const response = await api
    .post("trading/add_account", {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const depositToAccount = async (accountNumber, data) => {
  const response = await api
    .put(`trading/${accountNumber}`, {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const getAccounts = async (limit = null, offset = 0, arhive = null, type = "LIVE") => {
  const response = await api.get(`trading/accounts?offset=${offset}&limit=${limit}&arhive=${arhive}&type=${type}`).json();
  if (response != null && response.accounts != null) {
    return response.accounts;
  }
  return null;
};

export const setArchive = async (data) => {
  const response = await api
    .put("trading/set-archive", {
      json: data,
    })
    .json();
  console.log(response);
  if (response != null) {
    return response;
  }
  return null;
};

export const deleteDemoAccounts = async (data) => {
  const response = await api
    .put("trading/delete-demo-account", {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const transfer = async (data) => {
  const response = await api
    .put("trading/transfer", {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const changePassword = async (data) => {
  const response = await api
    .put("trading/change-password", {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const changeAccountLeverage = async (accountNumber, data) => {
  const response = await api
    .put(`trading/change-leverage/${accountNumber}`, {
      json: data,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};
