import React, { useEffect, useState } from "react";
// components
import AppBar from "../components/AppBar";
import ContactUsSelector from "../components/ContactUsSelector";
import { submitContactUsForm } from "../api/contactUs";
import ig from "../images/icons/instagram.svg";
import twitter from "../images/icons/twitter.svg";
import yticon from "../images/icons/youtubeicon.svg";

// css used
import "./Faq.css";
import useForm from "../hooks/useForm";
import { toast } from "react-toastify";

const ContactUs = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);

  const [selected, setSelected] = useState("");
  const Options = [
    "General enquiry",
    "Deposit",
    "Withdrawals",
    "Trading account",
    "Other",
  ];

  const initialFormValues = {
    email: "",
    subject: "",
    message: "",
  };
  const contactForm = useForm(initialFormValues);

  useEffect(() => {
    contactForm.setFormSubmissionValue(
      contactForm.formData.email &&
        contactForm.formData.subject &&
        contactForm.formData.message
    );
  }, [contactForm]);

  const submitForm = async (event) => {
    event.preventDefault();
    contactForm.setFormSubmissionValue(true);
    try {
      const response = await submitContactUsForm(contactForm.formData);
      if (response.errors == null) {
        toast.success("Successfully sent message");
        setSelected("");
        event.target.reset();
        contactForm.resetForm(initialFormValues);
      }
    } catch (err) {
      toast.error("An error has occurred while submitting the form");
    } finally {
      contactForm.setFormSubmissionValue(false);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Contact Us" />

        <ContactUsSelector />

        <div className="faq-container">
          <form action="#" onSubmit={submitForm} className="space-y-4">
            <div className="space-y-4">
              <label htmlFor="contact-email">Email:</label>
              <input
                type="email"
                placeholder="Enter your e-mail"
                id="contact-email"
                onChange={(e) =>
                  contactForm.setFieldData("email", e.target.value)
                }
              />
            </div>
            <div className="space-y-4">
              <label htmlFor="contact-subject">Subject:</label>
              <div className="contact-select">
                <select
                  required
                  id="contact-subject"
                  value={selected}
                  onChange={(e) => {
                    contactForm.setFieldData("subject", e.target.value);
                    setSelected(e.target.value);
                  }}
                >
                  <option value="" selected disabled hidden>
                    Select a Subject
                  </option>
                  {Options.map((option, index) => (
                    <option className="red" value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="space-y-4">
              <label htmlFor="contact-message">Write Message:</label>
              <textarea
                name=""
                id="contact-message"
                cols="30"
                rows="10"
                placeholder="Write your message here"
                onChange={(e) =>
                  contactForm.setFieldData("message", e.target.value)
                }
              ></textarea>
            </div>
            <button
              className="verification-continue-btn complete"
              disabled={!contactForm.submitting}
            >
              Send
            </button>
          </form>

          <div className="cinfo-row">
            <span>Follow Our Social Media for Daily Updates:</span>
            <div>
              <ul>
                <li>
                  <a
                    href="Https://www.Instagram.com/ApexMarkets"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ig} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="Https://www.Twitter.com/ApexMarketsLLC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/playlist?list=PL7OvhqzpeVOq7XsqxuXDyXKBZXkXIOF9u"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={yticon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
