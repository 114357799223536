import React, { useEffect, useState } from "react";
import styled from "styled-components";

// react-calendar
// https://www.npmjs.com/package/react-calendar
import Calendar from "react-calendar";
import moment from "moment";

import deleteIcon from "../images/icons/delete.svg";
import idfront from "../images/idfront.png";
import idback from "../images/idback.png";
import idselfie from "../images/idselfie.png";
import close from "../images/icons/closedocbtn.svg";

import "./VerificationReview.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 270%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const VerificationReview = ({
  setRegistrationHtml,
  formEmbedHtml,
  screenshot,
  documentToUpload,
  formBase64,
  datevalue,
  setdatevalue,
  dateOpen,
  setDateOpen,
  closeModal,
  firstname,
  setfirstname,
  lastname,
  setlastname,
  fullname,
  setfullname,
  address,
  number,
  setnumber,
  dobvalue,
  setdobvalue,
  dobOpen,
  setdobOpen,
  email,
  setemail,
  fullDate,
  selectedDocument,
  selectedCountry,
  setSelectedCountry,
  continueBtn,
  sigImage,
  male,
  setMale,
  female,
  setFemale,
  idnumber,
  setidnumber,
  line1,
  setline1,
  line2,
  setline2,
  state,
  setstate,
  zipcode,
  setzipcode,
  city,
  setcity,

  imageIdSignature,
  issuedatevalue,
}) => {
  const getRegistrationHtml = () => {
    const elementArr = window.document.querySelectorAll(".reg-info-box");
    // debugger;
    let html = "";
    for (let counter = 0; counter < elementArr.length; counter++) {
      const element = elementArr[counter];
      html += element.innerHTML;
    }
    return html;
  };

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="signature-modal big">
          <div className="cpm-container">
            <div className="cpm-top">
              <h2>KYC Verification Review</h2>

              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <p className="text-xs md:text-sm">
              To avoid delays during our KYC verification process, please make
              sure that the name, Government-Issued ID Number, Type, and
              signature on your Customer Agreement document all match what
              appears on your Government-Issued ID. <br /> <br /> Please also
              ensure that the information entered in the Customer Agreement form
              is consistent with what appears on your Government-Issued ID.
            </p>
            <br />
            <p className="text-xs text-red-600 md:text-sm">
              Do not upload blurry, low quality, expired documents or incorrect
              information. All information MUST match. Thank you for your
              cooperation.
            </p>
            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Registration info</p>
              </div>
            </div>
            <div className="reg-info-box">
              <div className="reg-info-row">
                <p>Full Name:</p>
                <span>
                  {firstname} {lastname}
                </span>
              </div>
              <div className="reg-info-row">
                <p>Email Address:</p>
                <span>{email}</span>
              </div>
              <div className="reg-info-row">
                <p>Phone Number:</p>
                <span>{number}</span>
              </div>

              <div className="reg-info-row">
                <p>Gender:</p>
                {male && (
                  <>
                    <span>Male</span>
                  </>
                )}
                {female && (
                  <>
                    <span>Female</span>
                  </>
                )}
              </div>
              <div className="reg-info-row">
                <p>Date of Birth:</p>
                <span>
                  {dobvalue ? moment(dobvalue).format("MMMM Do YYYY") : null}
                </span>
              </div>
              <div className="reg-info-row col">
                <p>Government-Issued ID:</p>
                <span>
                  <b>Type:</b> {selectedDocument} <br />
                  <b>Number:</b> {idnumber} <br />
                  <b>Issue Date:</b>{" "}
                  {issuedatevalue
                    ? moment(issuedatevalue).format("MMMM Do YYYY")
                    : null}
                  <br />
                  <b>Expiry Date:</b>{" "}
                  {datevalue ? moment(datevalue).format("MMMM Do YYYY") : null}
                </span>
              </div>

              <div className="reg-info-row col">
                <p>Address:</p>
                <span>
                  {line1}, {line2}, <br />
                  {city}, {state}, {zipcode} <br />
                  {selectedCountry}{" "}
                </span>
              </div>
            </div>
            {documentToUpload?.files != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Proof of Government-Issued ID</p>
                    <span>{selectedDocument}</span>
                  </div>
                </div>
                <div className="review-id">
                  {selectedDocument === "Passport" ? (
                    <div className="id-img center">
                      <img src={documentToUpload.files[0]} alt="" />
                      <p>Front Side</p>
                    </div>
                  ) : (
                    <>
                      {" "}
                      <div className="id-img">
                        <img src={documentToUpload.files[0]} alt="" />
                        <p>Front Side</p>
                      </div>
                      <div className="id-img">
                        <img src={documentToUpload.files[1]} alt="" />
                        <p>Back Side</p>
                      </div>
                    </>
                  )}
                </div>
              </>
            )}

            {screenshot != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Selfie Holding Government-Issued ID Beside Face</p>
                  </div>
                </div>
                <div className="review-selfie">
                  <img src={screenshot} alt="" />
                </div>
              </>
            )}

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Signature on Identification Document</p>
              </div>
              <div className="review-selfie">
                <img src={imageIdSignature} alt="" />
              </div>
            </div>

            {/* <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Step 5: Apex E-Commerce Services Customer Agreement</p>
              </div>
              <div
                class="verification-agreement-box"
                dangerouslySetInnerHTML={{ __html: formEmbedHtml }}
              />
            </div> */}

            <div className="flex flex-col justify-between gap-8 pt-8 md:flex-row ">
              <div
                className="bg-[#008FFD] rounded-full py-4 w-full flex justify-center text-white cursor-pointer verification-continue-btn complete"
                onClick={(e) => {
                  try {
                    setRegistrationHtml(getRegistrationHtml());
                    continueBtn(e);
                  } catch (err) {
                    window.rollbar.error(err);
                  }
                }}
              >
                Submit
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default VerificationReview;
