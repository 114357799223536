import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import moment from "moment";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

import { updateStatus } from "../../api/customer";

// css
// HistoryTable.css

const AdminClientsTable = ({
  refresh,
  data,
}) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const [depositData, setDepositData] = useState();

  const [loadingBlock, setLoadingBlock] = useState({
    customerId: null,
    state: false,
  });

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={'head-1'}>
            <th>Client #</th>
            <th>Name</th>
            <th>Email</th>
            <th>Country</th>
            <th>Date Joined</th>
            <th>Last Access</th>
            <th>Client Status</th>
            <th>Wallet Balance (USD)</th>
            <th>Total Deposits (USD)</th>
            <th>Affliate Earnings (USD)</th>
            <th>Rewards Earned (USD)</th>
            <th>Total Trading Accounts</th>
            <th>Total Trading Account Equity</th>
            <th>Introduced By</th>
            <th>Total Wallet Payout</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={'data-' + i}>
                <td>{row?.Customer_ID}</td>
                <td>{`${row?.Firstname} ${row?.Lastname}`}</td> 
                <td>{row?.Email}</td>
                <td>{row?.customer_address?.[0]?.country || row?.Country || '-'}</td>
                <td>{moment(row?.Date_Created).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{`${row?.customer_login_history?.[0]?.lastAccessed ? moment(row?.customer_login_history?.[0]?.lastAccessed).format("MMM D, YYYY hh:mm:ss A") : ''}`} <br /> {`${row?.customer_login_history?.[0]?.IP_Address ? 'IP: ' + row?.customer_login_history?.[0]?.IP_Address : ''}`}</td>
                <td>{row?.Status}</td>
                <td>{`$ ${row?.wallet?.Balance || 0}`}</td>
                <td>{`$ ${
                  parseFloat(row?.wallet?.wallet_transactions?.find((trx) => trx.Trans_Type_ID === 'M2PAY_DEPOSIT')?.totalTransactionAmount || 0) +
                  parseFloat(row?.wallet?.wallet_transactions?.find((trx) => trx.Trans_Type_ID === 'CARD_DEPOSIT')?.totalTransactionAmount || 0) +
                  parseFloat(row?.wallet?.wallet_transactions?.find((trx) => trx.Trans_Type_ID === 'WIRE_TRANSFER_DEPOSIT')?.totalTransactionAmount || 0)
                  }`}</td>
                <td>{`$ ${row?.wallet?.wallet_transactions?.find((trx) => trx.Trans_Type_ID === 'REWARDS_CONVERSION')?.totalTransactionAmount || 0}`}</td>
                <td>{row?.total_number_of_trading_accounts}</td>
                <td>{row?.total_trading_accounts_equity}</td>
                <td>{row?.Referral_Link ? row.Referral_Link.substring(row.Referral_Link.indexOf("-") + 1) : '-'}</td>
                <td>{row?.total_withdraws}</td>
                <td>
                  {/* {true && (
                    <button
                      disabled={true}
                      className="plain"
                      onClick={() => {
                        setShowManageModal(true);
                      }}
                    >
                      View
                    </button>
                  )} */}
                  {row?.Status !== "BLOCKED" && (
                    <span>
                      <button
                        className="plain"
                        onClick={async () => {
                          setLoadingBlock({
                            customerId: row.Customer_ID,
                            state: true,
                          });
                          await updateStatus(row.Customer_ID, "BLOCKED");
                          toast("Successfully updated", { type: "success" });
                          setLoadingBlock({ customerId: null, state: false });
                          refresh();
                        }}
                      >
                        {row?.Status === "BLOCKED" ? "UNBLOCKED" : "BLOCKED"}
                        {loadingBlock.state &&
                          loadingBlock.customerId === row.Customer_ID && (
                            <FontAwesomeIcon
                              className="fa-solid fa-sync fa-spin"
                              icon={faSpinner}
                            />
                          )}
                      </button>
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
          {showManageModal && (
            <>
              <ManageWireTransferModal
                closeModal={setShowManageModal}
                wireTransfer={depositData}
                refresh={refresh}
              />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default AdminClientsTable;
