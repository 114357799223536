import React from "react";
import styled from "styled-components";

const ModalInnerContainer = styled.div`
border-radius: 20px;
`;

const CloseButton = styled.img`
  padding-right: 5px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
`;

const ModelImage = styled.img`
  padding: 5px;
  border-radius: 20px;
  width: 55vw !important;
  height: 54vh !important;
`;

const ImageOpener = ({ deleteIcon, closeInnerModal, imageSrc, idCard }) => {
  return (
    <ModalInnerContainer>
      <div
        className={`cpm ${
          idCard == "front"
            ? "-ml-[60px]"
            : idCard == "back"
            ? "-ml-[100px]"
            : ""
        } `}
      >
        <div className="signature-modal w-[55vw]">
          <div className="flex justify-end" onClick={() => closeInnerModal("")}>
            <CloseButton src={deleteIcon} alt="Close" />
          </div>
          <ModelImage src={imageSrc} alt="Close" />
        </div>
      </div>
    </ModalInnerContainer>
  );
};

export default ImageOpener;
