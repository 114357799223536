import React, { useEffect, useState } from "react";
import styled from "styled-components";

import CurrencyInput from "react-currency-input-field";

import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";
// import edit from "../images/icons/green-edit.svg";
// import trash from "../images/icons/trashcan.svg";
import visa from "../images/icons/visa.svg";
// import selectempty from "../images/icons/select-empty.svg";
// import filledselect from "../images/icons/filled-select.svg";

import "./CardSelect.css";
import { getAvailableCards, withdrawToCard } from "../api/card";
import { toast } from "react-toastify";
import OverlayLoader from "./OverlayLoader";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { FadeLoader } from "react-spinners";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

export const WithdrawCardSelect = ({
  valueAmt,
  closeModal,
  refundAvailable,
}) => {
  const [availableCards, setAvailableCards] = useState([]);
  const [loadingAvailableCards, setLoadingAvailableCards] = useState(false);

  const [selectedCard, setSelectedCard] = useState();
  const [amount, setAmount] = useState(valueAmt);
  const [processing, setProcessing] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const withdraw = async () => {
    try {
      setProcessing(true);
      const response = await withdrawToCard({
        lastFourDigitsOfCard: selectedCard,
        amount: amount != null && amount !== "" ? amount : 0,
      });
      if (response.errors == null) {
        toast("Successfully withdrew the funds", { type: "success" });
        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };
  useEffect(() => {
    setAvailableCards(refundAvailable);
  }, []);
  return (
    <>
      {processing ? (
        <div className="absolute flex justify-center items-center h-[100vh] w-full z-[9999]">
          <FadeLoader color={color} loading={processing} size={80} />
        </div>
      ) : null}
      <ModalContainer>
        <div className="cpm">
          <div className="change-password-modal">
            <div className="cpm-container modal-form">
              <div className="cpm-top">
                <h2>Withdraw via Credit/Debit Card</h2>
                <div className="close-cpm" onClick={() => closeModal(false)}>
                  <img src={deleteIcon} alt="" />
                </div>
              </div>
              <div class="bank-info">
                <p>
                  To receive a Card Withdrawal from Apex Markets, please select
                  your Credit/ Debit Card account details below.
                </p>
                <p>
                  Card Withdrawals are processed instantly. However, based on
                  your bank, Card Withdrawals can take 1-5 business days to be
                  added to your Credit/Debit Card account.
                </p>
                <p>
                  If you encounter any delay, kindly contact your bank, notify
                  them about the card refund from Apex E-Commerce Services, and
                  they'll promptly assist you in locating and crediting the
                  transaction.
                </p>
                <p>Thank you for your cooperation!</p>
              </div>
              <div className="enter-amount">
                <div className="enter-amt-top-row">
                  <p>Amount</p>
                </div>
                <div className="amount-input">
                  {/* <input type="text" placeholder="$0.00" /> */}
                  <CurrencyInput
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "$0.00")}
                    id="input-example"
                    name="input-name"
                    placeholder="$0.00"
                    defaultValue={valueAmt}
                    prefix="$"
                    decimalsLimit={2}
                    onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                  />
                  <div className="select-currency-btn currency-type">
                    USD<BsChevronDown></BsChevronDown>
                    <div className="sc-content"></div>
                  </div>
                  {/* <button>USD</button> */}
                </div>
              </div>
              <div className="card-title">
                <p>Credit/Debit Card</p>
              </div>
              {loadingAvailableCards && (
                <>
                  Loading&nbsp;
                  <FontAwesomeIcon
                    className="fa-solid fa-sync fa-spin"
                    icon={faSpinner}
                  />
                </>
              )}
              {!loadingAvailableCards &&
                availableCards.map((card) => {
                  return (
                    <>
                      <div className="user-card" key={card.ID}>
                        {/* checkbox  */}
                        <div className="uc-left">
                          <input
                            type="checkbox"
                            checked={selectedCard === card.LastFourDigitsOfCard}
                            onChange={(e) => {
                              if (e.target.checked) {
                                setSelectedCard(card.LastFourDigitsOfCard);
                              }
                            }}
                          />
                          <span className="label"></span>
                          <img src={visa} alt="" />
                          <div className="flex flex-col ">
                            <span className="text-xs ">
                              XXXX - XXXX - XXXX - {card.LastFourDigitsOfCard}
                            </span>
                            {/* ----show the total amount that needs to be refunded to each card---- */}
                            {refundAvailable.length > 0 ? (
                              <p className="text-[#58bd7d]">
                                Refundable Amount : ${card.CardAvailableAmount}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}

              <button
                className="verification-continue-btn complete"
                onClick={() => withdraw()}
                disabled={processing}
              >
                {processing ? "Processing..." : "Next"}
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default WithdrawCardSelect;
