import React from "react";

// HISTORY TABLE CSS
import "./HistoryTable.css";

import download from "../images/icons/download.svg";
import moment from "moment";
const TransferHistoryTable = ({ data = null, onScroll }) => {
  const columns = [
    { field: "Date_Created", header: "Date" },
    { field: "from", header: "Transfer From" },
    { field: "to", header: "Transfer To" },
    { field: "Amount", header: "Amount" },
  ];
  return (
    <div className="table-container" onScroll={(e)=>onScroll(e)}>
      <table>
        <thead>
          <tr>{columns && columns.map((head) => <th>{head.header}</th>)}</tr>
        </thead>
        <tbody>
          {data &&
            data.map((row) => (
              <tr>
                {columns.map((col) => (
                  // className={`${row.class === "decline" && "decline"}`}
                  <td className={`${row[col.class] && row[col.class]} `}>
                    {col.field == "Date_Created"
                      ? row?.Date_Created
                        ? moment(row.Date_Created).format("DD/MM/YY")
                        : "Unknown"
                      : row[col.field]}
                  </td>
                ))}
                <td className="pdf-download"></td>
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No data to show </p>}
    </div>
  );
};

export default TransferHistoryTable;
