import React, { useState } from "react";
// components
import AppBar from "../components/AppBar";

import googleplay from "../images/icons/Google Play - eng.svg";
import applestore from "../images/icons/App Store - eng.svg";
import microsoft from "../images/icons/Microsoft Store - eng.svg";
import webtrader from "../images/icons/webtrader.svg";
import devices from "../images/devices.png";

// css used
import "./Faq.css";

const DownloadMetatrader = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Download MetaTrader" />

        <div className="py-10 text-center faq-container">
          <div className="flex flex-col items-center justify-center gap-4 text-center">
            <div className="">
              <h2 className="text-2xl sm:text-5xl">
                ApexMarkets’ MetaTrader 5 <br /> Trade Forex, Stock,
                Cryptocurrency & More!
              </h2>
            </div>
            <div className="main-secondary-text">
              <h4>Anytime, Anywhere with any device on ApexMarkets.</h4>
            </div>
            <div className="hero-btns download-options">
              <a
                href="https://download.mql5.com/cdn/mobile/mt5/android?server=ApexCapitalMarkets-Live"
                target="_blank"
                rel="noreferrer"
              >
                <img src={googleplay} alt="" />
              </a>
              <a
                href="https://download.mql5.com/cdn/mobile/mt5/ios?server=ApexCapitalMarkets-Live"
                target="_blank"
                rel="noreferrer"
              >
                <img src={applestore} alt="" />
              </a>
              <a
                href="https://download.mql5.com/cdn/web/apex.capital.markets/mt5/apexcapitalmarkets5setup.exe"
                target="_blank"
                rel="noreferrer"
              >
                <img src={microsoft} alt="" />
              </a>
              <a
                href="https://apexmarkets.io/web-trader.html"
                target="_blank"
                rel="noreferrer"
              >
                <img src={webtrader} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DownloadMetatrader;
