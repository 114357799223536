import { api } from "./index";

function filterParams(params) {
  const newParams = {};

  for (const [key, val] of Object.entries(params)) {
    if (!val || val === "null") {
      continue;
    }

    newParams[key] = val;
  }

  return newParams;
}

export const getAffiliateOverview = async (params) => {
  const response = await api
    .get("affiliate", { searchParams: filterParams(params) })
    .json();
  return response;
};

export const sendAffiliateEmailInvite = async (email) => {
  const response = await api
    .post(`affiliate/send-invite`, {
      json: {
        email,
      },
    })
    .json();
  return response;
};

export const getBonusPoints = async () => {
  const response = await api.get("affiliate/bonus-points").json();
  return response;
};


export const convertBonus = async (
  points,selectedToAccount='Wallet'
) => {
  const response = await api.post("affiliate/convert-bonus", {
    json: {
      points,
      selectedToAccount
    },
  })
    .json();
  return response;
};