import React from "react";
import { AiFillExclamationCircle } from "react-icons/ai";
import { Tooltip } from "react-tooltip";

const PersonalInfoTooltip = ({ name, message }) => {
  return (
    <div>
      <span className={name}>
        <AiFillExclamationCircle className="text-lg" />
      </span>
      <Tooltip
        anchorSelect={`.${name}`}
        place="right"
        style={{
          backgroundColor: "#FCFCFD",
          opacity: "100",
          color: "#777E90",
          borderRadius: "10px",
          width: "230px",
          fontSize: "14px",
          padding: "10px",
          lineHeight: "1.2",
        }}
      >
        <span className="font-[400]">{`  
        You can't change your  ${message} here. 
        Please contact the support team to request a  ${message} change
       `}</span>
      </Tooltip>
    </div>
  );
};

export default PersonalInfoTooltip;
