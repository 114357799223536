import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

const NotFoundPage = ({ allowedRoutes, showNav, noNav, showAdminNav }) => {
  const location = useLocation();
  const navigate = useNavigate();

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  noNav(true);
  showNav(false);
  showAdminNav(false);

  useEffect(() => {
    if (location.pathname && allowedRoutes.includes(location.pathname)) {
      navigate(location.pathname);
    }
  }, []);

  // useEffect(() => {
  //   if (location.pathname && !allowedRoutes.includes(location.pathname)) {
  //     setTimeout(() => {
  //       navigate("/");
  //     }, 2000);
  //   }
  // }, []);

  return (
    <>
      {location.pathname && !allowedRoutes.includes(location.pathname) && (
        <div>Page does not exist</div>
      )}
    </>
  );
};

export default NotFoundPage;
