import React, { useRef } from "react";
import Css from "./Paginator.module.css";
import { FaCaretLeft, FaCaretRight } from "react-icons/fa";

export default function Paginator({
  totalPages = 1,
  currentPage = 1,
  onPageChange = null,
}) {
  const pageInputRef = useRef(null);
  const startPage = calculateStartPage();

  function calculateStartPage() {
    let startPage = 0;

    if (totalPages >= 10 && totalPages - currentPage >= 3) {
      startPage = currentPage;
    }

    if (totalPages >= 10 && totalPages - currentPage < 3) {
      startPage = totalPages - 2;
    }

    if (totalPages < 10) {
      startPage = 1;
    }

    return startPage;
  }

  function emitPageChange(pageNumber) {
    if (onPageChange && !(pageNumber < 1) && !(pageNumber > totalPages)) {
      onPageChange && onPageChange(pageNumber);
    }
  }

  return (
    <div className={Css["paginator-container"]} style={{ flexWrap: "wrap" }}>
      <div className={Css["paginator-label"]}>
        Showing page {currentPage} of {totalPages} pages
      </div>

      <div className={Css["paginator-buttons"]} style={{ flexWrap: "wrap" }}>
        <nav className={Css["paginator-nav-button"]}>
          <FaCaretLeft
            style={{
              height: "3em",
              width: "2em",
            }}
            onClick={() => {
              if (currentPage !== 1) {
                emitPageChange(currentPage - 1);
                return;
              }

              emitPageChange(currentPage);
            }}
          />
        </nav>
        {Array(totalPages < 3 ? totalPages : 3)
          .fill(1)
          .map((_, index) => {
            return (
              <button
                className={`${Css["paginator-button"]} ${
                  startPage + index === currentPage
                    ? Css["paginator-button-selected"]
                    : ""
                }`}
                type="button"
                key={index}
                onClick={() => emitPageChange(startPage + index)}
              >
                {startPage + index}
              </button>
            );
          })}
        <nav
          className={Css["paginator-nav-button"]}
          onClick={() => {
            if (currentPage !== totalPages) {
              emitPageChange(currentPage + 1);
              return;
            }

            emitPageChange(currentPage);
          }}
        >
          <FaCaretRight
            style={{
              height: "3em",
              width: "2em",
            }}
          />
        </nav>
      </div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          debugger;
          emitPageChange(parseInt(pageInputRef.current.value));
        }}
      >
        <input
          ref={pageInputRef}
          type="number"
          name="page number"
          placeholder="Go to page"
          className={Css["paginator-search"]}
        />
      </form>
    </div>
  );
}
