import React, { useState } from "react";
import { Link, useNavigate, Outlet } from "react-router-dom";

// images / icons
import deposit from "../images/icons/deposit-black.svg";
import transfer from "../images/icons/transfer-black.svg";
import withdraw from "../images/icons/withdraw-black.svg";
import close from "../images/icons/red-close.svg";
import promoImg from "../images/promotion-icon.png";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import TransactionHistory from "../components/TransactionHistory";
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";

const Wallet = ({ showNav, noNav }) => {
  const customer = useSelector(selectCurrentCustomer);
  noNav(false);
  showNav(true);
  const navigate = useNavigate();

  const [show, setShow] = useState(true);

  return (
    <>
      <AppBar title="Accounts"></AppBar>
      <AccountsSelector></AccountsSelector>
      <Outlet></Outlet>
      {customer != null && (
        <div className="dashboard-container">
          <div className="deposit-page-grid">
            {/*--------------- Current Balance------------------------ */}
            <div class="deposit-funds">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#008FFD" }}
                  ></div>

                  <span>Wallet</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="enter-amount">
                <div className="wallet-amt-top-row">
                  <p className="dashboard-grid-title">Current Balance</p>
                </div>
                <div className="wallet-row2">
                  <div className="current-balance-amt">
                    {" "}
                    $ {customer?.wallet?.Balance ?? 0}
                  </div>
                  <div className="currency-type">USD</div>
                </div>
              </div>
              {show ? (
                <div className="promotion-box">
                  <div className="promo-icon">
                    <img src={promoImg} alt="" />
                  </div>
                  <div className="promotion-info">
                    <p>Trade Daily Earn Extra $100-$500 per month!</p>
                    <button onClick={() => navigate("/accounts/rewards")}>
                      Learn More
                    </button>
                  </div>
                  <div className="close-promo" onClick={() => setShow(false)}>
                    <img src={close} alt="" />
                  </div>
                </div>
              ) : null}

              <div className="cb-row3">
                <Link to="/deposit">
                  <div className="cb-btn" id="cb-deposit-btn">
                    Deposit Funds
                    <span>
                      <img src={deposit} alt="" />
                    </span>
                  </div>
                </Link>
                <Link to="/withdraw">
                  <div className="cb-btn" id="cb-withdraw-btn">
                    Withdraw Funds
                    <span>
                      <img src={withdraw} alt="" />
                    </span>
                  </div>
                </Link>
                <Link to="/transfer">
                  <div className="cb-btn" id="cb-transfer-btn">
                    Transfer Funds
                    <span>
                      <img src={transfer} alt="" />
                    </span>
                  </div>
                </Link>
              </div>
            </div>
            {/* ------------------Current Balance ----------------------*/}
            <div class="deposit-history">
              <TransactionHistory title="Transaction History"></TransactionHistory>
            </div>
            <div class="faq-section">
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#ffd166" }}
                ></div>
                <span>Payment Policy</span>
              </div>
              <div className="horizontal-line"></div>
              <div className="deposit-faq-text">
                <span>Security of Funds</span>
                <p>
                  Funding your Apex Markets account is quick, easy and secure.
                  Your funds are held in segregated client trust accounts and all
                  payments are processed using SSL (Secure Socket Layer)
                  technology which are encrypted to ensure security. All payment
                  information is confidential and is only used for the purpose of
                  funding your account with Apex Markets.
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Transaction Fees</span>

                <p>
                  Apex Markets does not charge any additional fees for deposits or
                  withdrawals. You however should be aware that fees may incur on
                  payments to and from some international banking institutions,
                  Credit/Debit Card and Cryptocurrency payment processors. Apex
                  Markets accepts no responsibility for any such transaction fees.
                </p>

                <p>
                  All payments made will be converted to USD and placed in your
                  Apex Markets Wallet. You can conduct transactions to and from
                  the wallet such as deposit and withdrawals. You can also conduct
                  internal transfers to and from their Apex Markets wallet to
                  their Apex Markets accounts under their profile.
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Third Party Payments</span>

                <p>
                  Apex Markets does not accept payments from third parties nor do
                  we make payments to third parties. Therefore we urge you to
                  ensure that all transactions including deposits into your Apex
                  Markets account comes directly from a bank account, Credit/Debit
                  Card in your name i.e The verified name on your Apex Markets
                  account must be the same as your Government Issued
                  Identification documents and Bank account, Credit or Debit Card
                  being used to make payment. Payments from Joint Bank Accounts,
                  Credit/Debit Cards are accepted if the Apex Markets account
                  holder is one of the parties on the Bank Account, Credit/Debit
                  Card.
                </p>

                <p>
                  Apex Markets will not be held liable for any costs incurred
                  resulting from client’s misuse, misconduct and or breach of
                  company and third party’s terms and service, Thereby, client’s
                  understand that in any such situation they will be the ones
                  liable and responsible to pay the costs.
                </p>
              </div>
              <div className="deposit-faq-text">
                <div>
                  We hope this answers your questions regarding our payment
                  policy. Please do not hesitate to contact us at{" "}
                  <span className="blue">
                    <a href="mailto:support@apexmarkets.io">
                      support@apexmarkets.io
                    </a>
                  </span>{" "}
                  if you have any further inquiries.
                </div>
              </div>
            </div>
          </div>
        </div>
      )
      }
    </>
  );
};

export default Wallet;
