export function clean(obj) {
    for (var propName in obj) {
        if (obj[propName] === null || obj[propName] === undefined || (typeof obj[propName] === "string" && obj[propName].length === 0)) {
            delete obj[propName];
        }
    }
    return obj
}

export const formatCardNumber = (firstSix, LastFour) => {
    let formattedNumber = "";
    formattedNumber =
      (firstSix ? firstSix.slice(0, 4) : "****") +
      " - " +
      (firstSix ? firstSix.slice(4, 6) : "**") +
      "** - ****" +
      LastFour;
    return formattedNumber;
};