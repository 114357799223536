import React from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import info from "../images/icons/info2.svg";

// css in
import "./DepositForm.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const DepositDetails = ({
  closeModal,
  detailsTitle,
  valueAmt,
  method,
  account,
}) => {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>{detailsTitle}</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="confirm-form">
              <div className="confirm-row">
                <p>Amount</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>
              <div className="confirm-row">
                <p>
                  Fee
                  <span>
                    <img src={info} alt="" />
                  </span>
                </p>
                <span>0.00</span>
              </div>
              <div className="confirm-row">
                <p>Total</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>
              <div className="confirm-row">
                <p>Status</p>
                <span className="green">Success</span>
              </div>
              <p>info</p>
              <div className="horizontal-line"></div>
              <div className="confirm-row">
                <p>Type</p>
                <span>Deposit</span>
              </div>
              <div className="confirm-row">
                <p>Transaction ID</p>
                <span>dgfgfgruygruyfgr</span>
              </div>{" "}
              <div className="confirm-row">
                <p>Currency</p>
                <span>USD</span>
              </div>
              <div className="confirm-row">
                <p>Processed Date</p>
                <span>{date}</span>
              </div>{" "}
              <div className="confirm-row">
                <p>Completed</p>
                <span>{date}</span>
              </div>
              <div className="confirm-row">
                <p>{method}</p>
                <span>Credit card(***65)</span>
              </div>
              <div className="confirm-row">
                <p>{account} </p>
                <span>Wallet</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DepositDetails;
