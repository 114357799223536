import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";

import LiveAccountBox from "../components/LiveAccountBox";
import OpenDemoAccount from "../components/OpenDemoAccount";
import AddFunds from "../components/AddFunds";
import AddDemoFunds from "../components/AddDemoFunds";
import ChangeInvestorPassword from "../components/ChangeInvestorPassword";
import PasswordChangedSuccess from "../components/PasswordChangedSuccess";
import {
  deleteDemoAccounts,
  getAccounts,
  setArchive,
} from "../api/trading";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import { BsFillArrowRightCircleFill } from "react-icons/bs";
import ChangeLeverage from "../components/ChangeLeverage";
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";
import ConfirmationModal from "../components/ConfirmationModel";

const DemoAccounts = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  const [loading, setLoading] = useState(false);
  const [accountNumberToDepositTo, setAccountNumberToDepositTo] = useState();
  const [demoAccounts, setDemoAccounts] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [openAddModal, setOpenAddModal] = useState(false);
  // change investor password
  const [openPassModal, setOpenPassModal] = useState(false);
  const [accountToChange, setAccountToChange] = useState();

  // BATCH SELECT lIVE ACCOUNTS
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [allAccountsSelected, setAllAccountsSelected] = useState(false);
  const [ismodalOpen, setIsModalOpen] = useState(false);
  const [demoAccountId, setDemoAccountId] = useState();
  const [closeSetting, setCloseSetting] = useState(false);

  const handleAccountSelection = (accountID) => {
    setAllAccountsSelected(false);
    let updatedSelection = [...selectedAccounts];

    if (updatedSelection.includes(accountID)) {
      updatedSelection = updatedSelection.filter((id) => id !== accountID);
    } else {
      updatedSelection.push(accountID);
    }

    setSelectedAccounts(updatedSelection);
  };

  const selectAllAccounts = () => {
    if (allAccountsSelected) {
      setSelectedAccounts([]);
    } else {
      const allAccountIds = demoAccounts.map((account) => account.id);
      setSelectedAccounts(allAccountIds);
    }
    setAllAccountsSelected(!allAccountsSelected);
  };

  // change master Password
  const [openMaster, setOpenMaster] = useState(false);
  let [color, setColor] = useState("#008ffd");
  let [showAll, setShowAll] = useState(false);

  // change Leverage
  const [openLeverage, setOpenLeverage] = useState(false);

  const type = ["APEX MT5 PRO DEMO"];
  const loadDemoAccounts = async (limit, archive = null) => {
    setLoading(true);
    setDemoAccounts([]);
    const accounts = await getAccounts(limit, 0, archive, "DEMO");
    if (accounts != null) {
      setDemoAccounts(accounts);
    }
    setLoading(false);
  };
  const refresh = () => {
    if (showAll) {
      loadDemoAccounts(null, "show_archive");
    } else {
      loadDemoAccounts(5);
    }
  };
  const setArchiveHandler = async (Account_ID, Archive) => {
    setLoading(true);
    const data = {
      Account_ID: Account_ID,
      Archive: Archive,
    };
    const response = await setArchive({ data });
    if (response) {
      refresh();
      if (!Archive) {
        toast("Successfully unarchived Account!", {
          type: "success",
        });
      } else {
        toast("Successfully archived Account!", {
          type: "success",
        });
      }
    }
  };

  const deleteDemoAccountsHandler = async (Account_ID, type) => {
    setCloseSetting(!closeSetting)
    setLoading(true);
    const data = {
      Account_ID: Account_ID,
      type,
    };
    const response = await deleteDemoAccounts({ data });
    if (response.message != null && response.errors == null) {
    setSelectedAccounts([]);
    setDemoAccountId();
    setIsModalOpen(false)
    refresh();
    toast("Successfully Deleted Account(s)!", { type: "success" });
    } else {
    setLoading(false);
    }
  };
  useEffect(() => {
    refresh();
  }, [showAll]);
  return (
    <>
      <AppBar title="Accounts"></AppBar>
      <AccountsSelector></AccountsSelector>
      <Outlet></Outlet>
      <div className="dashboard-container">
        {loading ? (
          <div className=" bg-white absolute flex justify-center  h-[100vh] w-full z-50">
            <FadeLoader color={color} loading={loading} size={80} />
          </div>
        ) : null}
        <div class="deposit-funds">
          {openModal && (
            <OpenDemoAccount
              title="Demo"
              title2="demo"
              type={type}
              openModal={openModal}
              closeModal={setOpenModal}
              deposit="Enter amount ( $100USD - $10000USD)"
            ></OpenDemoAccount>
          )}

          {openAddModal && (
            <AddDemoFunds
              openModal={openAddModal}
              closeModal={setOpenAddModal}
              accountNumber={accountNumberToDepositTo}
            ></AddDemoFunds>
          )}

          {openMaster && (
            <ChangeInvestorPassword
              openModal={openAddModal}
              closeModal={setOpenMaster}
              title="Change Master Password"
              type="main"
              accountToChange={accountToChange}
            ></ChangeInvestorPassword>
          )}

          {openPassModal && (
            <ChangeInvestorPassword
              openModal={openAddModal}
              closeModal={setOpenPassModal}
              title="Change Investor Password"
              type="investor"
              accountToChange={accountToChange}
            ></ChangeInvestorPassword>
          )}
          {openLeverage && (
            <ChangeLeverage
              openModal={openModal}
              closeModal={setOpenLeverage}
              accountToChange={accountToChange}
              refresh={refresh}
            ></ChangeLeverage>
          )}

          {ismodalOpen && (
            <ConfirmationModal
            setIsModalOpen={setIsModalOpen}
            setDemoAccountId={setDemoAccountId}
            deleteDemoAccountsHandler={deleteDemoAccountsHandler}
            Account_ID={demoAccountId? demoAccountId: selectedAccounts}
            />
          )}  

          <div className="flex-wrap justify-between gap-2 deposit-page-box-title">
            <div className="flex items-center">
              <div className="color-box" style={{ background: "#008FFD" }}></div>
              <span>MT5 Demo Accounts</span>
            </div>
            <div className="flex items-center gap-2 ">
              <button
                className="open-live-acc-btn"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Open Demo Account
              </button>
              {!showAll ? (
                <div
                  className="h-[56px] flex items-center justify-center px-4 py-2 font-semibold text-[#008ffd] bg-transparent border border-[#e5e7eb] rounded-[50px] cursor-pointer hover:bg-[#008ffd] hover:text-white hover:border-transparent"
                  onClick={() => setShowAll(true)}
                >
                  <p className="text-sm">Show All</p>{" "}
                  <BsFillArrowRightCircleFill className="mx-2"></BsFillArrowRightCircleFill>
                </div>
              ) : null}
            </div>
          </div>

          <div className="horizontal-line"></div>

          {/* <div className="live-acc-text">
              <p>You dont have any Demo Account</p>
              <span>Open your first demo account and start learing today</span>
              <button className="open-live-acc-btn">Open Demo Account</button>
            </div> */}

          <div className="flex items-center gap-2 ml-auto">
            {!showAll ? (
              <>
                {selectedAccounts.length > 0 ? (
                  <>
                    <button
                      className={`mt-2 cursor-pointer flex py-2 items-center justify-center text-xs md:text-sm font-500 rounded-md text-[#008ffd] hover:bg-[#008ffd] hover:text-white hover:border-transparent" bg-slate-200 border px-2 `}
                      onClick={() => setArchiveHandler(selectedAccounts, true)}
                    >
                      Archive All Selected
                    </button>
                    <button
                      className={`mt-2 cursor-pointer flex py-2 items-center justify-center text-xs md:text-sm font-500 rounded-md text-[#008ffd] hover:bg-[#008ffd] hover:text-white hover:border-transparent" bg-slate-200 border px-2 `}
                      onClick={() => {
                        setIsModalOpen(true);
                        setDemoAccountId()
                      }}
                    >
                      Delete All Selected
                    </button>
                  </>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}
            {!showAll ? (
              <button
                className={`mt-2 cursor-pointer flex py-2 items-center justify-center text-xs md:text-sm font-500 rounded-md text-[#008ffd] bg-slate-200 border w-[100px]  ${allAccountsSelected ? "outline outline-2 outline-[#008ffd]" : ""
                }`}
                onClick={selectAllAccounts}
              >
                Select All
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="live-acc-box-grid">
            {demoAccounts.map((account) => {
              return (
                <LiveAccountBox
                  title={account.trading_account_id}
                  openAddModal={openAddModal}
                  setAccountNumberToDepositTo={setAccountNumberToDepositTo}
                  setOpenAddModal={setOpenAddModal}
                  openPassModal={openPassModal}
                  setOpenPassModal={setOpenPassModal}
                  openMaster={openMaster}
                  setOpenMaster={setOpenMaster}
                  accType="APEX MT5 DEMO"
                  account={account}
                  setAccountToChange={setAccountToChange}
                  setArchive={setArchiveHandler}
                  Account_ID={account.id}
                  Archive={account.Archive}
                  handleSelection={() => handleAccountSelection(account.id)}
                  selected={selectedAccounts.includes(account.id)}
                  showAll={showAll}
                  openLeverage={openLeverage}
                  setOpenLeverage={setOpenLeverage}
                  setDemoAccountId={setDemoAccountId}
                  setIsModalOpen={setIsModalOpen}
                  closeSetting={closeSetting}
                ></LiveAccountBox>
              );
            })}
          </div>   
        </div>
      </div>
    </>
  );
};

export default DemoAccounts;
