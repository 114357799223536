import React, { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import TransferHistory from "../components/TransferHistory";
import CurrencyInput from "react-currency-input-field";

import ProcessingTransaction from "../components/ProcessingTransaction";
import TransferSuccess from "../components/TransferSuccess";
import DepositDetails from "../components/DepositDetails";

// icons
import { BsChevronDown } from "react-icons/bs";
import down from "../images/down-arrow.svg";

// CSS
import "./Deposit.css";
import { getAccounts, transfer } from "../api/trading";
import { toast } from "react-toastify";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";

import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";

const Transfer = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  const [loading, setLoading] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const [openModal, setOpenModal] = useState(false);

  const [openTransferTo, setOpenTransferTo] = useState(false);
  const [openTransferFrom, setOpenTransferFrom] = useState(false);

  const [selectedToAccount, setSelectedToAccount] = useState("");
  const [selectedFromAccount, setSelectedFromAccount] = useState("");
  const [walletData, setWalletData] = useState({});
  const [accountFromOptions, setAccountFromOptions] = useState([]);
  const [accountToOptions, setAccountToOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [destination, setDestination] = useState();
  // Keep track of amount entered
  const [valueAmt, setValueAmt] = useState(0);
  let [color, setColor] = useState("#008ffd");

  const customer = useSelector(selectCurrentCustomer);
  const [walletBalance, setWalletBalance] = useState();

  const [percentage, setPercentage] = useState(0);
  const [calculate, setCalculate] = useState(false);

  const handlePercentChange = (val) => {
    setCalculate(true);
    setPercentage(val);
    // calculatePercentage();
  };

  const loadLiveAccounts = async () => {
    setLoading(true);
    const accounts = await getAccounts();
    const walletBalance = customer?.wallet?.Balance || 0;
    const transferFromList = [];
    const transferList = [];

    if (accounts != null) {
      setWalletData({
        value: "Wallet",
        text: "Wallet (" + walletBalance + ")",
      });
      transferFromList.push({
        value: "Wallet",
        text: "Wallet (" + walletBalance + ")",
      });

      accounts.forEach((account) => {
        transferFromList.push({
          value: account.trading_account_id_numeric,
          text:
            account.trading_account_id_numeric + " (" + account.balance + ") ",
        });
        transferList.push({
          value: account.trading_account_id_numeric,
          text:
            account.trading_account_id_numeric + " (" + account.balance + ") ",
        });
      });
      setAccountList(transferList);
      setAccountFromOptions(transferFromList);
    }
    setLoading(false);
  };

  const getValueByText = (array, text) => {
    if (array && text) {
      const lst = array.find((arr) => arr.text === text);
      return lst.value;
    } else {
      return false;
    }
  };

  const getBalanceForSelectedAccount = (selectedAccountValue) => {
    const selectedAccount = accountList.find(
      (account) => account.value === selectedAccountValue
    );

    if (selectedAccount) {
      const balanceText = selectedAccount.text;
      // Extract the balance from the text (assuming it is enclosed in parentheses)
      const balanceMatch = balanceText.match(/\(([^)]+)\)/);
      if (balanceMatch && balanceMatch.length > 1) {
        const balance = parseFloat(balanceMatch[1].replace(",", ""));
        return balance;
      }
    }

    return 0; // Return 0 if the balance is not found or invalid
  };

  const handleFromAccountChange = (e) => {
    const selection = e.target.textContent;
    const selectedValue = getValueByText(accountFromOptions, selection);
    // Get the balance for the selected account and store it in a variable
    const selectedAccountBalance = getBalanceForSelectedAccount(selectedValue);
    setSelectedFromAccount(selectedValue);
    setSelectedToAccount("");
    setOpenTransferFrom(false);

    if (selectedValue !== "Wallet") {
      setDestination("WALLET");
      setAccountToOptions([walletData]);
      setWalletBalance(selectedAccountBalance);
    } else {
      setDestination("TRADING_ACCOUNT");
      setAccountToOptions(accountList);
      setWalletBalance(customer.wallet.Balance);
    }
  };

  const handleToAccountChange = (e) => {
    const selection = e.target.textContent;
    const getSelectedValue = getValueByText(accountToOptions, selection);

    setSelectedToAccount(getSelectedValue);
    setOpenTransferTo(false);
  };

  const submitTransfer = async () => {
    setIsDisabled(true);
    setLoading(true);
    const data = {
      destination,
      amount: valueAmt,
    };
    if (selectedFromAccount === "Wallet") {
      data.tradingAccountNumber = selectedToAccount;
    } else {
      data.tradingAccountNumber = selectedFromAccount;
    }
    try {
      const result = await transfer(data);
      if (result != null && result.errors == null) {
        toast("Successfully transferred funds", {
          type: "success",
        });
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    } catch (err) {
      setIsDisabled(false)
      toast("An error occurred! Please try again later", {
        type: "error",
      });
      console.error(err);
    } finally {
      setIsDisabled(false)
      setLoading(false);
    }
  };

  useEffect(() => {
    if (calculate) {
      const calculatedPercentage = (walletBalance * percentage) / 100; // Calculate % of the value
      setValueAmt(calculatedPercentage.toFixed(2));
      setCalculate(false);
    }
  }, [calculate, walletBalance, percentage]);

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    loadLiveAccounts();
  }, []);

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Transfer"></AppBar>

        <div className="dashboard-container">
          {openModal && (
            <TransferSuccess
              openModal={openModal}
              closeModal={setOpenModal}
              valueAmt={valueAmt}
              // for confirm deposit component
              confirmTitle="Confirm Deposit Details"
              // for confirm deposit
              method="Transfer From"
              account="Transfer To"
            ></TransferSuccess>
          )}
          <div class="deposit-page-grid ">
            {/* ------------TRANSFER FUNDS SECTION---------------- */}
            <div class="deposit-funds relative">
              {loading ? (
                <div className="absolute flex justify-center items-center h-[100vh] w-full z-50">
                  <FadeLoader color={color} loading={loading} size={80} />
                </div>
              ) : null}
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#008FFD" }}
                ></div>
                <span>Transfer Funds</span>
              </div>
              <div className="horizontal-line"></div>
              {/* <ClipLoader loading={loading} /> */}

              <div className="account-transfer-select">
                <div className="dropdowns">
                  <div class="custom-select">
                    <p>Transfer From</p>
                    <div className="select">
                      <span
                        onClick={() => setOpenTransferFrom(!openTransferFrom)}
                      >
                        <p>{selectedFromAccount}</p>
                        <img src={down} alt="" />
                      </span>
                      {openTransferFrom && (
                        <ul>
                          {accountFromOptions?.map((option) => {
                            return (
                              <li
                                key={option.value}
                                onClick={handleFromAccountChange}
                              >
                                {option.text}
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                  {selectedFromAccount != null && (
                    <>
                      <div class="custom-select">
                        <p>Transfer To</p>
                        <div className="select">
                          <span
                            onClick={() => setOpenTransferTo(!openTransferTo)}
                          >
                            <p>{selectedToAccount}</p>
                            <img src={down} alt="" />
                          </span>
                          {openTransferTo && (
                            <ul>
                              {accountToOptions?.map((option) => (
                                <li
                                  key={option.value}
                                  onClick={handleToAccountChange}
                                >
                                  {option.text}
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
              <div className="enter-amount">
                <div className="enter-amt-top-row">
                  <p>Enter Amount</p>
                  <span>
                    Available Balance - {walletBalance ? walletBalance : ""}
                  </span>
                </div>
                <div className="amount-section">
                  <div className="amount-input">
                    <CurrencyInput
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "$0.00")}
                      id="input-example"
                      name="input-name"
                      placeholder="$0.00"
                      prefix="$"
                      decimalsLimit={2}
                      value={valueAmt}
                      onValueChange={(value) => setValueAmt(value)}
                    />
                    <div className="select-currency-btn currency-type">
                      USD<BsChevronDown></BsChevronDown>
                      <div className="sc-content"></div>
                    </div>
                  </div>
                  <div className="amt-percentages">
                    <div className="percentages pt-[24px]">
                      <button
                        className="green"
                        onClick={() => handlePercentChange(25)}
                      >
                        25%
                      </button>
                      <button
                        className="green"
                        onClick={() => handlePercentChange(50)}
                      >
                        50%
                      </button>
                      <button
                        className="green"
                        onClick={() => handlePercentChange(75)}
                      >
                        75%
                      </button>
                      <button
                        className="green"
                        onClick={() => handlePercentChange(100)}
                      >
                        100%
                      </button>
                    </div>
                    <p>Processing Time (approx): Instant</p>
                  </div>
                </div>
              </div>
              <button
                className="verification-continue-btn complete"
                disabled={isDisabled}
                onClick={() => {
                  submitTransfer();
                }}
              >
                Continue
              </button>
            </div>
            {/* ------------TRANSFER FUNDS SECTION---------------- */}

            {/* --------TRANSFER HISTORY SECTION---------------- */}
            <div class="deposit-history">
              <TransferHistory title="Transfer History"></TransferHistory>
            </div>
            {/* --------TRANSFER HISTORY SECTION---------------- */}

            {/* --------FAQ SECTION---------------- */}
            <div class="faq-section">
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#ffd166" }}
                ></div>
                <span>Funds Transfer Notice</span>
              </div>
              <div className="horizontal-line"></div>
              <div className="deposit-faq-text">
                <p>
                  Apex Markets makes it quick and easy to transfer funds between
                  your Wallet and Trading Accounts in real-time. To do so,
                  simply select the trading account you wish to transfer funds
                  to or from, enter the amount, and click on the 'Continue'
                  button.
                </p>

                <p>
                  Funds can be transferred from your Wallet to your trading
                  account at any time. However, you are not allowed to transfer
                  funds from your trading account to your Wallet while you have
                  open positions.
                </p>
                <br />
                <div>
                  We hope this answers your questions regarding our funds
                  transfer policy. Please do not hesitate to contact us at{" "}
                  <span className="blue">
                    <a href="mailto:support@apexmarkets.io">
                      support@apexmarkets.io
                    </a>
                  </span>{" "}
                  if you have any further inquiries.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Transfer;
