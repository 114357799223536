import React from "react";
import "./FilterBox.css";

const FilterBox = () => {
  return (
    <div className="filter-box">
      <div className="filter-box-title">
        <p>Filter</p>
      </div>
      <div className="filter-box-options">
        <ul>
          <li>Date</li>
          <li>Client ID</li>
          <li>Name</li>
          <li>Invoice ID</li>
          <li>Deposit Method</li>
          <li>Status</li>
        </ul>
      </div>
    </div>
  );
};

export default FilterBox;
