import React from "react";

const OverviewBox = ({ title, img, data, data2, info, info2, color }) => {
  return (
    <div className="overview-box w-[300px]">
      <div className="ob-top">
        <p>{title}</p> <img className="w-8 h-8" src={img} alt="" />
      </div>
      <div className="ob-middle">
        {data}
        <span>{data2}</span>
      </div>
      <div className="ob-bottom">
        <span>{info2} </span> <p className={color}> {info}</p>
      </div>
    </div>
  );
};

export default OverviewBox;
