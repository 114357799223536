import React, { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
import ContactUsSelector from "../components/ContactUsSelector";
import { submitContactUsCallbackForm } from "../api/contactUs";
import ig from "../images/icons/instagram.svg";
import twitter from "../images/icons/twitter.svg";
import yticon from "../images/icons/youtubeicon.svg";

// css
import "./Faq.css";
import useForm from "../hooks/useForm";
import { toast } from "react-toastify";

const ContactInfo = ({ showNav }) => {
  showNav(true);
  const [date, setDate] = useState();
  const [time, setTime] = useState();

  const initialFormValues = {
    fullName: "",
    email: "",
    phoneNumber: "",
    date: "",
    time: "",
  };
  const contactForm = useForm(initialFormValues);

  useEffect(() => {
    contactForm.setFormSubmissionValue(
      Object.values(contactForm.formData).every((value) => value !== "")
    );
  }, [contactForm]);

  const onChange = (field, e) => {
    let { value } = e.target;
    if (field === "date") setDate(value);
    if (field === "time") {
      setTime(value);
      const [hours, minutes] = value.split(":").map(Number);
      const amPm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      value = `${String(formattedHours).padStart(2, "0")}:${String(
        minutes
      ).padStart(2, "0")} ${amPm}`;
    }
    contactForm.setFieldData(field, value);
  };

  const submitForm = async (event) => {
    event.preventDefault();
    contactForm.setFormSubmissionValue(true);
    try {
      const response = await submitContactUsCallbackForm(contactForm.formData);
      if (response.errors == null) {
        toast.success("Successfully sent message");
        event.target.reset();
        contactForm.resetForm(initialFormValues);
        setDate();
        setTime();
      }
    } catch (err) {
      toast.error("An error has occurred while submitting the form");
    } finally {
      contactForm.setFormSubmissionValue(false);
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Contact Us"></AppBar>
        <ContactUsSelector></ContactUsSelector>

        <div className="faq-container">
          <h1 className="faq-h1">
            Have a question or require specialist assistance? <br /> Our
            dedicated customer service team is here 24/7 to assist you. <br />{" "}
            Whether you're new to Apex Markets or have a question about your
            existing account, we can help.
          </h1>
          <form action="#" onSubmit={submitForm} className="space-y-4">
            <div className="space-y-4">
              <label>Full Name:</label>
              <input
                type="text"
                onChange={(e) => onChange("fullName", e)}
                placeholder="Enter Your Full Name"
              />
            </div>

            <div className="space-y-4">
              <label htmlFor="contact-email">Email:</label>
              <input
                type="email"
                placeholder="Enter your e-mail"
                id="contact-email"
                onChange={(e) => onChange("email", e)}
              />
            </div>

            <div className="space-y-4">
              <label>Phone Number:</label>
              <input
                type="tel"
                onChange={(e) => onChange("phoneNumber", e)}
                placeholder="Enter your phone number with your country code"
              />
            </div>

            <div className="flex-row">
              <label htmlFor="contact-email">Select Date and Time:</label>
              <span className="green">
                Based on Eastern Standard Time (EST)
              </span>
            </div>

            <div className="w-full flex justify-center gap-6 flex-wrap sm:flex-nowrap">
              <div className="input-with-icon w-full sm:w-[w-50%]">
                <input
                  className={`${!date && "text-[#777e90]"}`}
                  type="date"
                  onChange={(e) => onChange("date", e)}
                />
              </div>
              <div className="input-with-icon w-full sm:w-[w-50%]">
                <input
                  className={`${!time && "text-[#777e90]"}`}
                  type="time"
                  onChange={(e) => onChange("time", e)}
                />
              </div>
            </div>

            <button
              disabled={!contactForm.submitting}
              className="verification-continue-btn complete w-full"
            >
              Request A Callback
            </button>
          </form>

          <div className="cinfo-row">
            <span>Follow Our Social Media for Daily Updates:</span>
            <div>
              <ul>
                <li>
                  <a
                    href="Https://www.Instagram.com/ApexMarkets"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={ig} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="Https://www.Twitter.com/ApexMarketsLLC"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={twitter} alt="" />
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/playlist?list=PL7OvhqzpeVOq7XsqxuXDyXKBZXkXIOF9u"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={yticon} alt="" />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactInfo;
