import React, { useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import TableBtn from "../../components/admin/TableBtn";
import AdminLedgerTable from "../../components/admin/AdminLedgerTable";
import PriceFilter from "../../components/admin/PriceFilter";
import Paginator from "../../components/Paginator";
import FadeLoader from "react-spinners/FadeLoader";
import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxblue2 from "../../images/icons/adminboxblue2.svg";
import adminboxbluemoney from "../../images/icons/adminboxbluemoney.svg";
import adminboxbluetrophy from "../../images/icons/adminboxbluetrophy.svg";
import adminboxgreentick from "../../images/icons/adminboxgreentick.svg";
import adminboxgreensuccess from "../../images/icons/adminboxgreensuccess.svg";
import adminboxorangex from "../../images/icons/adminboxorangex.svg";
import adminboxblacktransfer from "../../images/icons/adminboxblacktransfer.svg";
import { getWireTransfers } from "../../api/wireTransfer";
import { getWalletTransactions, getWalletStats } from "../../api/data";
import { useEffect } from "react";
import { isCompositeComponent } from "react-dom/test-utils";
import { toast } from "react-toastify";

const Ledger = ({ showAdminNav }) => {
  const [transactions, setTransactions] = useState([]);

  const [filterParams, setFilterParams] = useState({
    search: "",
    transaction_method: "",
  });

  const [statsParams, setStatsParams] = useState({
    dateFilter: "This Week"
  });


  const [option, setOption] = useState();
  const [filter, setFilter] = useState();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const [stats, setStats] = useState(null);

  const loadWalletTransactions = async () => {
    setLoading(true);
    try {
      const { records, pages } = await getWalletTransactions({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      if (records != null) {
        setTransactions(records);
        setNumPages(pages > 0 ? pages : 1);
        if (currentPage > pages) {
          setCurrentPage(1);
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      setTransactions([]);
    }
  };

  const loadWalletStats = async () => {
    try {
      const { data } = await getWalletStats({
        ...statsParams,
      });
      setStats(data);
    } catch (err) {
      console.error(err);
      toast("Error loading stats", {
        type: "error",
      });
      setStats(null);

    }
  };

  useEffect(() => {
    loadWalletStats();
  }, [statsParams]);

  useEffect(() => {
    loadWalletTransactions();
  }, [currentPage, filterParams, filter]);

  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Ledger"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter
                setOption={(val) => { setStatsParams({ ...statsParams, dateFilter: val }); }}
                defaultValue={statsParams.dateFilter}
                list={["Today", "Yesterday", "This Week", "This Month", "This Year", "All"]}>
              </AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Wallet Amount"
              img={adminboxbluetrophy}
              data={`$ ${(stats?.totalWalletBalance?.balance || 0).toFixed(2)}`}
              info={`${(stats?.totalWalletBalance?.count || 0)}`}
              info2="Total: "
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Payouts"
              img={adminboxgreentick}
              data={`${stats?.totalPayoutCount || 0}`}
              info={`$ ${(stats?.totalPayout || 0).toFixed(2)}`}
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Transfer to Trading Accounts"
              img={adminboxblacktransfer}
              data={`$ ${(stats?.totalTransferToTrading || 0).toFixed(2)}`}
              info={`${stats?.totalTransferToTradingCount || 0}`}
              info2="Count : "
              color="green"
            ></OverviewBox>
                        <OverviewBox
              title="Total Transfer to Trading Accounts"
              img={adminboxblacktransfer}
              data={`$ ${(stats?.totalTransferFromTrading || 0).toFixed(2)}`}
              info={`${stats?.totalTransferFromTradingCount || 0}`}
              info2="Count : "
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Deposits"
              img={adminboxblue}
              data={`$ ${(stats?.totalDeposit || 0).toFixed(2)}`}
              info={`${stats?.totalPayoutCount || 0}`}
              info2="Total: "
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Affiliate Payout"
              img={adminboxgreensuccess}
              data={`$ ${(stats?.totalAffiliate || 0).toFixed(2)}`}
              data2="Am(EST)"
              info={`${stats?.totalAffiliateCount || 0}`}
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Highest Single Payout"
              img={adminboxblacktransfer}
              data={`$ ${(stats?.maxPayout?.Amount || 0).toFixed(2)}`}
              info={`${stats?.maxPayout?.Wallet?.Customer?.Firstname || ""} ${stats?.maxPayout?.Wallet?.Customer?.Lastname || ""}`}
              info2="By "
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Highest Single Deposit"
              img={adminboxorangex}
              data={`$ ${(stats?.maxDeposit?.Amount || 0).toFixed(2)}`}
              info={`${stats?.maxDeposit?.Wallet?.Customer?.Firstname || ""} ${stats?.maxPayout?.Wallet?.Customer?.Lastname || ""}`}
              info2="By "
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top-as-column">
              <div className="os-left">Wallet Ledger</div>
              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val?.trim());
                    setFilterParams({ ...filterParams, search: val?.trim() });
                  }}
                  filter={filter}
                  placeholder="Search by name or client id"
                ></TableSearch>
                <AdminFilter
                  classprop="admin-filter-ext long"
                  name="transaction_method"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      transaction_method:
                        val !== "Transaction Method" ? val : "",
                    });
                  }}
                  option={option}
                  list={[
                    "Transaction Method",
                    "TRADING",
                    "AFFLIATE",
                    "REWARDS",
                    "CRYPTO",
                    "WIRE TRANSFER",
                    "CARD",
                    "BALANCE CORRECTION",
                    "FTD CONVERSION BONUS",
                  ]}
                  defaultValue="Transaction Method"
                ></AdminFilter>
                <div className="flex-container price-seperator">
                  <PriceFilter
                    setValue={(val) => {
                      setMinValue(val);
                      setFilterParams({ ...filterParams, minAmount: val });
                    }}
                    value={minValue}
                    placeholder="Min. Amount"
                  ></PriceFilter>
                  <div> - </div>
                  <PriceFilter
                    setValue={(val) => {
                      setMaxValue(val);
                      setFilterParams({ ...filterParams, maxAmount: val });
                    }}
                    value={maxValue}
                    placeholder="Max. Amount"
                  ></PriceFilter>
                  <AdminFilter
                    classprop="admin-filter-ext long"
                    name="dateRange"
                    setOption={(val) => {
                      setOption(val);
                      setFilterParams({
                        ...filterParams,
                        dateRange:
                          val !== "Transaction Date"
                            ? val?.replace(" ", "_")?.toLocaleUpperCase()
                            : "",
                      });
                    }}
                    option={option}
                    list={["Transaction Date", "Last Week", "3 Months"]}
                    defaultValue="Transaction Date"
                  ></AdminFilter>
                </div>
              </div>
            </div>
            {loading && (
              <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            )}
            {!loading && (
              <>
                <AdminLedgerTable
                  data={transactions}
                  refresh={loadWalletTransactions}
                ></AdminLedgerTable>
                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Ledger;
