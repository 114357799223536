import dashboard from "../../images/icons/dashboard.svg";
import accounts from "../../images/icons/accounts.svg";
import deposit from "../../images/icons/deposit.svg";
import transfer from "../../images/icons/transfer.svg";
import withdraw from "../../images/icons/withdraw.svg";
import affiliate from "../../images/icons/affiliate.svg";
import leaderboards from "../../images/icons/leaderboards.svg";
import faq from "../../images/icons/faq.svg";
import contact from "../../images/icons/contact.svg";
import legal from "../../images/icons/legaldocuments.svg";
import home from "../../images/icons/home.svg";
import tv from "../../images/icons/tv.svg";

// ----------------------SIDEBAR LINKS------------------
export const AdminSidebarData = [
  {
    title: "Overview",
    icon: home,
    link: "/admin/overview",
    cName: "sidebar-title",
  },
  {
    title: "Broker Analytics",
    icon: accounts,
    link: "/admin/broker-analytics",
    cName: "sidebar-title",
  },
  {
    title: "Revenue",
    icon: tv,
    link: "/admin/revenue",
    cName: "sidebar-title",
  },
  {
    title: "Invoicing",
    icon: transfer,
    link: "/admin/invoicing",
    cName: "sidebar-title",
  },
  {
    title: "Deposits",
    icon: deposit,
    link: "/admin/deposits",
    cName: "sidebar-title",
  },
  {
    title: "Ledger",
    icon: transfer,
    link: "/admin/ledger",
    cName: "sidebar-title",
  },
  {
    title: "Payouts",
    icon: withdraw,
    link: "/admin/payouts",
    cName: "sidebar-title",
  },
  {
    title: "Clients",
    icon: leaderboards,
    link: "/admin/clients",
    cName: "sidebar-title",
  },
  {
    title: "Traders",
    icon: faq,
    link: "/admin/traders",
    cName: "sidebar-title",
  },
  {
    title: "Affiliate Metrics",
    icon: contact,
    link: "/admin/metrics",
    cName: "sidebar-title",
  },
  {
    title: "KYC Documents",
    icon: legal,
    link: "/admin/documents",
    cName: "sidebar-title",
  },
  {
    title: "Failed Audit",
    icon: legal,
    link: "/admin/fail-audit",
    cName: "sidebar-title",
  },
  {
    title: "App Credentials",
    icon: legal,
    link: "/admin/app-credentials",
    cName: "sidebar-title",
  },
  {
    title: "Marketing",
    icon: legal,
    link: "/admin/marketing",
    cName: "sidebar-title",
  },
  {
    title: "Customer Support",
    icon: legal,
    link: "/admin/customer-support",
    cName: "sidebar-title",
  },
  {
    title: "Chargeback Response",
    icon: legal,
    link: "/admin/chargeback-response",
    cName: "sidebar-title",
  },
  {
    title: "Audit Logs",
    icon: legal,
    link: "/admin/audit-logs",
    cName: "sidebar-title",
  },
  {
    title: "System Logs",
    icon: legal,
    link: "/admin/system-logs",
    cName: "sidebar-title",
  },
];
