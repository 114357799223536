import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import moment from "moment";

// css
// HistoryTable.css

const AdminTable = ({
  refresh,
  data,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const [wireTransfer, setWireTransfer] = useState();
  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={'head-1'}>
            <th>Client #</th>
            <th>Name</th>
            <th>Country</th>
            <th>Date Joined</th>
            <th>Last Access</th>
            <th>Total Trading Account</th>
            <th>Initial Balance (USD)</th>
            <th>Credit</th>
            <th>Equity</th>
            <th>% Gain</th>
            <th>Profit Expectancy Ratio</th>
            <th>Total Trades Taken</th>
            <th>Total Trading Days</th>
            <th>AVG Trading Style</th>
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((row, i) => {
            return (
              <tr key={'data-' + i}>
                <td>{row?.Customer_ID}</td>
                <td>{`${row?.Firstname} ${row?.Lastname}`}</td>
                <td>{row?.customer_address[0]?.country}</td>
                <td>{moment(row?.Date_Created).format("YYYY-MM-DD")}</td>
                <td>
                  {moment(row?.customer_login_history?.Date_Created).format("YYYY-MM-DD")}
                  {row?.customer_login_history?.IP_Address}
                </td>
                <td>{row?.tradingAccounts?.length}</td>
                <td>{
                  row?.tradingAccounts?.reduce((accumulator, object) => {
                    return accumulator + (object?.account?.Balance || 0);
                  }, 0)
                }</td>
                <td>{``}</td>
                <td
                  onClick={() => {
                    setWireTransfer(row);
                    setShowManageModal(true);
                  }}
                >
                  Manage
                </td>
              </tr>
            );
          })}
          {showManageModal && (
            <>
              <ManageWireTransferModal
                closeModal={setShowManageModal}
                wireTransfer={wireTransfer}
                refresh={refresh}
              />
            </>
          )}

          {/* {Object.values(data).map((obj, index) => (
            <tr key={index}>
              {Object.values(obj).map((value, index2) => (
                <td
                  className={`${index2 === 0 && color6} || ${
                    index2 === 1 && color
                  } || ${index2 === 3 && color2} || ${
                    index2 === 3 && value === "Verified" && "green"
                  } || ${index2 === 3 && value === "Rejected" && "red"} || ${
                    index2 === 4 && color3
                  } ${index2 === 4 && value === "Rejected" && "red"}  || ${
                    index2 === 4 && value === "Approved" && "green"
                  } || ${index2 === 5 && color4} || ${
                    index2 === 6 && color5
                  } || ${index2 === 6 && value === "Success" && "green"} || ${
                    index2 === 6 && value === "Decline" && "red"
                  } || ${index2 === 6 && value === "Proceeding" && "blue"} || ${
                    index2 === 7 && "admin-flex"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${index2 === 8 && value[0] === "+" && "green"} || ${
                    index2 === 8 && value[0] === "-" && "red"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  } ${index2 === 9 && "admin-flex"} || ${
                    index2 === 9 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${
                    index2 === 9 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  }`}
                  key={index2}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
};

export default AdminTable;
