import { api } from "./index";


export const affiliateData = async (month = false) => {
    const response = await api.get(`leaderboard/affiliate?months=${month}`, { months: month }).json();
    return response;
};

export const traderData = async (month = false) => {
    const response = await api.get(`leaderboard/trader?months=${month}`, { months: month }).json();
    console.log(response)
    return response;
};