import React, { useState, useEffect } from "react";
import AppBar from "../components/AppBar";
// components
import DepositForm from "../components/DepositForm";
import DeleteCardInfo from "../components/DeleteCardInfo";
import CardSelect from "../components/CardSelect";
import EditCardInfo from "../components/EditCardInfo";
import ConfirmDeposit from "../components/ConfirmDeposit";
import ProcessingTransaction from "../components/ProcessingTransaction";
import DepositSuccess from "../components/DepositSuccess";
import PaymentFailed from "../components/PaymentFailed";
import WireSubmitted from "../components/WireSubmitted";
import DepositDetails from "../components/DepositDetails";

import CryptoModal from "../components/CryptoModal";
import WireTransferModal from "../components/WireTransferModal";

import DepositHistoryTable from "../components/DepositHistoryTable";
import { depositTableData } from "../components/DepositHistoryData";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";

// CSS
import "./Deposit.css";
import { confirmPendingCharge } from "../api/card";
import { useNavigate } from "react-router-dom";

const PaymentProcessing = ({ showNav }) => {
  const navigate = useNavigate();
  const getQueryData = () => {
    let url = window.location.toString();
    let params = url?.split("?")[1]?.split("&");
    let obj = {};
    params?.forEach((el) => {
      let [k, v] = el?.split("=");
      obj[k] = v.replaceAll("%20", " ");
    });
    return obj;
  };
  const confirmPayment = async () => {
    debugger;
    const data = getQueryData();
    if (data.lastFourDigitsOfCard != null && data.orderNo != null) {
      const response = await confirmPendingCharge(
        data.lastFourDigitsOfCard,
        data.orderNo
      );
      if (response.errors == null) {
        window.location.href = "/";
      }
    }
  };
  useEffect(() => {
    confirmPayment();
  }, []);
  return (
    <>
      <p>Processing payment...please don't close this window.</p>
    </>
  );
};

export default PaymentProcessing;
