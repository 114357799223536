import React, { useState, useEffect } from "react";
import KycStatusTable from "../components/kyc/KycStatusTable";
import AppBar from "../components/AppBar";
import { azureUploadImage, getUserDocuments } from "../api/document";
import { toast } from "react-toastify";
import VerificationAgreement from "../components/kyc/VerificationAgreement";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import {
  getCustomerIdentification,
  submitVerificationForm,
} from "../api/customer";
import moment from "moment";
import AccountSettingsLinks from "../components/AccountSettingsLinks";
import VerificationBar from "../components/VerificationWarning";
import Loader from "../components/Loader";

const KycVerificationStatus = ({ showNav, noNav }) => {
  showNav(true);
  noNav(false);
  const customer = useSelector(selectCurrentCustomer);
  const { Status } = customer;
  const [customerIndentification, setCustomerIndentification] = useState({});
  const [signature, setSignature] = useState();
  const [selfie, setSelfie] = useState();
  const [govtId, setGovtId] = useState();
  const [openAgreement, setOpenAgreement] = useState(false);
  const [allDocsVerified, setAllDocsVerified] = useState(true);
  const [processing, setProcessing] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [formpdf, setFormPdf] = useState();
  const [formHtml, setFormHtml] = useState("");
  const [address, setAddress] = useState("");

  const [customerData, setCustomerData] = useState({
    firstName: customer.Firstname,
    lastName: customer.Lastname,
    dob: customer.DOB,
    gender: customer.gender,
    email: customer.Email,
    postalCode: customer.Postal_Code,
    telephoneCell: customer.Telephone_Cell,
    country: "",
    city: "",
    state: "",
    street1: "",
    street2: "",
    idNumber: "",
    idType: "",
    idExpiry: "",
  });

  useEffect(() => {
    setCustomerData((prevState) => ({
      ...prevState,
      country: address.country,
      city: address.city_town_district,
      state: address.state_parish_pronvince,
      street1: address.line1,
      street2: address.line2,
      idNumber: customerIndentification.id_number,
      idExpiry: moment(customerIndentification?.id_expiry)?.format(
        "MM-DD-YYYY"
      ),
      idType: customerIndentification?.identification_type
        ?.replace(/\W/g, "")
        ?.toLowerCase(),
    }));
  }, [customerIndentification, address]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (documents.length) {
      const getindentificationData = async () => {
        if (allDocsVerified) {
          try {
            const data = await getCustomerIdentification();
            if (data.customer) {
              setCustomerIndentification(data.customer);
            }
            if (data.customerAddress) {
              setAddress(data.customerAddress);
            }
          } catch (error) {
            console.log(error);
            toast.error("Something went wrong, please try again later");
          }
        }
      };
      getindentificationData();
    }
  }, [allDocsVerified, documents, refresh]);

  useEffect(() => {
    (async () => {
      try {
        const response = await getUserDocuments();
        if (response.length >= 3) {
          setDocuments(response);
        } else if (response.length < 3) {
          toast.error("Please Submit all of your documents");
          setTimeout(() => {
            window.location.href = "/kyc-verification";
          }, 3000);
        } else {
          toast.error("Something went wrong, please try again later");
          window.location.href = "/";
        }
      } catch (error) {
        console.error(error);
      }
    })();
  }, [refresh]);

  const hanldeSubmit = async () => {
    setProcessing(true);
    let formPdfUrl;
    try {
      if (formpdf) {
        const response = await azureUploadImage(formpdf);
        if (response.imageUrl) {
          formPdfUrl = response.imageUrl;
        } else {
          toast.error("something went wrong, please try again");
          formPdfUrl = "";
        }
      }
    } catch (error) {
      toast.error("something went wrong, please try again");
      formPdfUrl = "";
    } finally {
      setProcessing(false);
    }

    if (formPdfUrl) {
      setProcessing(true);
      const obj = {
        uploadId: documents[0].upload_id,
        formHtml,
        formpdf: formPdfUrl,
      };
      try {
        const response = await submitVerificationForm(obj);
        if (response.message) {
          toast.success("Form submitted successfully");
          setTimeout(() => {
            window.location.href = "/";
          }, 1000);
        } else {
          setFormHtml("");
        }
      } catch (error) {
        setFormHtml("");
        toast.error("something went wrong, please try again later");
      } finally {
        setProcessing(false);
      }
    }
  };

  const handleRefresh = () => {
    setRefresh(!refresh);
  };

  const isAgreeButtonDisabled = Object.values(customerData).some(
    (val) => val === "" || val === null || val === undefined
  );

  return (
    <div className="dashboard-page">
      <AppBar title="KYC Verification" />
      <div className="dashboard-container">
        <AccountSettingsLinks />
        <div className="security-line"></div>
        <div className="dashboard-container min-h-[100vh]">
          {!allDocsVerified && (
            <div className="text-right pt-5 -mb-14 pr-2 z-10 relative">
              <button
                disabled={allDocsVerified}
                className={`w-auto m-2 px-4 py-2 font-bold text-white rounded-md  ${
                  allDocsVerified ? "bg-blue-300" : "bg-blue-600 cursor-pointer"
                }`}
                onClick={handleRefresh}
              >
                Refresh
              </button>
            </div>
          )}
          {Status !== "VERIFIED" &&
            !allDocsVerified &&
            documents.length == 3 && (
              <div className="text-center p-10 mt-5 flex justify-center flex-col items-center">
                <p className="text-[18px] ">
                  {/* Automatic KYC verification for your document has failed.  */}
                  "You will be verified by our agent within 2 business days."
                </p>
              </div>
            )}
          <div>
            <KycStatusTable
              customer={customer}
              documents={documents}
              setAllDocsVerified={setAllDocsVerified}
              setSignature={setSignature}
              setSelfie={setSelfie}
              setGovtId={setGovtId}
              refresh={refresh}
            />
          </div>
          {!allDocsVerified && documents.length == 3 && (
            <div className="text-center p-10 flex justify-center flex-col items-center">
              <p className="text-[18px] ">
                "Once all your documents are verified, you will see a customer
                agreement form."
              </p>
            </div>
          )}

          {Status != "VERIFIED" && allDocsVerified && documents.length == 3 && (
            <div className="text-center p-10 flex justify-center flex-col items-center">
              <p className="text-[18px] ">
                "80% of your KYC is completed. Please accept the customer
                agreement form to proceed with verification."
              </p>
              {!openAgreement && (
                <button
                  className={`w-[15%] mt-5 px-4 flex-1 py-2 font-bold text-white bg-blue-600 rounded-md cursor-pointer`}
                  onClick={() => setOpenAgreement(true)}
                >
                  Preview
                </button>
              )}

              {processing ? (
                <Loader message="Processing..." />
              ) : (
                openAgreement &&
                customerIndentification &&
                signature && (
                  <div className="md:w-[700px] mx-auto w-auto text-black relative mt-5">
                    {isAgreeButtonDisabled && (
                      <VerificationBar personalInformation kycWarning />
                    )}
                    <div className="relative h-auto p-4 overflow-visible bg-white border-2 rounded-md border-gray-600/20">
                      <VerificationAgreement
                        customerData={customerData}
                        setCustomerData={setCustomerData}
                        customer={customer}
                        setFormPdf={setFormPdf}
                        setFormHtml={setFormHtml}
                        signature={signature}
                        selfie={selfie}
                        govtId={govtId}
                        customerIndentification={customerIndentification}
                      />

                      <div className="flex justify-between w-full gap-4 mt-4 text-base sm:w-auto sm:ml-auto">
                        <button
                          className="flex items-center justify-center flex-1 px-4 py-2 font-bold border-2 border-solid rounded-md cursor-pointer border-slate-200"
                          onClick={() => setOpenAgreement(false)}
                        >
                          Cancel
                        </button>
                        <button
                          className={`w-full px-4 flex-1 py-2 font-bold text-white rounded-md ${
                            isAgreeButtonDisabled ||
                            !selfie ||
                            !govtId ||
                            !signature ||
                            !formpdf
                              ? "bg-blue-300 cursor-not-allowed"
                              : "bg-blue-600 cursor-pointer"
                          }`}
                          onClick={hanldeSubmit}
                          disabled={
                            isAgreeButtonDisabled ||
                            !selfie ||
                            !govtId ||
                            !signature ||
                            !formpdf
                          }
                        >
                          I Accept
                        </button>
                      </div>
                    </div>
                    <div className="my-4 text-sm text-center ">
                      By clicking ‘I accept’ you are agreeing that you have read
                      and understood our customer services agreement
                    </div>
                  </div>
                )
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default KycVerificationStatus;
