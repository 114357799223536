import React, { useEffect, useRef } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";

import qrcode from "../images/qrcodenew.png";

const DownloadMetaTrader = ({ closeModal, ref }) => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const navigate = useNavigate();

  const refOne = useRef(null);
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  });

  const handleClickOutside = (e) => {
    if (!refOne?.current?.contains(e.target)) {
      closeModal(false);
    }
  };

  return (
    <div className="download-mt-modal" ref={refOne}>
      <div className="download-mt-container">
        <div className="download-mt">
          <p>Scan to Download MT5 App IOS & Android</p>
          <div className="overflow-hidden qr-code">
            <img src={qrcode} alt="" />
          </div>
          <button onClick={() => navigate("/download-MetaTrader")}>
            More Download Options
          </button>
        </div>
      </div>
    </div>
  );
};

export default DownloadMetaTrader;
