import React from "react";
import moment from "moment";

// HISTORY TABLE CSS
import "./HistoryTable.css";

import download from "../images/icons/download.svg";

const WithdrawHistoryTable = ({ data = null, hover = true }) => {
  const columns = [
    { field: "DateCreated", header: "Date" },
    { field: "withdrawalMethod", header: "Withdraw Method" },
    { field: "Amount", header: "Amount" },
  ];
  return (
    <div className='table-container'>
      <table>
        <thead>
          <tr>
            {columns && columns.map(head => <th>{head.header}</th>)}
            {/* <th>Invoice</th> */}
          </tr>
        </thead>
        <tbody>
          {data &&
            data.map((row, i)=> (
              <tr key={'wt-'+i}>
                <td>{moment(row.Date_Created).format("DD/MM/YY")}</td>
                <td>{row.withdrawalMethod}</td>
                <td>${row.Amount}</td>
                <td>{row.Status}</td>
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No data to show </p>}
    </div>
  );
};

export default WithdrawHistoryTable;
