import React, { useEffect, useState } from "react";
import styled from "styled-components";
import deleteIcon from "../../images/icons/delete.svg";
import "../VerificationReview.css";
import {
  updateDocumentStatus,
  updateDocumentNote,
  updateAllDocumentsStatus,
} from "../../api/document";
import { toast } from "react-toastify";
import AdminKycButton from "./AdminKycButton";
import CustomerIdentificationForm from "./CustomerIdentificationForm";
import CustomerInformation from "./CustomerInformation";
import ImageOpener from "../ImageOpener";
import { updateStatus } from "../../api/customer";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 500;
  border-radius: 20px;
`;

const AdminVerificationReview = ({
  customer,
  closeModal,
  refresh,
  allIDTypes,
  signatureStatus,
  selfieStatus,
  idCardStatus,
  docSignature,
  docSelfie,
  docIDFront,
  docForm,
}) => {
  const [note1, setNote1] = useState("");
  const [note2, setNote2] = useState("");
  const [note3, setNote3] = useState("");
  const [modelRefresh, setModelRefresh] = useState(false);
  const [tooltipContent, setTooltipContent] = useState("");
  const [htmlContent, setHtmlContent] = useState();
  const [modalIsOpen, setModalIsOpen] = useState("");

  useEffect(() => {
    setNote1(docIDFront?.rejected_reason);
    setNote2(docSelfie?.rejected_reason);
    setNote3(docSignature?.rejected_reason);
    setHtmlContent(docForm?.html_form);
  }, [docIDFront, docSelfie, docSignature, docForm]);

  const customerIdentification =
    customer?.customer?.customer_identification?.[0] || {};

  const formatDate = (isoString) => {
    if (!isoString) return "";
    const date = new Date(isoString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const [formData, setFormData] = useState({
    idType: customerIdentification.identification_type || "",
    idNumber: customerIdentification.id_number || "",
    issueDate: customerIdentification.id_issue
      ? formatDate(customerIdentification.id_issue)
      : "",
    expiryDate: customerIdentification.id_expiry
      ? formatDate(customerIdentification.id_expiry)
      : "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const updateNote = async (e, setNote) => {
    const val = e.target.value;
    setNote(val);
  };

  const docIDBack = customer?.kyc_documents?.find(
    (doc) =>
      doc.name.includes("back") &&
      allIDTypes.includes(doc?.kyc_document_type?.Type_Name)
  );

  const returnResponse = () => {
    toast("Successfully updated", {
      type: "success",
    });
    refresh();
  };

  const isButtonDisabled = !Object.values(formData).every(
    (val) => val.trim() !== ""
  );

  const handleTooltip = (content) => {
    setTooltipContent(content);
  };

  const openInnerModal = (index) => {
    setModalIsOpen(index);
  };

  const closeInnerModal = () => {
    setModalIsOpen("");
  };

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="signature-modal w-[50vw]">
          <div className="cpm-container">
            <div className="cpm-top">
              <h2>KYC Verification Review</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <CustomerInformation customer={customer?.customer} />
            {docIDFront != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Step 1: Proof of Government-Issued ID</p>
                  </div>
                </div>
                <div className="review-id">
                  {customer?.kyc_documents
                    .filter((doc) =>
                      allIDTypes.includes(doc?.kyc_document_type?.Type_Name)
                    )
                    .reverse()
                    .map((doc, index) => {
                      return (
                        <div key={doc?.id}>
                          <h2>
                            {doc.name.includes("back") ? "Back" : "Front"}
                          </h2>
                          <div className="id-img">
                            {doc?.image_path == "null" ||
                            doc?.image_path == "" ? (
                              <div>Document image is not uploaded</div>
                            ) : (
                              <>
                                {modalIsOpen !== 0 && modalIsOpen !== 1 && (
                                  <div
                                    className="pointer"
                                    onClick={() => openInnerModal(index)}
                                  >
                                    <img src={doc?.image_path} alt="" />
                                  </div>
                                )}
                                {modalIsOpen === index && (
                                  <ImageOpener
                                    idCard={index == 0 ? "front" : "back"}
                                    deleteIcon={deleteIcon}
                                    closeInnerModal={closeInnerModal}
                                    imageSrc={doc?.image_path}
                                  />
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      );
                    })}
                </div>
                <CustomerIdentificationForm
                  customerIdentification={customerIdentification}
                  handleInputChange={handleInputChange}
                  formData={formData}
                />
                <AdminKycButton
                  modelRefresh={modelRefresh}
                  setModelRefresh={setModelRefresh}
                  disabled={isButtonDisabled}
                  rejectReason={note1}
                  setRejectReason={setNote1}
                  updateNote={updateNote}
                  rejectedReasonOnClick={async () => {
                    const documentIDs = [docIDFront?.id];
                    if (docIDBack) {
                      documentIDs.push(docIDBack.id);
                    }
                    await Promise.all(
                      documentIDs.map(async (docID) => {
                        await updateDocumentNote(docID, note1);
                      })
                    );
                  }}
                  refresh={refresh}
                  onClickApprove={async () => {
                    const documentIDs = [docIDFront];
                    if (docIDBack) {
                      documentIDs.push(docIDBack);
                    }
                    await Promise.all(
                      documentIDs.map(async (doc, index) => {
                        const isFront = doc?.name?.includes("front");
                        const formDataToSend = isFront ? formData : "";
                        const { message } = await updateDocumentStatus(
                          doc.id,
                          "VERIFIED",
                          formDataToSend
                        );
                        if (message && (!docIDBack || index === 1)) {
                          returnResponse();
                        }
                      })
                    );
                  }}
                  onClickReject={async () => {
                    const documentIDs = [docIDFront?.id];
                    if (docIDBack) {
                      documentIDs.push(docIDBack.id);
                    }
                    await Promise.all(
                      documentIDs.map(async (docID, index) => {
                        const { message } = await updateDocumentStatus(
                          docID,
                          "REJECTED"
                        );
                        if (message && (!docIDBack || index === 1)) {
                          returnResponse();
                        }
                      })
                    );
                  }}
                  status={idCardStatus}
                />
              </>
            )}
            <hr className="mt-5"></hr>
            {docSelfie != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>
                      Step 2: Selfie Holding Government-Issued ID Beside Face
                    </p>
                  </div>
                </div>
                <div className="review-selfie">
                  {docSelfie.image_path == "null" ||
                  docSelfie.image_path == "" ? (
                    <div> Signature image is not uploaded</div>
                  ) : (
                    <>
                      <div
                        className="pointer"
                        onClick={() => openInnerModal(3)}
                      >
                        <img src={docSelfie.image_path} alt="" />
                      </div>
                      {modalIsOpen === 3 && (
                        <ImageOpener
                          deleteIcon={deleteIcon}
                          closeInnerModal={closeInnerModal}
                          imageSrc={docSelfie.image_path}
                        />
                      )}
                    </>
                  )}
                </div>
                <AdminKycButton
                  modelRefresh={modelRefresh}
                  setModelRefresh={setModelRefresh}
                  refresh={refresh}
                  rejectReason={note2}
                  setRejectReason={setNote2}
                  updateNote={updateNote}
                  rejectedReasonOnClick={async () => {
                    const { message } = await updateDocumentNote(
                      docSelfie?.id,
                      note2
                    );
                  }}
                  onClickApprove={async () => {
                    const { message } = await updateDocumentStatus(
                      docSelfie?.id,
                      "VERIFIED"
                    );
                    if (message) {
                      returnResponse();
                    }
                  }}
                  onClickReject={async () => {
                    const { message } = await updateDocumentStatus(
                      docSelfie?.id,
                      "REJECTED"
                    );
                    if (message) {
                      returnResponse();
                    }
                  }}
                  status={selfieStatus}
                />
                <div className="cursor-pointer red ">
                  <br />
                  Status:
                  {selfieStatus}
                </div>
              </>
            )}
            <hr style={{ marginTop: "0.5rem" }}></hr>
            {docSignature != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>
                      Step 4: Customer's Written Signature Affixed to Above
                      Customer Agreement
                    </p>
                  </div>
                </div>
                <div className="review-selfie">
                  {docSignature?.image_path == "null" ||
                  docSignature?.image_path == "" ? (
                    <div> Signature image is not uploaded</div>
                  ) : (
                    <>
                      <div
                        className="pointer"
                        onClick={() => openInnerModal(4)}
                      >
                        <img src={docSignature?.image_path} alt="" />
                      </div>
                      {modalIsOpen === 4 && (
                        <ImageOpener
                          deleteIcon={deleteIcon}
                          closeInnerModal={closeInnerModal}
                          imageSrc={docSignature?.image_path}
                        />
                      )}
                    </>
                  )}
                </div>
                <AdminKycButton
                  modelRefresh={modelRefresh}
                  setModelRefresh={setModelRefresh}
                  rejectReason={note3}
                  setRejectReason={setNote3}
                  updateNote={updateNote}
                  rejectedReasonOnClick={async () => {
                    await updateDocumentNote(docSignature?.id, note3);
                  }}
                  refresh={refresh}
                  onClickApprove={async () => {
                    const { message } = await updateDocumentStatus(
                      docSignature?.id,
                      "VERIFIED"
                    );
                    if (message) {
                      returnResponse();
                    }
                  }}
                  onClickReject={async () => {
                    const { message } = await updateDocumentStatus(
                      docSignature?.id,
                      "REJECTED"
                    );
                    if (message) {
                      returnResponse();
                    }
                  }}
                  status={signatureStatus}
                />
              </>
            )}
            {docForm != null && (
              <>
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Step 4: Customer Agreement Form</p>
                  </div>
                </div>
                <div className="review-selfie">
                  {htmlContent ? (
                    <div dangerouslySetInnerHTML={{ __html: htmlContent }} />
                  ) : (
                    <div>Form is not uploaded</div>
                  )}
                </div>
              </>
            )}
            <hr className="my-5"></hr>
            <div className="enter-amount">
              <p>
                Overall KYC Status of Customer:
                <span className="cursor-pointer red ">
                  {customer?.customer?.Status}
                </span>
              </p>
            </div>

            <div className="flex justify-around">
              <div>
                <h1 className="text-xl text-center">Customer </h1>
                <button
                  style={{ background: "#EAB308" }}
                  onClick={async () => {
                    const response = await updateStatus(
                      customer.customer.Customer_ID,
                      "PENDING"
                    );
                    if (response.message) {
                      returnResponse();
                      closeModal(false);
                    }
                  }}
                >
                  Pending
                </button>

                <button
                  onMouseEnter={() =>
                    handleTooltip(
                      "Please fill out customer's card information first"
                    )
                  }
                  style={{ background: "#16A34A" }}
                  className="verification-continue-btn complete"
                  onClick={async () => {
                    if (!isButtonDisabled) {
                      const response = await updateStatus(
                        customer.customer.Customer_ID,
                        "VERIFIED"
                      );
                      if (response.message) {
                        returnResponse();
                        closeModal(false);
                      }
                    }
                  }}
                >
                  Verify
                </button>

                <button
                  className="verification-continue-btn complete red"
                  onClick={async () => {
                    const response = await updateStatus(
                      customer.customer.Customer_ID,
                      "REJECTED"
                    );
                    if (response.message) {
                      returnResponse();
                      closeModal(false);
                    }
                  }}
                >
                  Reject
                </button>
              </div>

              <div>
                <h1 className="text-xl text-center">Documents </h1>
                <div onMouseLeave={() => handleTooltip("")}>
                  {tooltipContent && isButtonDisabled && (
                    <div className="absolute z-50 w-auto ml-16 whitespace-normal inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-100 tooltip dark:bg-gray-700">
                      {tooltipContent}
                    </div>
                  )}
                  <button
                    onMouseEnter={() =>
                      handleTooltip(
                        "Please fill out customer's card information first"
                      )
                    }
                    style={{ background: "#16A34A" }}
                    className="verification-continue-btn complete"
                    onClick={async () => {
                      if (!isButtonDisabled) {
                        const response = await updateAllDocumentsStatus(
                          "VERIFIED",
                          formData,
                          customer.kyc_documents
                        );
                        if (response.message) {
                          returnResponse();
                          closeModal(false);
                        }
                      }
                    }}
                  >
                    Verify all Documents
                  </button>
                </div>
                <button
                  className="verification-continue-btn complete red"
                  onClick={async () => {
                    const response = await updateAllDocumentsStatus(
                      "REJECTED",
                      {},
                      customer.kyc_documents
                    );
                    if (response.message) {
                      returnResponse();
                      closeModal(false);
                    }
                  }}
                >
                  Reject all Documents
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AdminVerificationReview;
