import React, { useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { openAccount } from "../api/trading";

import down from "../images/down-arrow.svg";
import deleteIcon from "../images/icons/delete.svg";
// css
import "./DepositForm.css";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 500;
  border-radius: 20px;
`;
const OpenDemoAccount = ({ closeModal, title, title2, type, deposit }) => {
  const [amount, setAmount] = useState(0);
  const [openAccType, setOpenAccTyp] = useState(false);
  const [openLeverage, setOpenLeverage] = useState(false);
  const [selectedAccType, setSelectedAccType] = useState("APEX MT5 DEMO");
  const accOptions = type;
  const [selectedLev, setSelectedLev] = useState("1:500");
  const levOptions = ["1:500", "1:400", "1:300", "1:200", "1:100"];
  const [loading, setLoading] = useState(false);
  const openDemoAccount = async () => {
    setLoading(true);
    const data = {
      amount,
      type: "DEMO",
    };
    if (selectedLev != null) {
      data.leverage = selectedLev.split(":")[1];
    }
    const response = await openAccount(data);
    if (response.errors == null) {
      toast("Successfully opened account", { type: "success" });

      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      setLoading(false);
    }
  };
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Open a {title} Account</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <span className="info-text">
              To open a {title2} trading account you must complete the form
              below.
            </span>
            <div className="form-label-warning">
              <label htmlFor="CardHolde Name">Trading Platform</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                id=""
                name=""
                placeholder=""
                value="MetaTrader 5 (MT5)"
              />
            </div>
            <div class="dropdown-select">
              <p>Account Type</p>
              <div className="select">
                <span onClick={() => setOpenAccTyp(!openAccType)}>
                  <p>{selectedAccType}</p>
                  <img src={down} alt="" />
                </span>
                {/* {openAccType && (
                  <ul>
                    {accOptions.map((option) => (
                      <li
                        onClick={(e) => {
                          setSelectedAccType(e.target.textContent);
                          setOpenAccTyp(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )} */}
              </div>
            </div>

            <div>
              <div className="form-label-warning">
                <label htmlFor="ExpiryDate">Currency</label>
              </div>
              <div className="hide-pw">
                <input type="text" id="" name="" placeholder="" value="USD" />
              </div>
            </div>
            <div class="dropdown-select">
              <p>Leverage</p>
              <div className="select">
                <span onClick={() => setOpenLeverage(!openLeverage)}>
                  <p>{selectedLev}</p>
                  <img src={down} alt="" />
                </span>
                {openLeverage && (
                  <ul>
                    {levOptions.map((option) => (
                      <li
                        onClick={(e) => {
                          setSelectedLev(e.target.textContent);
                          setOpenLeverage(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>
            <div>
              <div className="form-label-warning">
                <label htmlFor="SecurityCode ">Initial Deposit</label>
              </div>
              <div className="hide-pw">
                <input
                  type="number"
                  id="SecurityCode"
                  name="SecurityCode"
                  placeholder={deposit}
                  onChange={(e) => {
                    setAmount(e.target.value);
                  }}
                />
              </div>
            </div>
            {/* 
            <div className="df-save-card cpm-info">
              <input type="checkbox" />
              <span className="label"></span>
              <p className="">
                I have read and agree with Apex Market’s
                <span>User Agreement</span> and
                <span> Privacy Policy</span>.
              </p>
            </div> */}

            <button
              className="verification-continue-btn complete"
              onClick={() => openDemoAccount()}
              disabled={loading}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default OpenDemoAccount;
