import { useState } from "react";

export default function useForm(data = {}) {
  const [formData, setFormData] = useState(data);
  const [submitting, setSubmitting] = useState(false);

  /**
   * Sets the value of the form field
   *
   * @param {string} key
   * @param {any} value
   */
  function setFieldData(key, value) {
    setFormData(newValue => ({ ...newValue, [key]: value }));
  }

  function resetForm(data) {
    setFormData(data);
  }

  /**
   * Sets the value of the submitting value
   *
   * @param {boolean} value
   */
  function setFormSubmissionValue(value) {
    setSubmitting(() => value);
  }

  return { formData, setFieldData, submitting, setFormSubmissionValue, resetForm};
}
