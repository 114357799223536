import dashboard from "../images/icons/dashboard.svg";
import accounts from "../images/icons/accounts.svg";
import deposit from "../images/icons/deposit.svg";
import transfer from "../images/icons/transfer.svg";
import withdraw from "../images/icons/withdraw.svg";
import affiliate from "../images/icons/affiliate.svg";
import leaderboards from "../images/icons/leaderboards.svg";
import faq from "../images/icons/faq.svg";
import contact from "../images/icons/contact.svg";
import legal from "../images/icons/legaldocuments.svg";

// ----------------------SIDEBAR LINKS------------------
export const SidebarData = [
  {
    title: "Dashboard",
    icon: dashboard,
    link: "/dashboard",
    cName: "sidebar-title",
  },
  {
    title: "Accounts",
    icon: accounts,
    link: "/accounts/wallet-account",
    cName: "sidebar-title",
  },
  {
    title: "Deposit",
    icon: deposit,
    link: "/deposit",
    cName: "sidebar-title",
  },
  {
    title: "Transfer",
    icon: transfer,
    link: "/transfer",
    cName: "sidebar-title",
  },
  {
    title: "Withdraw",
    icon: withdraw,
    link: "/withdraw",
    cName: "sidebar-title",
  },
  {
    title: "Affiliate",
    icon: affiliate,
    link: "/affiliate",
    cName: "sidebar-title",
  },
  // {
  //   title: "Resources",
  //   icon: legal,
  //   link: "/resources",
  //   cName: "sidebar-title",
  // },
  {
    title: "Leaderboards",
    icon: leaderboards,
    link: "/leaderboards",
    cName: "sidebar-title",
  },
  {
    title: "FAQ",
    icon: faq,
    link: "/faq",
    cName: "sidebar-title",
  },
  {
    title: "Contact us",
    icon: contact,
    link: "/contact-us",
    cName: "sidebar-title",
  },
  {
    title: "Legal Documents",
    icon: legal,
    link: "/legal-documents",
    cName: "sidebar-title",
  },
];
