export const symbolrankdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "2.0",
    profit: "11.35",
    gain: "-2.54%",
  },
];

export const symbolcolNames = [
  "No",
  "Login Id",
  "Symbol",
  "Volume (Lot)",
  "Change",
];
