import actions from "../../images/icons/actions.svg";

const img = <img src={actions} alt="" />;

export const invoice_data = [
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Paid"],
    img: img,
  },
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Unpaid"],
    img: img,
  },
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Partially Paid"],
    img: img,
  },
];

export const invoice_datacolNames = [
  "Date",
  "Invoice No",
  "Name",
  "Wallet ID",
  "Order No",
  "Deposits Method",
  "Amount (USD)",
  "Status",
  "Action",
];
