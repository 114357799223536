// ---------------Wallet Deposits----------------------------------------------------
// ---------------DEPOSITS PAGE--------------------
import actions from "../../images/icons/actions.svg";
import arrow from "../../images/icons/arrow01.svg";

const img = <img src={actions} alt="" />;
const arrowimg = <img src={arrow} alt="" />;

export const deposits_data = [
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Flag", arrowimg],
    img: img,
  },
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Paid", arrowimg],
    img: img,
  },
  {
    date: "23/05/22",
    invoiceNo: "#1234567",
    name: "Abdullah Khaled",
    WalletID: "#1234567",
    OrderNo: "5",
    DepositsMethod: "Visa(24544433***65)",
    Amount: "$100000.00",
    Status: ["Pending"],
    img: img,
  },
];

export const deposits_datacolNames = [
  "Date",
  "Invoice No",
  "Name",
  "Wallet ID",
  "Order No",
  "Deposits Method",
  "Amount (USD)",
  "Status",
  "Action",
];
