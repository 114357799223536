import React, { useState } from "react";
import AppBar from "../components/AppBar";
import Switch from "@mui/material/Switch";
import AccountSettingsLinks from "../components/AccountSettingsLinks";
import { useEffect } from "react";
import { getNotification, updateNotification } from "../api/customer";
import Tooltip from "@mui/material/Tooltip";
import { AiOutlineInfoCircle } from "react-icons/ai";
import IconButton from "@mui/material/IconButton";

const label = { inputProps: { "aria-label": "Switch demo" } };

const Notification = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  const [allNotifications, setAllNotifications] = useState(true);

  const [allTransNotifications, setAllTransNotifications] = useState(true);
  const [depositNotifications, setDepositNotifications] = useState(true);
  const [withdrawalNotifications, setWithdrawalNotifications] = useState(true);
  const [transferNotifications, setTransferNotifications] = useState(true);

  const [allAffNotifications, setAllAffNotifications] = useState(true);
  const [commissionNotifications, setCommissionNotifications] = useState(true);
  const [ftdNotifications, setFtdNotifications] = useState(true);
  const [rewardsNotifications, setRewardsNotifications] = useState(false);

  const updateData = async () => {
    const data = {
      DEPOSIT_NOTIFICATIONS: depositNotifications,
      WITHDRAW_NOTIFICATIONS: withdrawalNotifications,
      TRANSFER_NOTIFICATIONS: transferNotifications,
      COMMISSION_NOTIFICATIONS: commissionNotifications,
      FTD_NOTIFICATIONS: ftdNotifications,
      REWARDS_NOTIFICATIONS: rewardsNotifications,
    };
    await updateNotification(data);
  };

  const getData = async () => {
    const data = await getNotification();
    if (data) {
      const settings = data.settings;
      for (let i = 0; i < settings.length; i++) {
        switch (settings[i].Transaction_Type) {
          case "DEPOSIT_NOTIFICATIONS":
            setDepositNotifications(settings[i].Status);
            break;
          case "WITHDRAW_NOTIFICATIONS":
            setWithdrawalNotifications(settings[i].Status);
            break;
          case "TRANSFER_NOTIFICATIONS":
            setTransferNotifications(settings[i].Status);
            break;
          case "COMMISSION_NOTIFICATIONS":
            setCommissionNotifications(settings[i].Status);
            break;
          case "FTD_NOTIFICATIONS":
            setFtdNotifications(settings[i].Status);
            break;
          case "REWARDS_NOTIFICATIONS":
            setRewardsNotifications(settings[i].Status);
            break;

          default:
            break;
        }
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    updateData();
    setAllTransNotifications(
      depositNotifications && withdrawalNotifications && transferNotifications
    );
    setAllAffNotifications(commissionNotifications && ftdNotifications);
  }, [
    depositNotifications,
    withdrawalNotifications,
    transferNotifications,
    commissionNotifications,
    ftdNotifications,
    rewardsNotifications,
  ]);

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Account Settings"></AppBar>
        <div className="h-screen dashboard-container">
          <AccountSettingsLinks></AccountSettingsLinks>
          <div className="security-line"></div>

          <div className="flex flex-col w-auto m-4 space-y-1 rounded-lg ">
            <form className="flex flex-col py-3 ">
              <fieldset className="p-2 space-y-2 border border-gray-300 border-solid rounded-md sm:p-3">
                <div className="flex items-center ">
                  <div className="w-[50%] px-4">
                    <p className="text-sm sm:text-base">
                      Transaction Notifications
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end ml-auto border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={
                          (depositNotifications &&
                            withdrawalNotifications &&
                            transferNotifications) ||
                          allTransNotifications
                        }
                        onChange={() => {
                          setAllTransNotifications(!allTransNotifications);
                          allTransNotifications
                            ? setDepositNotifications(!allTransNotifications)
                            : setDepositNotifications(!allTransNotifications);
                          allNotifications
                            ? setWithdrawalNotifications(!allTransNotifications)
                            : setWithdrawalNotifications(
                                !allTransNotifications
                              );
                          allNotifications
                            ? setTransferNotifications(!allTransNotifications)
                            : setTransferNotifications(!allTransNotifications);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications regarding incoming deposits to your account.">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm font-light text-gray-700">Deposits</p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={depositNotifications}
                        onChange={() => {
                          setDepositNotifications(!depositNotifications);
                          setAllTransNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications for outgoing withdrawals from your account.">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm font-light text-gray-700">
                      Withdrawals
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={withdrawalNotifications}
                        onChange={() => {
                          setWithdrawalNotifications(!withdrawalNotifications);
                          setAllTransNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications for transfers to your MetaTrader 5 (MT5) trading account. ">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm font-light text-gray-700">
                      Transfers to MetaTrader 5 (MT5)
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={transferNotifications}
                        onChange={() => {
                          setTransferNotifications(!transferNotifications);
                          setAllTransNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            <form className="flex flex-col py-3 ">
              <fieldset className="p-2 space-y-2 border border-gray-300 border-solid rounded-md sm:p-3">
                <div className="flex items-center ">
                  <div className="w-[50%] px-4">
                    <p className="text-sm sm:text-base">
                      Affiliate Notifications
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end ml-auto border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={
                          (commissionNotifications && ftdNotifications) ||
                          allAffNotifications
                        }
                        onChange={() => {
                          setAllAffNotifications(!allAffNotifications);
                          allAffNotifications
                            ? setCommissionNotifications(!allAffNotifications)
                            : setCommissionNotifications(!allAffNotifications);
                          allAffNotifications
                            ? setFtdNotifications(!allAffNotifications)
                            : setFtdNotifications(!allAffNotifications);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications related to affiliate commissions. When activated, you will receive notifications about any earned affiliate commissions, keeping you updated on your referral-based earnings.">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm font-light text-gray-700">
                      Affiliate Commissions
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={commissionNotifications}
                        onChange={() => {
                          setCommissionNotifications(!commissionNotifications);
                          setAllAffNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications regarding first-time deposit bonuses. When turned on, you'll receive alerts whenever a first-time deposit bonus is credited to your account.">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm font-light text-gray-700">
                      FTD Bonus
                    </p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        {...label}
                        checked={ftdNotifications}
                        onChange={() => {
                          setFtdNotifications(!ftdNotifications);
                          setAllAffNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
            <form className="flex flex-col py-3 ">
              <fieldset className="p-2 space-y-2 border border-gray-300 border-solid rounded-md sm:p-3">
                <div className="flex items-center ">
                  <div className="w-[50%] px-4 flex items-center gap-1">
                    <Tooltip title="Toggle this option to control notifications related to rewards earned on your account. ">
                      <IconButton size="small" sx={{ padding: 0 }}>
                        <AiOutlineInfoCircle className="text-lg"></AiOutlineInfoCircle>
                      </IconButton>
                    </Tooltip>
                    <p className="text-sm sm:text-base">Rewards</p>
                  </div>{" "}
                  <div className="">
                    <div className="flex items-center justify-end border-l border-l-gray-400/50">
                      {" "}
                      <Switch
                        disabled
                        {...label}
                        checked={rewardsNotifications}
                        onChange={() => {
                          setRewardsNotifications(!rewardsNotifications);
                          setAllAffNotifications(false);
                        }}
                      />
                      <p className="text-sm font-light ">Email</p>
                    </div>
                  </div>
                </div>
              </fieldset>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default Notification;
