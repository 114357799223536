import actions from "../../images/icons/actions.svg";

const img = <img src={actions} alt="" />;

export const wallet_payouts = [
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    DepositMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Decline",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    DepositMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Proceeding",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    invoiceid: "dgfgfgruygruyfgr",
    PayoutMethod: "Credi card(***65)",
    Amount: "$100000.00",
    Status: "Success",
    img: img,
  },
];

export const wallet_payoutscolNames = [
  "Date",
  "Client ID",
  "Name",
  "Invoice ID",
  "Payout Method",
  "Amount (USD)",
  "Status",
  "Actions",
];
