const email = (
  <span className="blue">
    <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
      support@apexmarkets.io
    </a>
  </span>
);
const moreinfo = (
  <p>
    For more information, please contact us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
  </p>
);

const issues = (
  <p>
    If you have any issues, need more information, or need faster verification
    of your KYC documents, once uploaded you can email us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
  </p>
);
const depositissues = (
  <p>
    If you have any issues with your payment, you can email us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    and we will be happy to assist you.
  </p>
);

const new1 = <button>Start Trading</button>;
const gen4 = (
  <p>
    To withdraw funds from your Apex Markets account, log in to your Apex
    Markets client office and select withdraw. You can withdraw funds from your
    Apex Markets account quickly by utilizing one of our lightning-fast
    withdrawal methods such as Bank Wire Transfer or Cryptocurrency. To start
    withdrawing funds from your Apex Market Account, choose the withdrawal
    option on your Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    enter the amount you want to withdraw, and then click the request withdraw
    button.
  </p>
);
const gen6 = (
  <p>
    Send an email to{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    to get in touch with the Apex Markets Support team. We respond 24 hours a
    day, 7 days a week; however, for a faster response, contact us between 9
    a.m. and 6 p.m. EST or visit our Instagram page,{" "}
    <span class="blue">
      <a href="http://www.Instagram.com/ApexMarkets">
        www.Instagram.com/ApexMarkets
      </a>
    </span>{" "}
    or youtube{" "}
    <span class="blue">
      <a href="https://www.youtube.com/playlist?list=PL7OvhqzpeVOq7XsqxuXDyXKBZXkXIOF9u">
        www.youtube.com/apexmarkets
      </a>
    </span>{" "}
    for helpful videos.
  </p>
);

const gen8 = (
  <p>
    To update your email address on Apex Markets, go to the profile page on your
    Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    and update your email address there. To verify your email address, an email
    with a confirmation request will be sent to the address you provided.
  </p>
);
const gen10 = (
  <p>
    To change your Apex Markets trading account password, go to your Client
    Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    , select ‘My Accounts’, and then choose a specific trading account number
    for which you want to change the account password.
  </p>
);

const gen11 = (
  <p>
    If you are not receiving emails from Apex Markets, email us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    and our team will resolve the issue.
  </p>
);
const gen12 = (
  <p>
    To reactivate your Apex Markets MetaTrader 5 (MT5) Trading Account log in to
    your Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    select ‘My Accounts’, and then choose a specific trading account number. You
    must make a deposit to reactivate your account. After you have made your
    deposit, you can log in to your MetaTrader 5 (MT5) platform using your
    MetaTrader 5 (MT5) Login Credentials to start trading.
  </p>
);
const gen13 = (
  <p>
    To delete your Apex Markets MetaTrader 5 (MT5) Trading Account: <br />
    1. Log in to your Apex Markets MetaTrader 5 (MT5) account terminal. <br />
    2. Close all your open positions.
    <br />
    3. Withdraw all your funds.
    <br />
    4. Your account will be archived.
    <br />
    5. Contact our support team at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
    , if you want to permanently remove it.
  </p>
);
const gen14 = (
  <p>
    If your Apex Markets account has been closed, it is possible that you have
    violated the terms and conditions of the platform. If you did not do this,
    please contact us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    for further assistance.
  </p>
);

const apexwebsite = (
  <span class="blue">
    <a href="https://www.apexmarkets.io/">www.apexmarkets.io/</a>
  </span>
);
const kycbullet = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>
        A <b>CLEAR</b> picture of the front and back of your valid
        Government-Issued Photo ID which can be any of the following: Passport,
        Drivers Licence, or National ID.
      </p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>
        A <b>CLEAR</b> picture of you holding your Government-Issued ID Beside
        your face.
      </p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        A <b>SIGNED</b> Apex Markets Customer Agreement.
      </p>
    </div>
  </div>
);
const kycbullet5 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>
        The personal information on your Apex Markets Client Office Dashboard
        must match the information on your Valid Government-issued ID and your
        Customer Agreement.
      </p>
    </div>
  </div>
);

const kycbullet10 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Government Issued ID blurry</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Government Issued ID information does not match signed information on
        customer agreement
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Government Issued ID expired</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Government Issued ID invalid</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Picture beside face (selfie) blurry</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Picture beside face (selfie) should be taken in better lighting (too
        dark or too bright)
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Customer agreement signature does not match signature on Government
        Issued ID
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Customer agreement information does not match information on Government
        Issued ID.
      </p>
    </div>
  </div>
);

const kyc3 = (
  <p>
    To upload your KYC Verification Documents to Apex Markets, log in to your
    Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    and navigate to the KYC documents section. From there, you will be able to
    upload your KYC documents.
  </p>
);
const kyc7 = (
  <p>
    To verify your Credit/Debit Card on Apex Markets, log in to your Apex
    Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    and complete the KYC Verification Process.
  </p>
);
const deposit5 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Access the Client Office.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Navigate to the 'Deposit' tab.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Choose the Cryptocurrency payment methods and follow the steps to make
        payment.
      </p>
    </div>
  </div>
);
const deposit7 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>Access the Client Office.</p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>Navigate to the 'Deposit' tab.</p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Choose the Cryptocurrency payment methods and follow the steps to make
        payment.
      </p>
    </div>
  </div>
);
const deposit10 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Card payments will reflect in your Apex Market account INSTANTLY.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Cryptocurrency payments can take anywhere from 10 minutes to a few hours
        to be reflected in your account.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Bank Transfer may take a few days to be reflected in your Apex Markets
        Account.
      </p>
    </div>
  </div>
);
const withdraw3 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Kraken.com</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>CEX.IO</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Coinbase.com</p>
    </div>
    <br />
    <p>
      If you have any issues you can utilize our direct Bank Wire Transfer
      option or if you need help, you can email us at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const withdraw4 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>Log in to your Apex Markets client office and select withdraw.</p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>Choose a Cryptocurrency Withdrawal method.</p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Copy your Cryptocurrency Withdrawal address from your Cryptocurrency
        wallet, paste this address on the Withdrawal Address section on Apex
        Markets and then enter the amount you would like to withdraw from your
        Apex Markets account.
      </p>
    </div>
    <div className="bullet">
      <span>4.</span>
      <p>Submit withdrawal.</p>
    </div>

    <br />
    <p>
      Please note: Cryptocurrency withdrawals may take anywhere from 10 minutes
      to a few hours to process.
    </p>
  </div>
);
const withdraw5 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>Log in to your Apex Markets client office and select withdraw.</p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>Choose the Withdrawal method of your choice.</p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Enter the amount you would like to withdraw from your Apex Markets
        account.
      </p>
    </div>
    <div className="bullet">
      <span>4.</span>
      <p>Submit withdrawal.</p>
    </div>

    <br />
    <p>
      Please note: Each withdrawal method has a different processing time. You
      may view our FAQ section to learn more about the different processing
      times for each withdrawal method.
    </p>
    <br />
    <p>
      If you haven’t received your payment after the processing time has
      elapsed, be sure to email us at{" "}
      <span class="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>{" "}
      </span>
      for our support team to assist you.
    </p>
  </div>
);
const withdraw9 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Card withdrawals will reflect in your account INSTANTLY.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Cryptocurrency withdrawals can take anywhere from 10 minutes to a few
        hours to be reflected in your Cryptocurrency Wallet.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Bank/Wire withdrawals may take a few days to be reflected in your
        account.
      </p>
    </div>

    <br />

    <p>
      If you have any issues with your payment, you can email us at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const withdraw10 = (
  <p>
    If you have any issues, need more information, or need faster verification
    of your KYC Documents, once uploaded you can email us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
  </p>
);
const withdrawissues = (
  <p>
    If you are still having issues, you can email us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    and our support team will assist you.
  </p>
);
const withdraw19 = (
  <p>
    Should your Apex Markets withdrawal be rejected we will send you an email
    explaining why it was declined and what to do next. If you have any
    questions, simply contact us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
  </p>
);
const orders2 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Card payments will reflect in your Apex Market account INSTANTLY.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Cryptocurrency payments can take anywhere from 10 minutes to a few hours
        to be reflected in your account.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Bank Transfer may take a few days to be reflected in your Apex Markets
        Account.
      </p>
    </div>
    <br />
    <p>To make payments to your account, simply follow the steps below:</p>

    <div className="bullet">
      <span>&bull;</span>
      <p>Access the Client Office.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Navigate to the 'Deposit' tab.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Choose one of our fast and easy to use payment methods and follow the
        steps to make a payment.
      </p>
    </div>
    <br />
    <p>
      If you have any issues with your payment, you can email us at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const orders4 = (
  <p>
    For payments made with a Credit or Debit Card, you must first verify your
    account; once verified, you will receive your payment instantly. Payments
    made with Cryptocurrency typically take 10 minutes to a few hours to appear.
    Bank deposits can take anywhere from a day to five days to arrive. If you
    have not received your funds within the specified time frame, please contact{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    for further assistance.
  </p>
);
const orders5 = (
  <p>
    If you are still experiencing problems, please contact{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>{" "}
    for further assistance.
  </p>
);
const rules6 = (
  <p>
    If you don’t understand the rules you may contact us at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
    .
  </p>
);

const meta1 = (
  <div className="terms-bullets">
    <p>
      Apex Markets support MetaTrader 5 (MT5) platform for trading. To get
      started Create a live account on your Apex Market Client Office Dashboard{" "}
      <span class="blue">
        <a href="http://www.apexmarkets.io">(www.apexmarkets.io)</a>
      </span>{" "}
      and make a deposit.
    </p>
    <br />
    <p>
      <b>
        Download the Meta Trader 5 (MT5) app on your device and start trading
        now:
      </b>
    </p>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=ApexCapitalMarkets-ECN">
          Download MetaTrader5 iOS
        </a>
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=ApexCapitalMarkets-ECN">
          Download MetaTrader5 Android
        </a>
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/web/apex.capital.markets/mt5/apexcapitalmarkets5setup.exe">
          Download MetaTrader5 Windows
        </a>
      </p>
    </div>
    <br />
    <p>
      If you have any questions, reach out to our 24/7 Support team via email at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);

const meta3 = (
  <div className="terms-bullets">
    <p>
      To access the MetaTrader 5 (MT5) platform to start trading, here is what
      you need to do:
    </p>
    <br />
    <div className="bullet">
      <span>1.</span>
      <p>
        Visit the Apex Markets website at{" "}
        <span class="blue">
          <a href="http://www.apexmarkets.io">www.apexmarkets.io</a>
        </span>{" "}
        to get started trading on the Apex Markets platform.
      </p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>You can open a trading account there.</p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Fund your account using one of our super fast and easy to use payment
        methods: [Card, Cryptocurrency, Bank/Wire transfer] and link your Apex
        Markets MetaTrader (MT5) Trading account to the Meta Trader (MT5)
        Platform.
      </p>
    </div>
    <br />
    <p>
      To connect your Apex Markers MetaTrader(MT5) Trading account to the Meta
      Trader (MT5) Platform
    </p>
    <p>
      <b>Download Meta Trader 5 (MT5):</b>
    </p>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=ApexCapitalMarkets-ECN">
          Download MetaTrader5 iOS
        </a>
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=ApexCapitalMarkets-ECN">
          Download MetaTrader5 Android
        </a>
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p className="blue">
        <a href="https://download.mql5.com/cdn/web/apex.capital.markets/mt5/apexcapitalmarkets5setup.exe">
          Download MetaTrader5 Windows
        </a>
      </p>
    </div>
    <br />
    <p>
      After downloading the MetaTrader 5 (MT5) app to your device complete the
      following steps:
    </p>
    <div className="bullet">
      <span>&bull;</span>
      <p>Go to settings</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Select server/company name and enter “Apex Capital Markets LLC” or
        ‘ApexCapitalMarkets’, You should see our logo.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Enter Apex Markets MT5 Trading account number and password and follow
        the steps to sign in.
      </p>
    </div>
    <br />
    <p>
      If you have any questions, reach out to our 24/7 Support team via email at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const meta4 = (
  <p>
    To find your Apex Markets MetaTrader 5 (MT5) Account Credentials, log in to
    your Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
    </span>{" "}
    and click Accounts to view your account credentials. There you will see your
    MetaTrader 5 (MT5) account number(s) be able to change your password,
    leverage, and create new accounts or archive accounts that aren’t being
    used.
  </p>
);
const meta5 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Check to ensure that your internet connection is working well.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        If your internet connection is working well, try to log out of your Apex
        Markets MetaTrader 5 (MT5) Trading Account and close the MetaTrader 5
        (MT5) application and restart your device.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Try to log in to the application again.</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        If this did not fix the issue try changing your Apex Markets MetaTrader
        5 (MT5) Trading Account Password by logging in to your Client Office
        Dashboard (
        <span class="blue">
          <a href="http://www.apexmarkets.io">www.apexmarkets.io</a>
        </span>
        ), select ‘My Accounts’, and then choose a specific trading account
        number for which you want to change the account password. Then try to
        log in to the application again
      </p>
    </div>
    <br />
    <p>
      If you are still experiencing difficulties, try watching our Youtube
      videos or reach out to our 24/7 Support team via email at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const meta6 = (
  <p>
    You can change your Apex Markets MetaTrader 5 (MT5) Trading Account login
    password by logging in to your Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
    </span>{" "}
    and navigating to the ‘Account’ section and then choosing a specific trading
    account number for which you want to change the account password.
  </p>
);
const meta7 = (
  <p>
    To change your Apex Markets MetaTrader 5 (MT5) Trading Account Number, you
    need to create a new Apex Markets MetaTrader 5 (MT5) account. To do this,
    log in to your Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
    </span>{" "}
    and navigate to your “account” section where you can create a new Apex
    Markets MetaTrader 5 (MT5) Live or Demo account. You can also archive the
    Apex Markets MetaTrader 5 (MT5) accounts that you are not currently using or
    have no further use for.
    <br /> <br />
    After you have created a new Apex Markets MetaTrader 5 (MT5) Trading
    Account, you will receive an email with your New Apex Markets MetaTrader 5
    (MT5) Trading Account credentials.
  </p>
);
const meta8 = (
  <p>
    If you want to permanently delete your previously created Apex Markets
    MetaTrader 5 (MT5) Trading Account, reach out to our 24/7 Support team via
    email at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
    .
  </p>
);
const meta9 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>Download the MetaTrader 5 (MT5) Application on the device:</p>
    </div>
    <div class="terms-bullets">
      <div className="bullet">
        <span>&bull;</span>
        <p className="blue">
          <a href="https://download.mql5.com/cdn/mobile/mt5/ios?server=ApexCapitalMarkets-ECN">
            Download MetaTrader5 iOS
          </a>
        </p>
      </div>
      <div className="bullet">
        <span>&bull;</span>
        <p className="blue">
          <a href="https://download.mql5.com/cdn/mobile/mt5/android?server=ApexCapitalMarkets-ECN">
            Download MetaTrader5 Android
          </a>
        </p>
      </div>
      <div className="bullet">
        <span>&bull;</span>
        <p className="blue">
          <a href="https://download.mql5.com/cdn/web/apex.capital.markets/mt5/apexcapitalmarkets5setup.exe">
            Download MetaTrader5 Windows
          </a>
        </p>
      </div>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>
        Navigate to ‘Settings’ on the MetaTrader 5 (MT5) Application on their
        device.
      </p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Select server/company name and enter “Apex Capital Markets LLC” or
        ‘ApexCapitalMarkets’, You should see our logo.
      </p>
    </div>
    <div className="bullet">
      <span>4.</span>
      <p>
        Enter Apex Markets MetaTrader 5 (MT5) Trading account number and
        password and follow the steps to sign in.
      </p>
    </div>
    <br />
    <p>
      <b>Please Note:</b>
    </p>

    <p>
      If you do not remember your Apex Markets MetaTrader 5 (MT5) account
      credentials, you can check your email or log in to your Apex Markets
      Client Office Dashboard (
      <span class="blue">
        <a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>
      </span>
      ) and navigate to the ‘Account' section to view your credentials.
    </p>
    <br />
    <p>
      If you are still experiencing difficulties, try watching our Youtube
      videos or reach out to our 24/7 Support team via email at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const meta10 = (
  <p>
    To change your Apex Markets Demo Account to a Live Account, log in to your
    Apex Markets Client Office Dashboard{" "}
    <span class="blue">
      <a href="https://www.apexmarkets.io/">(www.apexmarkets.io/)</a>
    </span>{" "}
    and navigate to the “Account” section where you can create an Apex Markets
    MetaTrader 5 (MT5) Live Trading account number. After you have created your
    Apex Markets MetaTrader 5 (MT5) Live Trading account number, make a deposit
    and receive a 100% bonus on deposits of $100USD or more, and start trading
    now!
  </p>
);
const meta16 = (
  <p>
    If your MetaTrader 5 (MT5) application fails to load or freezes, close
    MetaTrader 5 (MT5), restart your device, and try again. If that doesn't
    work, try removing your account and logging in again. If the problem
    persists, please contact{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
    .
  </p>
);
const meta12 = (
  <>
    <p>
      To change your Apex Markets MetaTrader 5 (MT5) Trading Account Leverage,
      Log in to your Apex Markets Client Office Dashboard{" "}
      <span class="blue">
        (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
      </span>{" "}
      and click accounts to view or change the leverage on your Apex Markets
      MetaTrader 5 (MT5) Account Credentials.
    </p>
    <p>
      If you are still experiencing difficulties, try watching our Youtube
      videos or reach out to our 24/7 Support team via email at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </>
);
const meta13 = (
  <>
    <p>
      To create a new Apex Markets MetaTrader 5 (MT5) Live Account to start
      trading, log in to your Apex Markets Client Office Dashboard{" "}
      <span class="blue">
        (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
      </span>{" "}
      and navigate to the ‘Account’ section where you can create a new Apex
      Markets MetaTrader 5 (MT5) Live account. You will then receive an email
      with the credentials for your new Apex Markets MetaTrader 5 (MT5) Live
      account. To start trading, make a deposit and receive a 100% bonus on
      deposits of $100USD or more!
    </p>
  </>
);
const meta14 = (
  <>
    <p>
      To create a new Apex Markets MetaTrader 5 (MT5) Demo Account to start
      trading do this, log in to your Apex Markets Client Office Dashboard{" "}
      <span class="blue">
        (<a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>)
      </span>{" "}
      and navigate to the ‘Account’ section where you can create a new Apex
      Markets MetaTrader 5 (MT5) Demo account. You will then receive an email
      with the credentials for your new Apex Markets MetaTrader 5 (MT5) Demo
      Account. To start trading, log in to the MetaTrader 5 (MT5) Application.
    </p>
  </>
);
const meta18 = (
  <>
    <p>
      log in into your Apex Markets Client Office Dashboard (
      <span class="blue">
        <a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>
      </span>
      ) and change your Apex Markets MetaTrader 5 (MT5) Trading Account password
      then try to log back into the MetaTrader 5 (MT5) application and try to
      place a trade again.{" "}
    </p>
    <p>
      If neither of the above options works, please contact{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </>
);
const meta19 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>1.</span>
      <p>
        Check to see if your device or the MetaTrader 5 (MT5) application needs
        to be updated
      </p>
    </div>
    <div className="bullet">
      <span>2.</span>
      <p>
        Check if your internet connection is stable if your device has enough
        storage space
      </p>
    </div>
    <div className="bullet">
      <span>3.</span>
      <p>
        Log out of your Apex Markets MetaTrader 5 (MT5) account on the
        MetaTrader 5 (MT5) application completely, close the application, and
        restart your device.
      </p>
    </div>
    <div className="bullet">
      <span>4.</span>
      <p>
        Try logging back into your Apex Markets MetaTrader 5 (MT5) account
        again.
      </p>
    </div>
    <br />
    <p>
      If you have any difficulties or the problem still continues, please
      contact our support team at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </div>
);
const meta20 = (
  <>
    <p>
      If you are having trouble logging in to your Apex Markets MetaTrader 5
      (MT5) Trading Account, double-check your account credentials or change
      your password and try again. You can change your Apex Markets MetaTrader 5
      (MT5) Trading Account login password by logging in to your Apex Markets
      Client Office Dashboard (
      <span class="blue">
        <a href="http://www.apexmarkets.io/">www.apexmarkets.io/</a>
      </span>
      ) and navigating to the ‘Account’ section and then choosing a specific
      trading account number for which you want to change the account password.
    </p>
    <p>
      If you have any difficulties or the problem still continues after changing
      your password, please contact our support team at{" "}
      <span className="blue">
        <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
          support@apexmarkets.io
        </a>
      </span>
      .
    </p>
  </>
);

const support = (
  <p>
    If you are still experiencing difficulties, try watching our Youtube videos
    or reach out to our 24/7 Support team via email at{" "}
    <span className="blue">
      <a href="mailto:support@apexmarkets.io?subject=Support Needed!">
        support@apexmarkets.io
      </a>
    </span>
    .
  </p>
);
const affiliate2 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Sign up to become our partner</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Submit your application by clicking (‘Become Our Partner’).</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Create and share your unique referral link with your family and friends.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Create, manage and track the performance of your invites easily from
        your Apex Markets Client Dashboard.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Earn up to 50% commission for each lot traded on our platform.</p>
    </div>
  </div>
);
const affiliate3 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Earn up to 50% commission for every person you recommend</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Withdraw your earnings are Quick and Easy using one of our payment
        methods: Bank Wire Transfer, Cryptocurrency, or Credit/Debit Card
        payments.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        We will provide you with marketing materials <br />
        - Additional Income Stream <br />
        - Unlimited earning potential <br />- No limit to your earnings
      </p>
    </div>

    <br />
  </div>
);
const affiliate5 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Sign up to become our partner and submit your application by clicking
        (‘Become Our Partner’)
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Create and share your unique referral link with your family and friends.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Create, manage and track the performance of your invites easily from
        your Apex Markets Client Dashboard.
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Earn up to 50% commission for each lot traded on our platform.</p>
    </div>
    <br />
  </div>
);

const starttradingbtn = <button>Start Trading Now</button>;
const marketing2 = (
  <div className="terms-bullets">
    <div className="bullet">
      <span>&bull;</span>
      <p>Spam or unsolicited email promotions</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>
        Spamming on blogs, forums, social media, or any other commenting systems
      </p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Any other type of illegal or deceptive marketing</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>We will provide you with marketing materials</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Use of materials that infringe on our intellectual property rights</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Cookie-stuffing</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Brokering or sub-affiliates</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>Pop-up or pop-under advertising</p>
    </div>
    <div className="bullet">
      <span>&bull;</span>
      <p>For more info, please refer to our Affiliate Terms of Service page.</p>
    </div>
  </div>
);
export const faqsdata = [
  {
    question: "1. What is Apex Markets?",
    answer:
      "Apex Markets is a global market company that provides a secure, reliable, and convenient gateway into the world of open financial systems to aspiring traders",
    open: true,
  },
  {
    question: "2. Who can join Apex Markets?",
    answer:
      "At Apex Markets we accept people from all around the world who are at or above the age of consent required to use online services in their respective jurisdictions and are able to trade on our platform. If you are under the age for consent required to use online services in your jurisdiction, you may use the site and services only with the permission of a parent or guardian.",
    open: false,
  },
  {
    question:
      "3. Why is Apex Markets one of the best trading platforms you can find?",
    answer:
      "Joining Apex Markets gives you the ability to trade on your own terms, which means you can trade 320+ Stocks, 30+ Commodities, 18+ Bonds, 100+ Forex Currencies, and more than 40 Cryptocurrencies. We also provide 24-hour trading of cryptocurrencies and a selection of over 2000 assets. \n\nTo protect your funds, Apex Markets provides Strong Security Protection against DDoS attacks, full data encryption, and PCI DSS compliance. All Apex Markets trades are routed directly to our independent liquidity providers. We have the quickest execution time as well as the quickest and simplest deposit and withdrawal process and time. There are also numerous payment options available: All Major Debit and Credit Cards, Cryptocurrency, Bank transfers, Wire transfers.",
  },
  {
    question: "4. How do I get started with Apex Markets?",
    answer:
      "Here’s how to get started with Apex Markets:\nStep 1 - Visit the Apex Market website at https://apexmarkets.io/.\nStep 2 - Click the start trading button on the website to create a new account or log in to your existing Apex Markets account.\nStep 3 - Fund your Apex Markets wallet using one of our super-fast and easy-to-use payment methods: All Major Debit and Credit Cards, Cryptocurrency, Bank transfer, Wire transfer. \nStep 4 - Transfer funds from your Apex Markets wallet to your Apex MT5 Trading Account.\nStep 5 - Trade like a PRO! IT’S THAT EASY.",
  },
  {
    question: "5. How many Apex Markets accounts can I have?",
    answer:
      "Apex Markets allows you to have an unlimited number of trading accounts on your Apex Markets Client Office profile. To do so, go to your profile and create a new Demo or Live Apex Markets MetaTrader 5 (MT5) trading account.\n\nJoin thousands of people who choose to trade with APEX MARKETS on over 2000 instruments including 24/7 trading of cryptocurrencies",
    answer2: new1,
  },
];

export const genfaqsdata = [
  {
    question: "1. Is there a Minimum Deposit Amount on Apex Markets?",
    answer:
      "Apex Markets requires a minimum deposit of $10 USD to open an account. However, if you deposit $100 USD or more, you will receive a 100% welcome deposit bonus. The more active trading days you accumulate, the more Apex Markets trading reward points you’ll earn for each day you trade! These points can be redeemed for cash or prizes, and the more you trade, the higher your chances of winning. So don’t wait – start trading today and earn your way to top prizes!",
    open: true,
  },
  {
    question:
      "2. What is the legal relationship between a Trader and Apex Markets?",
    answer:
      "Apex Markets is an e-commerce site that offers its authorized users a licensed software product as an online account facilitator. Apex Markets makes it simple for customers to create online accounts that allow them to access a variety of services.",
    open: false,
  },
  {
    question:
      "3. Which Cryptocurrency Wallet is best for depositing and withdrawing funds from your Apex Markets account?",
    answer:
      "Any TRUSTWORTHY Cryptocurrency Wallet can be used to deposit or withdraw funds from your Apex Markets account. However, our clients prefer the following easy-to-use wallets: Kraken.com, Coinbase.com, CEX.IO, and Mercuryo.IO.",
    answer2: starttradingbtn,
  },
  {
    question: "4. How do I withdraw my funds from Apex Markets?",
    answer: gen4,
    supportlink: email,
    title2: "\nApex Markets ⇒  Bank Account",
    answer2:
      "To withdraw funds from your Apex Markets Account via Bank Wire Transfer, the name entered in the banking information must match the name in the Apex Market Account.\n\nBank Wire Transfer withdrawals are processed the same day if requested before 12 p.m. EST. Requests received after 12 p.m. will be processed the next working day. Depending on your location, it should take anywhere from 1 to 5 working days for your funds to reach your Bank Account.",
    title3: "\nApex Markets ⇒ Cryptocurrency [Bitcoin or Ethereum]",
    answer3:
      "To withdraw Cryptocurrency from your Apex Markets Account, select either Bitcoin or Ethereum and enter your Bitcoin or Ethereum address for cryptocurrency withdrawals. Bitcoin and Ethereum withdrawals typically take anywhere from 5 minutes to a few hours to reach your wallet after being processed.\n\nIf you do not see your funds after the processing time has elapsed, reach out to us at support@apexmarkets.io",
  },
  {
    question: "5. Do I have to pay taxes on profits earned on Apex Markets?",
    answer:
      "Apex Markets does not require you to pay any taxes on the profits you make on our platform. All clients would be required to handle their taxes within their designated country.",
  },
  {
    question: "6. How can I contact the Apex Markets support team?",
    answer: gen6,
  },
  {
    question: "7. How do I update my personal details on Apex Markets?",
    answer:
      "Visit your Apex Market Client office and click your profile to update your personal information on your Apex Markets account.",
  },
  {
    question: "8. How do I update and verify my email on Apex Markets?",
    answer: gen8,
  },
  {
    question: "9. How do I reset my Apex Markets client office login password?",
    answer:
      "You can reset your Apex Market Client Office login password by going to the login page and selecting `forgot password`, after which you will receive a confirmation email with instructions on how to reset your Apex Markets Client Office login password.",
  },
  {
    question: "10. How do I change my Apex Markets trading account password?",
    answer: gen10,
  },
  {
    question: "11. I’m not receiving emails from Apex Markets, what do I do?",
    answer: gen11,
  },
  {
    question:
      "12. I Had an Apex Markets account in the past, how do I reactivate it?",
    title1: "To Reactivate Your Apex Markets Client Office Account:",
    answer:
      "To reactivate your Apex Markets Client Office Account, visit the login page and log in with your email address and password. If you do not remember your password, you can click the ‘forget password’ button and an email will be sent to you to reset your password. If you don’t remember your email address you can reach out to our support team via email at support@apexmarkets.io. ",
    title2:
      "To Reactivate Your Apex Markets MetaTrader 5 (MT5) Trading Account:",
    answer2: gen12,
    answer3: apexwebsite,
  },
  {
    question:
      "13. How do I delete my Apex Markets MetaTrader 5 (MT5) Trading Account?",
    answer: gen13,
  },
  {
    question: "14. My Apex Markets account has been closed, what’s going on?",
    answer: gen14,
  },
];

export const kycfaqsdata = [
  {
    question: "1. Do Apex Markets require KYC Verification Documents?",
    answer:
      "Yes, Apex Markets REQUIRES KYC verification documents from their clients to ensure that we are dealing with real people. Verification of KYC Documents also grants clients full access to their accounts and full access to the services available on the Apex Markets platform.",
    answer2: moreinfo,
    open: true,
  },
  {
    question:
      "2. What KYC Verification Documents are required by Apex Markets?",
    answer: "Apex Markets require the following KYC Verification Documents:",
    answer2: kycbullet,
    answer3: issues,
    open: false,
  },
  {
    question:
      "3. How do I upload my KYC Verification Documents on Apex Markets?",
    answer: kyc3,
    answer2: issues,
  },
  {
    question:
      "4. How long does it take for Apex Markets to process my KYC Verification Documents?",
    answer:
      " It may take up to 24 hours for the Apex Markets review team to process your KYC Verification Documents. You will be informed in an email of the status of your KYC Documents.",
    answer2: issues,
  },
  {
    question:
      "5. Why were my Apex Markets KYC Verification Documents rejected?",
    answer:
      "If your Apex Markets KYC Verification Documents were rejected, it means you did not upload a valid Government-issued ID, the documents were blurry or you did not correctly sign the Apex Markets Customer Agreement Document.",
    title2: "Please note:",
    answer2: kycbullet5,
    answer3:
      "Please check and ensure that all of the above criteria are met and resolve any issues you find with your document. You may re-submit your KYC documents once you have resolved the issues.",
    supportlink: issues,
  },
  {
    question:
      "6. I need to complete additional verification on Apex Markets before I can pay using their card payment method, what should I do?",
    answer:
      "To complete the necessary additional verification on your Apex Markets account, log in to your Apex Market Client Office Dashboard and submit the KYC verification documents there. Our review team will review your documents within 24 hours and notify you via email on the status of your documents.",
    answer2: issues,
  },
  {
    question: "7. How do I verify my Credit/Debit Card on Apex Markets?",
    answer: kyc7,
    answer2: issues,
  },
  {
    question: "8. My Apex Markets account status is flagged. What should I do?",
    answer:
      "If your Apex Markets account status is flagged, you should verify your account or contact us at support@apexmarkets.io for help.",
    answer2: issues,
  },
  {
    question: "9. Why is my Apex Markets Client Office Dashboard login locked?",
    answer2:
      "If your Apex Markets account is locked, this means that your Apex Markets account needs to be verified. ",
    answer3: kycbullet,
    answer4:
      "If your account is verified and you are still having this issue, contact us at support@apexmarkets.io.",
  },
  {
    question: "10. Why was my KYC Verification Documents  Rejected?",
    answer:
      "For rejected documents we need to show the reason for rejection. \n\nCommon reasons for rejection are:",
    answer2: kycbullet10,
  },
  {
    question: "11. Why are my KYC Verification documents pending?",
    answer:
      "If your KYC Verification documents are pending, please wait at least 24-48hrs for them to be verified. If that time frame has elapsed and your documents are still pending, you can reach out to us at support@apexmarkets.io for faster verification",
  },
];

export const depositfaqsdata = [
  {
    question: "1. Do Apex Markets have a deposit limit?",
    answer:
      "No, Apex Markets does not have a deposit limit. However, your Apex Markets account must be verified in order to have full access to our platform. Verify your account now and make a deposit to receive a 100% bonus on deposits of 100USD or more! The more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade! This makes it easy to get started with Apex and begin earning rewards right away.",
    answer2: moreinfo,
    open: true,
  },
  {
    question:
      "2. Do I have to be verified on Apex Markets before I can make a payment?",
    answer:
      "Yes, you have to be verified on Apex Markets before you can make a payment. Make a payment now by  simply following the steps below:",
    answer2:
      "1. Access the Apex Markets Client Office Dashboard.\n2. Complete the KYC Verification Process.\n3. After completing the KYC Verification process, navigate to the 'Deposit' tab on your Apex Markets Client Dashboard.\n4. Choose one of our fast and easy to use payment methods and follow the steps to make payment.",
    open: false,
  },
  {
    question:
      "3. Can I make payments to my Apex Markets account using a Credit/Debit Card?",
    answer:
      "You can make payments to your Apex Markets account using all major Credit and Debit Cards.",
    answer2:
      "To make a payment using a Credit/Debit Card:\n1. Access the Apex Markets Client Office Dashboard.\n2. Complete the KYC Verification Process.\n3. After completing the KYC Verification process, navigate to the 'Deposit' tab on your Apex Markets Client Dashboard.\n4. Choose one of our fast and easy to use payment methods and follow the steps to make payment.",
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! The more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade! So don't wait, make a deposit today to get started.",
  },
  {
    question:
      "4. Can I make payments to my Apex Markets account using Wire Transfer?",
    answer:
      "Apex Markets accepts Bank/Wire Transfer payments to our corporate Bank Account Apex E-Commerce Services LLC.",
    bold: "See Wire/Bank Transfer Details Below:",
    title2:
      "\nAPEX WIRE TRANSFER DETAILS- for International Transactions from persons outside of the US.",
    answer2:
      "Account Holder Name: APEX E-COMMERCE SERVICES\nAccount number:\nIBAN:\nAccount Holder Address  & bank Address\nSWIFT/BIC:\nBank Name:\nCurrency:\nReference/Note To Receiver: Put your Apex Markets Account # Here",
    bold2:
      "Please note: For Transactions outside the US, incoming payments can take 4-5 working days to be added to your account.",
    title3: "\nAPEX WIRE TRANSFER DETAILS- Transactions Inside of the US.",
    answer3:
      "Bank Name:\nAccount Holder:\nRouting number:\nAccount number:\nAccount Type:\nAddress:\nReference/Note To Receiver: Put your Apex Markets Account # Here",
    bold3:
      "Please note: For Transactions inside the US, incoming payments can take 2-3 working days to be added to your account",
    supportlink:
      "For faster transfers, you can consider using the Credit/Debit Card or Cryptocurrency Payment method.",
  },
  {
    question:
      "5. Can I make a payment to my Apex Markets account using cryptocurrency?",
    answer:
      "Yes, Apex Markets offers fast and easy deposits using Cryptocurrency (Bitcoin and Ethereum) payment methods.\nTo make payments to your account using Cryptocurrency, simply follow the steps below:",
    answer2: deposit5,
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! So make a deposit today to take advantage of this great offer! In addition, the more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade! So open an account today and start earning rewards!",
    bold3:
      "Please note: Cryptocurrency payments may take anywhere from 10 minutes to a few hours to process. Cryptocurrency payments are instant once processed. For a faster deposit, you can use the Credit/Debit card payment method.",
    supportlink: depositissues,
  },
  {
    question: "6.How do I make a payment to my Apex Markets account? ",
    answer:
      "It is simple and quick to make payments to your trading account. To make payments to your account, simply follow the steps below:",
    answer2: deposit5,
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! So make sure to take advantage of this great offer when making your deposit.",
    supportlink: depositissues,
  },
  {
    question:
      "7. What are the benefits of each of the payment methods offered by Apex Markets?",
    answer:
      "Apex Markets offers several payment methods that are fast, secure, easy to use, and have low fees. Regardless of which payment method you choose, you can be assured that your funds will arrive quickly and securely.\nTo make payments to your Apex Markets account, simply follow the steps below:",
    answer2: deposit7,
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! In addition, the more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade.",
    supportlink: depositissues,
  },
  {
    question: "8. Which payment method is the best to use on Apex Markets?",
    answer:
      "Apex Markets offers a variety of fast, secure, easy to use, and low-cost payment methods. The best payment method to use on Apex Markets depends on your accessibility and understanding of each. Credit or Debit Cards, Bank Wire Transfer, and Cryptocurrency are all accepted methods of payment on Apex Markets.\nCard deposits are instant, while Bank Wire Transfers take anywhere from 1-5 working days. Cryptocurrency deposits take 10 minutes to a few hours. To make payments to your account, simply follow the steps below:",
    answer2: deposit5,
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! In addition, the more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade!",
    supportlink: depositissues,
  },
  {
    question:
      "9. What are the fees associated with each payment method offered by Apex Markets?",
    answer:
      "Apex Markets does not charge any additional fees for deposits or withdrawals. However, some international banking institutions and credit/debit processors may charge transaction fees. Apex Markets does not accept responsibility for any such transaction fees.\nWhen you make a payment, it will be converted to USD and placed in your Apex Markets wallet. You can then use the funds in your wallet to make deposits and withdrawals, or to transfer funds internally to other Apex Markets accounts under your profile.\nTo make a payment, follow these steps:",
    answer2: deposit7,
    answer3:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! In addition, the more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade!",
    supportlink: depositissues,
  },
  {
    question:
      "10. How long does payment take to reflect in my Apex Markets account?",
    answer:
      "Based on the payment method you chose, payments may take different times to reflect in your Apex Markets account.\nPlease note:",
    answer2: deposit10,
    answer3: "To make payments to your account, simply follow the steps below:",
    answer4: deposit5,
    dashboardlink:
      "All deposits of $100 USD or more will receive a 100% welcome bonus! In addition, the more active trading days you accumulate, the more Apex Markets reward points you’ll earn for each day you trade!",
    supportlink: depositissues,
  },
  {
    question:
      "11. I made a payment but it is not reflecting, what should I do?",
    answer:
      "If you made a payment to your Apex Markets account and the payment is not being reflected in your account after the processing time has passed, email us at support@apexmarkets.io and we will be happy to assist you.",
  },
  {
    question:
      "12. I made a payment to Apex Markets using my Credit/Debit Card, but I want to withdraw the funds immediately using Cryptocurrency or Bank Wire Transfer. What should I do?",
    answer:
      "For security reasons, the initial accumulated amount of payment made with your Credit/Debit Card is sent back to the card that was initially used. All additional withdrawals will be processed to any other withdrawal method of your choice.",
  },
  {
    question:
      "13.  I did not receive my 100% deposit bonus on the payment made to my Apex Markets account. What should I do?",
    answer:
      "If you make a deposit of $100 USD or more to your Apex Markets account, you will be eligible to receive a 100% welcome bonus. This bonus is available to new traders who make a qualifying deposit and can be used to trade on the Apex Markets platform. In order to receive the bonus, you must meet the minimum deposit requirements and follow the terms and conditions of the bonus program. Once you have made your deposit, you will be able to trade on the platform and earn Apex Markets Reward Points for each day that you trade. These points can be redeemed for cash or prizes, and the more active you are on the platform, the more points you will earn. If you have any questions about the deposit bonus or the Apex Markets platform, you can contact our customer support team at support@apexmarkets.io and we will be happy to assist you.",
  },
  {
    question:
      "14. What will happen if I make a payment and then request a chargeback?",
    answer:
      "If you request a chargeback your account will be permanently banned.",
  },
  {
    question:
      "15. Can I use someone else’s card/bank account to make a payment on Apex Markets?",
    answer:
      "For security reasons, the Card/Bank account being used to make a payment to Apex Markets must belong to the owner of the Apex Markets account.",
  },
];

export const withdrawalfaqsdata = [
  {
    question:
      "1. Can I make withdrawals from my Apex Markets account using a Credit/Debit Card?",
    answer:
      "For security reasons, the initial accumulated amount of payment made with your Credit/Debit Card will be withdrawn to your card. All additional withdrawals will be processed to any other withdrawal method of your choice.",
    open: true,
  },
  {
    question:
      "2. Can I make withdrawals from my Apex Markets account using Bank/Wire Transfer?",
    answer:
      "Apex Markets allow clients to withdraw funds from their Apex Markets Account using Bank Wire Transfer.",
    bold: "\nFor Bank Wire Transfer withdrawal - International withdrawal made outside of the US.",
    answer2:
      "Beneficiary Bank Name:\nBeneficiary Bank Country:\nBeneficiary Bank Address:\nBeneficiary Account Holder Name:\nBeneficiary Address:\nBeneficiary Account Number:\nBeneficiary BIC/SWIFT Code:\nBeneficiary IBAN Code (optional)",
    bold2: "\nINTERMEDIARY INFORMATION:",
    answer3:
      "intermediary bank: \nIntermediary bank details\nIntermediary bank address :\nIntermediary Swift code:Intermediary routing/sort code:\nThen they will be asked for the Amount to withdraw and provided",
    bold3:
      "Please note: For Transactions outside of the US, payments can take 2-3 working days to arrive in your account\n\nFor Bank Wire Transfer withdrawal - Local Withdrawal made in the US.",
    answer4:
      "Bank Name:\nBank Address:\nBank Account type:\nAccount Holder Name:\nAccount Holder Address: \nAccount Routing number:\nAccount number: ",
    bold4:
      "Please note: For Transactions inside the US, payments can take 1 working day to arrive in your Bank Account.",
    open: false,
  },
  {
    question:
      "3. Can I make withdrawals from my Apex Markets account using cryptocurrency?",
    answer:
      "On Apex Markets you can withdraw funds from your Apex Markets account using Cryptocurrency to any Trustworthy Cryptocurrency wallet.\nHere are some easy to use platforms:",
    answer2: withdraw3,
  },
  {
    question:
      "4. How do I make a withdrawal from my Apex Markets account using cryptocurrency to Kraken.com, CEX.IO, Coinbase.com, Mercuryo.IO, Binance.com, or any cryptocurrency wallet of your choice?",
    answer: "To withdraw cryptocurrency from your Apex Markets account:",
    answer2: withdraw4,
  },
  {
    question: "5. How do I make a withdrawal from my Apex Markets account?",
    answer: "To make a withdrawal from your Apex Markets account:",
    answer2: withdraw5,
  },
  {
    question:
      "6. What are the benefits of each of the withdrawal methods offered by Apex Markets?",
    answer:
      "Using the withdrawal methods provided by Apex Markets allows you to withdraw your funds in record time with some of the lowest fees.",
  },
  {
    question: "7. Which withdrawal method is the best to use on Apex Markets?",
    answer:
      "Apex Markets offers a variety of excellent withdrawal options, including Bank Wire Transfers, which are processed the same day if requested before 12 p.m. EST. After 12 p.m requests will be processed the following working day. You can also request an immediate bitcoin withdrawal to your wallet. Bitcoin withdrawals can take anywhere from 10 minutes to a few hours to reach your cryptocurrency wallet after being processed on our platform.      ",
  },
  {
    question:
      "8. What are the fees associated with each withdrawal method offered by Apex Markets?    ",
    answer:
      "All Withdrawal fees are charged by the processor of the withdrawal method chosen on Apex Markets.       ",
  },
  {
    question:
      "9. How long do withdrawals take to reflect in my account after withdrawing from my Apex Markets account?      ",
    answer:
      "Based on the withdrawal method you chose, withdrawals from your Apex Markets account may take different times to reflect in your account.      ",
    bold: "Please note:      ",
    answer2: withdraw9,
  },
  {
    question:
      "10. Do I have to be verified on Apex Markets before I can make a withdrawal?",
    answer:
      "To be able to make withdrawals on  Apex Markets your account must be verified.\nApex Markets require the following KYC Verification Documents:",
    answer2: kycbullet,
    answer3: withdraw10,
  },
  {
    question:
      "11. I made a withdrawal from my Apex Markets account but it is not reflected in my bank account, what should I do?",
    answer:
      "If you made a withdrawal from your Apex Markets account and it is not yet reflected in your bank account, we recommend waiting 2-3 working days. If it is still not reflected, we suggest contacting the bank for more information.",
    answer2: withdrawissues,
  },
  {
    question:
      "12. I made a withdrawal from my Apex Markets account but it is not reflected in my Cryptocurrency Wallet, what should I do?",
    answer:
      "If you made a withdrawal from your Apex Markets account and it is not yet reflected in your Cryptocurrency Wallet, we recommend waiting anywhere from 5 minutes to 24 hours.\n\nIf it is still not reflected, we suggest contacting the Cryptocurrency Wallet provider for more information.      ",
    answer2: withdrawissues,
  },
  {
    question:
      "13.  What is the processing time for withdrawals from Apex Markets to my Bank account?",
    answer:
      "Withdrawal from your Apex Markets account to your Bank account usually takes 2-3 working days to be processed.\n\nIf the estimated arrival time has elapsed and you still have not received your withdrawal, we suggest contacting the bank for more information.      ",
    answer2: withdrawissues,
  },
  {
    question:
      "14. What is the processing time for withdrawals from Apex Markets to my Cryptocurrency Wallet?      ",
    answer:
      "The processing time for withdrawals from Apex Markets to your Cryptocurrency Wallet typically takes 10 minutes to a few hours. If the estimated arrival time has elapsed and you still have not received your withdrawal, we suggest contacting the Cryptocurrency Wallet provider for more information.",
    answer2: withdrawissues,
  },
  {
    question:
      "15. Why is my withdrawal from Apex Markets taking longer than usual?",
    answer:
      "If your Apex Markets withdrawal is taking longer than usual this may be as a result of a network issue. You can reach out to your bank or cryptocurrency wallet provider for more information.      ",
    answer2: withdrawissues,
  },
  {
    question:
      "16. Why is my withdrawal from Apex Markets less than expected?      ",
    answer:
      "If your withdrawal from Apex Markets is less than expected, a fee charge may have been deducted from your withdrawal resulting in a less-than-expected withdrawal amount.\n\nPlease note: All Withdrawal fees are charged by the processor of the withdrawal method chosen on Apex Markets.       ",
    answer2: withdrawissues,
  },
  {
    question:
      "17. What will happen if I make a withdrawal but a payment I made has a chargeback request?",
    answer:
      "If you request a chargeback for payment on your Apex Markets account, your account will automatically be forfeited.      ",
  },
  {
    question:
      "18. Can I use someone else’s Card/Bank account to make a withdrawal from Apex Markets?",
    answer:
      "To make a withdrawal from your Apex Markets account, the name on the Card/Bank account must match the name on your Apex Markets account.",
  },
  {
    question:
      "19. My withdrawal from Apex Markets was rejected, what should I do?",
    answer: withdraw19,
  },
  {
    question: "20. Can I withdraw funds from my Apex Markets Demo Account?",
    answer:
      "You are not permitted to withdraw funds from your Apex Demo Account. You must have an Apex Markets Live MetaTrader 5 (MT5) Trading account open in order to withdraw funds from your Apex Market Trading Account.",
  },
];

export const ordersfaqsdata = [
  {
    question: "1. What payment methods are available on Apex Markets?",
    answer:
      "The available payment methods to use on Apex Markets are: All major Debit/Credit Card, Cryptocurrency, Bank Wire Transfers.",
    open: true,
  },
  {
    question: "2. How long does it take to process payments on Apex Markets?",
    answer:
      "Based on the payment method you chose, payments may take different times to reflect in your Apex Markets account.\nPlease note:",
    answer2: orders2,
    open: false,
  },
  {
    question:
      "3. Does Apex Markets charge any fees? If ‘YES’,  Are the fees recurrent?",
    answer:
      "Apex Markets does not charge any payment fees. Your payment processor is responsible for any fees you may incur.",
  },
  {
    question:
      "4. I made a payment on my Apex markets account, when will I receive it?",
    answer: orders4,
  },
  {
    question:
      "5. My payment to Apex Markets has been declined or failed, what should I do?",
    answer:
      "If your payment to Apex Markets was declined or failed, For Card payments, check to see if the Card information is correctly entered or if you have sufficient funds on your Debit/Credit Card to make this payment. If you do this and your payment is still being declined or failing, we recommend you contact your financial institution and ask that they authorize the payment to Apex E-commerce Services Limited.\n\nFor Cryptocurrency payments, make sure you enter the correct Cryptocurrency address for the preferred Cryptocurrency method chosen. ",
    bold: "Tip:",
    answer2:
      "To avoid errors or failed/declined payment, copy and paste your Cryptocurrency address when using the Cryptocurrency payment method rather than typing out the address.Also, please ensure you have a sufficient amount of funds in your Cryptocurrency Wallet Balance to cover the payment amount plus processing fees.\n\nAfter all the issues have been resolved you can try making a payment again.",
    answer3: orders5,
  },
  {
    question:
      "6. What happens if I request a chargeback for a payment made to Apex Markets?",
    answer:
      "If you request a chargeback for payment on your Apex Markets account, your account will automatically be forfeited.",
  },
];

export const rulesfaqsdata = [
  {
    question: "1. What are the trading restrictions on Apex Markets?",
    answer:
      "At Apex Markets there are no trading restrictions. You can trade any market anytime, anywhere using your preferred trading style.      ",
    open: true,
  },
  {
    question: "2. Can I trade the news or hold my positions overnight?",
    answer:
      "Yes, you can trade the news and hold over your position overnight on your Apex Markets Trading account.        ",
    open: false,
  },
  {
    question:
      "3. Which financial instruments can I trade and what strategies am I allowed to use?      ",
    answer:
      " At Apex Markets we offer a range of over 2000+ tradable instruments which you can use: Forex markets, Cryptocurrencies, Stocks, Commodities, Indices, and Metals.      ",
  },
  {
    question: "4. Does Apex Markets allow copy trading or EAs?",
    answer:
      "Yes, at Apex Markets there are no trading restrictions. Apex Markets Markets allows clients to use EA’s and copy trading.",
  },
  {
    question:
      "5. What will happen if I request a chargeback on my Apex Market Account?",
    answer: " If you request a chargeback your account will be banned.",
  },
  {
    question: "6. What if I don’t understand the rules?",
    answer: rules6,
  },
];

export const metatraderfaqsdata = [
  {
    question: "1. Which platforms can I use for trading on Apex Markets?",
    answer: meta1,
    open: true,
  },
  {
    question: "2. What are the Apex Markets account specifications?",
    answer:
      "The Apex Markets account specifications are available directly on the MetaTrader 5 (MT5)  trading platform. To view the account specification on the MetaTrader 5 (MT5) app, open the Market Watch, right-click on the relevant instrument, and select ‘specification’.\n\nYou can trade over 2000 instruments in all markets on the MetaTrader 5 (MT5)  trading platform: Forex, stocks, cryptocurrencies, options, and more are all available.",
    open: false,
  },
  {
    question:
      "3. How do I access the MetaTrader 5 (MT5) platform to start trading?",
    answer: meta3,
  },
  {
    question:
      "4. Where do I find my Apex Markets MetaTrader 5 (MT5) Trading Account Credentials?",
    answer: meta4,
  },
  {
    question:
      "5. Why am I unable to close trades on my Apex Markets MetaTrader 5 (MT5) Trading Account",
    answer:
      "You may be unable to close trades on your Apex Markets MetaTrader 5 (MT5) Trading Account due to poor internet connection resulting in your Apex Markets Meta Trader 5 (MT5) account to freeze.\nTo resolve this issue:",
    answer2: meta5,
  },
  {
    question:
      "6. How do I change my Apex Markets MetaTrader 5 (MT5) Trading Account Password?",
    answer: meta6,
  },
  {
    question:
      "7. Can I change my Apex Markets MetaTrader 5 (MT5) Trading Account Number?",
    answer: meta7,
    answer2: support,
  },
  {
    question:
      "8. Can I delete my Apex Markets MetaTrader 5 (MT5) Trading Account Number?",
    answer: meta8,
  },
  {
    question:
      "9. How do I connect my Apex Markets MetaTrader 5 (MT5) Trading Account to the MetaTrader 5 (MT5) trading platform to start trading?",
    answer:
      "To connect your Apex Markets MetaTrader 5 (MT5) Trading Account to the MetaTrader 5 (MT5) application on your device:",
    answer2: meta9,
  },
  {
    question:
      "10. How do I change my Apex Markets Demo Account to a Live Account?",
    answer: meta10,
    answer2: support,
  },
  {
    question:
      "11. What leverage is offered by Apex Markets for MetaTrader 5 (MT5) Trading Accounts?",
    answer:
      "At Apex Markets we offer a leverage of up to 1:500  for MetaTrader 5 (MT5) Trading Accounts.",
  },
  {
    question:
      "12. How do I change my Apex Markets MetaTrader 5 (MT5) Trading Account leverage?",
    answer: meta12,
  },
  {
    question:
      "13. How to create a new Apex Markets MetaTrader 5 (MT5) Live Account to start trading? ",
    answer: meta13,
    answer2: support,
  },
  {
    question:
      "14. How to create a new Apex Markets MetaTrader 5 (MT5) Demo Account to start trading?      ",
    answer: meta14,
    answer2: support,
  },
  {
    question:
      "15. What is the company name or server name I should enter when connecting my Apex Markets MetaTrader 5 (MT5) Account to MetaTrader 5 (MT5)?",
    answer:
      "The company/server name you should enter when connecting your Apex Markets MetaTrader 5 (MT5) account to MetaTrader 5(MT5) is ‘Apex Capital Markets LLC’ or ‘ApexCapitalMarkets’. You should see our logo.    ",
    answer2: support,
  },
  {
    question:
      "16. The MetaTrader 5 (MT5) application is not loading or keeps freezing, what should I do?      ",
    answer:
      "If your withdrawal from Apex Markets is less than expected, a fee charge may have been deducted from your withdrawal resulting in a less-than-expected withdrawal amount.\n\nPlease note: All Withdrawal fees are charged by the processor of the withdrawal method chosen on Apex Markets.       ",
    answer2: meta16,
  },
  {
    question:
      "17. My Apex Markets MetaTrader 5 (MT5) Trading Account is disabled, what should I do?",
    answer:
      "If your Apex Markets MetaTrader 5 (MT5) trading account is disabled, it may be due to your account being inactive over a period of time. To enable your account, make a deposit. After depositing, close the MetaTrader 5 (MT5)  application, then reopen the application and try logging in again.      ",
  },
  {
    question:
      "18. I am logged in to my Apex Markets MetaTrader 5 (MT5) Trading Account however I am not able to place trades. What should I do?",
    answer:
      " If you are logged into your Apex Markets MetaTrader 5 (MT5) account and you’re not able to place trades try:",
    bold: "\nOption 1: ",
    answer2:
      "Close the MetaTrader 5 (MT5) application, restart your device, and try placing a trade again on the MetaTrader 5 (MT5) Application",
    bold2: "\nOption 2:",
    answer3: meta18,
  },
  {
    question:
      "19. The MetaTrader 5 platform keeps freezing causing me to be unable to place or manage open trades. What should I do?",
    answer:
      "f the MetaTrader 5 (MT5) application keeps freezing or you’re unable to place trades or manage open trades, the following steps should help resolve your issue:",
    answer2: meta19,
  },
  {
    question:
      "20. The MetaTrader 5 (MT5) application is showing an error code and not allowing me to log in to my Apex Markets MetaTrader 5 (MT5) Trading Account, what should I do?",
    answer: meta20,
  },
];
export const affiliatefaqsdata = [
  {
    question: "1. What is the Apex Markets Affiliate Program?",
    answer:
      "The Apex Markets Affiliate Program allows you to promote the services of Apex Markets and earn up to 50% commission for each lot traded on our platform. There are no complicated application forms or long waiting times and by signing up, we’ll give you your own unique referral link. Use this link to invite the people you know. If anyone clicks the link and registers on Apex Markets, they’ll be automatically attributed as your referee. You’ll receive a commission of up to 50%.",
    open: true,
  },
  {
    question: "2. How does the Apex Markets Affiliate Program work?",
    answer:
      "The Apex Markets Affiliate Program allows you to promote the services of Apex Markets and earn up to 50% commission for each lot traded on our platform.\nTo do so:",
    answer2: affiliate2,
    open: false,
  },
  {
    question: "3. What are the benefits of becoming an Apex Markets Affiliate?",
    answer:
      "Being an Apex Markets Affiliate allows you to earn the HIGHEST commission in the trading industry!\nRecommend Apex Markets now and earn up to 50% commission for each lot traded on our platform.\nHere are some additional benefits:",
    answer2: affiliate3,
  },
  {
    question:
      "4. What are the requirements to become an Apex Markets Affiliate partner?",
    answer:
      "To become an Apex Markets affiliate, sign up to become our partner and submit your application by clicking {‘Become Our Partner’}. Create and share your unique referral link with your family and friends. Create, manage and track the performance of your invites easily from your Apex Markets Client Dashboard. Earn up to 50% commission for each lot traded on our platform.",
  },
  {
    question:
      "5. Who should sign up to become an Apex Markets Affiliate Partner?",
    answer:
      "Do you have a social media account, community, group, business, or organization with active followers?\nAre you a YouTuber, writer in media, or any other content creator?\nAre you a Forex, Crypto, Stock, or Indices trader or trading community leader?\n\nWell, if your answer to any of the above questions is “YES” then you should sign up and become an Apex Markets affiliate partner.\nIt’s simple and easy.",

    answer2: affiliate5,
  },
  {
    question: "6. How does the Apex Markets Affiliate program work?",
    answer:
      "The Apex Markets Affiliate program is Simple and Easy:\nPromote Apex Markets and receive up to 50% commission for each lot traded. Promote us like you would any business opportunity or product you use daily. There is no monthly fee to being a part of the Apex Markets Affiliate program, so you can become an Affiliate and start EARNING with only one click of your mouse!",
  },
  {
    question:
      "7. How can I refer my friends using my Apex Markets Referral Link?",
    answer:
      "Sign in to your Apex Markets Client Dashboard account and select Refer Friends. You can copy your unique referral link to share on your own channels, or share directly via Email, YouTube, Instagram, Facebook, Twitter, What’s App, Telegram, and LinkedIn.",
  },
];
export const payoutfaqsdata = [
  {
    question: "1. How do I get my fund from my Apex Markets Affiliate?",
    answer:
      "Apex Markets Affiliate commissions are paid instantly and can be withdrawn instantly using one of our super fast and easy to use payout methods which include Bank Wire Transfer or Cryptocurrency.\nWhen you sign up, don’t forget to add your Bank Wire Transfer or Cryptocurrency Details to your affiliate account. For commissions exceeding $1,000 monthly, we support payout by direct wire transfer.",
    open: true,
  },
  {
    question:
      "2. Is there a minimum Affiliate payout amount? If yes, how much is it?",
    answer:
      "No, at Apex Markets there is no minimum affiliate payout amount. This enabled our affiliate partners to accumulate more earnings and scale up to earning up to 50% affiliate payout based on the number of successful referrals made.",
    open: false,
  },
  {
    question: "3. What is the Apex Markets Affiliate payout schedule?",
    answer:
      "Apex Markets Affiliate payouts are sent based on our normal withdrawal timeframes. However, to reach our affiliate target to receive a 50% commission payout, we recommend you invite more persons to use our platform within a 1 month period.",
  },
  {
    question: "4. What are the Apex Markets Affiliate payout methods?",
    answer:
      "Apex Markets offer payouts to affiliates partners through a variety of our super fast and easy to use payment methods: Bank Wire Transfer, Cryptocurrency, or Credit/Debit Card.\nAs an Apex Markets affiliate, you are able to choose any of our easy to use methods of payment to receive your affiliate payout (or any combination of these methods).",
  },
  {
    question: "5. How can I request an Apex Markets affiliate payout?",
    answer:
      'Apex Markets affiliate partners can request a payout by logging in to the Apex Markets client\'s dashboard and clicking "Affiliate" then requesting their payout by any of our super fast and easy to use payout methods such as Bank Wire Transfer or Cryptocurrency.',
  },
  {
    question:
      "6. How long will my Apex Markets affiliate payout take to arrive?",
    answer:
      "The time taken for you to receive your Apex markets affiliate payout is determined by the payout method chosen and the time at which the request was made.",
  },
  {
    question:
      "7. How long does it take to process Apex Markets affiliate payouts?",
    answer:
      "Once an Apex Markets affiliate partner requests an affiliate payout, payouts are subjected to our normal withdrawal process and are based on the withdrawal method chosen.",
  },
  {
    question: "8. When can I request my Apex Markets affiliate payout?",
    answer:
      "As an Apex Markets affiliate partner, you can request your affiliate payout at any time, and it will be sent to you as soon as our payment review team approves your payout request. However, the time for you to receive your payment may vary based on the payout method selected.",
  },
  {
    question: "9. What are the Apex Markets affiliate payout fees?",
    answer:
      "Apex Markets does NOT charge an affiliate payout fee.\nYou can withdraw commission immediately using one of our easy to use withdrawal methods such as Bank Wire Transfer or Cryptocurrency. However, based on the method you choose for your affiliate payout you might incur a transfer fee.",
  },
  {
    question:
      "10. Do I need to be verified to receive an Apex Market affiliate payout?",
    answer:
      "Yes, your account must be verified to receive an Apex Market affiliate payout. At Apex Markets we take your security as seriously as you do, so we’ll do everything within our power to protect both you and our business.\n\nBelow are the required documents for KYC verification:\nSubmit your documents for verification:",
    answer2: kycbullet,
  },
  {
    question:
      "11. What happens if a commission comes from fraudulent signup? Will I still get paid?",
    answer:
      "Apex markets will not pay commission for any payments made by a fraudulent customer.",
  },
  {
    question: "12. Does Apex Markets pay recurring commissions to affiliates?",
    answer:
      "Yes, you can promote Apex Markets and earn money for every purchase one of your referrals makes, not just a commission on their first purchase alone. But, all of them!\nWhat are you waiting for?",
    answer2:
      "Join the Apex Markets Affiliate Program TODAY!\nInvite your friends & Earn up to 50% commission INSTANTLY, for every lot traded.",
  },
];
export const marketingfaqsdata = [
  {
    question:
      "1. Do you have any banners or marketing materials for me to promote Apex Markets?",
    answer:
      "Yes! We’ll provide you with all marketing materials, such as banners, screenshots, infographics, videos, and logos, once you sign up for our Apex Markets affiliate program.",
    open: true,
  },
  {
    question: "2. How does the Apex Markets Affiliate Program track clicks?",
    answer:
      "Apex Markets affiliate programs track clicks with our reliable in-house system, so you never lose any of the commissions you refer.",
    open: false,
  },
  {
    question: "3. What marketing methods are not allowed?",
    answer: "At Apex Markets we do NOT allow the following:",
    answer2: marketing2,
    answer3:
      "Any Apex Markets affiliates found violating or attempting to circumvent these rules will have their commissions voided and payments withheld. Additionally, they will be banned from further participation in the Apex Markets Affiliate Program.",
  },
];
export const additionalfaqsdata = [
  {
    question: "1. What does credit out mean?",
    answer:
      "Credit is additional capital given to traders on special occasions, so they can leverage our funds to have more money to place trades. As such, once your account is inactive, or you’ve made a withdrawal of funds from your trading account, credit is withdrawn also. In short, Credit belongs to the broker and your capital belongs to you.",
    open: true,
  },
  {
    question: "2. Why was ‘Credit’ removed from my account?",
    answer: "Once your account balance goes zero, so does your credit.",
    open: false,
  },
];
