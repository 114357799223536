import moment from "moment";
import React from "react";

const CustomerInformation = ({ customer }) => {
  const { city_town_district, country, line1, line2, state_parish_pronvince } =
    customer?.customer_address?.[0] || {};
  return (
    <div className="p-4">
      <h2 className="text-xl font-bold mb-4">Customer Information</h2>
      <div className="grid grid-cols-2 gap-2">
        <div>
          <strong>Firstname:</strong>
        </div>
        <div>{customer.Firstname || "-"}</div>

        <div>
          <strong>Lastname:</strong>
        </div>
        <div>{customer.Lastname || "-"}</div>

        <div>
          <strong>Gender:</strong>
        </div>
        <div>{customer.gender || "-"}</div>

        <div>
          <strong>DOB:</strong>
        </div>
        <div>{customer.DOB || "-"}</div>

        <div>
          <strong>Phone:</strong>
        </div>
        <div>{customer.Telephone_Cell || "-"}</div>

        <div>
          <strong>Email:</strong>
        </div>
        <div>{customer.Email || "-"}</div>

        <div>
          <strong>Street Address 1:</strong>
        </div>
        <div>{line1 || "-"}</div>

        <div>
          <strong>Street Address 2:</strong>
        </div>
        <div>{line2 || "-"}</div>

        <div>
          <strong>City:</strong>
        </div>
        <div>{city_town_district || "-"}</div>

        <div>
          <strong>State:</strong>
        </div>
        <div>{state_parish_pronvince || "-"}</div>

        <div>
          <strong>Postal Code:</strong>
        </div>
        <div>{customer.Postal_Code || "-"}</div>

        <div>
          <strong>Country:</strong>
        </div>
        <div>{country || "-"}</div>

        <div>
          <strong>Date Created:</strong>
        </div>
        <div>{customer.Date_Created || "-"}</div>

        <div>
          <strong>Ran Automated KYC:</strong>
        </div>
        <div>{customer.Ran_Automated_KYC == 1 ? "True" : "False" || "-"}</div>

        <div>
          <strong>Verified By:</strong>
        </div>
        <div>{`${customer?.Verified_By_admin?.Customer?.Firstname ?? "-"} 
          ${customer?.Verified_By_admin?.Customer?.Lastname ?? "-"}`}</div>

        <div>
          <strong>Verified Date:</strong>
        </div>
        <div>
          {(customer.Verified_Date &&
            moment(customer?.Verified_Date)?.format("DD/MM/YY HH:mm:ss A")) ||
            "-"}
        </div>
      </div>
    </div>
  );
};

export default CustomerInformation;
