import React from "react";

import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import info from "../images/icons/info2.svg";

// css in
import "./DepositForm.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const ConfirmDeposit = ({ closeModal, confirmTitle, valueAmt }) => {
  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>{confirmTitle}</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="confirm-form">
              <div className="confirm-row">
                <p>Amount</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>

              <div className="confirm-row">
                <p>Pay with</p>
                <span>
                  <img src="" alt="" />
                  **** 2454
                </span>
              </div>

              <div className="confirm-row">
                <p>
                  Fee
                  <span>
                    <img src={info} alt="" />
                  </span>
                </p>
                <span>0.00</span>
              </div>

              <div className="confirm-row">
                <p>Processing Time (approx)</p> <span>Instant</span>
              </div>

              <div className="confirm-row">
                <p>Total</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>
            </div>

            {/* <div className="df-save-card">
              <input type="checkbox" />
              <span className="label"></span>
              <p>
                I have read and agree with Apex Market’s
                <span className="blue"> User Agreement</span> and{" "}
                <span className="blue">Privacy Policy</span>. and I authorize
                Apex Market’s to debit my chosen payment method for the amount
                above on {date}.
              </p>
            </div> */}

            <button className="verification-continue-btn complete">
              Confirm & Proceed
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfirmDeposit;
