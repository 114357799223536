import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";

// css
import "./DepositForm.css";
import {
  addPendingCharge,
  confirmPendingCharge,
  validateCardDetails,
} from "../api/card";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { toast } from "react-toastify";
import OverlayLoader from "./OverlayLoader";

import InputMask from "react-input-mask";
import defaultcard from "../images/icons/credit-card.svg";
import visa from "../images/icons/visa.svg";
import mastercard from "../images/icons/mastercard.svg";
import { FadeLoader } from "react-spinners";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const DepositForm = ({ closeModal, openModal, valueAmt }) => {
  const customer = useSelector(selectCurrentCustomer);

  const [ccNumber, setCCNumber] = useState();
  const [ccNumberMask, setCCNumberMask] = useState();
  const [ccLogo, setCCLogo] = useState(defaultcard);

  const [name, setName] = useState();
  const [amount, setAmount] = useState(valueAmt);
  const [cvv, setCvv] = useState();
  const [expiryDate, setExpiryDate] = useState();
  const [processing, setProcessing] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (customer != null) {
      setName(`${customer.Firstname} ${customer.Lastname}`);
    }
  }, [customer]);

  useEffect(() => {
    // document.addEventListener("click", handleClickOutside, true);
  });

  const handleCreditCardInput = (event) => {
    var value = event.target.value;
    var mask = "9999-9999-9999-9999";
    var logo = defaultcard;
    if (/^3[47]/.test(value)) {
      mask = "9999-999999-99999";
    }
    if (/^4[1-9]/.test(value)) {
      logo = visa;
    }
    if (/^5[1-5]/.test(value)) {
      logo = mastercard;
    }
    setCCNumberMask(mask);
    setCCNumber(value);
    setCCLogo(logo);
  };

  const handleClickOutside = (e) => {
    if (!refOne?.current?.contains(e.target)) {
      // closeModal(false);
    }
  };
  const refOne = useRef(null);

  const getForm = (url, target, values, method) => {
    function grabValues(x) {
      var path = [];
      var depth = 0;
      var results = [];

      function iterate(x) {
        switch (typeof x) {
          case "function":
          case "undefined":
          case "null":
            break;
          case "object":
            if (Array.isArray(x))
              for (var i = 0; i < x.length; i++) {
                path[depth++] = i;
                iterate(x[i]);
              }
            else
              for (var i in x) {
                path[depth++] = i;
                iterate(x[i]);
              }
            break;
          default:
            results.push({
              path: path.slice(0),
              value: x,
            });
            break;
        }
        path.splice(--depth);
      }
      iterate(x);
      return results;
    }
    var form = document.createElement("form");
    form.method = method;
    form.action = url;
    form.target = target;

    var values = grabValues(values);

    for (var j = 0; j < values.length; j++) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.value = values[j].value;
      input.name = values[j].path[0];
      for (var k = 1; k < values[j].path.length; k++) {
        input.name += "[" + values[j].path[k] + "]";
      }
      form.appendChild(input);
    }
    return form;
  };

  const submitCardCharge = async () => {
    setIsDisabled(true);
    setProcessing(true);
    const data = {
      name,
      amount: amount != null && amount !== "" ? amount : 0,
      email: customer.Email,
      phoneNumber: customer.Telephone_Cell,
      ccnumber: ccNumber.replace(/-/g, ""),
      expiryDate,
      cvv,
    };

    const validationReponse = await validateCardDetails(data);
    if (validationReponse.errors == null) {
      const firstSixDigitsOfCard = data?.ccnumber.slice(0, 6) || null;
      const lastFourDigitsOfCard = data.ccnumber.slice(-4);
      const pendingChargeResponse = await addPendingCharge(
        data.amount,
        firstSixDigitsOfCard,
        lastFourDigitsOfCard,
        expiryDate
      );

      if (
        pendingChargeResponse.errors == null &&
        pendingChargeResponse?.cardCharge?.ID != null
      ) {
        data.orderNo = pendingChargeResponse.cardCharge.PaymentProcessorOrderNo;
        var form = getForm(
          process.env.REACT_APP_PAYMENT_URL,
          // "https://apexcashier.io/payment-process.php",
          // "https://apexcashier.io/uat/payment-process.php",
          "",
          data,
          "post"
        );

        document.body.appendChild(form);
        form.submit();
        form.parentNode.removeChild(form);
        const cardCheckInterval = setInterval(() => {
          confirmPendingCharge(
            firstSixDigitsOfCard,
            lastFourDigitsOfCard,
            pendingChargeResponse.cardCharge.ID,
            data.orderNo
          )
            .then((response) => {
              if (response.errors != null) {
                clearInterval(cardCheckInterval);

                setProcessing(false);
              }
              if (response.errors == null && response.cardCharge != null) {
                clearInterval(cardCheckInterval);

                setProcessing(false);
                toast.success("Successfully processed payment");
                closeModal(false);
                setTimeout(() => {
                  window.location.reload();
                  setIsDisabled(false);
                }, 3000);
              }
            })
            .catch((err) => {
              clearInterval(cardCheckInterval);
              setIsDisabled(false);
              setProcessing(false);
            });
        }, 5000);
      }
    } else {
      setIsDisabled(false);
      setProcessing(false);
      toast.error(validationReponse.errors);
    }
  };

  return (
    <ModalContainer>
      {processing ? (
        <div className="absolute flex justify-center items-center h-[100vh] w-full z-[9999]">
          <FadeLoader color={color} loading={processing} size={80} />
        </div>
      ) : null}

      <div className="cpm" ref={refOne}>
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Deposit via Credit/Debit Card</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Amount</p>
              </div>
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={valueAmt}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                />
                <div className="select-currency-btn currency-type">
                  USD<BsChevronDown></BsChevronDown>
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardHolde Name">Card Holder Name</label>
            </div>
            <div className="hide-pw">
              <input
                type="text"
                id="CardHolderName"
                name="CardHolderName"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Verified card holder name"
                readOnly
              />
            </div>
            <div className="form-label-warning">
              <label htmlFor="CardNumber">Card Number</label>
            </div>
            <div className="hide-pw card_number_field">
              <InputMask
                mask={ccNumberMask}
                maskChar={null}
                type="text"
                id="CardNumber"
                name="CardNumber"
                placeholder="Enter card number"
                value={ccNumber}
                onChange={(e) => handleCreditCardInput(e)}
              />
              <img src={ccLogo} alt="card logo" />
            </div>
            <div className="form-flex-row">
              <div>
                <div className="form-label-warning">
                  <label htmlFor="ExpiryDate">Expiry Date</label>
                </div>
                <div className="hide-pw">
                  <InputMask
                    mask="99/99"
                    maskChar={null}
                    type="text"
                    id="ExpiryDate"
                    name="ExpiryDate"
                    placeholder="MM/YY"
                    value={expiryDate}
                    onChange={(e) => setExpiryDate(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <div className="form-label-warning">
                  <label htmlFor="SecurityCode">Security Code (CVV)</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    id="SecurityCode"
                    name="SecurityCode"
                    placeholder="Enter CVV"
                    value={cvv}
                    onChange={(e) => setCvv(e.target.value)}
                  />
                </div>
              </div>
            </div>

            <button
              disabled={isDisabled}
              className="verification-continue-btn complete"
              onClick={() => submitCardCharge()}
            >
              Pay
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default DepositForm;
