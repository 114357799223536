import React, { useEffect } from "react";

const KycButtons = ({
  continueBtn,
  previousBtn,
  index,
  disabled,
  setSubmitForm,
  typeOfDocumentToResubmit,
  setReSubmitForm,
  retrievedFront,
  retrievedSelfie,
  retrievedSig,
}) => {
  console.log(typeOfDocumentToResubmit,"typeOfDocumentToResubmit");
  
  disabled = disabled == "" || disabled == null || disabled == "undefined";
  return (
    <div className="flex justify-between w-full gap-4 mt-4 text-base sm:w-auto sm:ml-auto">
      {index > 1 && !typeOfDocumentToResubmit && (
        <div
          className="flex items-center justify-center flex-1 px-4 py-2 font-bold border rounded-md cursor-pointer border-slate-200"
          onClick={() => {
            if (index === 4 && retrievedFront) {
              previousBtn("sub");
              return;
            }
            previousBtn();
          }}
        >
          Previous
        </div>
      )}

      <button
        className={`w-full px-4 flex-1 py-2 font-bold text-white bg-blue-600 rounded-md cursor-pointer ${
          disabled && "bg-blue-600/20"
        }`}
        onClick={() => {
          if (
            (typeOfDocumentToResubmit == "Passport" && index == 3) ||
            (typeOfDocumentToResubmit == "National ID" && index == 3) ||
            (typeOfDocumentToResubmit == "Drivers License" && index == 3) ||
            (typeOfDocumentToResubmit == "Selfie" && index == 4) ||
            (typeOfDocumentToResubmit == "Signed Signature" && index == 5)
          ) {
            console.log("inder agitya a");
            
            setReSubmitForm(true);
            return;
          }
          if (
            (typeOfDocumentToResubmit == "Passport" &&
              index == 3 &&
              retrievedFront) ||
            (typeOfDocumentToResubmit == "National ID" &&
              index == 3 &&
              retrievedFront) ||
            (typeOfDocumentToResubmit == "Drivers license" &&
              index == 3 &&
              retrievedFront) ||
            (typeOfDocumentToResubmit == "Selfie" &&
              index == 4 &&
              retrievedSelfie)
          ) {
            window.location.href = "/settings-kyc-verification";
            return;
          }
          if (index === 5 && retrievedSig) {
            setSubmitForm(true);
            return;
          }
          if (index === 5 && !retrievedSig) {
            setSubmitForm(true);
            return;
          }
          if (index === 2 && retrievedFront) {
            continueBtn("sum");
            return;
          }
          continueBtn();
        }}
        disabled={disabled}
      >
        {index === 1 && "Next: Upload an ID document"}
        {index === 2 && retrievedFront && "Proceed"}
        {index === 3 && retrievedFront && "Proceed"}
        {index === 2 && !retrievedFront && "Next: Upload a document"}
        {index === 3 && !retrievedFront && "Next"}
        {index === 4 && !retrievedSelfie && "Next"}
        {index === 4 && retrievedSelfie && "Proceed"}
        {index === 5 && !retrievedSig && "Upload and Submit"}
        {index === 5 && retrievedSig && "Proceed"}
      </button>
    </div>
  );
};

export default KycButtons;
