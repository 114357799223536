import React, { useRef } from "react";
import "../../pages/Verification.css";
import SignatureCanvas from "react-signature-canvas";
import "react-image-crop/dist/ReactCrop.css";
import { toast } from "react-toastify";
import CustomQRCode from "../QRCodeGenerator";
import { useStore } from "../../store";

const SignatureConfirmation = ({
  signatureImage,
  setSignatureImage,
  typeOfDocumentToResubmit,
  setSigImageFormData,
  frontPreview,
  retrievedFront,
  customer,
  documentToResubmit,
  isMobile,
}) => {
  const { docFront } = useStore();
  const baseURL = process.env.REACT_APP_WEBSITE_URL;
  const emailPrefix = customer?.Email?.split("@")[0];
  const token = customer.token;

  const resubmitParam = typeOfDocumentToResubmit
    ? `resubmit=${documentToResubmit.id}`
    : "";

  const QRValue = `${baseURL}/kyc-verification?${resubmitParam}&docFront=${
    docFront ? docFront : retrievedFront ? retrievedFront.image_path : ""
  }&type=Signed%20Signature&email=${emailPrefix}&token=${token}`;

  const canvasRef = useRef(null);
  const inputRef = useRef(null);

  const handleClick = () => {
    inputRef.current.click();
  };

  const clearSignature = () => {
    setSigImageFormData("");
    setSignatureImage("");
    setTimeout(() => {
      canvasRef?.current?.clear();
    }, 10);
  };

  const captureSignature = () => {
    const canvas = canvasRef.current.getCanvas();
    const dataUrl = canvas.toDataURL();
    const filename = `signature_${Date.now()}.jpeg`;
    fetch(dataUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const formData = new FormData();
        formData.append("image", blob, filename);
        setSigImageFormData(formData);
      })
      .catch((err) => {
        toast.error("something went wrong, please try again");
        clearSignature();
      });
  };

  const handleFileInputChange = (event) => {
    event.preventDefault();
    const maxSizeInBytes = 4 * 1024 * 1024;
    const files =
      event.type === "drop" ? event.dataTransfer.files : event.target.files;
    if (files && files[0].size > maxSizeInBytes) {
      toast.error(
        "File size exceeds the limit (4MB). Please select a smaller file."
      );
      event.target.value = null;
      return;
    }
    if (files.length) {
      const filename = `signature_${Date.now()}.jpeg`;
      setSignatureImage(files[0]);
      const formData = new FormData();
      formData.append("image", files[0], filename);
      setSigImageFormData(formData);
    }
  };

  return (
    <>
      <div className="space-y-4 ">
        <p className="text-xl">
          {typeOfDocumentToResubmit
            ? "Upload your signature*"
            : "Draw your signature here*"}
        </p>
      </div>

      <div className="sig-input">
        <p className="text-sm font-[400]">
          {!typeOfDocumentToResubmit &&
            " Draw your signature within the frame to continue."}
        </p>

        <div className="flex flex-col items-center justify-center space-y-4">
          {!typeOfDocumentToResubmit ? (
            <>
              {retrievedFront ? (
                <img
                  className="w-full rounded-lg"
                  src={retrievedFront.image_path}
                />
              ) : (
                <img
                  className="w-full rounded-lg"
                  src={URL.createObjectURL(frontPreview)}
                />
              )}
              <SignatureCanvas
                ref={canvasRef}
                penColor="black"
                canvasProps={{
                  className: "sigCanvas",
                }}
                onEnd={() => captureSignature()}
              />

              {signatureImage && (
                <img
                  className="w-full rounded-lg"
                  src={URL.createObjectURL(signatureImage)}
                />
              )}
              <input
                type="file"
                onChange={handleFileInputChange}
                ref={inputRef}
                accept="image/jpeg"
                style={{ display: "none" }}
              />
              <button
                className="px-4 py-2 text-white bg-blue-600 rounded-md cursor-pointer"
                onClick={handleClick}
              >
                Choose a file
              </button>

              <div className="flex justify-end w-full">
                <button
                  className="px-4 py-2 font-bold  border border-solid border-slate-200 rounded-md cursor-pointer"
                  onClick={clearSignature}
                >
                  Clear
                </button>
              </div>
            </>
          ) : (
            <>
              {signatureImage && (
                <img
                  className="w-full rounded-lg"
                  src={URL.createObjectURL(signatureImage)}
                />
              )}
              <input
                type="file"
                onChange={handleFileInputChange}
                ref={inputRef}
                accept="image/jpeg"
                style={{ display: "none" }}
              />
              <button
                className="px-4 py-2 text-white bg-blue-600 rounded-md cursor-pointer"
                onClick={handleClick}
              >
                Choose a file
              </button>

              {!isMobile && (
                <div className="p-4 flex md:flex-row flex-col items-center justify-center rounded-md md:h-[178px] h-auto border-2 border-[#eaecf0]">
                  <div className="p-10">
                    {/* <CustomQRCode value={QRValue} /> */}
                    <CustomQRCode value={"This feature is currently under maintenance"} />
                  </div>
                  <div className="flex flex-col space-y-2 text-center text-black md:text-left">
                    <p className="text-lg font-bold">Scan the QR code</p>
                    <p className="text-sm font-light">
                      Open the camera app and scan the QR code on screen.
                    </p>
                    <p className="text-xs text-blue-600 underline cursor-pointer">
                      How to scan QR code
                    </p>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SignatureConfirmation;
