export const payoutrankdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Visa",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Visa",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Visa",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Wire",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Crypto",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    method: "Mastercard",
    profit: "$22045.05",
  },
];

export const payoutcolNames = [
  "No",
  "Login Id",
  "Name",
  "Method",
  "Amount (USD)",
];
