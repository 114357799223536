import React from "react";
import LegalDocumentsSelector from "../components/LegalDocumentsSelector";
import AppBar from "../components/AppBar";

const RiskDisclosureNotice = ({ showNav }) => {
  showNav(true);
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Legal Documents"></AppBar>
        <LegalDocumentsSelector></LegalDocumentsSelector>
        <div className="faq-container">
          <div className="terms-conditions">
            <h4>A. How To Interpret This Risk Warning</h4>
            <p>
              All terms used in this notice, which are defined in the Apex
              Markets Terms & Conditions (the “Terms & Conditions”), have the
              same meaning and construction as in the Terms & Condition.
            </p>

            <h4>B. Binance Services</h4>
            <p>
              This notice provides you with information about the risks
              associated with Apex Markets’ Services. Each Apex Markets Service
              has its own distinct risks. This notice provides a general
              description of the risks when you use Apex Markets’ Services.
              <br />
              This notice does not explain all of the risks or how such risks
              relate to your personal circumstances. It is important that you
              fully understand the risks involved before making a decision to
              use Apex Markets’ Services.
            </p>

            <h4>C. No Personal Advice</h4>
            <p>
              We do not provide personal advice in relation to our products or
              services. We sometimes provide factual information, information
              about transaction procedures and information about the potential
              risks. However, any decision to use our products or services is
              made by you. No communication or information provided to you by
              Binance is intended as, or shall be considered or construed as,
              investment advice, financial advice, trading advice, or any other
              sort of advice. You are solely responsible for determining whether
              any investment, investment strategy or related transaction is
              appropriate for you according to your personal investment
              objectives, financial circumstances and risk tolerance.
            </p>

            <h4>D. No Monitoring</h4>
            <p>
              Binance is not your broker, intermediary, agent, or advisor and
              has no fiduciary relationship or obligation to you in connection
              with any trades or other decisions or activities undertaken by you
              using Binance Services. We do not monitor whether your use of
              Binance Services is consistent with your financial goals and
              objectives. It is up to you to assess whether your financial
              resources are adequate for your financial activity with us, and to
              your risk appetite in the products and services you use.
            </p>
            <h4>E. No Tax, Regulatory or Legal Advice</h4>
            <p>
              The taxation of Digital Assets is uncertain, and you are
              responsible for determining what taxes you might be liable to, and
              how they apply, when transacting through the Binance Services. It
              is your responsibility to report and pay any taxes that may arise
              from transacting on the Binance Services, and you acknowledge that
              Binance does not provide legal or tax advice relative to these
              transactions. If you have any doubts about your tax status or
              obligations when using Binance Services, or with respect to the
              Digital Assets held to the credit of your Binance account, you may
              wish to seek independent advice.
            </p>
            <p>
              You acknowledge that, when, where and as required by applicable
              legislation, Apex Markets shall report information regarding your
              transactions, transfers, distributions or payments to tax or other
              public authorities. Similarly, when, where and as required by
              applicable law, Apex Markets shall withhold taxes related to your
              transactions, transfers, distributions or payments. Applicable
              legislation could also prompt Apex Markets to request you for
              additional tax information, status, certificates or documentation.
              You acknowledge that failure to answer these requests within the
              timeframe defined, may result in withholding taxes by Apex
              Markets, to be remitted to tax authorities as defined by relevant
              law. You are encouraged to seek professional and personal tax
              advice regarding the above and before making any digital asset
              transaction.
            </p>
            <h4>F. Market Risks</h4>
            <p>
              Digital Asset trading is subject to high market risk and price
              volatility. Changes in value may be significant and may occur
              rapidly and without warning. Past performance is not a reliable
              indicator of future performance. The value of an investment and
              any returns can go down as well as up, and you may not get back
              the amount you had invested.
            </p>
            <h4>G. Liquidity risk</h4>
            <p>
              Digital Assets may have limited liquidity which may make it
              difficult or impossible for you to sell or exit a position when
              you wish to do so. This may occur at any time, including at times
              of rapid price movements.
            </p>
            <h4>H. Fees & Charges</h4>
            <p>
              Our fees and charges are set out here. Apex Markets may, in its
              discretion, update the fees & charges from time to time. Please be
              aware of all costs and charges that apply to you, because such
              costs and charges will affect the gains you generate from using
              Apex Markets’ Services.
            </p>
            <h4>I. Availability Risk</h4>
            <p>
              We do not guarantee that the Apex Markets Services will be
              available at any particular time or that Apex Markets Services
              will not be subject to unplanned service outages or network
              congestion. It may not be possible for you to buy, sell, store,
              transfer, send or receive Digital Assets when you wish to do so.
            </p>
            <h4>J. Third Party Risk</h4>
            <p>
              Third parties, such as payment providers, custodians, and banking
              partners may be involved in the provision of Apex Markets’
              Services. You may be subject to the terms & conditions of these
              third parties, and Apex Markets may not be responsible for any
              loss that these third parties may cause to you.
            </p>
            <h4>K. Security Riskk</h4>
            <p>
              It is not possible for Apex Markets to eliminate all security
              risks. You are responsible for keeping your Apex Markets Account
              password safe, and you may be responsible for all the transactions
              under your Apex Markets Account, whether you authorized them or
              not. Transactions in Digital Assets may be irreversible, and
              losses due to fraudulent or unauthorized transactions may not be
              recoverable.
            </p>
            <h4>L. Risks related to Digital Assets</h4>
            <p>
              Given the nature of Digital Assets and their underlying
              technologies, there are a number of intrinsic risks, including but
              not limited to:
            </p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>1.</span>
                <p>
                  faults, defects, hacks, exploits, errors, protocol failures or
                  unforeseen circumstances occurring in respect of a Digital
                  Asset or the technologies or economic systems on which the
                  Digital Asset rely;
                </p>
              </div>
              <div className="bullet">
                <span>2.</span>
                <p>
                  transactions in Digital Assets being irreversible.
                  Consequently, losses due to fraudulent or accidental
                  transactions may not be recoverable;
                </p>
              </div>
              <div className="bullet">
                <span>3.</span>
                <p>
                  technological development leading to the obsolescence of a
                  Digital Asset;
                </p>
              </div>
              <div className="bullet">
                <span>4.</span>
                <p>
                  delays causing a transactions not be settled on the scheduled
                  delivery date; and
                </p>
              </div>
              <div className="bullet">
                <span>5.</span>
                <p>
                  attacks on the protocol or technologies on which a Digital
                  Asset depends, including, but not limited to: i. distributed
                  denial of service; ii. sybil attacks; iii. phishing; iv.
                  social engineering; v. hacking; vi. smurfing; vii. malware;
                  viii. double spending; ix. majority-mining, consensus-based or
                  other mining attacks; x. misinformation campaigns; xi. forks;
                  and xii. spoofing.
                </p>
              </div>
            </div>
            <h4>M. Monitoring Risks</h4>
            <p>
              Digital Asset markets are open 24 hours a day, 7 days a week.
              Rapid price changes may occur at any time, including outside of
              normal business hours.
            </p>
            <h4>N. Communication Risksk</h4>
            <p>
              When you communicate with us via electronic communication, you
              should be aware that electronic communications can fail, can be
              delayed, may not be secure and/or may not reach the intended
              destination.
            </p>
            <h4>O. Currency</h4>
            <p>
              Currency exchange fluctuations will impact your gains and losses.
            </p>
            <h4>P. Legal Risk</h4>
            <p>
              Changes in laws and regulations may materially affect the value of
              Digital Assets. This risk is unpredictable and may vary from
              market to market.
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default RiskDisclosureNotice;
