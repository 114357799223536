import React, { useState } from "react";
import { useEffect } from "react";
import { transfersDownload } from "../api/data";
import TransferHistoryTableDownload from "../components/TransferHistoryTableDownload";


const TransferHistoryDownload = ({ title,selected }) => {
  // DEPOSIT FILTER

  const [transferList, setTransferList] = useState([]);



  useEffect(() => {
    transfersDownload({ type: selected.data })
      .then((response) => {
        setTransferList(response);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [selected]);

  return (
    <>
      <TransferHistoryTableDownload data={transferList}></TransferHistoryTableDownload>
    </>
  );
};

export default TransferHistoryDownload;
