import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// components
import AppBar from "../components/AppBar";
import AffiliateHistoryTable from "../components/AffiliateHistoryTable";
import TransferAffiliateEarnings from "../components/TransferAffiliateEarnings";
import { affiliateTableData } from "../components/AffiliateHistoryData";
import IntroducedTable from "../components/IntroducedTable";
import { IntroducedTableData } from "../components/IntroducedData";
import PayoutHistoryTable from "../components/PayoutHistoryTable";
import { PayoutData } from "../components/PayoutData";
import ShareInvite from "../components/ShareInvite";
import ConvertBonusPoints from "../components/ConvertBonusPoints";

import { BsChevronDown } from "react-icons/bs";
import infowarning from "../images/icons/infowarning.svg";
import rharrow from "../images/icons/rharrow.svg";

import "./Affiliate.css";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import {
  getAffiliateOverview,
  getBonusPoints,
  sendAffiliateEmailInvite,
} from "../api/affiliate";
import { useEffect } from "react";
import OverlayLoader from "../components/OverlayLoader";
import FadeLoader from "react-spinners/FadeLoader";

import { toast } from "react-toastify";

// react-copy-to-clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// https://www.npmjs.com/package/react-copy-to-clipboard

import PropTypes from "prop-types";
import clsx from "clsx";
import { styled, Box } from "@mui/system";
import ModalUnstyled from "@mui/base/ModalUnstyled";

// ! Modal
const BackdropUnstyled = React.forwardRef((props, ref) => {
  const { open, className, ...other } = props;
  return (
    <div
      className={clsx({ "MuiBackdrop-open": open }, className)}
      ref={ref}
      {...other}
    />
  );
});
BackdropUnstyled.propTypes = {
  className: PropTypes.string.isRequired,
  open: PropTypes.bool,
};
const Modal = styled(ModalUnstyled)`
  position: fixed;
  z-index: 1300;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-y: scroll;
`;
const Backdrop = styled(BackdropUnstyled)`
  z-index: -1;
  position: fixed;
  right: 0;
  bottom: 0;
  top: 0;
  left: 0;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.5);
  -webkit-tap-highlight-color: transparent;
`;
const style = (theme) => ({
  width: 400,
  bgcolor: theme.palette.mode === "dark" ? "#0A1929" : "white",
  border: "1px solid currentColor",
  padding: "12px 24px",
  borderRadius: "8px",
  margin: "0 12px",
});

const Affiliate = ({ showNav }) => {
  const customer = useSelector(selectCurrentCustomer);
  showNav(true);
  const navigate = useNavigate();
  const [isActive, setIsActive] = useState(false);
  /*
  const [selected, setSelected] = useState("All");
  const options = [
    "All",
    "Today",
    "Yesterday",
    "This Week",
    "This Month",
    "This Year",
  ];*/
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setOpen(false);
  };

  const [openModal, setOpenModal] = useState(false);
  const [openTransferModal, setOpenTransferModal] = useState(false);

  const [data, setData] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);

  const [numPagesTab2, setNumPagesTab2] = useState(1);
  const [numRowsTab2, setNumRowsTab2] = useState(25);
  const [currentPageTab2, setCurrentPageTab2] = useState(1);

  const [numPagesTab3, setNumPagesTab3] = useState(1);
  const [numRowsTab3, setNumRowsTab3] = useState(25);
  const [currentPageTab3, setCurrentPageTab3] = useState(1);

  const [limit, setLimit] = useState(10);

  const getAffiliateData = async () => {
    setLoading(true);
    try {
      const overview = await getAffiliateOverview({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
        limitTab2: currentPageTab2 * numRowsTab2,
        numRowsTab2: numRowsTab2,
        /*limitTab3: currentPageTab3 * numRowsTab3,
      numRowsTab3: numRowsTab3,*/
      });
      setData(overview);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      setData([]);
    }
  };

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const [copied, setCopied] = useState(false);
  const [introduced, setIntroduced] = useState(true);
  const [affiliate, setAffiliate] = useState(false);
  const [payout, setPayout] = useState(false);
  const [points, setPoints] = useState(0);

  const getTotalBonusPoints = async () => {
    const bonusPoints = await getBonusPoints();
    setPoints(bonusPoints.points);
  };

  const [filterParams, setFilterParams] = useState({
    search: "",
    sortBy: "",
    range: "",
    searchTab2: "",
    sortByTab2: "",
    rangeTab2: "",
    searchTab3: "",
    sortByTab3: "",
    rangeTab3: "",
  });
  const emailInput = React.useRef();

  const sendAffiliateInvite = async () => {
    const value = emailInput.current.value?.trim();
    if (value == null || value === "") {
      return;
    }
    try {
      const result = await sendAffiliateEmailInvite(value);
      if (result?.status == 200) {
        toast("Invite sent!", {
          type: "success",
        });
        emailInput.current.value = "";
      }
    } catch (err) {
      console.log(err);
      toast("Invite was not sent!", {
        type: "error",
      });
    }
  };

  useEffect(() => {
    getAffiliateData();
  }, [currentPage, filterParams, numRows]);

  // For Bonus Points
  useEffect(() => {
    getTotalBonusPoints();
  }, [open]);

  return (
    <div className="relative dashboard-page">
      {/* <OverlayLoader loading={loading}></OverlayLoader> */}
      {loading ? (
        <div className="absolute flex justify-center items-center h-[100vh] w-full z-50">
          <FadeLoader color={color} loading={loading} size={80} />
        </div>
      ) : null}
      <AppBar title="Affiliate"></AppBar>
      <div className="dashboard-container">
        {openModal && (
          <ShareInvite
            openModal={openModal}
            closeModal={setOpenModal}
            // for confirm deposit component
            confirmTitle="Confirm Deposit Details"
            // for confirm deposit
          ></ShareInvite>
        )}
        {openTransferModal && (
          <TransferAffiliateEarnings
            openModal={openTransferModal}
            closeModal={setOpenTransferModal}
            // for confirm deposit component
            confirmTitle="Confirm Deposit Details"
            // for confirm deposit
          ></TransferAffiliateEarnings>
        )}
        <div class="affiliate-page-grid ">
          {/* REFER A FRIEND */}
          <div class="refer-friend">
            <div className="deposit-page-box-title">
              <div
                className="color-box"
                style={{ background: "#008FFD" }}
              ></div>
              <span>
                Get Rewarded for Sharing Apex Markets: <br /> Earn Up to 80%
                Commission and $35 Instant FTD Bonus!
              </span>
            </div>
            <div className="horizontal-line"></div>
            <div className="refer-friend-info">
              <p>
                Copy your exclusive Apex Markets invite link and share it with
                fellow traders to earn up to 80% commission on their trades.
                Plus, when they sign up and deposit, you'll receive an instant
                $35 FTD bonus. Start earning today by copying your invite link
                below and sharing it with your network!
              </p>
            </div>

            <div className="pt-5 enter-amount">
              <div className="enter-amt-top-row">
                <p>Share Invite Link</p>
              </div>
              <div className="refer-input md:h-[50px] h-auto ">
                <div className="flex flex-wrap items-center justify-center pb-4 sm:pb-0 refer-with-icon">
                  <input
                    type="text"
                    value={`${process.env.REACT_APP_WEBSITE_URL}/ref/${customer.Referral_Link}`}
                    readonly
                  />
                  <CopyToClipboard
                    text={`${process.env.REACT_APP_WEBSITE_URL}/ref/${customer.Referral_Link}`}
                    onCopy={() => setCopied(true)}
                  >
                    {copied ? <div>Link Copied</div> : <div>Copy Link</div>}
                  </CopyToClipboard>
                </div>
                {/* <button
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Share
                </button> */}
              </div>
            </div>
            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Invite by E-mail</p>
              </div>
              <div className="refer-input border border-[#e6e8ec] rounded-lg bg-white">
                <input
                  type="text"
                  placeholder="Enter email address"
                  className="px-2 ml-1"
                  ref={emailInput}
                />
                <button onClick={() => sendAffiliateInvite()}>
                  Send Invite
                </button>
              </div>
            </div>

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Resources</p>
              </div>
              <a
                href="/affiliate-confirmation-letter"
                target="_blank"
                rel="noopener noreferrer"
                className="underline blue"
              >
                <button className="smkit-btn">
                  Download Affiliate Confirmation Letter
                </button>
              </a>
            </div>
          </div>
          {/* REFER A FRIEND */}
          {data != null && (
            <>
              {/* Affiliate  Overview */}
              <div class="affiliate-overview">
                <div className="deposit-page-box-title">
                  <div
                    className="color-box"
                    style={{ background: "#FFD166" }}
                  ></div>
                  <span>Affiliate Overview</span>
                  {/* <button
                onClick={() => {
                  setOpenTransferModal(true);
                }}
              >
                Transfer Funds
              </button> */}
                </div>
                <div className="horizontal-line"></div>
                <div className="rh-row2">
                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      {/* Your Earnings <img src={infowarning} alt="" /> */}
                      Total Link Clicks
                    </div>
                    <span id="earnings">{data.totalLinkClicks} </span>
                  </div>
                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      Total Signups
                      {/* <img src={infowarning} alt="" /> */}
                    </div>
                    <span id="earnings">{data.totalSignups} </span>
                  </div>
                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      Total Active Traders
                      {/* Total Joined <img src={infowarning} alt="" /> */}
                    </div>
                    <span id="earnings">{data.totalActiveTraders} </span>
                  </div>
                </div>
              </div>
              {/* Affiliate  Overview */}

              {/* -------Affiliate Commission------- */}
              <div class="refferal-history">
                <Modal
                  aria-labelledby="unstyled-modal-title"
                  aria-describedby="unstyled-modal-description"
                  open={open}
                  onClose={handleClose}
                  slots={{ backdrop: Backdrop }}
                >
                  <Box sx={style}>
                    {" "}
                    <ConvertBonusPoints
                      open={open}
                      setOpen={setOpen}
                      handleClose={handleClose}
                      points={points}
                    ></ConvertBonusPoints>
                  </Box>
                </Modal>
                <div className="deposit-page-box-title">
                  <div
                    className="color-box"
                    style={{ background: "#58BD7D" }}
                  ></div>
                  <span>Affiliate Commission</span>
                  {/* <button
                onClick={() => {
                  setOpenTransferModal(true);
                }}
              >
                Transfer Funds
              </button> */}
                </div>
                <div className="horizontal-line"></div>
                <div className="rh-row2 lg:ml-20">
                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      Total Earnings
                      {/* Your Earnings <img src={infowarning} alt="" /> */}
                    </div>
                    <span id="earnings">
                      $ {data.totalEarnedCommission} USD
                    </span>
                    <div
                      onClick={() => {
                        setPayout(true);
                        setIntroduced(false);
                        setAffiliate(false);
                      }}
                    >
                      <a href="/affiliate#payout-history">Payout History</a>
                    </div>
                  </div>
                  <div className="rh-row2-col">
                    <div>
                      <div className="rh-row2-title">
                        FTD Bonus
                        {/* Your Earnings <img src={infowarning} alt="" /> */}
                      </div>
                      <p
                        id="earnings"
                        className="text-[#58BD7D] lg:text-[40px] text-[24px]"
                      >
                        {points}
                      </p>
                      <div className="pt-4" onClick={handleOpen}>
                        <a href="##">Convert Bonus</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="rh-row2 lg:ml-20">
                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      Next Payout Amount
                      {/* <img src={infowarning} alt="" /> */}
                    </div>
                    <span id="earnings">$ {data.payableCommission} USD</span>
                    <div className="red-link">
                      <a href="##">Minimum Commission Payout $50 USD</a>
                    </div>
                  </div>

                  <div className="rh-row2-col">
                    <div className="rh-row2-title">
                      Next Payout Date
                      {/* Total Joined <img src={infowarning} alt="" /> */}
                    </div>
                    <p>{data.nextPayout} </p>
                  </div>
                </div>
              </div>
              {/* SELECTOR */}

              <div class="dm-wrapper introduced">
                <input
                  type="radio"
                  name="select"
                  id="option-1"
                  checked={introduced && true}
                  onClick={() => {
                    setIntroduced(true);
                    setPayout(false);
                    setAffiliate(false);
                    setIsActive(false);
                  }}
                />
                <input
                  type="radio"
                  name="select"
                  id="option-2"
                  checked={affiliate && true}
                  onClick={() => {
                    setIntroduced(false);
                    setAffiliate(true);
                    setPayout(false);
                    setIsActive(false);
                  }}
                />
                <input
                  type="radio"
                  name="select"
                  id="option-3"
                  checked={payout && true}
                  onClick={() => {
                    setIntroduced(false);
                    setAffiliate(false);
                    setPayout(true);
                    setIsActive(false);
                  }}
                />
                <label for="option-1" class="option option-1">
                  <span>Introduced by you</span>
                </label>
                <div className="line"></div>
                <label for="option-2" class="option option-2">
                  <span>Affiliate Trading History</span>
                </label>
                {/*<div className="line"></div>
                <label for="option-3" class="option option-3">
                  <span>Affiliate Payout History</span>
                </label>*/}
              </div>
            </>
          )}

          {/* -------affiliate-history------- */}
          {introduced ? (
            <div className="payout">
              <IntroducedTable
                data={data?.affiliates?.records}
                hover={true}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                setNumRows={setNumRows}
                numPages={numPages}
                currentPage={setCurrentPage}
                setCurrentPage={setCurrentPage}
              ></IntroducedTable>
            </div>
          ) : null}
          {affiliate ? (
            <div className="payout">
              <AffiliateHistoryTable
                data={data?.affiliatePayouts?.records}
                hover={true}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                setNumRowsTab2={setNumRowsTab2}
                numPagesTab2={numPagesTab2}
                currentPageTab2={currentPageTab2}
                setCurrentPageTab2={setCurrentPageTab2}
              ></AffiliateHistoryTable>

              {/* <button className="load-more-btn">Load More</button> */}
            </div>
          ) : null}

          {payout ? (
            <div className="payout">
              <PayoutHistoryTable
                data={data?.affiliatePayouts?.records}
                hover={true}
                filterParams={filterParams}
                setFilterParams={setFilterParams}
                setNumRowsTab3={setNumRowsTab3}
                numPagesTab3={numPagesTab3}
                currentPageTab3={currentPageTab3}
                setCurrentPageTab3={setCurrentPageTab3}
              ></PayoutHistoryTable>

              {/* <button className="load-more-btn">Load More</button> */}
            </div>
          ) : null}

          {/* -------------- */}
          {/* HOW IT WORKS */}
          <div class="how-it-works">
            <div className="deposit-page-box-title cb-row1">
              <div className="cb-row-1-right">
                <div
                  className="color-box"
                  style={{ background: "#FFD166" }}
                ></div>
                <span>How It Works</span>
              </div>
            </div>
            <div className="horizontal-line"></div>
            <div className="hiw-info">
              <h2>
                How To Start Earning up to 80% commission and $35 instant FTD
                bonus. It's Easy!
              </h2>
              <p>
                Just promote Apex Markets by sharing your unique invite link on
                social media, your website, email, group chats or anywhere else.
                For each trader that signs up, verifies KYC, deposits $10 or
                more and trades on Apex Markets through your unique invite link,
                you'll receive an instant first time deposit bonus of $35, in
                addition to earning up to 80% commission of the trading fees
                they generate on their Apex Markets trading account. They’ll get
                up to 100% Welcome Bonus on first time deposit of $100 USD or
                more.
              </p>
            </div>
            <div className="hiw-info">
              <h2>Be Top affiliates & Get Special Rewards</h2>
              <p>
                Top affiliates will receive special rewards such as cash, badges
                and other prizes and get highlighted on our platform. So what
                are you waiting for? Start inviting your friends today and start
                earning more money!
              </p>
            </div>
            <div className="hiw-info">
              <h2>
                Want to know more about Apex Markets Affiliate Invite Program?
              </h2>
              <p>
                For More questions about the Apex Markets Affiliate Invite
                Program, Please Contact us at{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>{" "}
                We are always happy to help!
              </p>
            </div>
          </div>
          {/* HOW IT WORKS */}

          {/* -------------- */}
        </div>
      </div>
    </div>
  );
};

export default Affiliate;
