import React from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import failed from "../images/icons/failed-x.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const PaymentFailed = ({ closeModal }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="delete-card">
              <img src={failed} alt="" />
              <p>Payment Failed</p>
            </div>
            <div className="delete-card-info">
              <p>
                Oh no Zesan! Your payment using the card number (****4080) was
                unsuccessful.Please ensure that there are sufficient funds on
                your card for this purchase. or Contact your financial
                institution and ask that they authorize the payment to Apex
                E-Commerce Services and lift any holds on your account.
              </p>
            </div>
            <button className="verification-continue-btn complete">
              Try Again
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default PaymentFailed;
