import "./App.css";
import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider, ErrorBoundary } from "@rollbar/react"; // <-- Provider imports 'rollbar' for us
import Rollbar from "rollbar";
import Dashboard from "./pages/Dashboard";
import Accounts from "./pages/Accounts";
import Deposit from "./pages/Deposit";
import Transfer from "./pages/Transfer";
import Withdraw from "./pages/Withdraw";
import Affiliate from "./pages/Affiliate";
import Leaderboards from "./pages/Leaderboards";
import Faq from "./pages/Faq";
import ContactUs from "./pages/ContactUs";
import LegalDocuments from "./pages/LegalDocuments";
import Verification from "./pages/Verification";
import PersonalInformation from "./pages/PersonalInformation";
import Security from "./pages/Security";
import Notification from "./pages/Notification";
import KycVerificationSettings from "./pages/KycVerificationSettings";
import LiveAccounts from "./pages/LiveAccounts";
import DemoAccounts from "./pages/DemoAccounts";
import TransactionHistory from "./pages/TransactionHistory";
import Rewards from "./pages/Rewards";
import Wallet from "./pages/Wallet";
import ContactInfo from "./pages/ContactInfo";
import Navbar from "./components/Navbar";
import RiskDisclosureNotice from "./pages/RiskDisclosureNotice";
import OrderExecutionPolicy from "./pages/OrderExecutionPolicy";
import MonthlyLeaderboards from "./pages/MonthlyLeaderboards";

import KycDocStatus from "./pages/KycDocStatus";

import AffiliateLetter from "./pages/AffiliateLetter";
// WEBSITE

//import Home from "./pages/Website/Home";
import NotFoundPage from "./pages/Website/NotFoundPage";

// ADMIN
import AdminSidebar from "./components/admin/AdminSidebar";
import Overview from "./pages/admin/Overview";
import BrokerAnalytics from "./pages/admin/BrokerAnalytics";
import Revenue from "./pages/admin/Revenue";
import Invoicing from "./pages/admin/Invoicing";
import Deposits from "./pages/admin/Deposits";
import Ledger from "./pages/admin/Ledger";
import Payouts from "./pages/admin/Payouts";
import Clients from "./pages/admin/Clients";
import Traders from "./pages/admin/Traders";
import AffiliateMetrics from "./pages/admin/AffiliateMetrics";
import Documents from "./pages/admin/Documents";
import Marketing from "./pages/admin/Marketing";
import CustomerSupport from "./pages/admin/CustomerSupport";
import ChargebackResponse from "./pages/admin/ChargebackResponse";
import AuditLogs from "./pages/admin/AuditLogs";
import FailedAudit from "./pages/admin/FailedAudit";
import AppCredentials from "./pages/admin/AppCredentials";
import SystemLogs from "./pages/admin/SystemLogs";
import { useSelector, useDispatch } from "react-redux";
import {
  checkIfLoggedIn,
  selectCurrentCustomer,
} from "./redux/slices/customerSlice";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PaymentSuccess from "./pages/PaymentSuccess";
import PaymentProcessing from "./pages/PaymentProcessing";
import TransferHistoryPDF from "./pages/TransferHistoryPDF";
import ClosedNav from "./components/ClosedNav";
import NavbarMobile from "./components/NavbarMobile";
import { userActivityTest } from "./api/profile";
import DownloadMetatrader from "./pages/DownloadMetatrader";
import NewVerification from "./pages/NewVerification";
import Cookies from "js-cookie";
import Translator from "./components/Translator";
import { logout } from "./api/customer";

function App() {
  const dispatch = useDispatch();
  const customer = useSelector(selectCurrentCustomer);
  const [showNav, setShowNav] = useState(false);
  const [showAdminNav, setShowAdminNav] = useState(false);
  const [activeNav, setActiveNav] = useState(true);
  const [mobileActive, setMobileActive] = useState(false);
  const [noNav, setNoNav] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [userPermissions, setUserPermissions] = useState(["regular"]);
  const [allowedRoutes, setAllowedRoutes] = useState([]);
  const [params, setParams] = useState();
  const [queryChecked, setQueryChecked] = useState(false);
  const [isSelectedLanguage, setIsSelectedLanguage] = useState();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const paramValue = urlParams.get("cookie");
    setParams(paramValue);
    setQueryChecked(true);
  }, []);

  useEffect(() => {
    setIsSelectedLanguage(Cookies.get("googtrans"));
  }, []);

  const rollbarConfig = {
    accessToken: process.env.REACT_APP_ROLLBAR_TOKEN,
    environment: process.env.REACT_APP_ENVIRONMENT,
    payload: {
      code_version: "2.0.8",
    },
    captureUncaught: true,
    captureUnhandledRejections: true,
  };
  const rollbar = new Rollbar(rollbarConfig);
  window.rollbar = rollbar;
  window.rollbar.info("Dashboard initializing...");

  useEffect(() => {
    if (!params && queryChecked) {
      dispatch(checkIfLoggedIn());
    }
  }, [dispatch, params, queryChecked]);

  /*useEffect(() => {
    if (!params && queryChecked) {
      window.addEventListener("focus", onFocus);
      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [params, queryChecked]);*/

  /*const onFocus = () => {
    if (!params && queryChecked) {
      dispatch(checkIfLoggedIn());
    }
  };
  const onScroll = () => {
    if (!params && queryChecked) {
      dispatch(checkIfLoggedIn());
    }
  };*/

  useEffect(() => {
    if (!params && queryChecked) {
      if (
        customer != null &&
        customer.admin != null &&
        Array.isArray(customer.admin) &&
        customer.admin.length > 0
      ) {
        if (!userPermissions.includes("admin")) {
          setUserPermissions([...userPermissions, "admin"]);
          setShowAdminNav(true);
          setShowNav(false);
        }
      } else {
        let index = userPermissions.indexOf("admin");
        if (index !== -1) {
          userPermissions.splice(index, 1);
          setUserPermissions([...userPermissions]);
        }
      }

      if (
        typeof customer?.Status === "string" &&
        customer?.Status.toUpperCase() === "VERIFIED"
      ) {
        if (!userPermissions.includes("regular-verified")) {
          setUserPermissions([...userPermissions, "regular-verified"]);
        }
      } else {
        let index = userPermissions.indexOf("regular-verified");
        if (index !== -1) {
          userPermissions.splice(index, 1);
          setUserPermissions([...userPermissions]);
        }
      }
    }
  }, [customer, params, queryChecked]);

  useEffect(() => {
    if (!params && queryChecked) {
      const handleResize = () => {
        const isMobileDevice = window.innerWidth < 850;
        const isDesktop = window.innerWidth > 850;
        if (isMobileDevice) {
          setActiveNav(false);
        }
      };

      handleResize();
      window.addEventListener("resize", handleResize);

      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [params, queryChecked]);

  const userClicked = async () => {
    const { message } = await userActivityTest();
    if (!message) {
      setTimeout(async () => {
        await logout(1);
      }, 1000);
    }
  };

  const routes = [
    {
      path: "/",
      element: <Dashboard showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    {
      path: "/dashboard",
      element: <Dashboard showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    {
      path: "/download-MetaTrader",
      element: <DownloadMetatrader showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    {
      path: "/contact-us",
      element: <ContactUs showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/contact-info",
      element: <ContactInfo showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts",
      element: <Accounts showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts/wallet-account",
      element: <Wallet showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts/live-accounts",
      element: <LiveAccounts showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts/demo-accounts",
      element: <DemoAccounts showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts/transaction-history",
      element: <TransactionHistory showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/accounts/rewards",
      element: <Rewards showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/transfer",
      element: <Transfer showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/deposit",
      element: <Deposit showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/withdraw",
      element: <Withdraw showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/affiliate",
      element: <Affiliate showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/leaderboards",
      element: <Leaderboards showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/personal-information",
      element: <PersonalInformation showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    {
      path: "/security",
      element: <Security showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },

    {
      path: "/notification",
      element: <Notification showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    // {
    //   path: "/settings-kyc-verification",
    //   element: (
    //     <KycVerificationSettings showNav={setShowNav} noNav={setNoNav} />
    //   ),
    //   permissions: ["regular"],
    // },
    // {
    //   path: "/kyc-verification",
    //   element: <Verification showNav={setShowNav} noNav={setNoNav} />,
    //   permissions: ["regular"],
    // },
    {
      path: "/kyc-verification",
      element: <NewVerification showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    {
      path: "/settings-kyc-verification",
      element: <KycDocStatus showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },
    // {
    //   path: "/resubmit/:id",
    //   element: <Verification showNav={setShowNav} noNav={setNoNav} />,
    //   permissions: ["regular"],
    // },
    {
      path: "/resubmit/:id",
      element: <NewVerification showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular"],
    },

    {
      path: "/faq",
      element: <Faq showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/legal-documents",
      element: <LegalDocuments showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/Risk-Disclosure-Notice",
      element: <RiskDisclosureNotice showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/Order-Execution-Policy",
      element: <OrderExecutionPolicy showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/transfers-list",
      element: <TransferHistoryPDF showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/monthly-leaderboards",
      element: <MonthlyLeaderboards showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/payment-success",
      element: <PaymentSuccess showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/payment-processing",
      element: <PaymentProcessing showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },
    {
      path: "/affiliate-confirmation-letter",
      element: <AffiliateLetter showNav={setShowNav} noNav={setNoNav} />,
      permissions: ["regular-verified"],
    },

    /* ADMIN */
    {
      path: "/admin/overview",
      element: <Overview showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/broker-analytics",
      element: <BrokerAnalytics showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/revenue",
      element: <Revenue showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/invoicing",
      element: <Invoicing showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/deposits",
      element: <Deposits showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/ledger",
      element: <Ledger showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/payouts",
      element: <Payouts showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/clients",
      element: <Clients showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/traders",
      element: <Traders showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/metrics",
      element: <AffiliateMetrics showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/documents",
      element: <Documents showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/marketing",
      element: <Marketing showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/customer-support",
      element: <CustomerSupport showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/chargeback-response",
      element: <ChargebackResponse showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/audit-logs",
      element: <AuditLogs showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/fail-audit",
      element: <FailedAudit showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/app-credentials",
      element: <AppCredentials showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    {
      path: "/admin/system-logs",
      element: <SystemLogs showAdminNav={setShowAdminNav} />,
      permissions: ["admin"],
    },
    ///////////////////////////////
  ];

  useEffect(() => {
    if (!params && queryChecked) {
      let permissions = userPermissions;
      if (
        customer != null &&
        customer.admin != null &&
        Array.isArray(customer.admin) &&
        customer.admin.length > 0
      ) {
        if (!userPermissions.includes("admin")) {
          permissions.push("admin");
          setShowAdminNav(true);
          setShowNav(false);
        }
      } else {
        let index = userPermissions.indexOf("admin");
        if (index !== -1) {
          permissions.splice(index, 1);
        }
      }

      if (
        typeof customer?.Status === "string" &&
        customer?.Status.toUpperCase() === "VERIFIED"
      ) {
        if (!userPermissions.includes("regular-verified")) {
          permissions.push("regular-verified");
        }
      } else {
        let index = userPermissions.indexOf("regular-verified");
        if (index !== -1) {
          permissions.splice(index, 1);
        }
      }

      setUserPermissions(permissions);
      setNoNav(false);

      const paths = [];
      routes
        .filter((route) =>
          route.permissions.some((routePermission) =>
            userPermissions.includes(routePermission)
          )
        )
        .map((route) => paths.push(route.path));
      setAllowedRoutes(paths);
    }
  }, [customer, params, queryChecked]);

  useEffect(() => {
    if (!params && queryChecked) {
      dispatch(checkIfLoggedIn());
    }
  }, [dispatch]);

  /*useEffect(() => {
    if (!params && queryChecked) {
      window.addEventListener("focus", onFocus);
      window.addEventListener("scroll", onScroll);
      return () => {
        window.removeEventListener("focus", onFocus);
        window.removeEventListener("scroll", onScroll);
      };
    }
  }, [params, queryChecked]);*/

  useEffect(() => {
    if (params) {
      const cookiesObject = JSON.parse(params);
      setTimeout(() => {
        const domain = process.env.REACT_APP_COOKIE_DOMAIN;
        if (cookiesObject) {
          for (const key in cookiesObject) {
            if (cookiesObject.hasOwnProperty(key)) {
              const value = cookiesObject[key];
              Cookies.set(key, value, { domain });
            }
          }
        }
        window.location.href = "/";
      }, 500);
    }
  }, [params]);

  return !params && queryChecked ? (
    customer != null ? (
      <div
        style={{ marginTop: isSelectedLanguage ? -40 : 0 }}
        className="main"
        onClick={userClicked}
      >
        <Provider config={rollbarConfig}>
          <ErrorBoundary>
            <ToastContainer />
            <Router>
              {showNav && (
                <div>
                  <Navbar
                    activeNav={activeNav}
                    setActiveNav={setActiveNav}
                    isMobile={isMobile}
                    setIsMobile={setIsMobile}
                  />
                  <NavbarMobile
                    mobileActive={mobileActive}
                    setMobileActive={setMobileActive}
                    isMobile={isMobile}
                    setIsMobile={setIsMobile}
                  />
                </div>
              )}
              {showAdminNav && (
                <AdminSidebar
                  activeNav={activeNav}
                  setActiveNav={setActiveNav}
                />
              )}
              <div className="w-full flex justify-end pr-10">
                <Translator />
              </div>
              <div
                className={`main-content-container ${
                  activeNav ? "active" : "inactive"
                } ${noNav ? "nonav" : ""}`}
              >
                <Routes>
                  {routes
                    .filter((route) =>
                      route.permissions.some((routePermission) =>
                        userPermissions.includes(routePermission)
                      )
                    )
                    .map((route, index) => (
                      <Route
                        path={route.path}
                        element={route.element}
                        key={index}
                      />
                    ))}
                  <Route
                    path="*"
                    element={
                      <NotFoundPage
                        allowedRoutes={allowedRoutes}
                        showNav={setShowNav}
                        noNav={setNoNav}
                        showAdminNav={setShowAdminNav}
                      />
                    }
                  />
                </Routes>
              </div>
            </Router>
          </ErrorBoundary>
        </Provider>
      </div>
    ) : null
  ) : (
    <div className="text-xl flex justify-center items-center h-screen">
      Redirecting...
    </div>
  );
}

export default App;
