export const trading_commission_data = [
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
  {
    date: "23/05/22",
    clientid: "#1234567",
    loginid: "104032123",
    volume: "5",
    cost: "$6.00",
    commissionearned: "$30.00",
  },
];

export const trading_commissioncolNames = [
  "Date",
  "Client ID",
  "Login ID",
  "Volume (lot) Traded",
  "Cost Per Lot (USD)",
  "Commission Earned (USD)",
];
