import React from "react";
import { NavLink, Link } from "react-router-dom";

import ClosedNav from "../components/ClosedNav";
import logo from "../images/apex-markets-logo.svg";
import closenav from "../images/icons/navclose.svg";

import "./Navbar.css";
import { SidebarData } from "../components/SidebarData";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import ClosedNavMobile from "./ClosedNavMobile";
// ----------------------SIDEBAR / NAVBAR ------------------
const NavbarMobile = ({
  mobileActive,
  setMobileActive,
  isMobile,
  setIsMobile,
}) => {
  const customer = useSelector(selectCurrentCustomer);
  // const [active, setActive] = useState(true);
  return (
    customer != null && (
      <div className="navbar" id="mobile-sidebar">
        <ClosedNavMobile
          mobileActive={mobileActive}
          setMobileActive={setMobileActive}
          isMobile={isMobile}
          setIsMobile={setIsMobile}
        ></ClosedNavMobile>
        <div className={`sidebar ${mobileActive ? "" : "inactive"} `}>
          <div className="logo-row">
            <div className="logo">
              <Link to="#">
                <img src={logo} alt="Apex Markets Logo" />
              </Link>
            </div>
            <div
              className="close-nav"
              onClick={() => setMobileActive(!mobileActive)}
            >
              <img src={closenav} alt="" />
            </div>
          </div>

          <div className="menu-heading">
            <p>Menu</p>
            <div className="menu-heading-underline"></div>
          </div>
          <div className="sidebar-link-container">
            <ul className="sidebar-list">
              {SidebarData.filter((item) => {
                const allowed = ["/dashboard"];
                if (
                  customer?.Status != "VERIFIED" &&
                  !allowed.includes(item.link)
                ) {
                  return false;
                }
                return true;
              }).map((val, key) => {
                return (
                  <li
                    key={key}
                    className={val.cName}
                    onClick={() => setMobileActive(false)}
                  >
                    <NavLink to={val.link} className="row">
                      <div id="icon">
                        <img src={val.icon} alt="" />
                      </div>
                      <div id="title">{val.title}</div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    )
  );
};

export default NavbarMobile;
