import { api } from "./index";
import { clean } from "../util";

function filterParams(params) {
  const newParams = {};

  for (const [key, val] of Object.entries(params)) {
    if (!val || val === "null") {
      continue;
    }

    newParams[key] = val;
  }

  return newParams;
}

export const getCurrentUser = async () => {
  const response = await api.get("customer/current").json();
  if (response != null && response.customer != null) {
    return response.customer;
  }
  return null;
};

export const submitVerificationDocument = async (obj) => {
  const response = await api
    .post("customer/submit-verification", {
      json: obj,
      timeout: false,
    })
    .json();

  if (response != null) {
    return response;
  }
  return null;
};

export const submitVerificationForm = async (data) => {
  const response = await api
    .post("customer/submit-verification-form", {
      json: data,
      timeout: false,
    })
    .json();

  if (response != null) {
    return response;
  }
  return null;
};

export const kycVerification = async (obj) => {
  const response = await api
    .post("customer/kyc-verification", {
      json: obj,
      timeout: false,
    })
    .json();

  return response;
};

export const reSubmitVerificationDocument = async (obj, id) => {
  const response = await api
    .put(`customer/resubmit-verification/${id}`, {
      json: obj,
    })
    .json();
  if (response != null && response.customer != null) {
    return response.customer;
  }
  return null;
};

export const logout = async (redirect) => {
  const response = await api.get("auth/logout").json();
  if (redirect) {
    window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/login`;
  }
  return response;
};

export const getCustomers = async (params) => {
  const response = await api
    .get("customer/all", { searchParams: clean(params), timeout: false })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const getCustomerIdentification = async () => {
  const response = await api.get("customer/customer-identification").json();
  if (response != null) {
    return response;
  }
  return null;
};

export const getClients = async (params) => {
  const response = await api
    .get("customer/all-clients", {
      searchParams: clean(params),
      timeout: false,
    })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const getClientOverview = async (params) => {
  const response = await api
    .get("customer/client-overview", { searchParams: filterParams(params) })
    .json();
  // { searchParams: filterParams(params) })
  return response;
};

export const getAggregateDeposits = async (params) => {
  const response = await api
    .get("customer/aggregate-deposits", {
      searchParams: clean(params),
      timeout: false,
    })
    .json();
  if (response != null) {
    return response;
  }
  return [];
};

export const getAggregateWithdrawals = async (params) => {
  const response = await api
    .get("customer/aggregate-withdraws", {
      searchParams: clean(params),
      timeout: false,
    })
    .json();
  if (response != null) {
    return response;
  }
  return [];
};

export const getAggregateTradingAccounts = async (params) => {
  const response = await api
    .get("customer/aggregate-trading-accounts", {
      searchParams: clean(params),
      timeout: false,
    })
    .json();
  if (response != null) {
    return response;
  }
  return [];
};

export const updateStatus = async (id, status) => {
  const response = await api
    .put(`customer/update-status/${id}`, {
      json: {
        status,
      },
    })
    .json();

  return response;
};

export const getNotification = async () => {
  const response = await api.get(`customer/notification-settings`).json();
  return response;
};

export const updateNotification = async (data) => {
  const response = await api
    .put(`customer/update-notification-settings`, {
      json: data,
    })
    .json();
  console.log(await response);
};
