import React, { useEffect, useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";

import TableBtn from "../../components/admin/TableBtn";
import AdminTable from "../../components/admin/AdminTable";

import PriceFilter from "../../components/admin/PriceFilter";
import Paginator from "../../components/Paginator";
import FadeLoader from "react-spinners/FadeLoader";
import {
  payouts_data,
  payouts_datacolNames,
} from "../../components/admin/PayoutsData";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxorangetransfer from "../../images/icons/adminboxorangetransfer.svg";
import adminboxorange3 from "../../images/icons/adminboxorange3.svg";
import adminboxorangex from "../../images/icons/adminboxorangex.svg";
import { getWireTransferWithdrawalRequests } from "../../api/wireTransfer";
import WithdrawalRequests from "../../components/admin/WithdrawalRequests";
import CryptoPayoutRequests from "../../components/admin/CryptoPayoutRequests";
import { getCryptoWithdrawalRequests } from "../../api/crypto";
import { getWithdrawalRequests, getWithdrawalOverview } from "../../api/data";
import moment from "moment";
import { toast } from "react-toastify";

const Payouts = ({ showAdminNav }) => {
  showAdminNav(true);
  const [payouts, setPayouts] = useState([]);

  const [filterParams, setFilterParams] = useState({
    search: "",
    status: "PENDING",
  });

  const [option, setOption] = useState();
  const [filter, setFilter] = useState();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [overview, setOverview] = useState(null)
  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const [overviewDateFilter, setOverviewDateFilter] = useState('This Week');

  const [currentTab, setCurrentTab] = useState("wire");

  const loadPayouts = async () => {
    setLoading(true);
    try {
      const { withdrawalRequests, pages } = await getWithdrawalRequests({
        ...filterParams,
        tab: currentTab,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      setPayouts(withdrawalRequests);
      setNumPages(pages > 0 ? pages : 1);
      if (currentPage > pages) {
        setCurrentPage(1);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load data successfully", {
        type: "error",
      });
      setPayouts([]);
    }
  };

  const loadOverview = async () => {
    try {
      const res = await getWithdrawalOverview({ dateFilter: overviewDateFilter });
      setOverview(res.data);
      console.log("overview", res.data)
    } catch (error) {
      console.error(error)
      setLoading(false)

    }
  }

  useEffect(() => {
    loadOverview();
  }, [overviewDateFilter])

  useEffect(() => {
    loadPayouts();
  }, [currentPage, filterParams, currentTab]);

  return (
    <div className="dashboard-page">
      <AdminBar title="Payouts"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter
                setOption={(val) => {
                  setOverviewDateFilter(val);
                }}
                option={overviewDateFilter}
                defaultValue="This Week"
                list={["Today", "Yesterday", "This Week", "This Month", "This Year", "All"]}></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Payouts"
              img={adminboxorange}
              data={`$ ${overview?.totalApproved || '0'}`}
              info={overview?.totalApprovedCount}
              info2="Number of Approved :"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Payouts Pending"
              img={adminboxorangex}
              data={`$ ${overview?.totalPending || '0'}`}
              info={overview?.totalPendingCount}
              info2="Number of Pending :"
              color="blue"
            ></OverviewBox>
            <OverviewBox
              title="Payouts Rejected"
              img={adminboxorangex}
              data={`$ ${overview?.totalRejected || '0'}`}
              info={overview?.totalRejectedCount}
              info2="Number of Rejected :"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Hightest Payout"
              img={adminboxorangex}
              data={`$ ${overview?.highestPayout?.Amount || '0'}`}
              info={`${overview?.highestPayout?.customer?.Firstname || ''} ${overview?.highestPayout?.customer?.Lastname || ''}`}
              color="blue"
            ></OverviewBox>
            <OverviewBox
              title="Most Consistent Payout"
              img={adminboxorangex}
              data={`$ ${overview?.ConsistentPayout?.Amount || '0'}`}
              info={`${overview?.ConsistentPayout?.customer?.Firstname || ''} ${overview?.ConsistentPayout?.customer?.Lastname || ''}`}
              color="blue"
            ></OverviewBox>
            <OverviewBox
              title="Average Payout Time"
              img={adminboxorangex}
              data={`${overview?.avgDateTime ? moment(overview?.avgDateTime).format('hh:mm:ss') : '00:00:00'}`}
              info={"Average Time"}
              color="blue"
            ></OverviewBox>

          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div class="flex gap-3 mb-3 ">
                <div
                  class={` py-2 px-4 text-sm md:text-lg font-medium  leading-4 text-center border-b-2  cursor-pointer  ${currentTab === "wire"
                    ? "text-[#008ffd] border-b-[#008ffd] "
                    : "border-b-gray-200 hover:border-b-black text-gray-400"
                    }`}
                  onClick={() => setCurrentTab("wire")}
                >
                  Wire Payout Requests
                </div>

                <div
                  class={` py-2 px-4 text-sm md:text-lg leading-4 font-medium  text-center border-b-2  cursor-pointer  ${currentTab === "crypto"
                    ? "text-[#008ffd] border-b-[#008ffd] "
                    : "border-b-gray-200 hover:border-b-black text-gray-400"
                    }`}
                  onClick={() => setCurrentTab("crypto")}
                >
                  Crypto Payout Requests
                </div>
              </div>

              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val);
                    setFilterParams({ ...filterParams, search: val });
                  }}
                  filter={filter}
                  placeholder="Search by name or email"
                ></TableSearch>

                <AdminFilter
                  classprop="long"
                  name="withdrawal_status"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      status: val !== "ALL" ? val : "",
                    });
                  }}
                  option={option}
                  list={["ALL", "APPROVED", "PENDING", "REJECTED"]}
                  defaultValue="PENDING"
                ></AdminFilter>

                <div className="flex-container price-seperator">
                  <PriceFilter
                    setValue={(val) => {
                      setMinValue(val);
                      setFilterParams({ ...filterParams, minAmount: val });
                    }}
                    value={minValue}
                    placeholder="Min. Amount"
                  ></PriceFilter>
                  <div> - </div>
                  <PriceFilter
                    setValue={(val) => {
                      setMaxValue(val);
                      setFilterParams({ ...filterParams, maxAmount: val });
                    }}
                    value={maxValue}
                    placeholder="Max. Amount"
                  ></PriceFilter>
                </div>

                {/* 
                  <TableBtn text="Add Manual Payout"></TableBtn>
                  <TableFilter></TableFilter>
                */}
              </div>
            </div>
            {loading && (
              <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            )}
            {!loading && (
              <>
                {currentTab === "crypto" ? (
                  <CryptoPayoutRequests
                    refresh={loadPayouts}
                    data={payouts}
                    colNames={payouts_datacolNames}
                    color="green"
                    color2="blue"
                    color3="green"
                    color4="black"
                  ></CryptoPayoutRequests>
                ) : (
                  <WithdrawalRequests
                    refresh={loadPayouts}
                    data={payouts}
                    colNames={payouts_datacolNames}
                    color="green"
                    color2="blue"
                    color3="green"
                    color4="black"
                  ></WithdrawalRequests>
                )}

                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Payouts;
