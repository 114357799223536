import React from "react";
import { NavLink } from "react-router-dom";

const AccountsSelector = () => {
  return (
    <div class="dm-wrapper accounts-selecter">
      <input type="radio" name="select" id="option-1" />
      <input type="radio" name="select" id="option-2" />
      <input type="radio" name="select" id="option-3" />
      <input type="radio" name="select" id="option-4" />
      <input type="radio" name="select" id="option-5" />
      <NavLink to="/accounts/wallet-account">
        <label for="option-1" className="option option-1">
          <span>Wallet Account</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/accounts/live-accounts">
        <label for="option-2" className="option option-2">
          <span>Live Accounts</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/accounts/demo-accounts">
        <label for="option-3" className="option option-3">
          <span>Demo Accounts</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/accounts/transaction-history">
        <label for="option-4" className="option option-4">
          <span>Transaction History</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/accounts/rewards">
        <label for="option-5" className="option option-5">
          <span>Rewards</span>
        </label>
      </NavLink>
    </div>
  );
};

export default AccountsSelector;
