import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import LiveAccountBox from "../components/LiveAccountBox";
import OpenLiveAccount from "../components/OpenLiveAccount";
import AddFunds from "../components/AddFunds";
import ChangeInvestorPassword from "../components/ChangeInvestorPassword";
import PasswordChangedSuccess from "../components/PasswordChangedSuccess";
import LiveAccSuccess from "../components/LiveAccSuccess";
import { getAccounts, setArchive } from "../api/trading";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { toast } from "react-toastify";
import {
  BsFillArrowRightCircleFill,
  BsFillArrowRightSquareFill,
} from "react-icons/bs";

import ChangeLeverage from "../components/ChangeLeverage";
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";


const LiveAccounts = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  const [loading, setLoading] = useState(false);
  const [accountNumberToDepositTo, setAccountNumberToDepositTo] = useState();
  const [liveAccounts, setLiveAccounts] = useState([]);
  const [openModal, setOpenModal] = useState(false);

  const [openAddModal, setOpenAddModal] = useState(false);

  // change investor password
  const [openPassModal, setOpenPassModal] = useState(false);

  // change master Password
  const [openMaster, setOpenMaster] = useState(false);

  // change Leverage
  const [openLeverage, setOpenLeverage] = useState(false);

  const [index, setIndex] = useState(1);

  const [accountToChange, setAccountToChange] = useState();

  let [showAll, setShowAll] = useState(false);

  // BATCH SELECT lIVE ACCOUNTS
  const [selectedAccounts, setSelectedAccounts] = useState([]);
  const [allAccountsSelected, setAllAccountsSelected] = useState(false);

  const handleAccountSelection = (accountID) => {
    setAllAccountsSelected(false);
    let updatedSelection = [...selectedAccounts];

    if (updatedSelection.includes(accountID)) {
      updatedSelection = updatedSelection.filter((id) => id !== accountID);
    } else {
      updatedSelection.push(accountID);
    }

    setSelectedAccounts(updatedSelection);
  };

  const selectAllAccounts = () => {
    if (allAccountsSelected) {
      setSelectedAccounts([]);
    } else {
      const allAccountIds = liveAccounts.map((account) => account.id);
      setSelectedAccounts(allAccountIds);
    }
    setAllAccountsSelected(!allAccountsSelected);
  };

  const continueBtn = () => {
    if (index < 2) {
      setIndex((prevIndex) => prevIndex + 1);
      window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    }
  };

  const resetIndex = (index) => {
    setIndex(1);
  };

  const loadLiveAccounts = async (limit, archive = null) => {
    setLoading(true);
    setLiveAccounts([]);
    const accounts = await getAccounts(limit, 0, archive, "LIVE");
    if (accounts != null) {
      setLiveAccounts(accounts);
    }
    setLoading(false);
  };

  const setArchiveHandler = async (Account_ID, Archive) => {
    const data = {
      Account_ID: Account_ID,
      Archive: Archive,
    };
    const response = await setArchive({ data });
    setLoading(true);
    if (response) {
      refresh();
      if (!Archive) {
        toast("Successfully unarchived Account!", {
          type: "success",
        });
      } else {
        toast("Successfully archived Account!", {
          type: "success",
        });
      }
    }
  };

  const refresh = () => {
    if (showAll) {
      loadLiveAccounts(null, "show_archive");
    } else {
      loadLiveAccounts(5);
    }
  };

  useEffect(() => {
    if (showAll) {
      loadLiveAccounts(null);
    } else {
      loadLiveAccounts(5);
    }
  }, [showAll]);

  const type = ["APEX MT5 PRO"];
  let [color, setColor] = useState("#008ffd");
  return (
    <>
      <AppBar title="Accounts"></AppBar>
      <AccountsSelector></AccountsSelector>
      <Outlet></Outlet>
      <div className="dashboard-container">
        {loading ? (
          <div className="bg-white static flex justify-center w-full">
            <FadeLoader color={color} loading={loading} size={80} />
          </div>
        ) : null}
        <div class="deposit-funds">
          {openModal && (
            <OpenLiveAccount
              title="Live"
              title2="Live"
              type={type}
              openModal={openModal}
              closeModal={setOpenModal}
              continueBtn={continueBtn}
            ></OpenLiveAccount>
          )}

          {openAddModal && (
            <AddFunds
              openModal={openAddModal}
              closeModal={setOpenAddModal}
              continueBtn={continueBtn}
              accountNumber={accountNumberToDepositTo}
            ></AddFunds>
          )}

          {openMaster && (
            <ChangeInvestorPassword
              openModal={openAddModal}
              closeModal={setOpenMaster}
              title="Change Master Password"
              continueBtn={continueBtn}
              type="main"
              accountToChange={accountToChange}
            ></ChangeInvestorPassword>
          )}

          {openPassModal && (
            <ChangeInvestorPassword
              openModal={openAddModal}
              closeModal={setOpenPassModal}
              title="Change Investor Password"
              continueBtn={continueBtn}
              type="investor"
              accountToChange={accountToChange}
            ></ChangeInvestorPassword>
          )}

          {openLeverage && (
            <ChangeLeverage
              openModal={openModal}
              closeModal={setOpenLeverage}
              accountToChange={accountToChange}
              refresh={refresh}
            />
          )}

          <div className="flex-wrap justify-between gap-2 deposit-page-box-title">
            <div className="flex items-center">
              <div className="color-box" style={{ background: "#008FFD" }}></div>
              <span>MT5 Live Accounts</span>
            </div>

            <div className="flex items-center gap-2 ">
              <button
                className="open-live-acc-btn"
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Open Live Account
              </button>
              {!showAll ? (
                <div
                  className=" h-[56px] flex items-center justify-center px-4 py-2 font-semibold text-[#008ffd] bg-transparent border border-[#e5e7eb] rounded-[50px] cursor-pointer hover:bg-[#008ffd] hover:text-white hover:border-transparent"
                  onClick={() => setShowAll(true)}
                >
                  <p className="text-sm">Show All</p>{" "}
                  <BsFillArrowRightCircleFill className="mx-2"></BsFillArrowRightCircleFill>
                </div>
              ) : null}
            </div>
          </div>
          <div className="horizontal-line"></div>

          {/* <div className="live-acc-text">
              <p>You dont have any Live Accounts</p>
              <span>Open your first live account and start trading today</span>
              <button className="open-live-acc-btn">Open Live Account</button>
            </div> */}

          <div className="flex items-center gap-2 ml-auto">
            {!showAll ? (
              <>
                {selectedAccounts.length > 0 ? (
                  <button
                    className={`mt-2 cursor-pointer flex py-2 items-center justify-center text-xs md:text-sm font-500 rounded-md text-[#008ffd] hover:bg-[#008ffd] hover:text-white hover:border-transparent" bg-slate-200 border px-2 `}
                    onClick={() => setArchiveHandler(selectedAccounts, true)}
                  >
                    Archive All Selected
                  </button>
                ) : (
                  ""
                )}
              </>
            ) : (
              ""
            )}

            {!showAll ? (
              <button
                className={`mt-2 cursor-pointer flex py-2 items-center justify-center text-xs md:text-sm font-500 rounded-md text-[#008ffd] bg-slate-200 border w-[100px]  ${allAccountsSelected ? "outline outline-2 outline-[#008ffd]" : ""
                  }`}
                onClick={selectAllAccounts}
              >
                Select All
              </button>
            ) : (
              ""
            )}
          </div>

          <div className="live-acc-box-grid">
            {liveAccounts.map((account) => {
              return (
                <LiveAccountBox
                  key={account.id}
                  title={account.trading_account_id}
                  setAccountNumberToDepositTo={setAccountNumberToDepositTo}
                  openAddModal={openAddModal}
                  setOpenAddModal={setOpenAddModal}
                  openPassModal={openPassModal}
                  setOpenPassModal={setOpenPassModal}
                  openMaster={openMaster}
                  setOpenMaster={setOpenMaster}
                  accType="APEX MT5 PRO"
                  account={account}
                  setAccountToChange={setAccountToChange}
                  setArchive={setArchiveHandler}
                  Account_ID={account.id}
                  Archive={account.Archive}
                  handleSelection={() => handleAccountSelection(account.id)}
                  selected={selectedAccounts.includes(account.id)}
                  showAll={showAll}
                  openLeverage={openLeverage}
                  setOpenLeverage={setOpenLeverage}
                ></LiveAccountBox>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default LiveAccounts;
