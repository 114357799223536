import React, { useEffect, useLayoutEffect, useState } from "react";
import KycFlow from "../components/kyc/KycFlow";
import KycInstructions from "../components/kyc/KycInstructions";
import "./Verification.css";
import { useParams } from "react-router-dom";
import AppBar from "../components/AppBar";
import { getSpecificUserDocument } from "../api/document";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";
import Cookies from "js-cookie";
// ----------------------VERIFICATION PROCESS PAGE------------------

const NewVerification = ({ showNav, noNav }) => {
  const customer = useSelector(selectCurrentCustomer);
  const [processing, setProcessing] = useState(false);
  const [documentToResubmit, setDocumentToResubmit] = useState();
  const [typeOfDocumentToResubmit, setTypeOfDocumentToResubmit] = useState();
  const [selectedCountry, setSelectedCountry] = useState("");
  const [allCountries, setAllCountries] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState("");
  const [isMobile, setIsMobile] = useState(false);
  const [index, setIndex] = useState(0);
  const [cookie, setCookie] = useState("");
  const { id } = useParams();
  showNav(true);
  noNav(false);
  useEffect(() => {
    if (customer.Status == "VERIFIED") {
      window.location.href = "/";
    }
    window.scrollTo(0, 0);
  }, []);

  const restoreCookie = () => {
    let cookies = Cookies.get(),
      parsedCookies;
    if (cookies) {
      parsedCookies = `${encodeURIComponent(JSON.stringify(cookies))}`;
    }
    setCookie(parsedCookies);
  };

  useEffect(() => {
    restoreCookie();
  }, []);

  const detectDevice = () => {
    if (
      navigator.userAgent.match(/Android/i) ||
      navigator.userAgent.match(/webOS/i) ||
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/samsung/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i) ||
      navigator.userAgent.match(/BlackBerry/i) ||
      navigator.userAgent.match(/Windows Phone/i)
    ) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  };

  useEffect(() => {
    detectDevice();
    window.addEventListener("resize", detectDevice);
    window.addEventListener("orientationchange", detectDevice);
    return () => {
      window.removeEventListener("resize", detectDevice);
      window.removeEventListener("orientationchange", detectDevice);
    };
  }, []);

  const loadDocumentToResubmit = async () => {
    setProcessing(true);
    try {
      if (id != null) {
        const doc = await getSpecificUserDocument(id);
        if (doc?.kyc_document_type.Type_Name == "Passport") {
          setIndex(2);
        } else if (doc?.kyc_document_type.Type_Name == "National ID") {
          setIndex(2);
        } else if (doc?.kyc_document_type.Type_Name == "Drivers License") {
          setIndex(2);
        }
        if (doc?.kyc_document_type.Type_Name == "Selfie") {
          setIndex(4);
        } else if (doc?.kyc_document_type.Type_Name == "Signed Signature") {
          setIndex(5);
        }
        setDocumentToResubmit(doc);
        setTypeOfDocumentToResubmit(doc?.kyc_document_type.Type_Name);
      }
    } catch (err) {
      setProcessing(false);
      console.error(err);
    } finally {
      setProcessing(false);
    }
  };

  useLayoutEffect(() => {
    loadDocumentToResubmit();
  }, []);

  const continueBtn = (sum) => {
    if (index === 5) return;
    if (index < 6) {
      if (sum == "sum") {
        setIndex((prevIndex) => prevIndex + 2);
      } else {
        setIndex((prevIndex) => prevIndex + 1);
      }
    }
  };
  const previousBtn = (sub) => {
    if (index <= 5) {
      if (sub == "sub") {
        setIndex((prevIndex) => prevIndex - 2);
      } else {
        setIndex((prevIndex) => prevIndex - 1);
      }
    }
  };

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="KYC Verification"></AppBar>
        <div className="dashboard-container">
          <div className="mx-auto verification-form-container">
            {!id && index === 0 && (
              <KycInstructions
                isMobile={isMobile}
                setSelectedCountry={setSelectedCountry}
                setAllCountries={setAllCountries}
                continueBtn={continueBtn}
                previousBtn={previousBtn}
                cookie={cookie}
              />
            )}
            {id &&
              documentToResubmit &&
              typeOfDocumentToResubmit &&
              index > 0 && (
                <KycFlow
                  isMobile={isMobile}
                  documentToResubmit={documentToResubmit}
                  customer={customer}
                  id={id}
                  index={index}
                  setIndex={setIndex}
                  continueBtn={continueBtn}
                  previousBtn={previousBtn}
                  processing={processing}
                  setProcessing={setProcessing}
                  typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                  selectedCountry={selectedCountry}
                  setSelectedCountry={setSelectedCountry}
                  selectedDocument={selectedDocument}
                  setSelectedDocument={setSelectedDocument}
                />
              )}
            {index > 0 && !id && (
              <KycFlow
                isMobile={isMobile}
                customer={customer}
                index={index}
                setIndex={setIndex}
                continueBtn={continueBtn}
                previousBtn={previousBtn}
                processing={processing}
                setProcessing={setProcessing}
                allCountries={allCountries}
                typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
                selectedDocument={selectedDocument}
                setSelectedDocument={setSelectedDocument}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default NewVerification;
