import React, { useState } from "react";
import moment from "moment";

// css
// HistoryTable.css

const AdminCryptoDepositTable = ({
  refresh,
  data,
  colNames,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {
  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={"head-1"}>
            <th>#</th>
            <th>M2P Payment ID</th>
            <th>Date Requested</th>
            <th>Client #</th>
            <th>Name</th>
            <th>Amount (USD)</th>
            <th>Date Processed</th>
            <th>Status</th>
            <th>Processing Time</th>
            <th>Processed By</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={"data-" + i}>
                <td>{i + 1}</td>
                <td>{row?.M2P_Payment_ID}</td>
                <td>
                  {moment(row.Date_Created).format("MMM D, YYYY hh:mm:ss A")}
                </td>
                <td>{row?.Customer_ID}</td>
                <td>{`${row?.Customer?.Firstname} ${row?.Customer?.Lastname}`}</td>
                <td>{row.Amount}</td>
                <td>
                  {moment(row.Date_Created).format("MMM D, YYYY hh:mm:ss A")}
                </td>
                <td>{row.Status}</td>
                <td>{null}</td>
                <td>{null}</td>
              </tr>
            );
          })}

          {/* {Object.values(data).map((obj, index) => (
            <tr key={index}>
              {Object.values(obj).map((value, index2) => (
                <td
                  className={`${index2 === 0 && color6} || ${
                    index2 === 1 && color
                  } || ${index2 === 3 && color2} || ${
                    index2 === 3 && value === "Verified" && "green"
                  } || ${index2 === 3 && value === "Rejected" && "red"} || ${
                    index2 === 4 && color3
                  } ${index2 === 4 && value === "Rejected" && "red"}  || ${
                    index2 === 4 && value === "Approved" && "green"
                  } || ${index2 === 5 && color4} || ${
                    index2 === 6 && color5
                  } || ${index2 === 6 && value === "Success" && "green"} || ${
                    index2 === 6 && value === "Decline" && "red"
                  } || ${index2 === 6 && value === "Proceeding" && "blue"} || ${
                    index2 === 7 && "admin-flex"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${index2 === 8 && value[0] === "+" && "green"} || ${
                    index2 === 8 && value[0] === "-" && "red"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  } ${index2 === 9 && "admin-flex"} || ${
                    index2 === 9 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${
                    index2 === 9 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  }`}
                  key={index2}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </table>
    </div>
  );
};

export default AdminCryptoDepositTable;
