import React, { useState, useEffect } from "react";
import AppBar from "../components/AppBar";
// components
import DepositForm from "../components/DepositForm";
import DeleteCardInfo from "../components/DeleteCardInfo";
import CardSelect from "../components/CardSelect";
import EditCardInfo from "../components/EditCardInfo";
import ConfirmDeposit from "../components/ConfirmDeposit";
import ProcessingTransaction from "../components/ProcessingTransaction";
import DepositSuccess from "../components/DepositSuccess";
import PaymentFailed from "../components/PaymentFailed";
import WireSubmitted from "../components/WireSubmitted";
import DepositDetails from "../components/DepositDetails";

import CryptoModal from "../components/CryptoModal";
import WireTransferModal from "../components/WireTransferModal";

import DepositHistoryTable from "../components/DepositHistoryTable";
import { depositTableData } from "../components/DepositHistoryData";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";

// CSS
import "./Deposit.css";
import { addPendingCharge } from "../api/card";
import { transfers } from "../api/data";

const Deposit = ({ showNav, noNav }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // this will fire only on first render
  }, []);

  useEffect(() => {}, []);
  showNav(true);
  noNav(false);

  // DEPOSIT FILTER
  const options = [
    { label: "All", data: null },
    { label: "Last 3 Months", data: "3_MONTHS" },
    { label: "Last Week", data: "LAST_WEEK" },
  ];
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(options[0]);

  const [openModal, setOpenModal] = useState(false);

  const [openCryptoModal, setOpenCryptoModal] = useState(false);

  const [openWireModal, setOpenWireModal] = useState(false);

  // Keep track of amount entered in deposit
  const [valueAmt, setValueAmt] = useState("");

  //  DEPOSIT METHODS
  const [card, setCard] = useState(true);
  const [crypto, setCrypto] = useState(false);
  const [wire, setWire] = useState(false);
  const [deposits, setDeposits] = useState([]);

  useEffect(() => {
    transfers({ range: selected.data, type: "ALL_DEPOSITS" })
      .then((response) => {
        setDeposits(response);
      })
      .catch((err) => console.error(err));
  }, [selected]);

  const depositMessage = (
    <p>
      Congratulations Zesan! Your payment using the card number{" "}
      <b className="black">(****4080)</b> was successful. Check your{" "}
      <span className="blue">wallet.</span>
    </p>
  );

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Deposit"></AppBar>
        <div className="dashboard-container">
          {openModal && (
            <DepositForm
              openModal={openModal}
              closeModal={setOpenModal}
              valueAmt={valueAmt}
              // for confirm deposit component
              confirmTitle="Confirm Deposit Details"
              // for confirm deposit
              message={depositMessage}
            ></DepositForm>
          )}

          {openCryptoModal && (
            <CryptoModal
              openModal={openModal}
              closeModal={setOpenCryptoModal}
              valueAmt={valueAmt}
              title="Deposit via CryptoCurrency"
              deposit="deposit"
            ></CryptoModal>
          )}

          {openWireModal && (
            <WireTransferModal
              openModal={openModal}
              closeModal={setOpenWireModal}
              valueAmt={valueAmt}
              title="Deposit via CryptoCurrency"
              detailsTitle="Transaction Details"
              method="Deposit Method"
              account="Deposit Account"
            ></WireTransferModal>
          )}

          <div class="deposit-page-grid">
            {/* ------------DEPOSIT FUNDS SECTION---------------- */}
            <div class="deposit-funds">
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#008FFD" }}
                ></div>
                <span>Deposit Funds</span>
              </div>
              <div className="horizontal-line"></div>
              <div className="deposit-method">
                <p>Deposit Method</p>
                <div class="dm-wrapper">
                  <input
                    type="radio"
                    name="select"
                    id="option-1"
                    checked={card && true}
                    onClick={() => {
                      setCard(true);
                      setCrypto(false);
                      setWire(false);
                    }}
                  />
                  <input
                    type="radio"
                    name="select"
                    id="option-2"
                    onClick={() => {
                      setCard(false);
                      setCrypto(true);
                      setWire(false);
                    }}
                  />
                  <input
                    disabled
                    type="radio"
                    name="select"
                    id="option-3"
                    onClick={() => {
                      setCard(false);
                      setCrypto(false);
                      setWire(true);
                    }}
                  />
                  <label for="option-1" class="option option-1">
                    <span>Credit/Debit Card</span>
                  </label>
                  <div className="line"></div>
                  <label for="option-2" class="option option-2">
                    <span>CryptoCurrency</span>
                  </label>
                  <div className="line"></div>
                  <label for="option-3" class="option option-3 disabled">
                    <span>Wire Transfer</span>
                  </label>
                </div>
              </div>
              <div className="enter-amount">
                <div className="enter-amt-top-row">
                  <p>Enter Amount</p>
                  <span>Between USD $1 - 1,000,000</span>
                </div>
                <div className="amount-section">
                  <div className="amount-input">
                    {/* <input type="text" placeholder="$0.00" /> */}
                    <CurrencyInput
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "$0.00")}
                      id="input-example"
                      name="input-name"
                      placeholder="$0.00"
                      defaultValue={""}
                      prefix="$"
                      decimalsLimit={2}
                      // onValueChange={(value) => console.log(value)}
                      onValueChange={(value) => setValueAmt(value)}
                    />
                    <div className="select-currency-btn currency-type">
                      USD<BsChevronDown></BsChevronDown>
                      <div className="sc-content"></div>
                    </div>

                    {/* <button>USD</button> */}
                  </div>
                  {wire ? (
                    <p>Processing Time (approx): 1-5 business days</p>
                  ) : (
                    <p>Processing Time (approx): Instant</p>
                  )}
                </div>
              </div>

              {/* CARD METHOD */}
              {card && (
                <button
                  className="verification-continue-btn complete"
                  disabled={valueAmt == null}
                  onClick={() => {
                    setOpenModal(true);
                  }}
                >
                  Continue
                </button>
              )}

              {/* CRYPTO METHOD */}
              {crypto && (
                <button
                  className="verification-continue-btn complete crypto"
                  disabled={valueAmt == null || valueAmt == ""}
                  onClick={() => {
                    setOpenCryptoModal(true);
                  }}
                >
                  Continue
                </button>
              )}

              {/* WIRE TRANSFER METHOD */}
              {wire && (
                <button
                  className="verification-continue-btn complete"
                  onClick={() => {
                    setOpenWireModal(true);
                  }}
                >
                  Continue
                </button>
              )}
            </div>
            {/* ------------DEPOSIT FUNDS SECTION---------------- */}

            {/* --------DEPOSIT HISTORY SECTION---------------- */}
            <div class="deposit-history">
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#141416" }}
                ></div>
                <span>Deposit History</span>
                {/*--------------- Dropdown filter ---------- */}
                <div class="deposit-filter">
                  <div
                    className="df-btn"
                    onClick={(e) => {
                      setIsActive(!isActive);
                    }}
                  >
                    {selected.label}
                    <span>
                      <BsChevronDown></BsChevronDown>
                    </span>
                  </div>
                  {isActive && (
                    <div className="df-content">
                      {options.map((option) => (
                        <div
                          onClick={(e) => {
                            setSelected(option);
                            setIsActive(false);
                          }}
                          className="df-item"
                        >
                          {option.label}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                {/*--------------- Dropdown filter ---------- */}
              </div>

              <DepositHistoryTable
                data={deposits}
                hover={true}
              ></DepositHistoryTable>
            </div>
            {/* --------DEPOSIT HISTORY SECTION---------------- */}

            {/* --------FAQ SECTION---------------- */}
            <div class="faq-section">
              <div className="deposit-page-box-title">
                <div
                  className="color-box"
                  style={{ background: "#ffd166" }}
                ></div>
                <span>Deposit Notice</span>
              </div>
              <div className="horizontal-line"></div>
              <div className="deposit-faq-text">
                <p>
                  Apex Markets understands that when it comes to depositing your
                  account, you want to know that your funds are secure and there
                  are no hidden fees. Here is a quick rundown of our payment
                  policy to put your mind at ease:
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Processing Time</span>

                <p>
                  <div>&bull;</div>
                  Card payments will reflect in your Apex Market account
                  INSTANTLY.
                </p>

                <p>
                  <div>&bull;</div>
                  Cryptocurrency payments can take anywhere from 10 minutes to a
                  few hours to reflect in your account.
                </p>
                <p>
                  <div>&bull;</div>
                  Bank/Wire Transfer payments may take 1-5 business days to
                  reflect in your Apex Markets Account.
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Terms</span>
                <p>
                  <div>&bull;</div>
                  All payments will be processed to the chosen payment method.
                </p>
                <p>
                  <div>&bull;</div>
                  All payments made to Apex Markets are converted to USD and
                  placed in your Apex Markets Wallet. From there, you can
                  conduct transactions to and from the wallet, such as deposits
                  and withdrawals, or internal transfers to and from other Apex
                  Markets accounts under your profile.
                </p>
                <p>
                  <div>&bull;</div>
                  Apex Markets does not accept payments from third parties, nor
                  do we make payments to third parties. So we strongly urge you
                  to only make payments to your Apex Markets account directly
                  from a bank account or Credit/Debit Card in your name. This
                  way, we can ensure that the person making the payment is
                  indeed you. Payments from Joint Bank Accounts or Credit/Debit
                  Cards are accepted if the Apex Markets account holder is one
                  of the parties on the account.
                </p>
                <p>
                  <div>&bull;</div>
                  For security reasons, the initial payment made will be
                  withdrawn back to the initial payment method used. All
                  additional withdrawals will be processed to any other
                  withdrawal method of your choice.
                </p>
                <p>
                  <div>&bull;</div>
                  If you request a chargeback for a payment on your Apex Markets
                  account, your account will automatically be forfeited.
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Transaction Fees</span>
                <p>
                  <div>&bull;</div>
                  Apex Markets does not charge any fee for deposits. You should
                  however be aware that fees may incur on deposits from some
                  international banking institutions, Credit/Debit Card and
                  Cryptocurrency payment processors. Apex Markets accepts no
                  responsibility for any such transaction fees.
                </p>
              </div>
              <div className="deposit-faq-text">
                <span>Recommended Minimum</span>
                <p>
                  <div>&bull;</div>The recommended minimum amount for deposit is
                  $100 USD.
                </p>
              </div>
              <div className="deposit-faq-text">
                <div>
                  We hope this answers your questions regarding our payment
                  policy. Please do not hesitate to contact us at{" "}
                  <span className="blue">
                    <a href="mailto:support@apexmarkets.io">
                      support@apexmarkets.io
                    </a>
                  </span>{" "}
                  if you have any further inquiries.{" "}
                  <p>Thank you for choosing Apex Markets!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Deposit;
