import { React, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Formik, Field, FastField, Form } from "formik";
import * as Yup from "yup";

import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";

import { blockCard, unblockCard } from "../api/card";
import { formatCardNumber } from "../util";
const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1055;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ManageUnblockCardModal = ({ closeModal, chargeBackData, refresh }) => {
  const [loading, setLoading] = useState(false);
  const customer = useSelector(selectCurrentCustomer);

  const unblockCardAction = async (id) => {
    const response = await unblockCard(id);
    
    if (response.errors == null) {
        toast("Successfully unblocked card", { type: "success" });
        refresh();
        closeModal(false);
    }
  }

  useEffect(() => {});

  return (
    <ModalContainer>
      <div className="signature-modal medium-width">
        <div className="cpm-container">
          <div className="overview-section-top">
            <div className="os-left">Block Card</div>
          </div>
          <br />
          <Formik
            initialValues={{
              FirstSixDigitsOfCard: chargeBackData.FirstSixDigitsOfCard || null,
              LastFourDigitsOfCard: chargeBackData.LastFourDigitsOfCard || null,
              Reason: "",
              UserId: customer?.admin[0]?.ID,
              Customer_ID: chargeBackData?.Customer_ID
            }}
            onSubmit={async (
              values,
              { resetForm, setErrors, setStatus, setSubmitting }
            ) => {
              try {
                setLoading(true);
                unblockCardAction(chargeBackData.blocked[0]?.ID);
                setLoading(false);
                resetForm();
              } catch (err) {
                // toast.error("Something went wrong!");
                setStatus({ success: false });
                setErrors({ submit: err.message });
                setSubmitting(false);
              }
            }}
          >
            {({ errors, values, dirty, isValid, handleSubmit }) => {
              return (
                <form onSubmit={handleSubmit}>
                  <>
                    {errors && Object.keys(errors).length > 0 && (
                      <>
                        <div className="alert alert-danger">
                          {Object.values(errors).map((error) => (
                            <div>{error}</div>
                          ))}
                        </div>
                      </>
                    )}
                    <div className="form-group">
                      <label htmlFor="message">
                        Are you sure you would like to unblock this card?
                      </label>
                      <div className="form-group">
                        <h3>
                          {formatCardNumber(
                            chargeBackData.FirstSixDigitsOfCard,
                            chargeBackData.LastFourDigitsOfCard
                          )}
                        </h3>
                      </div>
                    </div>
                    <div className="buttons">
                      <button
                        type="submit"
                        className="verification-continue-btn complete"
                        disabled={loading}
                      >
                        { loading? 'Loading...' : 'Unblock Card' }
                      </button>
                      <button
                        className="verification-continue-btn complete"
                        onClick={() => {
                          closeModal(false);
                        }}
                      >
                        Close
                      </button>
                    </div>
                  </>
                </form>
              );
            }}
          </Formik>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ManageUnblockCardModal;
