import React, { useEffect } from "react";
import "../../pages/Verification.css";
import "./SignatureModal.css";
import $ from "jquery";
import html2pdf from "html2pdf.js";
import Loader from "../Loader";

const VerificationAgreement = ({
  signature,
  selfie,
  govtId,
  setFormHtml,
  setFormPdf,
  customerData,
}) => {
  const getFormHtml = () => {
    const elementArr = document.querySelectorAll(".verification-agreement-box");
    let html = "";
    for (let counter = 0; counter < elementArr.length; counter++) {
      const element = elementArr[counter];
      html += element.innerHTML;
    }
    let dom = $(html);
    dom.find('input[type="text"]').each(function () {
      const elem = $(this);
      const value = $(this).attr("value");
      elem.replaceWith(
        `<span class="swapped">${value != null ? value : ""}</span>`
      );
    });
    html = "";
    dom.each(function () {
      html += $(this).html();
    });
    html = html.replace("undefined", "");
    setFormHtml(html);
    return html;
  };

  useEffect(() => {
    const timestampInSeconds = Math.floor(Date.now() / 1000);
    const filename = `Form_${timestampInSeconds}.pdf`;
    if (selfie && govtId && signature) {
      html2pdf()
        .set({
          margin: [6, 30, 6, 30],
        })
        .from(getFormHtml())
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          fetch(pdfAsString)
            .then((res) => res.blob())
            .then((blob) => {
              const formData = new FormData();
              formData.append("image", blob, filename);
              setFormPdf(formData);
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [selfie, govtId, signature]);

  return (
    <>
      {!selfie || !signature || !govtId ? (
        <Loader message="Processing..." height />
      ) : (
        <div className="verification-agreement-box !h-[70vh]">
          <div className="pdf">
            <div className="customer-agreement-container">
              <h1 className="heading-1">
                Apex E-Commerce Services Customer Agreement
              </h1>
              <div className="sub-container">
                <h2 className="heading-2">Client’s Personal Information</h2>
                <div className="thin-font">
                  <div className="d-flex">
                    <div className="d-flex magin-right-4 margin-bottom-2">
                      <label className="right-margin-3 thin-font width-8rem">
                        First Name:
                      </label>
                      <input
                        className="thin-font"
                        value={customerData.firstName}
                        type="text"
                        name="firstName"
                        readOnly
                      />
                    </div>

                    <div className="d-flex magin-right-4 margin-bottom-2">
                      <label className="right-margin-3 thin-font width-8rem">
                        Last Name:
                      </label>
                      <input
                        className="thin-font"
                        value={customerData.lastName}
                        type="text"
                        name="lastName"
                        readOnly
                      />
                    </div>
                  </div>

                  <div
                    style={{ display: "flex", alignItems: "center" }}
                    className="d-flex item-center margin-bottom-2"
                  >
                    <div className="right-margin-3 thin-font width-8rem">
                      Gender:
                    </div>
                    <div
                      style={{ marginRight: "1.25rem" }}
                      className="d-flex gap- "
                    >
                      <input
                        checked={customerData?.gender?.toUpperCase() === "MALE"}
                        type="checkbox"
                        name="gender"
                        value="MALE"
                        disabled
                      />
                      <p>Male</p>
                    </div>
                    <div className="d-flex gap-">
                      <input
                        disabled
                        type="checkbox"
                        name="gender"
                        value="FEMALE"
                        checked={
                          customerData?.gender?.toUpperCase() === "FEMALE"
                        }
                      />
                      <p>Female</p>
                    </div>
                  </div>

                  <div className="d-flex magin-right-4 margin-bottom-2">
                    <label className="right-margin-3 thin-font width-8rem">
                      Date of Birth:
                    </label>
                    <input
                      className="thin-font"
                      value={customerData.dob}
                      type="date"
                      name="dob"
                      readOnly
                    />
                  </div>

                  <div className="d-flex magin-right-4 margin-bottom-2">
                    <label className="right-margin-3 thin-font width-8rem">
                      Phone Number:
                    </label>
                    <input
                      className="thin-font"
                      name="firstName"
                      value={customerData.telephoneCell}
                      type="telephoneCell"
                      readOnly
                    />
                  </div>
                  <div className="d-flex magin-right-4 margin-bottom-2">
                    <label className="right-margin-3 thin-font width-8rem">
                      Email Address:
                    </label>
                    <input
                      style={{ width: "200px" }}
                      className="thin-font w-[65%]"
                      name="email"
                      value={customerData.email}
                      type="email"
                      readOnly
                    />
                  </div>

                  <div className="margin-top-2">
                    <h2 className="bold-font">Address</h2>
                    <div style={{ marginLeft: "1.5rem" }}>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          Line 1:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          name="street"
                          value={customerData.street1}
                          type="text"
                          readOnly
                        />
                      </div>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          Line 2:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          value={customerData.street2}
                          type="text"
                          name="street2"
                          readOnly
                        />
                      </div>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          City:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          value={customerData.city}
                          type="text"
                          name="city"
                          readOnly
                        />
                      </div>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          State/Region:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          value={customerData.state}
                          type="text"
                          name="state"
                          readOnly
                        />
                      </div>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          Postal/Zip Code:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          value={customerData.postalCode}
                          type="text"
                          name="postalCode"
                          readOnly
                        />
                      </div>
                      <div className="d-flex margin-top-1">
                        <label className="right-margin-3 thin-font width-8rem">
                          Country:
                        </label>
                        <input
                          className="thin-font grow-flex"
                          value={customerData.country}
                          type="text"
                          name="country"
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                  <div style={{ marginTop: "1rem" }}>
                    <label className="right-margin-3 thin-font">
                      Date Signed:
                    </label>
                    <input
                      className="thin-font"
                      value={new Date().toISOString().split("T")[0]}
                      type="date"
                      readOnly
                    />
                  </div>
                </div>

                <div className="margin-top-6 light-font font-15">
                  <h6>To: Apex E-Commerce Services</h6>
                  <h1
                    style={{
                      wordSpacing: "0.25rem",
                      marginTop: "0.75rem",
                      fontSize: "1.125rem",
                      lineHeight: "1.75rem",
                      fontWeight: 600,
                    }}
                    className="underline-text"
                  >
                    Re: Payment Authorization For Apex E-Commerce Services’
                    Online Software Services.
                  </h1>

                  <p className="light-font margin-top-6">
                    I{" "}
                    <span className="bold-font">
                      {!customerData.firstName && !customerData.lastName
                        ? "___________________"
                        : customerData.firstName + " " + customerData.lastName}
                    </span>{" "}
                    holder of
                  </p>
                  <div
                    style={{
                      justifyContent: "center",
                      flexWrap: "wrap",
                      gap: "2rem",
                    }}
                    className="d-flex item-center margin-top-2"
                  >
                    <div className="d-flex gap-">
                      <input
                        disabled
                        type="checkbox"
                        checked={
                          customerData.idType &&
                          customerData.idType.includes("driverslicence")
                        }
                      />{" "}
                      <p>Driver's License</p>
                    </div>
                    <div className="d-flex gap-">
                      <input
                        disabled
                        type="checkbox"
                        checked={
                          customerData.idType &&
                          customerData.idType?.includes("passport")
                        }
                      />{" "}
                      <p>Passport</p>
                    </div>
                    <div className="d-flex gap-">
                      <input
                        disabled
                        type="checkbox"
                        checked={
                          customerData.idType &&
                          customerData.idType?.includes("nationalid")
                        }
                      />{" "}
                      <p>National ID</p>
                    </div>
                  </div>
                  <p className="light-font margin-top-6">
                    <span className="bold-font">
                      {customerData?.idType?.charAt(0)?.toUpperCase() +
                        customerData?.idType?.slice(1)?.toLowerCase()}
                      ID Number: &nbsp;
                      <span style={{ textDecorationLine: "underline" }}>
                        {customerData.idNumber}
                      </span>
                    </span>
                    &nbsp; with{" "}
                    <span className="bold-font">
                      Expiry Date: &nbsp;
                      <span style={{ textDecorationLine: "underline" }}>
                        {customerData.idExpiry}
                      </span>
                      &nbsp;
                    </span>
                    hereby confirm the authorization of the legality and
                    validity of payments made to Apex E-Commerce Services by
                    card payment/bank transfers.
                  </p>
                  <p className="light-font margin-top-6">
                    I confirm that I am the authorized holder of the above
                    stated identification number, and the authorized card holder
                    of the card used to make the payments. Thereby, verifying
                    this non-refundable payment and future payments to Apex
                    E-Commerce Services for access to their online software
                    licenses pursuant to Apex E-Commerce Services&#39; terms of
                    service.
                  </p>
                  <p className="light-font margin-top-6">
                    I represent and warrant that any transaction in accordance
                    with this letter will serve as an act in full compliance
                    with all the applicable local, national and international
                    laws and regulations.
                  </p>
                  <p className="light-font margin-top-6">
                    Continually, I represent and warrant and certify that I am
                    by no means under investigation by any governmental
                    authority with regards to, have been charged with, or
                    convicted of; money laundering, drug trafficking, any
                    terrorist related activities, or any crimes which in any
                    jurisdiction would be predicate crimes or offenses that are
                    in contravention to anti-money laundering laws.
                  </p>
                  <p className="light-font margin-top-6">
                    I affirm that I have not been assessed civil or criminal
                    penalties under any Anti money laundering law or have had
                    any seizure of funds or forfeiture of any action under any
                    anti-money laundering laws of any country/ jurisdiction.
                  </p>
                  <p className="light-font margin-top-6">
                    Therefore, I represent and warrant that the funds for this
                    monetary payment to Apex E-Commerce Services does not and
                    will not derive from any illegal activity that violates any
                    Anti Money Laundering Laws and Regulations.
                  </p>
                  <p className="light-font margin-top-6">
                    I recognize that by law, Apex E-Commerce Services may
                    disclose my name and identity in the event that such
                    information is requested in relation to this letter.
                    Additionally, I will notify Apex E-Commerce Services at
                    once, when it is known to me that said representation is
                    fallacious or becomes fallacious.
                  </p>
                  <p className="light-font margin-top-6">
                    Finally, subject to and upon the terms and conditions of
                    this agreement, I shall indemnify and hold harmless Apex
                    E-Commerce Services, its employees, affiliates and directors
                    from any claim or disputes that may arise as a result of any
                    payments made by me in relation to Apex E-Commerce Services
                    software license agreement/s.
                  </p>
                  <p className="light-font margin-top-6">
                    I further confirm that this is a non refundable monetary
                    payment conducted pursuant to Apex E- Commerce Services'
                    terms of service.
                  </p>
                </div>

                <div className="font-15">
                  <h1 className="margin-top-6">
                    Declaration and Acknowledgment of Accuracy:
                  </h1>
                  <div>
                    <h2 className="thin-font margin-top-6">
                      1. &nbsp; Comprehensive Declaration:
                    </h2>
                    <p className="thin-font margin-left-5 margin-top-6">
                      By signing this Agreement, the client declares all
                      provided information, whether explicit or implied, to be
                      accurate. This encompasses personal details,
                      qualifications, experiences, and representations made
                      during the evaluation and contracting process.
                    </p>

                    <h2 className="thin-font margin-top-6">
                      2. &nbsp; Implications of Misrepresentation:
                    </h2>
                    <p className="thin-font margin-left-5 margin-top-6">
                      Misrepresentations, whether by omission or commission, may
                      lead to immediate voiding of the application, termination
                      of this Agreement, and potential legal actions.
                    </p>

                    <h2 className="thin-font margin-top-6">
                      3. &nbsp; Catch-All Provision:
                    </h2>
                    <p className="thin-font margin-left-5 margin-top-6">
                      The client acknowledges this declaration as a
                      comprehensive safeguard for Apex E-Commerce Services and
                      its affiliates, ensuring the company&#39;s protection
                      against undisclosed facts, misrepresented information, or
                      unforeseen circumstances that arise contrary to the
                      client&#39;s declarations. This provision covers all
                      engagement aspects with Apex E-Commerce Services.
                    </p>

                    <h2 className="thin-font margin-top-6">
                      4. &nbsp; Responsibility of the Client:
                    </h2>
                    <p className="thin-font margin-left-5 margin-top-6">
                      The client is responsible for truthfully disclosing all
                      relevant information and promptly informing Apex
                      E-Commerce Services of any changes. Failure to do so
                      constitutes a breach of this Agreement, potentially
                      leading to termination.
                    </p>
                  </div>
                </div>

                <div className="d-flex item-center space-between margin-top-6">
                  <div className="d-flex item-center half-width">
                    <label style={{ marginRight: 8 }}>
                      Client’s Full Name:
                    </label>
                    <input
                      className="thin-font grow-flex"
                      value={`${customerData.firstName} ${customerData.lastName}`}
                      type="text"
                      readOnly
                    />
                  </div>
                  <div className="d-flex item-center flex-end half-width">
                    <label>Client’s Signature:</label>
                    <img
                      style={{
                        height: 70,
                        maxWidth: 220,
                        objectFit: "contain",
                      }}
                      src={signature}
                      alt="Client's Signature"
                    />
                  </div>
                </div>

                <div className="font-15">
                  <p className="margin-top-6 thin-font">
                    We,{" "}
                    <span className="bold-font">
                      Apex E-Commerce Services LLC
                    </span>
                    , acknowledge the above statements.
                  </p>
                  <p className="margin-top-6 thin-font">
                    We acknowledge and understand that payments shall be made
                    through our corporate bank account in the name Apex
                    E-Commerce Services. We further confirm that payments made
                    by the named payer will reach the stated bank account. If
                    this is to change for any given reason, it is our duty to
                    promptly inform the payer of such changes.
                  </p>
                  <p className="margin-top-6 thin-font">
                    Apex E-Commerce Services represents and warrants that any
                    activity in relation to this payment letter will be in full
                    compliance with all applicable local, national and
                    international laws, rules and regulations. This includes
                    Anti-Money Laundering laws and regulation of any applicable
                    jurisdiction or to respond to requests for information
                    concerning the identity of the payer from any government
                    Authority, regulatory organization or financial institution
                    in connection with its anti-money laundering compliance and
                    to update such information as necessary.
                  </p>
                  <p className="margin-top-6 thin-font">
                    Conversely, in compliance with anti-money laundering laws
                    and other regulatory bodies related to this issue, we agree
                    to provide at any time further documents verifying the
                    identity of the named payer and the source of funds used for
                    payment transfer towards our bank account.
                  </p>
                  <p
                    style={{ lineHeight: 2, fontSize: 14 }}
                    className="margin-top-6 thin-font"
                  >
                    <span className="bold-font">
                      Apex E-Commerce Services LLC holds a strict NO REFUND
                      POLICY
                    </span>{" "}
                    <br />
                    If the client makes a request for cancellation after
                    payment, the client is not entitled to a refund. <br />
                    Additionally, If you made any payment error, you may contact
                    us as soon as reasonably possible and we will assist you in
                    resolving the issue. If a refund is requested without
                    contacting us it will be held invalid and will risk your
                    account being banned. <br />
                    It should be duly noted that all accounts associated with a
                    chargeback will be automatically forfeited.
                  </p>

                  <div className="d-flex item-center space-between margin-top-6">
                    <div className="d-flex item-center">
                      <label style={{ marginRight: 8, width: "50%" }}>
                        Apex E-Commerce Services Authorized Representative
                        Signature:
                      </label>
                      <input
                        style={{ fontStyle: "oblique 20deg" }}
                        className="thin-font  w-[50%] text-right"
                        value="Apex E-commerce Services"
                        type="text"
                        readOnly
                      />
                    </div>
                  </div>
                </div>

                <div
                  style={{ borderTopWidth: ".01px", margin: "5px 0px" }}
                  className="black-border"
                />

                <div>
                  <p
                    style={{ widthMarginTop: "8rem", fontSize: 12 }}
                    className="thin-font"
                  >
                    Disclaimer of Warranties and Limitation of liability: Apex
                    E-Commerce Services provides a licensed online software
                    service that facilitates the creation of accounts for
                    authorized users, which enables them access to a host of
                    services. The service provided by Apex E-Commerce Services
                    is not to be confused or misconstrued to be anything else as
                    not to prejudice the operation and goodwill of the company.
                    Apex E-Commerce Services bears no responsibility or
                    liability for any aspect of usage by users that are not in
                    conformity with our services as per the governing laws
                    concerning this industry. Lastly, if any provision of this
                    agreement is unenforceable or held illegal in a judicial
                    proceeding such provision shall be severed and held
                    inoperative, whilst holding the remaining portion of this
                    agreement operative and binding on both parties. Apex
                    E-Commerce Services LLC and Apex E-Commerce Services Ltd are
                    one in the same, registered in different countries.
                  </p>
                </div>

                <div style={{ marginTop: "5rem" }} className="center-text">
                  <h1 className="underline-text">
                    PLEASE ATTACH YOUR VALID GOVERNMENT ISSUED ID AND A PICTURE
                    OF YOU HOLDING YOUR GOVERNMENT ISSUED ID BESIDE YOUR FACE:
                  </h1>
                  <table
                    style={{
                      width: "100%",
                      borderCollapse: "collapse",
                      marginTop: "2.5rem",
                      marginBottom: "2.5rem",
                      borderWidth: 1,
                      borderColor: "black",
                    }}
                    className="black-border"
                  >
                    <thead>
                      <tr>
                        <th
                          style={{ borderWidth: 1, borderColor: "black" }}
                          className="black-border half-width center-text font-15 black-text"
                        >
                          GOVERNMENT ISSUED ID
                        </th>
                        <th className="black-border half-width center-text font-15 black-text">
                          PICTURE HOLDING YOUR GOVERNMENT ISSUED ID BESIDE YOUR
                          FACE
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        style={{ height: 200, borderStyle: "none !important" }}
                      >
                        <td className="black-border">
                          <img
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              maxWidth: 250,
                              height: 250,
                              paddingLeft: 16,
                              objectFit: "contain",
                            }}
                            src={govtId}
                            alt="Client's Signature"
                          />
                        </td>
                        <td
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                          className="black-border"
                        >
                          <img
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              maxWidth: 250,
                              height: 250,
                              verticalAlign: "middle",
                              objectFit: "contain",
                              paddingLeft: 16,
                            }}
                            src={selfie}
                            alt="Client's Signature"
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default VerificationAgreement;
