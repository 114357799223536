import React, { useState } from "react";
import styled from "styled-components";

// react-spinners
// https://openbase.com/js/react-spinners
// https://www.davidhu.io/react-spinners/storybook/index.html?path=/docs/fadeloader--main
import FadeLoader from "react-spinners/FadeLoader";

import deleteIcon from "../images/icons/delete.svg";
// Deposit page processing transaction

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const ProcessingTransaction = ({ closeModal }) => {
  let [loading, setLoading] = useState(true);
  let [color, setColor] = useState("#008ffd");

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>Processing your Transaction</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="delete-card">
              <div>
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
              <p>Processing</p>
            </div>
            <div className="delete-card-info">
              <p>
                Please wait while we process your transaction. This will take no
                more than 30 seconds. and do not close this window or hit back.
              </p>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ProcessingTransaction;
