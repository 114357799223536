import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminBar from "../../components/admin/AdminBar";
import {
  selectCurrentCustomer,
} from "../../redux/slices/customerSlice";
import { createAppCreds } from "../../api/data";

import { toast } from "react-toastify";

const AppCredentials = ({ showAdminNav }) => {
  showAdminNav(true);
  const [loading, setLoading] = useState(false);
  const [appName, setAppName] = useState('');
  const [urlList, setUrlList] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const customer = useSelector(selectCurrentCustomer);

  const generateAppCreds = async () => {
    setLoading(true);
    if (appName.trim() !== '' && urlList.trim() !== '') {
      try {
        const { username, password } = await createAppCreds(appName,urlList);
        setUsername(username);
        setPassword(password);
        setAppName('');
        document.getElementById("AppName01").value = '';
        setUrlList('');
        document.getElementById("accessUrls").value = '';
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
        toast(err.message, {
          type: "error",
        });
      }
    }
    setLoading(false);
  };

  return (
    <div className="dashboard-page">
      <AdminBar title="Third-Party App Credentials"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="">
            <p>This section is used to generate credentials for external applications to use Apex Market's hooks.
              <br />Note, only usable by super admin.
            </p>
            <div className="cushion">
            <p>Enter a name descriptive of the external application or system:</p>
              <input
                type="text"
                id="AppName01"
                name="AppName"
                value={appName}
                placeholder="App Name"
                onChange={(e) => setAppName(e.target.value)}
                disabled={customer?.admin[0]?.Admin_Tier !== 2}
              />
            </div>
            <div className="cushion">
              <p>Enter the relative urls of the webhooks to give access to separated by a new line:</p>
              <textarea
                id="accessUrls"
                rows="5"
                cols="33"
                placeholder={"/trading/hook" + "\r\n" + "/crypto/hook"}
                onChange={(e) => setUrlList((e.target.value).trim())}
                ></textarea>
            </div>
            <div className="cushion">
              <button
                className="verification-continue-btn complete"
                onClick={() => generateAppCreds()}
                disabled={loading || customer?.admin[0]?.Admin_Tier !== 2}
              >
                Generate
              </button>
            </div>
            <div>
              <p>Generated Username: {username}</p>
              <p>Generated Password: {password}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppCredentials;
