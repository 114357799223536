import React, { useState, useEffect } from "react";
import AppBar from "../components/AppBar";
import WithdrawHistoryTable from "../components/WithdrawHistoryTable";
import { withdrawTableData } from "../components/WithdrawHistoryData";
// CARD
import WithdrawCardSelect from "../components/WithdrawCardSelect";
import ConfirmDeposit from "../components/ConfirmDeposit";
// CRYPTO
import CryptoModal from "../components/CryptoModal";
import ProcessingTransaction from "../components/ProcessingTransaction";
// WIRE
import BankAccDetails from "../components/BankAccDetails";
import ConfirmBankWithdraw from "../components/ConfirmBankWithdraw";
import EditBankInfo from "../components/EditBankInfo";
import DeleteBankInfo from "../components/DeleteBankInfo";
import DepositDetails from "../components/DepositDetails";

import CurrencyInput from "react-currency-input-field";
import OverlayLoader from "../components/OverlayLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { BsChevronDown } from "react-icons/bs";
// CSS
import "./Deposit.css";

import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css"; // optional

import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import CryptoWithdrawalModal from "../components/CryptoWithdrawalModal";
import { transfers } from "../api/data";
import { getAvailableCards } from "../api/card";
import { toast } from "react-toastify";

const Withdraw = ({ showNav, noNav }) => {
  const options = [
    { label: "All", data: null },
    { label: "Last 3 Months", data: "3_MONTHS" },
    { label: "Last Week", data: "LAST_WEEK" },
  ];

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // this will fire only on first render
  }, []);
  showNav(true);
  noNav(false);

  // DEPOSIT FILTER
  const [isActive, setIsActive] = useState(false);

  const [selected, setSelected] = useState(options[0]);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  // DEPOSIT FILTER

  const [openModal, setOpenModal] = useState(false);

  const [openCryptoModal, setOpenCryptoModal] = useState(false);

  const [openWireModal, setOpenWireModal] = useState(false);

  //  Withdraw METHODS
  const [card, setCard] = useState(true);
  const [crypto, setCrypto] = useState(false);
  const [wire, setWire] = useState(false);

  const [disableAllWithdrawals, setDisableAllWithdrawals] = useState(false);

  // Keep track of amount entered
  const [valueAmt, setValueAmt] = useState("");
  const [withdrawals, setWithdrawals] = useState([]);

  const customer = useSelector(selectCurrentCustomer);
  const walletBalance = customer.wallet.Balance;

  const [percentage, setPercentage] = useState(0);
  const [totalAvailable, setTotalAvailable] = useState(0);
  const [calculate, setCalculate] = useState(false);

  const [refundAvailable, setRefundAvailable] = useState([]);

  const handlePercentChange = (val) => {
    setCalculate(true);
    setPercentage(val);
    // calculatePercentage();
  };

  const loadCards = async () => {
    setLoading(true);
    try {
      const cards = await getAvailableCards();
      let sum = 0.0;
      for (const card of cards) {
        sum += Number(Number(card.CardAvailableAmount).toFixed(2));
      }
      setRefundAvailable(cards);
      setTotalAvailable(sum);
      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (calculate) {
      const calculatedPercentage = (walletBalance * percentage) / 100; // Calculate % of the value
      setValueAmt(calculatedPercentage.toFixed(2));
      setCalculate(false);
    }
  }, [calculate, walletBalance, percentage]);

  useEffect(() => {
    setLoading(true);
    transfers({ range: selected.data, type: "ALL_WITHDRAWALS" })
      .then((response) => {
        setWithdrawals(response);
        setLoading(false);
      })
      .catch((err) => console.error(err));
    setLoading(false);
  }, [selected]);

  useEffect(() => {
    const values = Object.values(customer.enabledWithdrawals);
    setDisableAllWithdrawals(!values.includes(true));

    if (
      !customer.enabledWithdrawals.card &&
      customer.enabledWithdrawals.wireTransfer
    ) {
      setCard(false);
      setWire(true);
    }
  }, [customer]);

  useEffect(() => {
    loadCards();
  }, []);

  return (
    customer != null && (
      <>
        <div className="dashboard-page">
          <AppBar title="Withdraw"></AppBar>
          <div className="dashboard-container">
            {openModal && (
              <WithdrawCardSelect
                openModal={openModal}
                closeModal={setOpenModal}
                valueAmt={valueAmt}
                refundAvailable={refundAvailable}
                confirmTitle="Confirm Withdraw Details"
              ></WithdrawCardSelect>
            )}

            {openCryptoModal && (
              <CryptoWithdrawalModal
                openModal={openModal}
                closeModal={setOpenCryptoModal}
                valueAmt={valueAmt}
                title="Withdraw via CryptoCurrency"
              ></CryptoWithdrawalModal>
            )}

            {openWireModal && (
              <BankAccDetails
                openModal={openModal}
                closeModal={setOpenWireModal}
                valueAmt={valueAmt}
                method="Withdraw Method"
                account="Withdraw From"
              ></BankAccDetails>
            )}

            <div class="deposit-page-grid">
              {/* ------------DEPOSIT FUNDS SECTION---------------- */}
              <div class="deposit-funds">
                <div className="deposit-page-box-title">
                  <div
                    className="color-box"
                    style={{ background: "#008FFD" }}
                  ></div>
                  <span>Withdraw Funds</span>
                </div>
                <div className="horizontal-line"></div>
                <div className="deposit-method">
                  <p>Withdraw Method</p>
                  <div class="dm-wrapper">
                    {customer.enabledWithdrawals.card && (
                      <>
                        <input
                          type="radio"
                          name="select"
                          id="option-1"
                          checked={card && true}
                          onClick={() => {
                            setCard(true);
                            setCrypto(false);
                            setWire(false);
                          }}
                        />
                      </>
                    )}
                    {customer.enabledWithdrawals.crypto && (
                      <>
                        <input
                          type="radio"
                          name="select"
                          id="option-2"
                          onClick={() => {
                            setCard(false);
                            setCrypto(true);
                            setWire(false);
                          }}
                        />
                      </>
                    )}
                    {customer.enabledWithdrawals.wireTransfer && (
                      <>
                        <input
                          type="radio"
                          name="select"
                          id="option-3"
                          checked={wire && true}
                          onClick={() => {
                            setCard(false);
                            setCrypto(false);
                            setWire(true);
                          }}
                        />
                      </>
                    )}
                    <label
                      for="option-1"
                      className={`option option-1 ${
                        customer?.enabledWithdrawals?.card ? "" : "disabled"
                      } `}
                    >
                      <Tippy
                        content={
                          !customer?.enabledWithdrawals?.card ? (
                            <span>
                              The original amount deposited from your
                              debit/credit has been withdrawn, wire transfer is
                              active
                            </span>
                          ) : (
                            ""
                          )
                        }
                        disabled={customer?.enabledWithdrawals?.card}
                      >
                        <span>Credit/Debit Card</span>
                      </Tippy>
                    </label>
                    <div className="line"></div>
                    <label
                      for="option-2"
                      className={`option option-2 ${
                        customer?.enabledWithdrawals?.crypto ? "" : "disabled"
                      } `}
                    >
                      <span>CryptoCurrency</span>
                    </label>
                    <div className="line"></div>

                    <label
                      for="option-3"
                      className={`option option-3 ${
                        customer?.enabledWithdrawals?.wireTransfer
                          ? ""
                          : "disabled"
                      } `}
                    >
                      <Tippy
                        content={
                          !customer?.enabledWithdrawals?.wireTransfer ? (
                            <span>
                              The original amount deposited from your
                              debit/credit card must be withdrawn before a wire
                              transfer can be done
                            </span>
                          ) : (
                            ""
                          )
                        }
                        disabled={customer?.enabledWithdrawals?.wireTransfer}
                      >
                        <span>Wire Transfer</span>
                      </Tippy>
                    </label>
                  </div>
                </div>
                {/* TOTAL AMT TO BE REFUNDED */}
                {refundAvailable.length > 0 ? (
                  <div className="refund">
                    <p>${totalAvailable} available for refund</p>
                  </div>
                ) : (
                  ""
                )}

                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>
                      {!disableAllWithdrawals
                        ? "Enter Amount"
                        : "A deposit is required"}
                    </p>
                    <span>Available Balance USD ${walletBalance}</span>
                  </div>
                  {((card && customer?.enabledWithdrawals?.card) ||
                    (crypto && customer?.enabledWithdrawals?.crypto) ||
                    (wire && customer?.enabledWithdrawals?.wireTransfer)) && (
                    <>
                      <div className="amount-section">
                        <div className="amount-input">
                          <CurrencyInput
                            onFocus={(e) => (e.target.placeholder = "")}
                            onBlur={(e) => (e.target.placeholder = "$0.00")}
                            id="input-example"
                            name="input-name"
                            placeholder="$0.00"
                            prefix="$"
                            decimalsLimit={2}
                            value={valueAmt}
                            onValueChange={(value) => setValueAmt(value)}
                          />
                          <div className="select-currency-btn currency-type">
                            USD<BsChevronDown></BsChevronDown>
                            <div className="sc-content"></div>
                          </div>
                          {/* <button>USD</button> */}
                        </div>

                        <div className="amt-percentages">
                          <div className="percentages pt-[24px]">
                            <button
                              className="green"
                              onClick={() => handlePercentChange(25)}
                            >
                              25%
                            </button>
                            <button
                              className="green"
                              onClick={() => handlePercentChange(50)}
                            >
                              50%
                            </button>
                            <button
                              className="green"
                              onClick={() => handlePercentChange(75)}
                            >
                              75%
                            </button>
                            <button
                              className="green"
                              onClick={() => handlePercentChange(100)}
                            >
                              100%
                            </button>
                          </div>
                          {wire ? (
                            <p>Processing Time (approx): 3-5 days</p>
                          ) : (
                            <p>Processing Time (approx): Instant</p>
                          )}
                        </div>
                      </div>
                    </>
                  )}
                </div>
                {/* CARD METHOD */}
                {card && (
                  <button
                    className="verification-continue-btn complete card"
                    disabled={disableAllWithdrawals}
                    onClick={() => {
                      setOpenModal(true);
                    }}
                  >
                    Continue
                  </button>
                )}

                {/* CRYPTO METHOD */}
                {crypto && (
                  <button
                    className="verification-continue-btn complete crypto"
                    disabled={disableAllWithdrawals}
                    onClick={() => {
                      setOpenCryptoModal(true);
                    }}
                  >
                    Continue
                  </button>
                )}

                {/* WIRE TRANSFER METHOD */}
                {wire && (
                  <button
                    className="verification-continue-btn complete wire"
                    disabled={disableAllWithdrawals} // Disable if amount is less than $100
                    onClick={() => {
                      if (valueAmt >= 100 && valueAmt <= walletBalance) {
                        setOpenWireModal(true);
                      }
                      if (valueAmt > walletBalance) {
                        toast.error(
                          "Amount is greater than your available wallet balance"
                        );
                      }
                      if (valueAmt >= 100) {
                      } else {
                        toast.error(
                          "Wire Transfer Amount must be greater than or equal to $100."
                        );
                      }
                    }}
                  >
                    Continue
                  </button>
                )}
              </div>

              {/* --------DEPOSIT HISTORY SECTION---------------- */}
              <div className="relative deposit-history">
                {loading ? (
                  <div className="absolute z-50 flex items-center justify-center w-full h-full">
                    <FadeLoader color={color} loading={loading} size={80} />
                  </div>
                ) : null}
                <div className="deposit-page-box-title">
                  <div
                    className="color-box"
                    style={{ background: "#141416" }}
                  ></div>
                  <span>Withdraw History</span>
                  {/*--------------- Dropdown filter ---------- */}
                  <div className="deposit-filter">
                    <div
                      className="df-btn"
                      onClick={(e) => {
                        setIsActive(!isActive);
                      }}
                    >
                      {selected.label}
                      <span>
                        <BsChevronDown></BsChevronDown>
                      </span>
                    </div>
                    {isActive && (
                      <div className="df-content">
                        {options.map((option) => (
                          <div
                            data-option={option.data}
                            onClick={(e) => {
                              setSelected(option);
                              setIsActive(false);
                            }}
                            className="df-item"
                          >
                            {option.label}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                  {/*--------------- Dropdown filter ---------- */}
                </div>

                <WithdrawHistoryTable
                  data={withdrawals}
                  hover={true}
                  striped={true}
                ></WithdrawHistoryTable>
              </div>

              {/* --------FAQ SECTION---------------- */}
              <div class="faq-section">
                <div className="deposit-page-box-title">
                  <div
                    className="color-box"
                    style={{ background: "#ffd166" }}
                  ></div>
                  <span>Withdrawal Notice</span>
                </div>
                <div className="horizontal-line"></div>
                <div className="deposit-faq-text">
                  <span>Processing Time</span>
                  <p>
                    <div>&bull;</div>
                    Withdrawal requests must be submitted before 12:00PM EST in
                    order to be processed the same day. Requests made after this
                    time will be processed the next business day.
                  </p>
                  <p>
                    <div>&bull;</div>
                    Card withdrawals usually appear in your account within 1-5
                    business days. If you encounter any delay, kindly contact
                    your bank, notify them about the card refund from Apex
                    E-Commerce Services, and they'll promptly assist you in
                    locating and crediting the transaction.
                  </p>
                  <p>
                    <div>&bull;</div>
                    Cryptocurrency withdrawals can take anywhere from 10 minutes
                    to a few hours to reflect in your Cryptocurrency Wallet.
                  </p>
                  <p>
                    <div>&bull;</div>
                    Bank/Wire Transfer withdrawals may take 1-7 business days to
                    reflect in your Bank Account.
                  </p>
                </div>

                <div className="deposit-faq-text">
                  <span>Terms</span>
                  <p>
                    <div>&bull;</div>
                    All withdrawals will be processed to the chosen withdrawal
                    method.
                  </p>
                  <p>
                    <div>&bull;</div>
                    For security reasons, the initial accumulated amount of
                    payment made with your Credit/Debit Card will be withdrawn
                    back to your card. All additional withdrawals will be
                    processed to any other withdrawal method of your choice.
                  </p>
                  <p>
                    <div>&bull;</div>
                    Apex Markets is not liable for any errors made by the
                    account holder when submitting information for withdrawal
                    request
                  </p>
                  <p>
                    <div>&bull;</div>
                    Apex Markets does not allow third-party transfers; the name
                    on the Card/Bank account must match the name on your Apex
                    Markets account.
                  </p>
                  <p>
                    <div>&bull;</div>
                    You must add your bank details if you are making a request
                    to your bank account for the first time.
                  </p>
                  <p>
                    <div>&bull;</div>
                    All withdrawal requests require additional confirmation; you
                    can do this by clicking the 'Confirm Withdrawal' button in
                    the email you will receive after submitting your request.
                  </p>
                </div>
                <div className="deposit-faq-text">
                  <span>Transaction Fees</span>

                  <p>
                    <div>&bull;</div>
                    Apex Markets does not charge any fee for withdrawals. You
                    should however be aware that fees may incur on withdrawals
                    to some international banking institutions, credit/debit
                    card and cryptocurrency payment processors. Apex Markets
                    accepts no responsibility for any such transaction fees.
                  </p>
                </div>
                <div className="deposit-faq-text">
                  <span>Recommended Minimum</span>

                  <p>
                    <div>&bull;</div>
                    The recommended minimum amount for withdrawals to Bank
                    accounts or Cryptocurrency is $100 USD.
                  </p>
                </div>
                <div className="deposit-faq-text">
                  <div>
                    We hope this answers your questions regarding our withdrawal
                    policy. Please do not hesitate to contact us at{" "}
                    <span className="blue">
                      <a href="mailto:support@apexmarkets.io">
                        support@apexmarkets.io
                      </a>
                    </span>{" "}
                    if you have any further inquiries.
                  </div>
                  <p>Thank you for choosing Apex Markets!</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
};

export default Withdraw;
