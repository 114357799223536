import React, { useState } from "react";
import { Outlet } from "react-router-dom";
import ConvertRewardsPoints from "../components/ConvertRewardsPoints";

import RewardsHistoryTable from "../components/RewardsHistoryTable";
import { rewardsTableData } from "../components/RewardsHistoryData";
// import { Link } from "react-router-dom";
import ProgressBar from "../components/ProgressBar";

// images / icons
import { BsChevronDown } from "react-icons/bs";
import greenIcon from "../images/icons/howitworks.svg";

// import close from "../images/icons/red-close.svg";

import "./Rewards.css";
import { getConversionData } from "../api/reward";
import { useEffect } from "react";
import { getAccounts } from "../api/trading";
import ClipLoader from "react-spinners/ClipLoader";
import FadeLoader from "react-spinners/FadeLoader";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";

const Rewards = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  // DEPOSIT FILTER
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All");
  const options = ["All", "Last 3 Months", "Last Week"];

  const [openModal, setOpenModal] = useState(false);

  const [rewardConversionData, setRewardConversionData] = useState();
  const points = 30;

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const [accountToOptions, setAccountToOptions] = useState([]);
  const [accountList, setAccountList] = useState([]);
  const [walletData, setWalletData] = useState({});
  const customer = useSelector(selectCurrentCustomer);

  // const loadLiveAccounts = async () => {
  //   setLoading(true);
  //   const accounts = await getAccounts();
  //   if (accounts != null) {
  //     const list = accounts.map(
  //       (account) => account.trading_account_id_numeric
  //     );
  //     setAccountList(["WALLET", ...list]);
  //   }
  // };
  const loadLiveAccounts = async () => {
    const accounts = await getAccounts();
    const walletBalance = customer?.wallet?.Balance || 0;
    const transferFromList = [];
    const transferList = [];

    if (accounts != null) {
      setWalletData({
        value: "Wallet",
        text: "Wallet (" + walletBalance + ")",
      });
      transferFromList.push({
        value: "Wallet",
        text: "Wallet (" + walletBalance + ")",
      });

      accounts.forEach((account) => {
        transferFromList.push({
          value: account.trading_account_id_numeric,
          text:
            account.trading_account_id_numeric + " (" + account.balance + ") ",
        });
        transferList.push({
          value: account.trading_account_id_numeric,
          text:
            account.trading_account_id_numeric + " (" + account.balance + ") ",
        });
      });
      setAccountList(transferList);
      setAccountToOptions(transferFromList);
    }
    setLoading(false);
  };

  const getRewardData = async () => {
    const results = await getConversionData();
    setRewardConversionData(results);
  };

  const loadData = async () => {
    try {
      setLoading(true);
      await getRewardData();
      await loadLiveAccounts();
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    loadData();
  }, []);

  return (
    <>
      <AppBar title="Accounts"></AppBar>
      <AccountsSelector></AccountsSelector>
      <Outlet></Outlet>
      {loading ? (
        <div className=" bg-white absolute flex justify-center  h-[100vh] w-full z-50">
          <FadeLoader color={color} loading={loading} size={80} />
        </div>
      ) : null}
      {rewardConversionData != null && accountList.length > 0 && (
        <div className="dashboard-container">
          {openModal && rewardConversionData && (
            <ConvertRewardsPoints
              openModal={openModal}
              closeModal={setOpenModal}
              accountToOptions={accountList}
              // for confirm deposit component
              confirmTitle="Confirm Deposit Details"
              // for confirm deposit
              points={points}
              data={rewardConversionData}
            ></ConvertRewardsPoints>
          )}

          <div className="rewards-page-grid">
            {/* REWARDS */}
            <div class="rewards">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#008FFD" }}
                  ></div>
                  <span>Rewards</span>
                </div>
                <div className="points-available">
                  <span>00 Points Available</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="progress-bar">
                <div className="bar-title">
                  <p>Trade Points</p>
                  <span>{rewardConversionData.points} points</span>
                </div>
                <ProgressBar done={rewardConversionData.points}></ProgressBar>
                <span className="bar-info">
                  Everyday the point will be refreshed at 12:00 PT
                </span>
              </div>
              <div className="progress-bar">
                <div className="bar-title">
                  <p>Day Counter</p>
                  <span>{rewardConversionData.consecutiveDays}/30</span>
                </div>
                <ProgressBar
                  done={rewardConversionData.consecutiveDays}
                ></ProgressBar>
                <span className="bar-info">
                  If you miss 1day, this counter will be automaticly reset
                </span>
              </div>
              <button
                className="verification-continue-btn complete"
                disabled={rewardConversionData.points <= 0}
                onClick={() => {
                  setOpenModal(true);
                }}
              >
                Convert Points
              </button>
            </div>
            {/* REWARDS */}

            {/* REWARDS HISTORY */}

            {/* REWARDS HISTORY */}

            {/* HOW IT WORKS */}
            <div class="how-it-works">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#58BD7D" }}
                  ></div>
                  <span>How It Works</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="hiw-info">
                <h2>How easy is it to earn Apex Reward Points?</h2>
                <p>
                  Each day you trade you earn one 1 point. You can accumulate
                  more points over time and exchange them for Credit Bonus or
                  Wallet Balance.
                </p>
              </div>
              <div className="hiw-info">
                <h2>What's the catch?</h2>
                <p>
                  There is no catch! Earning Apex Reward Points is easy and
                  free. Just make sure that you open your trade for more than 10
                  minutes and have a minimum daily trading volume of 1 lot.
                </p>
              </div>
              <div className="hiw-info">
                <h2>Benefits of Apex Reward Points?</h2>
                <p>
                  You can use your points to get Credit Bonus or Wallet Balance.
                  This can be used to withdraw cash or apply the full amount to
                  your trading account.
                </p>
              </div>

              <div className="hiw-info">
                <h2>
                  So what are you waiting for? <br />
                  Start earning Apex Reward Points TODAY!
                </h2>
              </div>
            </div>
            {/* HOW IT WORKS */}

            {/* APEX REWARDS */}
            <div class="apex-rewards">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#FF6838" }}
                  ></div>
                  <span>Apexmarkets Rewards</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="apex-rewards-info">
                <div className="rewards-info-box">
                  <div className="rewards-info-top">
                    <p>SILVER</p>
                    <p>$100 USD</p>
                  </div>
                  <div className="rewards-info-body">
                    <p>
                      Qualify when you’ve accumulated <br /> 30 Trading Days
                    </p>
                  </div>
                </div>
                <div className="rewards-info-box">
                  <div className="rewards-info-top">
                    <p>GOLD</p>
                    <p>$300 USD</p>
                  </div>
                  <div className="rewards-info-body">
                    <p>
                      Qualify when you’ve accumulated <br /> 90 Trading Days
                    </p>
                  </div>
                </div>
                <div className="rewards-info-box">
                  <div className="rewards-info-top">
                    <p>PLATINUM</p>
                    <p>$500 USD</p>
                  </div>
                  <div className="rewards-info-body">
                    <p>
                      Qualify when you’ve accumulated <br /> 180 Trading Days
                    </p>
                  </div>
                </div>
                <div className="apex-rewards-terms">
                  <p>*Terms & Conditions Applied</p>
                </div>
                {/* <ul>
              <li>30 Points(Tier 1)</li>
              <li>- </li>
              <li>$1 USD</li>
            </ul>
            <ul>
              <li>50 Points(Tier 2)</li>
              <li>- </li>
              <li>$1 USD</li>
            </ul>
            <ul>
              <li>100 Points(Tier 3)</li>
              <li>- </li>
              <li>$100 USD</li>
            </ul>
            <ul>
              <li>200 Points(Silver)</li>
              <li>- </li>
              <li>$250 USD</li>
            </ul>
            <ul>
              <li>500 Points(Gold)</li>
              <li>- </li>
              <li>$650 USD</li>
            </ul>
            <ul>
              <li>800 Points(Platinum)</li>
              <li>-</li>
              <li>$1000 USD</li>
            </ul> */}
              </div>
            </div>
            {/* APEX REWARDS */}

            {/* <div class="faq-section">
          <div className="deposit-page-box-title">
            <div className="color-box" style={{ background: "#ffd166" }}></div>
            <span>FAQ</span>
          </div>
          <div className="horizontal-line"></div>
          <div className="deposit-faq-text">
            <span>Security of Funds</span>
            <p>
              Funds are held for clients in Segregated Client Trust Accounts.
              When funding your Apex E-Commerce Services LLC account online all
              payments are processed using SSL (Secure Socket Layer) technology
              which are encrypted to ensure security. All payment information is
              confidential and is only used for the purpose of funding your
              account with Apex E-Commerce Services LLC.
            </p>
          </div>
          <div className="deposit-faq-text">
            <span>Transaction Fees</span>

            <p>
              Apex E-Commerce Services LLC does not charge any additional fees
              for deposits or withdrawals. Clients, however, should be aware
              that fees may incur on payments to and from some international
              banking institutions and credit/debit and cryptocurrency payment
              processors. Apex E-Commerce Services LLC accepts no responsibility
              for any such transaction fees.
            </p>

            <p>
              All payments made will be converted to USD and placed in their
              Apex E-Commerce Services LLC wallet. Clients can conduct
              transactions to and from the wallet such as deposit to and
              withdrawals. They can also conduct internal transfers to and from
              their Apex E-Commerce Services LLC wallet to their Apex E-Commerce
              Services LLC accounts under their profile.
            </p>
          </div>
        </div> */}
          </div>
        </div>
      )}
    </>
  );
};

export default Rewards;
