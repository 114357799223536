import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import trophy from "../images/icons/trophy.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const ChallengeModal = ({ closeModal }) => {
  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  });

  const handleClickOutside = (e) => {
    if (!refOne.current.contains(e.target)) {
      closeModal(false);
    }
  };

  const refOne = useRef(null);

  //   const navigate = useNavigate();
  return (
    <ModalContainer>
      <div className="join-challenge-modal">
        <div className="challenge-modal" ref={refOne}>
          <div className="challenge-container">
            <img src={trophy} alt="" />
            <h2>Summer Challenge : Fully Paid Trip for 2 to Panama</h2>
            <p>Terms and Conditons</p>
            <p>
              <span>1.</span>To enter a trader would need to deposit a minimum
              of $100 USD into there challenge account.
            </p>
            <p>
              <span>2.</span>The trader with the highest percentage growth wins
              a free fully paid trip for 2 to panama for the weekend.
            </p>
            <div>Join to The Challenge</div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChallengeModal;
