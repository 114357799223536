import React, { useEffect, useState } from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";
import { withdrawWireTransfer, getFee } from "../api/wireTransfer";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const BankAccDetails = ({ closeModal, valueAmt }) => {
  const [amount, setAmount] = useState(valueAmt);
  const [processing, setProcessing] = useState(false);

  const [inputValues, setInputValues] = useState({
    name: "",
    beneficiaryBank: "",
    beneficiaryBankAddress: "",
    beneficiaryBankSwift: "",
    beneficiaryBankBranch: "",
    beneficiaryBankAccountNumber: "",
    beneficiaryAddress: "",
    intermediaryBank: "",
    intermediaryBankAddress: "",
    intermediaryBankRouting: "",
    intermediaryBankRouting: "",
  });

  const {
    name,
    beneficiaryBank,
    beneficiaryBankAddress,
    beneficiaryBankSwift,
    beneficiaryBankBranch,
    beneficiaryBankAccountNumber,
    beneficiaryAddress,
    intermediaryBank,
    intermediaryBankAddress,
    intermediaryBankSwift,
    intermediaryBankRouting,
  } = inputValues;

  const [feeAmt, setFeeAmt] = useState();
  const roundedAmount = Number((amount - feeAmt).toFixed(2));
  const customer = useSelector(selectCurrentCustomer);
  const walletBalance = customer.wallet.Balance;

  const accOptions = ["Savings", "Chequing"];

  // intermediary
  const [openInterAccOption, setOpenInterAccOption] = useState(false);

  const [selectedInterAccOpt, setSelectedInterAccOpt] = useState(
    "Select intermediary bank account type"
  );
  // intermediary choice
  const [intermediary, setintermediary] = useState(false);
  const [selectintermediary, setSelectIntermediary] = useState(true);

  const interaccOptions = ["Savings", "Chequing"];

  const [bankindex, setBankIndex] = useState(1);
  const bankcontinueBtn = () => {
    if (bankindex < 4) {
      setBankIndex((prevIndex) => prevIndex + 1);
    }
  };
  const reverseBtn = () => {
    if (bankindex <= 4) {
      setBankIndex((prevIndex) => prevIndex - 1);
    }
  };
  const submit = async () => {
    setProcessing(true);
    const data = {
      amount,
      name,
      beneficiaryBank,
      beneficiaryBankAddress,
      beneficiaryBankSwift,
      beneficiaryBankBranch,
      beneficiaryBankAccountNumber,
      beneficiaryAddress,
      intermediaryBank,
      intermediaryBankAddress,
      intermediaryBankSwift,
      intermediaryBankRouting,
    };
    const response = await withdrawWireTransfer(data);
    if (response.errors == null) {
      closeModal(false);
      toast("Successfully submitted withdrawal request", {
        type: "success",
      });
      setTimeout(() => {
        window.location.href = "/";
      }, 2000);
    } else {
      setProcessing(false);
    }
  };

  const getFeeAmt = async () => {
    const response = await getFee();
    if (response.errors == null) {
      setFeeAmt(response.fee);
    }
  };

  useEffect(() => {
    getFeeAmt();
  }, []);
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Confirm Withdraw Details</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="bank-info">
              <p>
                To receive a Bank/Wire Transfer payment from Apex Markets,
                please enter the amount and provide your bank account details
                below.
              </p>
              <p>
                The receiver name and your banking details MUST correspond with
                your Apex Markets account profile.
              </p>
              <p>
                Bank/Wire Transfers, after being processed by our team, can take
                1-7 working days to be added to your Bank account.
              </p>
              <p>Thank you for your cooperation!</p>
            </div>

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Amount</p>
              </div>
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={valueAmt}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt) => setAmount(valueAmt)}
                />
                <div className="select-currency-btn currency-type">
                  USD<BsChevronDown></BsChevronDown>
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>

              {amount >= 100 && amount <= walletBalance && (
                <p className="mt-1 text-sm leading-4 text-[#58bd7d]">
                  The balance you will be receiving after fees ($40) are
                  deducted is ${roundedAmount}
                </p>
              )}
              {amount > walletBalance && (
                <p className="mt-1 text-sm leading-4 text-red-500">
                  Amount is greater than your available wallet balance.
                </p>
              )}
              {amount < 100 && (
                <p className="mt-1 text-sm leading-4 text-red-500">
                  Wire Transfer Amount must be greater than or equal to $100.
                </p>
              )}
            </div>

            {/* ------------------------------------ */}
            {bankindex === 2 && (
              <>
                <div className="bank-info-top">
                  <span className="text-xl font-bold">
                    Receiver Banking Details
                  </span>
                </div>
                <div className="horizontal-line"></div>
                <div className="form-label-warning">
                  <label htmlFor="CardNumber">Bank Name</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank name"
                    value={beneficiaryBank}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryBank: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-label-warning">
                  <label htmlFor="CardNumber">Bank Address</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank address"
                    value={beneficiaryBankAddress}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryBankAddress: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-label-warning">
                  <label htmlFor="CardNumber">Bank BIC/SWIFT Code</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank BIC/SWIFT code"
                    value={beneficiaryBankSwift}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryBankSwift: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-label-warning">
                  <label htmlFor="CardNumber">
                    Bank Branch/Routing/Sort Code:
                  </label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank branch/routing/sort code:"
                    value={beneficiaryBankBranch}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryBankBranch: e.target.value,
                      }))
                    }
                  />
                </div>
              </>
            )}

            {/* ------------------------------- */}
            {bankindex === 3 && (
              <>
                <div className="bank-info-top">
                  <span className="text-xl font-bold">
                    Account Holder Details
                  </span>
                </div>
                <div className="horizontal-line"></div>
                <div className="form-label-warning">
                  <label htmlFor="CardHolde Name">
                    Bank Account Holder Name
                  </label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    id="CardHolderName"
                    name="CardHolderName"
                    placeholder="Enter name on bank account"
                    value={name}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        name: e.target.value,
                      }))
                    }
                  />
                </div>

                <div className="form-label-warning">
                  <label htmlFor="CardNumber">
                    Bank Account Holder Address
                  </label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank account holder address"
                    value={beneficiaryAddress}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryAddress: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="form-label-warning">
                  <label htmlFor="CardNumber">Bank Account Number</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter bank account number"
                    value={beneficiaryBankAccountNumber}
                    onChange={(e) =>
                      setInputValues((prevValue) => ({
                        ...prevValue,
                        beneficiaryBankAccountNumber: e.target.value,
                      }))
                    }
                  />
                </div>
              </>
            )}
            {bankindex === 4 && (
              <>
                {selectintermediary ? (
                  <div>
                    <p className="text-xl">
                      Do you have Intermediary Banking Information?
                    </p>{" "}
                    <div className="flex gap-4 mt-3">
                      <div
                        className="px-4 border-2 border-black rounded-full cursor-pointer p4-2"
                        onClick={() => {
                          setSelectIntermediary(false);
                          setintermediary(false);
                          setInputValues((prevValue) => ({
                            ...prevValue,
                            intermediaryBank: "",
                            intermediaryBankAddress: "",
                            intermediaryBankSwift: "",
                            intermediaryBankRouting: "",
                          }));
                        }}
                      >
                        No
                      </div>

                      <div
                        className="px-4 border-2 border-black rounded-full cursor-pointer p4-2"
                        onClick={() => setintermediary(true)}
                      >
                        Yes
                      </div>
                    </div>
                  </div>
                ) : null}

                {intermediary ? (
                  <>
                    <div className="bank-info-top">
                      <span className="text-xl font-bold">
                        Intermediary Information (Optional)
                      </span>
                    </div>
                    <div className="horizontal-line"></div>
                    <div className="form-label-warning">
                      <label htmlFor="CardNumber">Intermediary Bank Name</label>
                    </div>
                    <div className="hide-pw">
                      <input
                        type="text"
                        placeholder="Enter intermediary bank name"
                        value={intermediaryBank}
                        onChange={(e) =>
                          setInputValues((prevValue) => ({
                            ...prevValue,
                            intermediaryBank: e.target.value,
                          }))
                        }
                      />
                    </div>

                    <div className="form-label-warning">
                      <label htmlFor="CardNumber">
                        Intermediary Bank Address
                      </label>
                    </div>
                    <div className="hide-pw">
                      <input
                        type="text"
                        placeholder="Enter intermediary bank address"
                        value={intermediaryBankAddress}
                        onChange={(e) =>
                          setInputValues((prevValue) => ({
                            ...prevValue,
                            intermediaryBankAddress: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="form-label-warning">
                      <label htmlFor="CardNumber">
                        Intermediary Swift Code
                      </label>
                    </div>
                    <div className="hide-pw">
                      <input
                        type="text"
                        placeholder="Enter intermediary swift code"
                        value={intermediaryBankSwift}
                        onChange={(e) =>
                          setInputValues((prevValue) => ({
                            ...prevValue,
                            intermediaryBankSwift: e.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="form-label-warning">
                      <label htmlFor="CardNumber">
                        Intermediary Routing/Sort Code
                      </label>
                    </div>
                    <div className="hide-pw">
                      <input
                        type="text"
                        placeholder="Enter intermediary routing/sort code"
                        value={intermediaryBankRouting}
                        onChange={(e) =>
                          setInputValues((prevValue) => ({
                            ...prevValue,
                            intermediaryBankRouting: e.target.value,
                          }))
                        }
                      />
                    </div>
                  </>
                ) : null}
              </>
            )}

            {/* ------------------------------------ */}

            {/* ------------------------------- */}
            <div className="flex gap-2">
              {bankindex <= 4 && bankindex > 1 && (
                <button
                  className="verification-continue-btn complete"
                  onClick={() => {
                    reverseBtn();
                    setSelectIntermediary(true);
                    setintermediary(false);
                  }}
                >
                  Previous
                </button>
              )}

              {!selectintermediary || intermediary ? (
                <button
                  className="verification-continue-btn complete"
                  disabled={processing || amount > walletBalance}
                  onClick={() => submit()}
                >
                  Confirm
                </button>
              ) : (
                <button
                  className="verification-continue-btn complete"
                  onClick={bankcontinueBtn}
                >
                  Next
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default BankAccDetails;
