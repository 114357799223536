import React from "react";
import { NavLink } from "react-router-dom";

const AccountSettingsLinks = () => {
  return (
    <div className="account-settings-links">
      <ul>
        <li>
          <NavLink to="/personal-information"> Personal Information</NavLink>
        </li>
        <li>
          <NavLink to="/security"> Security</NavLink>
        </li>
        <li>
          <NavLink to="/notification">Notifications</NavLink>
        </li>
        <li>
          <NavLink to="/settings-kyc-verification"> KYC Verification</NavLink>
        </li>
      </ul>
    </div>
  );
};

export default AccountSettingsLinks;
