import actions from "../../images/icons/actions.svg";

const img = <img src={actions} alt="" />;

export const data = [
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    datejoined: "23/05/22",
    status: "Approved",
    depositedAmount: "$100.44",
    affiliateby: "Rebeka Ratry ",
    affiliateCommision: "$20.11",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    datejoined: "23/05/22",
    status: "Approved",
    depositedAmount: "$100.44",
    affiliateby: "Rebeka Ratry ",
    affiliateCommision: "$20.11",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    datejoined: "23/05/22",
    status: "Pending",
    depositedAmount: "$100.44",
    affiliateby: "Rebeka Ratry ",
    affiliateCommision: "$20.11",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    datejoined: "23/05/22",
    status: "Rejected",
    depositedAmount: "$100.44",
    affiliateby: "Rebeka Ratry ",
    affiliateCommision: "$20.11",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    datejoined: "23/05/22",
    status: "Approved",
    depositedAmount: "$100.44",
    affiliateby: "Rebeka Ratry ",
    affiliateCommision: "$20.11",
    action: img,
  },
];

export const colNames = [
  "No.",
  "Client ID",
  "Name",
  "Date Joined",
  "KYC Status",
  "Deposited Amount",
  "Affiliate by",
  "Affiliate Comission",
  "Action",
];
