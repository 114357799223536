import React from "react";
import search from "../../images/icons/search.svg";
import { DebounceInput } from "react-debounce-input";
const TableSearch = ({ classprop, setFilter, filter, placeholder = "Search here" }) => {
  return (
    <div className={`table-search ${classprop}`}>
      <DebounceInput
        type="text"
        debounceTimeout={300}
        placeholder={placeholder}
        onChange={(e) => setFilter(e.target.value)}
        value={filter}
      />
      <div className="ts-icon">
        <img src={search} alt="" />
      </div>
    </div>
  );
};

export default TableSearch;
