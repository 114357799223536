import React from "react";
import AppBar from "../components/AppBar";
import LegalDocumentsSelector from "../components/LegalDocumentsSelector";

import "./LegalDocuments.css";

// TERMS AND CONDITIONS PAGE
const LegalDocuments = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Legal Documents"></AppBar>
        <LegalDocumentsSelector></LegalDocumentsSelector>
        {/* <div className="faq-container legal-container">
          <h1>General Risk Warning</h1>

          <h4>A. How to interpret this Risk Warning</h4>
          <p>
            All terms used in this notice, which are defined in the Binance
            Terms of Use (the “Terms of Use”), have the same meaning and
            construction as in the Terms of Use.
          </p>

          <h4>B. Binance Services</h4>
          <p>
            This notice provides you with information about the risks associated
            with Binance Services. Each Binance Service has its own distinct
            risks. This notice provides a general description of the risks when
            you use Binance Services. This notice does not explain all of the
            risks or how such risks relate to your personal circumstances. It is
            important that you fully understand the risks involved before making
            a decision to use Binance Services.
          </p>

          <h4>C. No Personal Advice</h4>
          <p>
            We do not provide personal advice in relation to our products or
            services. We sometimes provide factual information, information
            about transaction procedures and information about the potential
            risks. However, any decision to use our products or services is made
            by you. No communication or information provided to you by Binance
            is intended as, or shall be considered or construed as, investment
            advice, financial advice, trading advice, or any other sort of
            advice. You are solely responsible for determining whether any
            investment, investment strategy or related transaction is
            appropriate for you according to your personal investment
            objectives, financial circumstances and risk tolerance.
          </p>

          <h4>D. No Monitoring</h4>
          <p>
            Binance is not your broker, intermediary, agent, or advisor and has
            no fiduciary relationship or obligation to you in connection with
            any trades or other decisions or activities undertaken by you using
            Binance Services. We do not monitor whether your use of Binance
            Services is consistent with your financial goals and objectives. It
            is up to you to assess whether your financial resources are adequate
            for your financial activity with us, and to your risk appetite in
            the products and services you use.
          </p>
          <h4>E. No Tax, Regulatory or Legal Advice</h4>
          <p>
            The taxation of Digital Assets is uncertain, and you are responsible
            for determining what taxes you might be liable to, and how they
            apply, when transacting through the Binance Services. It is your
            responsibility to report and pay any taxes that may arise from
            transacting on the Binance Services, and you acknowledge that
            Binance does not provide legal or tax advice relative to these
            transactions. If you have any doubts about your tax status or
            obligations when using Binance Services, or with respect to the
            Digital Assets held to the credit of your Binance account, you may
            wish to seek independent advice.
          </p>
        </div> */}
        <div className="faq-container ">
          <div className="terms-conditions">
            <div>
              <h4>Privacy Policy:</h4>

              <p>
                This Policy is in line with Article 24 GDPR (EU) 2016/679,
                taking into account the nature, scope, context and purposes of
                processing as well as the risks to the rights and freedoms of
                natural persons, Apex Markets has implemented appropriate
                technical and organisational measures to ensure pursuance to the
                General Data Protection Regulation (GDPR). This policy stands as
                the cornerstone to Apex Markets compliance with GDPR and is
                reviewed and updated accordingly.
              </p>

              <p>
                This Policy provides data subjects with information on how Apex
                Markets collects personal data, what is done with it, and to
                whom it is shared. This privacy notice has been drafted in
                compliance with the requirements of the General Protection
                Regulation, Regulation (EU) 2016/679, (the “GDPR”) and on the
                basis of the information Commissioner’s Code of Practice on
                “Privacy notices, transparency and control” and the Article 29
                European Commission Guidelines on transparency under the GDPR.
              </p>
              <p>
                It is strongly recommended that you read this privacy notice
                carefully as your privacy on the internet especially when
                manoeuvring our platform is important to us.
              </p>
            </div>
            <div>
              <h4>Information collected by us:</h4>

              <p>
                In the course of your registration as a client, signing up for
                an account with Apex Markets or completing any form on our
                website, subscribing to our services, news or offers, marketing
                communications or posting material, the following information
                about you (“Your Data”) will be collected and stored for
                administrative, service related and/or legal purposes.
              </p>
              <p>
                The collection of personal information is limited as to what is
                necessary to administer our business and carry out our regulated
                activities in an effort to provide you with quality service.
              </p>
            </div>
            <div>
              <h4>Information that you provide to us directly:</h4>

              <p>
                Personal information, such as names, addresses, personal
                registration number, national identification number, passport
                number and email addresses etc. (“Personal Information”), and
                some info is optional and conditional depending on the situation
                such as financial Information, trading experience and employment
                information for appropriateness assessment will also be
                collected.
              </p>

              <p>
                However, the meaning of data “provided to” Apex Markets is not
                limited to this. Additionally, as standard, client's Email
                addresses may be used by Apex Markets in relation to its
                products and services (including any marketing campaigns related
                to these products or services). If you do not wish to receive
                marketing material and communications, you can withdraw at any
                time by clicking on “unsubscribe” or by sending an email stating
                so to{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>
              </p>
            </div>
            <div>
              <h4>The type of data collected and purpose of collection:</h4>

              <p>
                The purpose of collecting stated information is to be in
                compliance with the anti money laundering laws (AML) and other
                regulatory obligations in relation to Know Your Client (KYC) and
                client due diligence. To verify your identity using our
                verification processes.
              </p>
              <p>
                The type of personal information related to the above stated
                purpose includes: Personal information such as gender, name,
                date of birth and address, Ethnicity, citizenship and
                identification number/social security number.
              </p>
            </div>
            <div>
              <h4>Responding to legal requests or preventing harm:</h4>
              <p>
                The collection of data and personal information to access our
                platform such as signing the authorization forms protects the
                company and clients and allows us to respond to legal requests
                and to prevent harm more effectively. Information collected is
                accessed, preserved and shared with regulators, law enforcement
                or others by request. The company responds to legal requests
                when we have a good-faith belief that the response is required
                by law in that jurisdiction, affects users in that jurisdiction,
                and is consistent with internationally recognised standards.
              </p>
              <p>
                A belief from good-faith is necessary to detect, prevent and
                address fraud, unauthorised use of the services or products,
                violations of our terms or policies, or other harmful or illegal
                activity; to protect ourselves (including our rights, property
                or Products), you or others, including as part of investigations
                or regulatory inquiries; or to prevent death or imminent bodily
                harm. For example, if relevant, we provide information to and
                receive information from third-parties about the reliability of
                your account to prevent fraud, abuse and other harmful activity
                on and off our services and or products.
              </p>
              <p>
                Information we receive about you (including financial
                transactions as data related to deposits and withdrawals) can be
                accessed and preserved for an extended period when it is the
                subject of a legal request or obligation, governmental
                investigation, or investigations of possible violations of our
                terms or policies, or otherwise to prevent harm. Information
                from accounts disabled for term violations for at least a year
                to prevent repeat abuse or other term violations may also be
                retained.
              </p>
            </div>
            <div>
              <h4>Keeping your personal information secure </h4>
              <p>
                Clients' Data is stored and kept confidential according to the
                legislation on protection of personal data and processing
                thereof applicable in the jurisdiction in which Apex Markets
                with which you have signed up is located.
              </p>
              <p>
                There are appropriate security measures in place to prevent
                personal information from being accidentally lost, misused,
                modification, disclosure or accessed in an unauthorised way.
                Access is limited to your personal information to those who have
                a genuine business need to know it. Those processing your
                information will do so only in an authorised manner and are
                subject to a duty of confidentiality.{" "}
              </p>
              <p>
                There are also procedures in place to deal with any suspected
                data security breach. Which will be notified to you and any
                applicable regulator of a suspected data security breach where
                we are legally required to do so.
              </p>
            </div>
            <div>
              <h4>Legal basis for processing personal data</h4>
              <p>
                The authorised use and collecting of client's personal
                information is empowered to the company on a lawful basis for
                processing under EU data protection law, there must be a lawful
                basis for all processing of personal data (unless an exemption
                or derogation applies). We rely on:
              </p>
              <div className="terms-bullets">
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    Contractual necessity Processing is necessary for the
                    performance of a contract with the data subject or to take
                    steps to enter into a contract to conduct regulated
                    activities, when processing is necessary for the entry into,
                    or performance of contract with the data subject or in order
                    to take steps at this or her request prior to the entry into
                    a contract.
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    Compliance with legal obligations Processing is necessary
                    for compliance with our legal obligation. Apex Markets has
                    the necessity for compliance with a legal obligation.
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    Legitimate interest Data will only be processed where it is
                    necessary for the purposes of the legitimate interests
                    pursued by Apex Markets, and these interests or fundamental
                    rights are not overridden by the interests, rights and
                    freedoms of the data subject and that the processing would
                    not cause unwarranted harm. For instance, it is a legitimate
                    interest of Apex Markets to process personal data on data
                    subjects in order to expand the business, develop new
                    business relations, prevention of fraud, maintaining the
                    security of our systems if/when necessary, enhancing,
                    modifying or improving our services. The data subject must
                    be given information on the specific legitimate interest if
                    a processing is based on this provision.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h4>Your rights as a client/user:</h4>
              <p>
                The General Data Protection Regulation (GDPR) provides a number
                of important rights if you are resident within the European
                Union. Some of which includes; the fair processing of
                information and transparency over how it is used. There is the
                right to request that your personal data is corrected if it is
                found to be inaccurate and require us to correct any mistakes in
                your information which we hold.
              </p>
            </div>
            <div>
              <h4>Contact us:</h4>
              <p>
                If you wish to contact us with any queries, concerns or
                complaints, you can email us at{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>{" "}
              </p>
            </div>
            <div>
              <h4 id="refund">Refund Policy</h4>
              <p>
                Apex Capital Markets LLC holds a strict NO REFUND POLICY; It
                should be taken into account that services offered by Apex
                Capital Markets LLC are intangible and have no means of being
                returned.
              </p>
              <p>
                Thereby, after authorised payments are made and clients are
                granted complete access to the platform, their right to a refund
                has been automatically rescinded.{" "}
              </p>
              <p>
                A confirmation email will be sent to you after receiving a
                cleared authorised payment for any one of our services/programs.{" "}
              </p>
              <p>
                The contents of this email will include the login details to
                grant access to the platform serving as the grant of a licence
                to access our online software services and products.
              </p>
              <p>
                Therefore, after payment is cleared and once this information is
                dispensed to you thereby resulting in access being granted to
                you, the ability to get a refund has been revoked.
              </p>
              <p>
                However, If you believe that you have made a payment in error,
                please contact Apex Capital Markets LLC as soon as it is
                reasonably possible at{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>{" "}
                to have the issue rectified. If you request a refund without
                contacting us it will be an invalid request and doing that will
                risk your account being banned.
              </p>
            </div>
            <div>
              <h4>Cancellation policy:</h4>
              <p>
                You may cancel your Service by contacting Apex Markets support
                via email at{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>
                .
              </p>
              <p>
                As stated previously; If the client makes a request for
                cancellation after payment, the client is not entitled to a
                refund.
              </p>
              <p>
                Additionally, If you overpaid, underpaid or made any payment
                error, you may contact us and we will assist you in resolving
                the issue.
              </p>
              <p>
                It should be duly noted that all accounts associated with a
                chargeback will be automatically forfeited.
              </p>
            </div>
            <div>
              <h4>Acceptance of this Policy</h4>
              <p>
                The term of service for Apex Capital Markets LLC should be read
                and understood by all users and clients.
              </p>
              <p>
                Therefore, It is your responsibility to familiarise yourself
                with the Refund and Cancellation Policy.{" "}
              </p>
              <p>
                The act of making a payment for any of the services offered by
                Apex Markets, signing the required documents upon payment and
                accepting the terms on conditions virtually on the platform will
                be treated as the client’s acceptance of this policy and the
                company’s Terms and Conditions.
              </p>
              <p>
                If you are not in agreement with this policy and this company’s
                terms of service do not make a payment or place an order.
              </p>

              <p>
                Additionally, If you have any queries pertaining to our Refund
                Policy and payment procedure please contact us at{" "}
                <span className="blue">
                  <a href="mailto:support@apexmarkets.io">
                    support@apexmarkets.io
                  </a>
                </span>{" "}
              </p>
            </div>
            <div>
              <h4 id="payment">Payment procedure</h4>
              <p>
                Payments on the platform will be handled by third party payment
                processors. The following shall be the procedures to be adhered
                to by users. Users who make payments through bank transfers and
                credit/debit cards should note that it is crucial to sign the
                necessary forms before making payment on the platform. If there
                are any inconsistencies or suspicion that arises with the
                information provided we may require you to provide sufficient
                evidence (to our satisfaction) of the source of any funds you
                use to make any payments to us and we reserve our rights to
                prohibit you from entering into any contracts or transactions
                until we are satisfied with the information (or evidence) you
                have supplied and that information (or evidence) does not give
                us any reason to suspect any illegality in respect of those
                payments made by you.
              </p>
              <p>
                To determine whether to accept payments from you under this
                agreement, we will have utmost regard to our obligations under
                applicable Governing Legislation. Accordingly, we may in our
                absolute discretion reject payments from you or a third party.
              </p>
              <p>
                In particular, payments will not be accepted from a bank account
                if it is not evident to us that the bank account is in the
                Client’s name. Also, Clients must ensure that they are the
                authorised card holder for the card used to make payments on the
                platform.
              </p>
              <p>
                Time is of the essence in respect of any payment obligation
                under this Agreement. Lastly, It is acknowledged that in some
                instances there might be delays in your deposited funds being
                reflected in your account. You acknowledge and agree that the
                Company shall not be held responsible for any such delays.
              </p>
            </div>
            <div>
              <h4>Risk Warning:</h4>
              <p>
                By gaining access to our online software services, one must be
                cognizant of the risks involved that results from the personal
                usage and choices with their accounts on this platform.
              </p>
              <p>
                Clients making the decision to partake in transactions dealing
                with derivatives/ leveraged products, should understand that it
                carries a high level of risk to capital and therefore it is
                advised to only use money one can afford to lose. Therefore,
                such transactions involving derivatives may not be suitable for
                all investors. Clients should also take into account their
                investment or monetary objectives, risk tolerance and level of
                experience.
              </p>
              <p>
                Lastly, if necessary feel free to further educate yourself or
                seek independent advice.
              </p>
              <p>
                The information on this site is not intended for residents or
                use by any person in any country or jurisdiction where such
                distribution or use would be contrary to local law or
                regulation.
              </p>
            </div>
            <div id="kyc">
              <h4>Know Your Client (KYC) and Anti Money Laundering (AML):</h4>
              <div className="terms-bullets">
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    You acknowledge and agree:to the fact that we are required
                    to properly identify and verify you prior to agreeing to
                    open an account because we are subject to the AML Laws;
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    to provide all information and documentation we require to
                    verify you;
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    we reserve our rights to reject your application for an
                    account if we cannot properly verify you, and will not be
                    liable whatsoever for that rejection;
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    that we may delay, block or refuse to make any payment or to
                    provide any service if we believe on reasonable grounds that
                    to do so may breach AML Laws or any law in our jurisdiction
                    or that of any other country, and we will incur no liability
                    to you if we do so;
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    that during the term of this agreement, we also reserve our
                    rights to take action against you upon reasonable belief and
                    as seen necessary if we suspect that you are breaching any
                    AML Laws; and
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    that the payment of money to us or any instructions given by
                    you, will not breach any law in any country. You agree to
                    provide all information (and complete any documents) that we
                    are required to obtain in accordance with the Foreign
                    Account Tax Compliance Act and Anti money laundering laws.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h4>Anti money laundering policy</h4>
              <p>
                Every client and user represents (hereby stated as I) and
                warrants that any transaction in accordance with this will serve
                as an act in full compliance with all the applicable local,
                national and international laws and regulations. Continually, I
                represent and warrant and certify that I am by no means under
                investigation by any governmental authority with regards to,
                have been charged with, or convicted of; money laundering, drug
                trafficking, any terrorist related activities, or any crimes
                which in any jurisdiction would be predicate crimes or offenses
                that are in contravention to anti-money laundering laws.I affirm
                that I have not been assessed civil or criminal penalties under
                any Anti money laundering law or have had any seizure of funds
                or forfeiture of any action under any anti-money laundering laws
                of any country/ jurisdiction.Therefore, I represent and warrant
                that the funds for this monetary payment to Apex Markets does
                not and will not derive from any illegal activity that violates
                any Anti Money Laundering Laws and Regulations.
              </p>
              <p>
                I recognize that by law, Apex Markets may disclose my name and
                identity in the event that such information is requested by
                authorised personnel only. Additionally, I will notify Apex
                Markets at once, when it is known to me that said representation
                is fallacious or becomes fallacious. Finally, subject to and
                upon the terms and conditions, Clients shall indemnify and hold
                harmless Apex Markets, its employees, affiliates and directors
                and third party providers from any claim or disputes that may
                arise as a result of any transactions and payments made by me in
                relation to Apex Markets software licence agreement/s.
              </p>
            </div>
            <div>
              <h4>Requirements </h4>
              <p>
                Every client should note that it is crucial to sign the customer
                agreement, (and any other necessary documents stipulated by the
                company) when payment is made on the platform. If this is not
                adhered to, we retain the right to reject payment, and or
                suspend a client’s use of services for non compliance.
              </p>
              <p>
                As aforementioned, clients are required to verify payment/s by
                signing the customer agreement which will unlock certain payment
                methods.
              </p>
              <p>
                There are no limits on credit or debit card payments or wire
                transfers once the required valid government issued ID (drivers
                licence, passport, national ID), customer agreement for wire
                transfer or bank payment are submitted and approved. The
                signature on the customer payment agreement must match or be
                identical to the signature on the ID. Information submitted to
                us must be consistent and valid for an approval to take place.
                Our team will review documents once submitted and reserves the
                right to reject or disapprove documents presented if any
                inconsistency or any other reasonable grounds of rejection is
                suspected.
              </p>
              <p>The following documents are required for KYC Verification:</p>
              <div className="terms-bullets">
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    A <b>CLEAR</b> picture of the front and back of your valid
                    Government-Issued Photo ID which can be any of the
                    following: Passport, Drivers Licence, or National ID.
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    A <b>CLEAR</b> picture of you holding your Government-Issued
                    ID Beside your face.
                  </p>
                </div>
                <div className="bullet">
                  <span>&bull;</span>
                  <p>
                    A <b>SIGNED</b> Customer Agreement.
                  </p>
                </div>
              </div>
            </div>
            <div>
              <h4>Disclaimer of Warranties and Limitation of liability:</h4>
              <p>
                Apex Capital Markets LLC provides a licensed software product as
                an online account facilitator to authorised users, which enables
                them access to a host of services through their account. The
                service provided by Apex Capital Markets LLC is to be taken as
                just that is described and not to be confused or misconstrued to
                be anything else as not to prejudice the operation and goodwill
                of the company. Apex Capital Markets LLC has no responsibility
                or liability for any aspect of usage by users not in conformity
                with our services, with respect to being in full compliance with
                governing laws concerning this industry. Lastly, if any
                provision of the terms of service and legal documents are
                unenforceable or held illegal in a judicial proceeding such
                provision shall be severed and held inoperative, whilst holding
                the remaining portion of this document operative and binding on
                both parties. Apex Capital Markets LLC and Apex Capital Markets
                Ltd are one in the same, registered in different countries.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LegalDocuments;
