import React from "react";
import { useEffect, useState } from "react";
import { getCardCharges } from "../../api/card";

// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";

import TableSearch from "../../components/admin/TableSearch";
import CustomerChargeBackTable from "../../components/admin/Chargeback/CustomerChargeBackTable";
import Paginator from "../../components/Paginator";

import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";
import { FadeLoader } from "react-spinners";
import { toast } from "react-toastify";

const ChargebackResponse = ({ showAdminNav }) => {
  showAdminNav(true);
  const [showManageModal, setShowManageModal] = useState(false);
  const [filterParams, setFilterParams] = useState({
    search: "",
    status: "APPROVED",
  });

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [charges, setCharges] = useState([]);
  const [filter, setFilter] = useState();
  const [lastDigitsFilter, setLastDigitsFilter] = useState();
  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const loadCardCharges = async () => {
    setLoading(true);
    try {
      const { charges, pages } = await getCardCharges({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      if (charges != null) {
        setCharges(charges);
        setNumPages(pages > 0 ? pages : 1);
        if (currentPage > pages) {
          setCurrentPage(1);
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      setCharges([]);
    }
  };

  useEffect(() => {
    loadCardCharges();
  }, [currentPage, filterParams]);

  return (
    <div className="dashboard-page">
      <AdminBar title="Chargeback Response"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Chargeback"
              img={adminboxblue}
              data="120"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Pending"
              img={adminboxorange}
              data="120"
              info="Last 24 Hours -0.21%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total Rejected"
              img={adminboxblack}
              data="100"
              info="Last 24 Hours -0.21%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Didn’t Apply"
              img={adminboxgreen}
              data="130"
              info="Last 24 Hours +3.00%"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Customer ChargeBack Response</div>
              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val);
                    setFilterParams({ ...filterParams, search: val });
                  }}
                  filter={filter}
                  placeholder="Search by name or email"
                ></TableSearch>
                <TableSearch
                  setFilter={(val) => {
                    setLastDigitsFilter(val);
                    setFilterParams({ ...filterParams, lastDigits: val });
                  }}
                  filter={lastDigitsFilter}
                  placeholder="Card Last 4 Digits"
                ></TableSearch>
                {/* 
                    <TableFilter></TableFilter>
                    <AdminFilter></AdminFilter> 
                  */}
              </div>
            </div>
            {loading && (
              <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            )}
            {!loading && (
              <>
                <CustomerChargeBackTable
                  showManageModal={showManageModal}
                  setShowManageModal={setShowManageModal}
                  data={charges}
                  refresh={loadCardCharges}
                />
                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChargebackResponse;
