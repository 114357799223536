import actions from "../../images/icons/actions.svg";

const img = <img src={actions} alt="" />;

export const rank_data = [
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    totalInvites: "26",
    totalJoined: "26",
    totalCommision: "$20.11",
    status: "Approved",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    totalInvites: "26",
    totalJoined: "26",
    totalCommision: "$20.11",
    status: "Approved",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    totalInvites: "26",
    totalJoined: "26",
    totalCommision: "$20.11",
    status: "Approved",
    action: img,
  },
  {
    no: "01",
    clientid: "#1234567",
    name: "Abdullah Khaled",
    totalInvites: "26",
    totalJoined: "26",
    totalCommision: "$20.11",
    status: "Approved",
    action: img,
  },
];

export const rank_colNames = [
  "No.",
  "Client ID",
  "Name",
  "Total Invites",
  "Total Joinded",
  "Total Comission",
  "Status",
  "Action",
];
