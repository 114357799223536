import React, { useState } from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";

// css
import "./DepositForm.css";
import { depositToAccount } from "../api/trading";
import { toast } from "react-toastify";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const AddDemoFunds = ({ closeModal, accountNumber }) => {
  const [amount, setAmount] = useState(0);
  const [processing, setProcessing] = useState(false);
  const depositToDemoAccount = async () => {
    setProcessing(true);
    const result = await depositToAccount(accountNumber, {
      amount,
    });
    if (result != null && result.errors == null) {
      toast("Successfully added funds", {
        type: "success",
      });
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    } else {
      setProcessing(false);
    }
  };
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Add Funds</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Enter Amount</p>
                <span>
                  Enter an amount anywhere from <br /> $1 - $10,000 USD
                </span>
              </div>
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  //   defaultValue={valueAmt}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                />
                <div className="select-currency-btn currency-type">
                  USD<BsChevronDown></BsChevronDown>
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>
              {/* <div className="amt-percentages">
                <div className="percentages">
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                </div>
              </div> */}
            </div>
            {/* <div className="df-save-card">
              <input type="checkbox" />
              <span className="label"></span>
              <p>
                I have read and agree with Apex Market’s{" "}
                <span className="blue">User Agreement</span> and{" "}
                <span className="blue">Privacy Policy</span>.
              </p>
            </div> */}

            <button
              className="verification-continue-btn complete"
              disabled={processing}
              onClick={() => depositToDemoAccount()}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default AddDemoFunds;
