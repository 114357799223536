import React, { useState } from "react";
import styled from "styled-components";
// icons
import deleteIcon from "../images/icons/delete.svg";
// css
import "./DepositForm.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const ConfirmationModal = ({
  Account_ID,
  setIsModalOpen,
  setDemoAccountId,
  deleteDemoAccountsHandler,
}) => {
  return (
    <ModalContainer>
      <div className="cpm sticky top-24">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h3 className="md:pr-10 pl-3 pr-8 md:text-lg">
                Are you sure you want to delete this?
              </h3>
              <div
                className="close-cpm"
                onClick={() => {
                  setIsModalOpen(false);
                  setDemoAccountId();
                }}
              >
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div className="flex w-full justify-end gap-1">
              <button
                className="verification-continue-btn complete"
                onClick={() => {
                  setIsModalOpen(false);
                  setDemoAccountId();
                }}
              >
                Cancel
              </button>
              <button
                className="verification-continue-btn complete"
                onClick={() => {
                  deleteDemoAccountsHandler(Account_ID, "DEMO");
                  setIsModalOpen(false);
                }}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConfirmationModal;
