import React, { useRef, useState } from "react";

const AdminKycButton = ({
  onClickApprove,
  onClickReject,
  status,
  disabled,
  modelRefresh,
  setModelRefresh,
  rejectReason = "",
  setRejectReason,
  updateNote,
  rejectedReasonOnClick,
}) => {
  const [showPopover, setShowPopover] = useState(false);
  const popoverRef = useRef(null);

  const togglePopover = () => {
    setShowPopover(!showPopover);
  };

  return (
    <div className="flex gap-2 cc-right flex-wrap">
      <button
        disabled={disabled && disabled}
        className="cursor-pointer black underline"
        onClick={() => {
          onClickApprove();
          setModelRefresh(!modelRefresh);
        }}
      >
        Approve
      </button>
      <div className="relative">
        <button className="cursor-pointer blue" onClick={togglePopover}>
          Reject
        </button>
        <div
          ref={popoverRef}
          className={`absolute mt-2 p-2 z-10 bg-white border rounded shadow-lg ${
            showPopover ? "" : "hidden"
          }`}
        >
          <input
            type="text"
            placeholder="Enter reason"
            className="p-2 border rounded w-full"
            onChange={(e) => updateNote(e, setRejectReason)}
            value={rejectReason}
          />
          <div className="flex">
            <button
              className="cursor-pointer black underline"
              onClick={togglePopover}
            >
              Cancel
            </button>
            <button
              disabled={!rejectReason}
              className="cursor-pointer black underline"
              onClick={() => {
                rejectedReasonOnClick();
                togglePopover();
                onClickReject();
              }}
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="cursor-pointer">
        <br />
        Status:{" "}
        <span
          className={`${
            status === "REJECTED"
              ? "text-red-600"
              : status === "PENDING"
              ? "text-yellow-600"
              : "text-green-600"
          }`}
        >
          {status}
        </span>
      </div>
    </div>
  );
};

export default AdminKycButton;
