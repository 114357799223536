import React, { useEffect, useRef } from "react";

import profile from "../images/profile-icon.png";
import { Link, useNavigate } from "react-router-dom";
import deleteIcon from "../images/icons/delete.svg";

import "./UserProfileModal.css";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { logout } from "../api/customer";
import Avatar from "react-avatar";

// ----------------------USER PROFILE BOX------------------
const UserProfileModal = ({ closeModal }) => {
  const customer = useSelector(selectCurrentCustomer);

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
  });

  const handleClickOutside = (e) => {
    if (!refOne?.current?.contains(e.target)) {
      closeModal(false);
    }
  };

  const handleLogout = async () => {
    await logout(1);
  };

  const refOne = useRef(null);

  return (
    customer != null && (
      <div className="user-profile-modal" ref={refOne}>
        <div className="upm-container">
          <div className="upm-top">
            <Avatar
              name={customer.Firstname + " " + customer.Lastname}
              round={true}
              size={40}
            />
            <div className="upm-name">
              <p>
                {customer?.Firstname} {customer?.Lastname}
              </p>
              <div
                className={`verification-status ${
                  customer?.Status?.toUpperCase() === "VERIFIED"
                    ? "verified"
                    : ""
                }`}
              >
                {customer?.Status?.toUpperCase()}
              </div>
              {/* <div className="verification-status verified">Verified</div> */}
            </div>
          </div>
          <div className="upm-middle">
            <ul>
              {(customer?.Status?.toUpperCase() !== "VERIFIED" ||
                customer.Ran_Automated_KYC == 1) &&
                (customer.documentsSubmitted ? (
                  <>
                    <Link to="/settings-kyc-verification">
                    Account Verification
                    </Link>
                  </>
                ) : (
                  <>
                    <Link to="/kyc-verification">Verify Now</Link>
                  </>
                ))}
              <Link to="/personal-information">Account Settings</Link>
              {/* <Link to="#">Reports</Link>
              <Link to="#">Help</Link> */}
            </ul>
          </div>
          <div className="upm-bottom">
            <div className="sign-out-btn" onClick={handleLogout}>
              Sign Out
            </div>
          </div>
        </div>
        <div className="close-upm" onClick={() => closeModal(false)}>
          <img src={deleteIcon} alt="" />
        </div>
      </div>
    )
  );
};

export default UserProfileModal;
