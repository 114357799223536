import React from "react";
import { Link } from "react-router-dom";

const FaqDropdown = ({ faq, index, toggleFAQ }) => {
  return (
    <div className={"faq " + (faq.open ? "open" : "")} key={index}>
      <div className="faq-question" onClick={() => toggleFAQ(index)}>
        <p>{faq.question}</p>
      </div>
      <div className="faq-answer css-fix">
        <h4>{faq.title1}</h4>
        {faq.answer}
        <h4>{faq.title2}</h4>
        {faq.answer2}
        <h4>{faq.title3}</h4>
        {faq.answer3}
        <div className="faq-button">
          <Link to="#">{faq.button}</Link>
        </div>
        <p>{faq.dashboardlink}</p>
        <p>{faq.supportlink}</p>
      </div>
    </div>
  );
};

export default FaqDropdown;
