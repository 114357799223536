import React from "react";
import { NavLink } from "react-router-dom";

const LegalDocumentsSelector = () => {
  return (
    <div class="dm-wrapper accounts-selecter">
      <input type="radio" name="select" id="option-1" />
      <input type="radio" name="select" id="option-2" />
      <input type="radio" name="select" id="option-3" />
      <input type="radio" name="select" id="option-4" />
      <input type="radio" name="select" id="option-5" />
      <NavLink to="/legal-documents">
        <label for="option-1" className="option option-1">
          <span>Terms & Conditions</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/Risk-Disclosure-Notice">
        <label for="option-2" className="option option-2">
          <span>Risk Disclosure Notice</span>
        </label>
      </NavLink>
      <div className="line"></div>
      <NavLink to="/Order-Execution-Policy">
        <label for="option-2" className="option option-2">
          <span>Order Execution Policy</span>
        </label>
      </NavLink>
    </div>
  );
};

export default LegalDocumentsSelector;
