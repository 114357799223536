import React, { useEffect, useState } from "react";
import AppBar from "../components/AppBar";
// import VerificationBar from "../components/VerificationWarning";
import LeaderboardsSelector from "../components/LeaderboardsSelector";
import "./Leaderboards.css";
import { TraderData } from "../components/TraderData";
// import { AffiliateData } from "../components/AffiliateData";
import profileAvatar from "../images/profile-icon.png";


import { BsChevronDown } from "react-icons/bs";
import info from "../images/icons/info2.svg";
import img from "../images/Img.png";
import trophy from "../images/icons/trophy.svg";
import payout from "../images/icons/payout.svg";
import { affiliateData, traderData } from '../api/leaders';

const Leaderboards = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All");
  const [AffiliateData, setAffiliateData] = useState([]);
  const [TraderData, setTraderData] = useState([]);


  useEffect(() => {
    affiliateData(false).then((data) => setAffiliateData(data?.leaderboard));
    traderData(false).then((data) => {
      if (data.traders) {
        setTraderData(data?.traders)
      }
    });
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const options = ["All", "Last 3 Months", "Last Week"];
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Leaderboards"></AppBar>
        {/* <VerificationBar></VerificationBar> */}
        <div className="leaderboards-top">
          <div>
            <LeaderboardsSelector></LeaderboardsSelector>
          </div>
          {/* <div class="deposit-filter">
            <div
              className="df-btn"
              onClick={(e) => {
                setIsActive(!isActive);
              }}
            >
              {selected}
              <span>
                <BsChevronDown></BsChevronDown>
              </span>
            </div>
            {isActive && (
              <div className="df-content">
                {options.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelected(e.target.textContent);
                      setIsActive(false);
                    }}
                    className="df-item"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div> */}
        </div>

        <div className="faq-container">
          <div className="leaderboard-page-grid">

            {/* <div className="weekly-trader">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#FF6838" }}
                  ></div>
                  <span>Trader Of The Week</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="totw-info">
                <div className="totw-img">
                  <img src={img} alt="" />
                  <div className="trophy">
                    <img src={trophy} alt="" />
                  </div>
                </div>
                <div className="totw-details">
                  <h2>Zesan H.</h2>
                  <p>
                    Total Gain of <span>150.45%</span>
                  </p>
                </div>
              </div>
            </div>

            <div className="highest-pay">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#58BD7D" }}
                  ></div>
                  <span>Highest Payout</span>
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="totw-info">
                <div className="totw-img">
                  <img src={img} alt="" />
                  <div className="trophy">
                    <img src={payout} alt="" />
                  </div>
                </div>
                <div className="hp-details">
                  <h2>Zesan H.</h2>
                  <p>
                    Total Payout <span>100050.45USD</span>
                  </p>
                </div>
              </div>
            </div>*/}

            <div className="top-traders">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#FFD166" }}
                  ></div>
                  <span>Top 10 Trader</span>
                </div>
                <div>
                  <img src={info} alt="" />
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="top-header">
                <p>Name</p>
                <p>Gain</p>
              </div>
              <ul className="trader-list">
                {TraderData.map((data, key) => {
                  return (
                    <>
                      <div className="horizontal-line"></div>
                      <li
                        key={key}
                        className="trader"
                      // onClick={() => setActive(!active)}
                      >
                        <div className="trader-name">
                          <img src={profileAvatar} alt="" />
                          <div id="">{data?.Name}</div>
                        </div>
                        <div className="trader-gain">{data?.gain}</div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>

            <div className="top-affiliates">
              <div className="deposit-page-box-title cb-row1">
                <div className="cb-row-1-right">
                  <div
                    className="color-box"
                    style={{ background: "#FFD166" }}
                  ></div>
                  <span>Top 10 Affiliate</span>
                </div>
                <div>
                  <img src={info} alt="" />
                </div>
              </div>
              <div className="horizontal-line"></div>
              <div className="top-header">
                <p>Name</p>
                <p>Gain</p>
              </div>
              <ul className="trader-list">
                {AffiliateData.map((data, key) => {
                  return (
                    <>
                      <div className="horizontal-line"></div>
                      <li
                        key={key}
                        className="trader"
                      // onClick={() => setActive(!active)}
                      >
                        <div className="trader-name">
                          <img src={profileAvatar} alt="" />
                          <div>{data.Wallet.Customer.Firstname}</div>
                        </div>
                        <div className="trader-gain">{data.total} USD</div>
                      </li>
                    </>
                  );
                })}
              </ul>
            </div>
            <div hidden className="challenge">
              <img src={trophy} alt="" />
              <h2>
                Summer Challenge :Fully Paid <br /> Trip for 2 to Panama
              </h2>
              <p>
                To enter a trader would need to deposit a minimum of $100 USD
                into there challenge account the trader with the highest
                percentage growth wins a free fully paid trip for 2 to panama
                for the weekend
              </p>
              <div>Join to The Challenge</div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Leaderboards;
