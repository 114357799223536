import React from "react";
import { useState } from "react";
import { toast } from "react-toastify";

import VerificationInput from "react-verification-input";
import styled from "styled-components";
import { changePassword } from "../api/trading";
// import { Link } from "react-router-dom";

// icons
import deleteIcon from "../images/icons/delete.svg";
import eye from "../images/icons/eye.svg";
// hide password
import hidepw from "../images/icons/hidepw.svg";

// css
import "./AccountSettings.css";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const ChangeInvestorPassword = ({
  closeModal,
  title,
  type,
  accountToChange,
}) => {
  const [loading, setLoading] = useState(false);

  const changeTradingPassword = async () => {
    debugger;
    const data = {
      type,
      loginId: accountToChange.trading_account_id_numeric,
    };
    setLoading(true);
    const response = await changePassword(data);
    if (response.errors == null) {
      toast.success("Successfully changed password");
      setTimeout(() => {
        window.location.reload();
        setLoading(false);
      }, 2000);
    }
  };
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container personal-info-form">
            <div className="cpm-top">
              <h2>{title}</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <button onClick={() => changeTradingPassword()} disabled={loading}>
              Generate New Password
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChangeInvestorPassword;
