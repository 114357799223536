import React from "react";
import AppBar from "../components/AppBar";
import AccountSettingsLinks from "../components/AccountSettingsLinks";
import "../components/AccountSettings.css";
import { useState } from "react";
import { useEffect } from "react";
import { getCurrentProfile, saveProfile } from "../api/profile";
import { toast } from "react-toastify";
import { parse } from "date-fns";
import "react-datepicker/dist/react-datepicker.css";
import FadeLoader from "react-spinners/FadeLoader";
import moment from "moment";
import "react-tooltip/dist/react-tooltip.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import PersonalInfoTooltip from "./PersonalInfoTooltip";

const PersonalInformation = ({ showNav, noNav }) => {
  showNav(true);
  noNav(false);
  const [firstName, setFirstName] = useState("");
  const [savedFirstName, setSavedFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [savedLastName, setSavedLastName] = useState("");
  const [username, setUsername] = useState("");
  const [dob, setDob] = useState("");
  const [savedDob, setSavedDob] = useState("");
  const [phone, setPhone] = useState("");
  const [savedPhone, setSavedPhone] = useState("");
  const [gender, setGender] = useState("");
  const [savedGender, setSavedGender] = useState("");
  const [street1, setStreet1] = useState("");
  const [savedStreet1, setSavedStreet1] = useState("");
  const [street2, setStreet2] = useState("");
  const [savedStreet2, setSavedStreet2] = useState("");
  const [city, setCity] = useState("");
  const [savedCity, setSavedCity] = useState("");
  const [state, setState] = useState("");
  const [savedState, setSavedState] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [savedPostalCode, setSavedPostalCode] = useState("");
  const [country, setCountry] = useState("");
  const [savedCountry, setSavedCountry] = useState("");
  const [show, setShow] = useState(false);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const loadProfileInfo = async () => {
    setLoading(true);
    const customer = await getCurrentProfile();
    if (customer != null) {
      setFirstName(customer.Firstname);
      setSavedFirstName(customer.Firstname);
      setLastName(customer.Lastname);
      setSavedLastName(customer.Lastname);
      setUsername(customer.Username);
      setPostalCode(customer.Postal_Code);
      setSavedPostalCode(customer.Postal_Code);
      setPhone(customer.Telephone_Cell);
      setSavedPhone(customer.Telephone_Cell);
      setGender(
        customer.gender
          ? customer.gender.charAt(0) + customer.gender.slice(1).toLowerCase()
          : ""
      );
      setSavedGender(
        customer.gender
          ? customer.gender.charAt(0) + customer.gender.slice(1).toLowerCase()
          : ""
      );
      if (customer.DOB != null) {
        const date = parse(customer.DOB, "yyyy-MM-dd", new Date());
        console.log("@@@", moment(date).format("YYYY-MM-DD"));
        setDob(moment(date).format("YYYY-MM-DD"));
        setSavedDob(moment(date).format("YYYY-MM-DD"));
      }
    }
    if (customer.customer_address.length) {
      const {
        city_town_district,
        country,
        state_parish_pronvince,
        line1,
        line2,
      } = customer.customer_address[0];
      setStreet1(line1);
      setSavedStreet1(line1);
      setStreet2(line2);
      setSavedStreet2(line2);
      setCity(city_town_district);
      setSavedCity(city_town_district);
      setState(state_parish_pronvince);
      setSavedState(state_parish_pronvince);
      setCountry(country);
      setSavedCountry(country);
    }
    setLoading(false);
  };

  const save = async () => {
    try {
      const obj = {
        firstName,
        lastName,
        gender: gender.toUpperCase(),
        phone,
        dob,
        street1,
        street2,
        city,
        state,
        postalCode,
        country,
      };
      setLoading(true);
      const result = await saveProfile(obj);
      if (result != null && result.errors == null) {
        toast("Successfully updated", { type: "success" });
        loadProfileInfo();
      }
    } catch (error) {
      console.error("An error occurred during profile save:", error);
      toast("An error occurred during profile save. Please try again later.", {
        type: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadProfileInfo();
  }, []);
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Account Settings"></AppBar>

        <div className="dashboard-container">
          <AccountSettingsLinks></AccountSettingsLinks>
          <div className="security-line"></div>
          <div className="personal-info-form">
            {loading ? (
              <div className="absolute z-50 flex items-center justify-center w-full h-full">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            ) : null}

            <div>
              <div className="form-label-warning">
                <label htmlFor="firstname">First Name</label>
                {savedFirstName && (
                  <PersonalInfoTooltip
                    name="firstName-tooltip"
                    message="First Name"
                  />
                )}
              </div>
              <input
                type="text"
                id="firststname"
                name="firstname"
                value={firstName}
                readOnly={savedFirstName ? true : false}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </div>

            <div>
              <div className="form-label-warning">
                <label htmlFor="lastName">Last Name</label>
                {savedLastName && (
                  <PersonalInfoTooltip
                    name="lastName-tooltip"
                    message="Last Name"
                  />
                )}
              </div>
              <input
                type="text"
                id="lastName"
                name="lastName"
                value={lastName}
                readOnly={savedLastName ? true : false}
                onChange={(e) => setLastName(e.target.value)}
              />
            </div>

            <div>
              <div className="form-label-warning">
                <label htmlFor="contact-subject">Gender</label>
                {savedGender && (
                  <PersonalInfoTooltip name="gender-tooltip" message="Gender" />
                )}
              </div>
              <div className="gender-select">
                <select
                  required
                  id="contact-subject"
                  value={gender}
                  disabled={savedGender ? true : false}
                  onChange={(e) => setGender(e.target.value)}
                >
                  <option value="" disabled hidden>
                    Select Gender
                  </option>
                  {["Male", "Female"].map((option, index) => (
                    <option className="red" value={option} key={index}>
                      {option}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            {savedPhone ? (
              <div>
                <div className="form-label-warning">
                  <label htmlFor="phone">Phone</label>
                  <PersonalInfoTooltip name="phone-tooltip" message="Phone" />
                </div>
                <input
                  type="telephoneCell"
                  id="phone"
                  name="phone"
                  value={savedPhone}
                  readOnly
                />
              </div>
            ) : (
              <div>
                <label htmlFor="">Phone Number*</label>
                <div className="phone-select">
                  <PhoneInput
                    country="jm"
                    value={phone}
                    onChange={(value) => setPhone(value)}
                    placeholder="Enter your phone number"
                    enableSearch={true}
                    onClick={() => setShow(false)}
                  />
                  {show ? (
                    <div
                      className="c-flag"
                      onClick={() => setShow(false)}
                    ></div>
                  ) : null}
                </div>
              </div>
            )}

            <div>
              <div className="form-label-warning">
                <label htmlFor="dob">Date of Birth</label>
                {savedDob && (
                  <PersonalInfoTooltip
                    name="dob-tooltip"
                    message="Date of Birth"
                  />
                )}
              </div>
              <div className="input-with-icon w-full sm:w-[w-50%]">
                <input
                  name="dob"
                  className={`${!dob && "text-[#777e90]"}`}
                  type="date"
                  value={dob}
                  required
                  readOnly={savedDob ? true : false}
                  onChange={(e) => setDob(e.target.value)}
                />
              </div>
            </div>

            <div>
              <div className="form-label-warning">
                <label htmlFor="street1">Street Address 1</label>
                {savedStreet1 && (
                  <PersonalInfoTooltip
                    name="street1-tooltip"
                    message="Street Address"
                  />
                )}
              </div>
              <input
                placeholder="Enter your street address"
                type="text"
                id="street1"
                name="street1"
                readOnly={savedStreet1 ? true : false}
                value={street1}
                onChange={(e) => setStreet1(e.target.value)}
              />
            </div>

            <div>
              <div className="form-label-warning">
                <label htmlFor="street2">Street Address 2</label>
                {savedStreet2 && (
                  <PersonalInfoTooltip
                    name="street2-tooltip"
                    message="Street Address"
                  />
                )}
              </div>
              <input
                placeholder="Enter your street address"
                type="text"
                id="street2"
                name="street2"
                value={street2}
                readOnly={savedStreet2 ? true : false}
                onChange={(e) => setStreet2(e.target.value)}
              />
            </div>

            <div className="form-flex-row ">
              <div>
                <div className="form-label-warning">
                  <label htmlFor="fullname">City/Town</label>
                  {savedCity && (
                    <PersonalInfoTooltip name="city-tooltip" message="City" />
                  )}
                </div>
                <input
                  type="text"
                  id="city"
                  name="city"
                  placeholder="Enter city/town name"
                  value={city}
                  readOnly={savedCity ? true : false}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>

              <div>
                <div className="form-label-warning">
                  <label htmlFor="fullname">State/Parish/Province/Region</label>
                  {savedState && (
                    <PersonalInfoTooltip name="state-tooltip" message="State" />
                  )}
                </div>
                <input
                  type="text"
                  id="state"
                  name="state"
                  placeholder="Enter state name"
                  readOnly={savedState ? true : false}
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>

              <div>
                <div className="form-label-warning">
                  <label htmlFor="fullname">Postal/Zip Code</label>
                  {savedPostalCode && (
                    <PersonalInfoTooltip
                      name="postal-tooltip"
                      message="Postal Code"
                    />
                  )}
                </div>
                <input
                  placeholder="Enter code"
                  type="text"
                  id="postcode"
                  name="postcode"
                  value={postalCode}
                  readOnly={savedPostalCode ? true : false}
                  onChange={(e) => setPostalCode(e.target.value)}
                />
              </div>

              <div>
                <div className="form-label-warning">
                  <label htmlFor="fullname">Country</label>
                  {savedCountry && (
                    <PersonalInfoTooltip
                      name="country-tooltip"
                      message="Country"
                    />
                  )}
                </div>
                <input
                  placeholder="Enter your country"
                  type="text"
                  id="country"
                  name="country"
                  value={country}
                  onChange={(e) => setCountry(e.target.value)}
                  readOnly={savedCountry ? true : false}
                />
              </div>
            </div>
            <button className="personal-info-save-btn complete" onClick={save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PersonalInformation;
