import React, { useRef, useState, useEffect, useCallback } from "react";
import VerificationProgressBar from "./VerificationProgressBar";
import CountrySelector from "./CountrySelector";
import KycButtons from "./KycButtons";
import upload from "../../images/icons/upload.svg";
import DocumentUpload from "./DocumentUpload";
import idicon from "../../images/icons/IDicon.svg";
import idicon2 from "../../images/icons/idicon2.svg";
import passport from "../../images/icons/passporticon.svg";
import tick from "../../images/icons/tick.svg";
import cameraicon from "../../images/icons/cameraicon.svg";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import DocumentPreviewModal from "./DocumentPreviewModal";
import DocumentUploadBox from "./DocumentUploadBox";
import checkmark from "../../images/icons/greencheckmark.svg";
import deleteIcon from "../../images/icons/delete.svg";
import { useStore } from "../../store";
import { TailSpin } from "react-loader-spinner";
import SignatureConfirmation from "./SignatureConfirmation";
import { toast } from "react-toastify";
import {
  reSubmitVerificationDocument,
  submitVerificationDocument,
} from "../../api/customer";
import { useNavigate } from "react-router-dom";
import {
  azureUploadImage,
  base64FaceMatch,
  getUserUploadedDocuments,
  base64SignatureMatch,
  deleteDocument,
} from "../../api/document";
import Webcam from "react-webcam";
import CustomQRCode from "../QRCodeGenerator";

const KycFlow = ({
  continueBtn,
  previousBtn,
  selectedCountry,
  setSelectedCountry,
  selectedDocument,
  setSelectedDocument,
  index,
  id,
  setIndex,
  processing,
  setProcessing,
  typeOfDocumentToResubmit,
  documentToResubmit,
  allCountries,
  customer,
  isMobile,
}) => {
  const navigate = useNavigate();
  const { setSelfie, docFront, docBack, selfie } = useStore();
  const [value, setValue] = useState("");
  const [selfiePreview, setSelfiePreview] = useState("");
  const [frontPreview, setFrontPreview] = useState(null);
  const [backPreview, setBackPreview] = useState(null);
  const [submitForm, setSubmitForm] = useState(false);
  const [reSubmitForm, setReSubmitForm] = useState(false);
  const [selfieUploadedId, setSfieUploadedId] = useState();
  const [documentUploadedId, setDocumentUploadedId] = useState();
  const [documentToUpload, setDocumentToUpload] = useState();
  const [retrievedSelfie, setRetrievedSelfie] = useState();
  const [retrievedSig, setRetrievedSig] = useState();
  const [retrievedFront, setRetrievedFront] = useState();
  const webcamRef = useRef(null);
  const [isWebcamOpen, setIsWebcamOpen] = useState(false);
  const isNumber = value !== "" && value !== null;
  const [signatureImage, setSignatureImage] = useState("");
  const [sigImageFormData, setSigImageFormData] = useState("");
  const [documentExtracted, setDocumentExtracted] = useState("");
  const [selfieMatched, setSelfieMatched] = useState("");
  const [captureFromWeb, setCaptureFromWeb] = useState(false);
  const [open, setOpen] = useState(false);
  const modalRef = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleWebcamSelfie = useCallback(async () => {
    setProcessing(true);
    try {
      const imageSrc = webcamRef.current.getScreenshot();
      setIsWebcamOpen(false);
      setCaptureFromWeb(true);
      const filename = `selfie${Date.now()}.jpeg`;
      const blob = await fetch(imageSrc).then((res) => res.blob());

      const formData = new FormData();
      formData.append("image", blob, filename);

      const response = await azureUploadImage(formData);
      if (response.imageUrl) {
        setSelfie(response.imageUrl);
        setSelfiePreview(response.imageUrl);
      } else {
        toast.error("Something went wrong, please try again later");
        setSelfiePreview("");
        setSelfie("");
      }
      setProcessing(false);
    } catch (error) {
      setProcessing(false);
      setBackPreview("");
      setSelfiePreview("");
      console.error("Error during upload selfie:", error);
    }
  }, [webcamRef]);

  const handleUploadSelfie = async (event) => {
    event.preventDefault();
    setProcessing(true);
    const files =
      event.type === "drop" ? event.dataTransfer.files : event.target.files;
    const maxSizeInBytes = 4 * 1024 * 1024;
    if (files && files[0].size > maxSizeInBytes) {
      toast.error(
        "File size exceeds the limit (4MB). Please select a smaller file."
      );
      event.target.value = null;
      return;
    }
    if (files.length) {
      const filename = `selfie${Date.now()}.jpeg`;
      const formData = new FormData();
      formData.append("image", files[0], filename);
      setSelfiePreview(files[0]);
      try {
        const response = await azureUploadImage(formData);
        if (response.imageUrl) {
          setSelfie(response.imageUrl);
        } else {
          toast.error("something went wrong, please try again later");
          setSelfiePreview("");
          setSelfie("");
        }
        setProcessing(false);
      } catch (error) {
        setProcessing(false);
        setBackPreview("");
        setSelfiePreview("");
        console.error("Error during OCR check:", error);
      }
    }
  };

  const getUserAllUploadedDocuments = async () => {
    const params = id
      ? {
          resubmit: id,
        }
      : {};
    try {
      const response = await getUserUploadedDocuments(params);
      if (response.length) {
        response.forEach((doc) => {
          const { kyc_document_type, name } = doc;
          const typeName = kyc_document_type.Type_Name;
          switch (typeName) {
            case "Selfie":
              setRetrievedSelfie(doc);
              setSfieUploadedId("");
              break;
            case "Signed Signature":
              setRetrievedSig(doc);
              break;
            case "Passport":
            case "National ID":
            case "Drivers License":
              setSelectedDocument(typeName);
              if (name.includes("front")) {
                setRetrievedFront(doc);
                setDocumentUploadedId("");
              }
              break;
          }
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteUploadedDocument = async (id) => {
    try {
      setProcessing(true);
      const response = await deleteDocument(id);
      if (!response) {
        toast.error("Something went wrong, Can't delete this document");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setProcessing(false);
    }
  };

  const handleSubmit = async () => {
    setProcessing(true);

    const checkSignature = async (frontImagePath, signature) => {
      const res = await base64SignatureMatch(frontImagePath, signature);
      return res.query.length && res.query[0].similarities[0] >= 0.5
        ? "VERIFIED"
        : "REJECTED";
    };

    try {
      let signature = "",
        signatureStatus = "";

      if (!retrievedSig) {
        const response = await azureUploadImage(sigImageFormData);
        if (response.imageUrl) {
          signature = response.imageUrl;
        } else {
          throw new Error("Image upload failed");
        }
      } else {
        toast.success("Document(s) Submitted Successfully");
        setTimeout(() => {
          navigate("/settings-kyc-verification");
        }, 1500);
        return;
      }

      // if (retrievedFront && signature) {
      //   signatureStatus = await checkSignature(
      //     retrievedFront.image_path,
      //     signature
      //   );
      // }

      // if (signature && docFront) {
      //   signatureStatus = await checkSignature(docFront, signature);
      // }

      const obj = {
        selectedDocument,
        signature,
        signatureStatus: "PENDING",
      };

      // if (signatureStatus) {
        const response = await submitVerificationDocument(obj);
        if (response.message) {
          toast.success("Document(s) Submitted Successfully");
          setTimeout(() => {
            navigate("/settings-kyc-verification");
          }, 1500);
        } else {
          setSubmitForm(false);
        }
      // } else {
      //   throw new Error("Signature verification failed");
      // }
    } catch (error) {
      console.error("Error:", error);
      toast.error(
        "An error occurred while submitting your documents. Please contact support for assistance!"
      );
    } finally {
      setProcessing(false);
      setSigImageFormData("");
    }
  };

  const resubmitDocument = async () => {
    setProcessing(true);
    try {
      let obj;
      if (typeOfDocumentToResubmit != null) {
        switch (typeOfDocumentToResubmit) {
          case "Passport":
          case "National ID":
          case "Drivers License":
            if (documentToUpload?.type === typeOfDocumentToResubmit) {
              obj = {
                front: docFront,
                back: docBack,
                typeOfDocumentToResubmit,
              };
            } else {
              obj = {
                front: docFront,
                back: docBack,
                typeOfDocumentToResubmit: selectedDocument,
              };
            }
            await reSubmitVerificationDocument(obj, id);
            break;
          case "Signed Signature":
            try {
              const signature = await azureUploadImage(sigImageFormData);
              obj = {
                signature: signature.imageUrl,
                typeOfDocumentToResubmit,
              };
              await reSubmitVerificationDocument(obj, id);
            } catch (error) {
              toast.error("Something went wrong, please try again");
              setSigImageFormData("");
              setSignatureImage("");
            }

            break;
          case "Selfie":
            obj = {
              selfie,
              typeOfDocumentToResubmit,
            };
            await reSubmitVerificationDocument(obj, id);
            break;
        }
        toast("Successfully resubmitted document", {
          type: "success",
        });
        setTimeout(() => {
          navigate("/settings-kyc-verification");
        }, 1000);
        setReSubmitForm(false);
      }
    } catch (err) {
      setReSubmitForm(false);
      console.error(err);
    } finally {
      setReSubmitForm(false);
      setProcessing(false);
    }
  };

  const submitSingleDoc = async (
    customerIdentification,
    documentStatus,
    documentRejectedReason,
    selfieStatus,
    selfieRejectedReason
  ) => {
    const obj = {
      selectedDocument,
    };
    if (documentStatus) {
      if (customerIdentification) {
        obj.customerIdentification = customerIdentification;
      }
      if (documentRejectedReason) {
        obj.documentRejectedReason = documentRejectedReason;
      }
      obj.documentStatus = documentStatus;
      obj.front = docFront;
      obj.back = docBack;
    }
    if (selfieStatus) {
      if (selfieRejectedReason) {
        obj.selfieRejectedReason = selfieRejectedReason;
      }
      obj.selfie = selfie;
      obj.selfieStatus = selfieStatus;
    }

    try {
      const { uploadedId } = await submitVerificationDocument(obj);
      if (uploadedId) {
        if (documentStatus) {
          setDocumentUploadedId(uploadedId);
        } else if (selfieStatus) {
          setSfieUploadedId(uploadedId);
        }
      }
    } catch (err) {
      console.error(err);
      toast.error(
        "An error occurred while submitting your documents. Please contact support for assistance!"
      );
    }
  };

  const matchFaces = async () => {
    try {
      if (selfie) {
        setProcessing(true);
        const response = await base64FaceMatch("", selfie);
        if (!response.length || response.length == 1) {
          await submitSingleDoc(
            "",
            "",
            "",
            "REJECTED",
            "The selfie you submitted does not include you holding the document. Please submit a selfie where you are clearly holding the document next to your face"
          );
          setSelfieMatched(true);
          return;
        }
      }
      if ((docFront || retrievedFront) && selfie && !selfieMatched) {
        const response = await base64FaceMatch(
          docFront || retrievedFront.image_path,
          selfie
        );

        const CONFIDENCE_DIFFERENCE_THRESHOLD = parseFloat(
          process.env.REACT_APP_CONFIDENCE_DIFFERENCE
        );

        const status =
          response.matches?.length >= 2
            ? Math.abs(
                response.matches[0].confidence - response.matches[1].confidence
              ) <= CONFIDENCE_DIFFERENCE_THRESHOLD
              ? "VERIFIED"
              : "REJECTED"
            : "REJECTED";

        await submitSingleDoc("", "", "", status);
        setSelfieMatched(true);
      }
    } catch (error) {
      console.error("Error matching faces:", error);
      setSelfieMatched("");
      setSelfie("");
      setSelfiePreview("");
    } finally {
      setProcessing(false);
    }
  };

  useEffect(() => {
    getUserAllUploadedDocuments();
  }, []);

  useEffect(() => {
    if (retrievedFront && retrievedSelfie && retrievedSig)
      navigate("/settings-kyc-verification");
  }, [retrievedFront, retrievedSelfie, retrievedSig]);

  useEffect(() => {
    if (index == 5 && submitForm) handleSubmit();
  }, [submitForm]);

  useEffect(() => {
    if (reSubmitForm) resubmitDocument();
  }, [reSubmitForm]);

  useEffect(() => {
    // matchFaces();
    const handleDocumentSubmission = async () => {
      await submitSingleDoc("", "", "", "PENDING");
    };
    handleDocumentSubmission();
  }, [selfie]);

  const baseURL = process.env.REACT_APP_WEBSITE_URL;
  const emailPrefix = customer?.Email?.split("@")[0];
  const token = customer.token;
  const resubmitParam = typeOfDocumentToResubmit
    ? `resubmit=${documentToResubmit.id}`
    : "";
  const QRValue = `${baseURL}
  /kyc-verification?${resubmitParam}&docFront=${
    docFront ? docFront : retrievedFront ? retrievedFront.image_path : ""
  }
  &type=Selfie&email=${emailPrefix}&token=${token}`;
  return (
    <div className="md:w-[546px] mx-auto w-auto text-black relative ">
      <div className="text-right ">
        {index > 1 && (
          <button
            className={`w-auto px-4 mb-5 flex-1 py-2 font-bold text-white bg-blue-600 rounded-md cursor-pointer`}
            onClick={() => {
              getUserAllUploadedDocuments();
              setRetrievedFront("");
              setRetrievedSig("");
              setRetrievedSelfie("");
            }}
          >
            Refresh
          </button>
        )}
      </div>
      {index < 9 && (
        <div className="relative h-auto p-4 overflow-visible bg-white border-2 rounded-md border-gray-600/20">
          <div className="flex items-center justify-between">
            <div>
              <p className="text-sm font-[500]"> Verify your identity</p>
            </div>

            <div className="my-6">
              <VerificationProgressBar step={index} setIndex={setIndex} />
            </div>

            {processing && (
              <div className="absolute z-10 top-0 left-0 w-full bg-white rounded-md sm:[h-70%] h-full border-2 border-[#D0D5DD]">
                <div className="flex flex-col items-center justify-center h-full px-4 space-y-6 text-center">
                  <TailSpin
                    height="60"
                    width="60"
                    color="#1570EF"
                    ariaLabel="tail-spin-loading"
                    radius="1"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                  />
                  <p className="font-bold text-[24px]">Processing...</p>
                  <p className="text-sm font-light">
                    Hang on while we process your documents.{" "}
                  </p>
                </div>
              </div>
            )}
          </div>

          {index === 1 && (
            <div className="flex flex-col h-[90%]">
              <CountrySelector
                allCountries={allCountries}
                selectedCountry={selectedCountry}
                setSelectedCountry={setSelectedCountry}
              />
              <p className="font-light ">
                By knowing your country, we can ensure that we are complying
                with the laws and regulations of that country when verifying
                your identity and processing transactions.
              </p>
              <KycButtons
                disabled={selectedCountry}
                index={index}
                continueBtn={continueBtn}
                previousBtn={previousBtn}
              />
            </div>
          )}
          {index === 2 && (
            <div className="flex flex-col h-[90%]">
              {!retrievedFront ? (
                <div className="my-4">
                  <p className="text-xl">Verify it’s you</p>
                  <p className="mt-4 font-light ">
                    Select a document to verify your identity, please ensure you
                    have the selected document and ensure that they are
                    up-to-date.
                  </p>
                  <div className="flex flex-wrap items-center justify-center gap-4 my-4 text-center md:justify-between">
                    <div
                      className={`flex flex-col items-center justify-evenly px-6 py-4 border border-gray-600/20 h-[160px] w-[152px] rounded-md hover:bg-[#EAECF0] ${
                        selectedDocument === "National ID" && "border-blue-600"
                      }`}
                      onClick={() => setSelectedDocument("National ID")}
                    >
                      <img src={idicon} alt="" />
                      <p className="text-sm">National ID</p>
                      {selectedDocument === "National ID" && (
                        <img src={tick} alt="" />
                      )}
                    </div>
                    <div
                      className={`flex flex-col items-center justify-evenly px-6 py-4 border border-gray-600/20 h-[160px] w-[152px] rounded-md cursor-pointer hover:bg-[#EAECF0] ${
                        selectedDocument === "Passport" && "border-blue-600"
                      }`}
                      onClick={() => setSelectedDocument("Passport")}
                    >
                      <img src={passport} alt="" />
                      <p className="text-sm">Passport</p>
                      {selectedDocument === "Passport" && (
                        <img src={tick} alt="" />
                      )}
                    </div>{" "}
                    <div
                      className={`flex flex-col items-center justify-evenly px-6 py-4 border border-gray-600/20 h-[160px] w-[152px] rounded-md hover:bg-[#EAECF0] ${
                        selectedDocument === "Drivers License" &&
                        "border-blue-600"
                      }`}
                      onClick={() => setSelectedDocument("Drivers License")}
                    >
                      <img src={idicon} alt="" />
                      <p className="text-sm">Drivers License</p>
                      {selectedDocument === "Drivers License" && (
                        <img src={tick} alt="" />
                      )}
                    </div>
                  </div>
                </div>
              ) : (
                <div className="my-4">
                  <p className="text-xl">{`${selectedDocument} is Already Submitted`}</p>
                </div>
              )}

              <KycButtons
                retrievedFront={retrievedFront}
                typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                disabled={retrievedFront ? retrievedFront : selectedDocument}
                index={index}
                continueBtn={continueBtn}
                previousBtn={previousBtn}
              />
            </div>
          )}
          {index === 3 && (
            <>
              {!retrievedFront ? (
                <div className="flex flex-col h-[90%]">
                  {selectedDocument === "National ID" && (
                    <>
                      <div className="mt-4">
                        <p className="text-xl">Upload your document</p>
                        <div className="relative custom-select">
                          <div className="select">
                            <span className="flex justify-between px-4">
                              <div className="flex gap-1">
                                <img src={idicon2} alt="" />
                                <p className="mt-[1.5px]">{selectedDocument}</p>
                              </div>
                              <div
                                className="text-sm text-blue-600 cursor-pointer change-doc-btn"
                                onClick={previousBtn}
                              >
                                Change
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="my-4 text-sm">
                          <p className="font-light">
                            Please upload a copy of your valid{" "}
                            {selectedDocument}.
                          </p>
                          <DocumentUpload
                            documentUploadedId={documentUploadedId}
                            deleteUploadedDocument={deleteUploadedDocument}
                            submitSingleDoc={submitSingleDoc}
                            documentExtracted={documentExtracted}
                            setDocumentExtracted={setDocumentExtracted}
                            isMobile={isMobile}
                            documentToResubmit={documentToResubmit}
                            typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                            customer={customer}
                            retrievedFront={retrievedFront}
                            setProcessing={setProcessing}
                            selectedDocument={selectedDocument}
                            frontPreview={frontPreview}
                            setFrontPreview={setFrontPreview}
                            backPreview={backPreview}
                            setBackPreview={setBackPreview}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {selectedDocument === "Passport" && (
                    <>
                      <div className="mt-4">
                        <p className="text-xl">Upload your document</p>
                        <div className="relative custom-select">
                          <div className="select">
                            <span className="flex justify-between px-4">
                              <div className="flex gap-1">
                                <img src={idicon2} alt="" />
                                <p className="mt-[1.5px]">{selectedDocument}</p>
                              </div>
                              <div
                                className="text-sm text-blue-600 cursor-pointer change-doc-btn"
                                onClick={previousBtn}
                              >
                                Change
                              </div>
                            </span>
                          </div>
                        </div>

                        <div className="my-4 text-sm">
                          <p className="font-light">
                            Please upload a copy of your valid{" "}
                            {selectedDocument}.
                          </p>
                          <DocumentUpload
                            documentUploadedId={documentUploadedId}
                            deleteUploadedDocument={deleteUploadedDocument}
                            submitSingleDoc={submitSingleDoc}
                            documentExtracted={documentExtracted}
                            setDocumentExtracted={setDocumentExtracted}
                            isMobile={isMobile}
                            documentToResubmit={documentToResubmit}
                            typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                            customer={customer}
                            setProcessing={setProcessing}
                            selectedDocument={selectedDocument}
                            frontPreview={frontPreview}
                            setFrontPreview={setFrontPreview}
                            backPreview={backPreview}
                            setBackPreview={setBackPreview}
                          />
                        </div>
                      </div>
                    </>
                  )}
                  {selectedDocument === "Drivers License" && (
                    <>
                      <div className="mt-4">
                        <p className="text-xl">Upload your document</p>
                        <div className="relative custom-select">
                          <div className="select">
                            <span className="flex justify-between px-4">
                              <div className="flex gap-1">
                                <img src={idicon2} alt="" />
                                <p className="mt-[1.5px]">{selectedDocument}</p>
                              </div>
                              <div
                                className="text-sm text-blue-600 cursor-pointer change-doc-btn"
                                onClick={previousBtn}
                              >
                                Change
                              </div>
                            </span>
                          </div>
                        </div>
                        <div className="my-4 text-sm">
                          <p className="font-light">
                            Please upload a copy of your valid{" "}
                            {selectedDocument}.
                          </p>
                          <DocumentUpload
                            documentUploadedId={documentUploadedId}
                            deleteUploadedDocument={deleteUploadedDocument}
                            submitSingleDoc={submitSingleDoc}
                            documentExtracted={documentExtracted}
                            setDocumentExtracted={setDocumentExtracted}
                            isMobile={isMobile}
                            documentToResubmit={documentToResubmit}
                            typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                            customer={customer}
                            setProcessing={setProcessing}
                            selectedDocument={selectedDocument}
                            frontPreview={frontPreview}
                            setFrontPreview={setFrontPreview}
                            backPreview={backPreview}
                            setBackPreview={setBackPreview}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div className="my-4">
                  <p className="text-xl">{`${selectedDocument} is Already Submitted`}</p>
                </div>
              )}
              <KycButtons
                retrievedFront={retrievedFront}
                setReSubmitForm={setReSubmitForm}
                typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                disabled={
                  retrievedFront
                    ? retrievedFront
                    : typeOfDocumentToResubmit == "Passport" ||
                      typeOfDocumentToResubmit == "National ID" ||
                      typeOfDocumentToResubmit == "Drivers license"
                    ? selectedDocument === "Passport"
                      ? docFront
                      : docFront && docBack
                    : selectedDocument === "Passport"
                    ? docFront
                    : docFront && docBack
                  // : selectedDocument === "Passport"
                  // ? documentExtracted
                  // : documentExtracted && docBack
                }
                index={index}
                continueBtn={continueBtn}
                previousBtn={previousBtn}
              />
            </>
          )}
          {index === 4 && (
            <>
              <div className="flex flex-col h-[90%]">
                {!retrievedSelfie ? (
                  <div className="my-4 space-y-4">
                    <p className="text-xl">Take a selfie</p>
                    <p className="mt-4 text-sm font-light">
                      To verify your uploaded documents, please take a selfie
                      holding ID beside your face.
                    </p>
                    {open && (
                      <DocumentPreviewModal
                        modalRef={modalRef}
                        index={index}
                        selfiePreview={selfiePreview}
                        onClose={handleClose}
                      />
                    )}
                    <div className="mt-2 space-y-4 border-2 border-[#eaecf0] flex justify-center items-center flex-col sm:p-6 p-2 rounded-md text-center">
                      <img src={cameraicon} alt="" />
                      {!isWebcamOpen && !captureFromWeb && (
                        <button
                          onClick={() => setIsWebcamOpen(true)}
                          className="px-4 py-2 text-white bg-blue-600 rounded-md cursor-pointer"
                        >
                          Take Selfie Using webcam
                        </button>
                      )}
                      {isWebcamOpen && (
                        <>
                          <Webcam
                            audio={false}
                            ref={webcamRef}
                            screenshotFormat="image/jpeg"
                          />
                          <button onClick={handleWebcamSelfie}>
                            Capture photo
                          </button>
                        </>
                      )}

                      {!isWebcamOpen ? (
                        <>
                          {selfiePreview ? (
                            <div className="flex flex-col items-center justify-center w-full gap-1 p-6 mt-4 text-left border border-blue-200 rounded-md sm:gap-6 sm:flex-row">
                              <img src={upload} alt="" className="h-8" />
                              <div>
                                <p className="text-sm">Upload successful</p>
                                <div className="flex justify-center gap-1">
                                  <span>200 KB</span> -{" "}
                                  <span className="text-green-600">Done</span>
                                </div>
                              </div>
                              <div className="flex items-center justify-between gap-4 ml-0 sm:ml-auto">
                                <button onClick={handleOpen}>View</button>
                                <img src={checkmark} alt="" />{" "}
                                <img
                                  src={deleteIcon}
                                  alt=""
                                  className="h-8 cursor-pointer"
                                  onClick={() => {
                                    if (selfieUploadedId) {
                                      deleteUploadedDocument(selfieUploadedId);
                                    }
                                    setSelfieMatched("");
                                    setSelfiePreview(null);
                                    setSelfie("");
                                    setCaptureFromWeb(false);
                                  }}
                                />
                              </div>
                            </div>
                          ) : (
                            <DocumentUploadBox
                              handleUpload={handleUploadSelfie} // replace with your function
                              uploadText="Click to upload selfie holding your ID"
                              preview={selfiePreview} // replace with your image data
                              innerClass={
                                "w-full p-4 space-y-1 text-sm document-upload-box"
                              }
                            />
                          )}
                        </>
                      ) : (
                        <button
                          onClick={() => setIsWebcamOpen(false)}
                          className="px-4 py-2 text-white bg-blue-600 rounded-md cursor-pointer"
                        >
                          Upload File
                        </button>
                      )}
                    </div>
                    <div className="flex items-center justify-between gap-2">
                      <div className="w-full h-[1px] bg-gray-800/20"></div>
                      <p>or</p>
                      <div className="w-full h-[1px] bg-gray-800/20"></div>
                    </div>
                    {/* OTHER OPTIONS */}
                    {!isMobile && (
                      <div className="flex flex-col justify-between gap-2 md:flex-row">
                        <div className="mt-2 space-y-4 border-2 border-[#eaecf0] flex   flex-col p-4 rounded-md w-full">
                          <p className="text-xs font-light">Option 1</p>
                          <p className="text-xl">Scan the QR code</p>
                          <p className="text-xs font-light">
                            Open the camera app and scan the QR code on screen.{" "}
                            <br />{" "}
                            <span className="text-blue-600 underline cursor-pointer ">
                              How to scan QR code
                            </span>
                          </p>
                          <div className="p-10">
                            {/* <CustomQRCode value={QRValue} /> */}
                            <CustomQRCode
                              value={
                                "This feature is currently under maintenance"
                              }
                            />
                          </div>
                        </div>
                        <div className="mt-2 space-y-4 border-2 border-[#eaecf0] flex flex-col p-4 rounded-md w-full">
                          <p className="text-xs font-light">Option 2</p>
                          <p className="text-xl">Get link via SMS</p>
                          <p className="text-xs font-light">
                            We’ll text a secure link to your mobile at no extra
                            cost.
                          </p>
                          <div className="space-y-2">
                            {" "}
                            <p className="text-xs font-light">Phone number</p>
                            <div className="w-full px-2 bg-white border border-gray-300 rounded-lg shadow-sm">
                              <PhoneInput
                                placeholder="Eg-1-800-000-0000"
                                value={value}
                                onChange={setValue}
                              />
                            </div>
                          </div>
                          <button
                            disabled={!isNumber}
                            className={`${
                              isNumber
                                ? "bg-[#008ffd] hover:bg-blue-500"
                                : "bg-blue-600/20"
                            }  w-full px-4 py-2 font-bold text-white rounded-md cursor-pointer `}
                          >
                            Get the link
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="my-4">
                    <p className="text-xl">{`Selfie is Already Submitted`}</p>
                  </div>
                )}
                <KycButtons
                  retrievedFront={retrievedFront}
                  retrievedSelfie={retrievedSelfie}
                  setReSubmitForm={setReSubmitForm}
                  typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                  disabled={
                    retrievedSelfie
                      ? retrievedSelfie
                      : typeOfDocumentToResubmit == "Selfie"
                      ? selfie
                      : selfie
                    // : selfieMatched
                  }
                  index={index}
                  continueBtn={continueBtn}
                  previousBtn={previousBtn}
                />
              </div>
            </>
          )}
          {index === 5 && (
            <>
              <div className="flex flex-col h-[90%]">
                {!retrievedSig ? (
                  <SignatureConfirmation
                    isMobile={isMobile}
                    customer={customer}
                    documentToResubmit={documentToResubmit}
                    retrievedFront={retrievedFront}
                    frontPreview={frontPreview}
                    typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                    setProcessing={setProcessing}
                    continueBtn={continueBtn}
                    signatureImage={signatureImage}
                    setSignatureImage={setSignatureImage}
                    sigImageFormData={sigImageFormData}
                    setSigImageFormData={setSigImageFormData}
                  />
                ) : (
                  <div className="my-4">
                    <p className="text-xl">Signature is Already Submitted</p>
                  </div>
                )}
                <KycButtons
                  retrievedSig={retrievedSig}
                  setSubmitForm={setSubmitForm}
                  setReSubmitForm={setReSubmitForm}
                  typeOfDocumentToResubmit={typeOfDocumentToResubmit}
                  disabled={retrievedSig ? retrievedSig : sigImageFormData}
                  index={index}
                  continueBtn={continueBtn}
                  previousBtn={previousBtn}
                />
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};

export default KycFlow;
