import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import ManageWithdrawRequestModal from "../ManageWithdrawRequestModal";
import moment from "moment";
// css
// HistoryTable.css

const CryptoPayoutRequests = ({
  refresh,
  data,
  colNames,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const [withdrawRequest, setWithdrawRequest] = useState();

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <th>#</th>
          <th>Date Requested</th>
          <th>Client #</th>
          <th>Name</th>
          <th>Email</th>
          <th>Wallet Balance (USD)</th>
          <th>Amount Requested</th>
          <th>Fee (USD)</th>
          <th>Total Payout (USD)</th>
          <th>Date Processed</th>
          <th>Processing Time</th>
          <th>Status</th>
          <th>Action</th>
          <th>Processed By</th>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={row?.ID}>
                <td>{i + 1}</td>
                <td>
                  {moment(row.DateCreated).format("MMM D, YYYY hh:mm:ss A")}
                </td>
                <td>{row?.Customer_ID}</td>
                <td>{`${row?.customer?.Firstname} ${row?.customer?.Lastname}`}</td>
                <td>{row?.customer?.Email}</td>
                <td>{row?.customer?.wallet?.Balance}</td>
                <td>{row.Amount + (!isNaN(row.Fee) ? row.Fee : 0)}</td>
                <td>{row.Fee}</td>
                <td>{row.Amount}</td>
                <td>
                  {moment(row.DateCreated).format("MMM D, YYYY hh:mm:ss A")}
                </td>
                <td>{null}</td>
                <td>{row.Status}</td>
                <td
                  /* onClick={() => {
                    setWithdrawRequest(row);
                    setShowManageModal(true);
                  }} */
                >
                  {/*Manage*/}
                </td>
                <td>{null}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
      {showManageModal && (
        <>
          <ManageWithdrawRequestModal
            closeModal={setShowManageModal}
            withdrawRequest={withdrawRequest}
            refresh={refresh}
          />
        </>
      )}
    </div>
  );
};

export default CryptoPayoutRequests;
