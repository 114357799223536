import React from "react";
// components
import AdminBar from "../../components/admin/AdminBar";

const CustomerSupport = ({ showAdminNav }) => {
  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Customer Support"></AdminBar>
      <div className="dashboard-container"></div>
    </div>
  );
};

export default CustomerSupport;
