export const balancerankdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$22045.05",
  },
];

export const balancecolNames = ["No", "Login Id", "Name", "Balance (USD)"];
