import { api } from "./index";

export const submitContactUsForm = async (data) => {
  return await api
    .post("contact/", {
      json: data,
      timeout: false,
    })
    .json();
};

export const submitContactUsCallbackForm = async (data) => {
  return await api
    .post("contact/callback", {
      json: data,
      timeout: false,
    })
    .json();
};
