import React, { useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import AdminTable from "../../components/admin/AdminTable";
import {
  wallet_deposits,
  wallet_depositscolNames,
} from "../../components/admin/WalletDepositsData";
import {
  wallet_payouts,
  wallet_payoutscolNames,
} from "../../components/admin/WalletPayoutsData";
import {
  trading_commission_data,
  trading_commissioncolNames,
} from "../../components/admin/TradingCommissionData";
import {
  traders_revenue_data,
  traders_revenuecolNames,
} from "../../components/admin/TradersRevenueData";
import FilterBox from "../../components/admin/FilterBox";

import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";

const Revenue = ({ showAdminNav }) => {
  showAdminNav(true);

  const [openFilter, setOpenFilter] = useState(false);
  const handleFilter = (event) => {
    // 👇️ calculate next state based on current state
    setOpenFilter(!openFilter);
  };

  return (
    <div className="dashboard-page">
      <AdminBar title="Revenue"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Deposits"
              img={adminboxblue}
              data="$12,000.00"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Payout"
              img={adminboxorange}
              data="$10,000.00"
              info="Last 24 Hours -0.21% "
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total Balance"
              img={adminboxgreen}
              data="$5,000.00"
              info="Last 24 Hours +3.00%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Commission Earned"
              img={adminboxblack}
              data="$5,000.00"
              info="Last 24 Hours -0.12%"
              color="red"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Total Wallet Deposits</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter
                  setOpenFilter={setOpenFilter}
                  openFilter={openFilter}
                ></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            {openFilter && <FilterBox></FilterBox>}
            <AdminTable
              data={wallet_deposits}
              colNames={wallet_depositscolNames}
              color="blue"
              color2="green"
              color3="black"
              color4="black"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Total Wallet Payouts</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter
                  setOpenFilter={setOpenFilter}
                  openFilter={openFilter}
                ></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            {openFilter && <FilterBox></FilterBox>}
            <AdminTable
              data={wallet_payouts}
              colNames={wallet_payoutscolNames}
              color="blue"
              color2="green"
              color3="black"
              color4="black"
            ></AdminTable>
            <button>Load More</button>
          </div>

          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Trading Commission Generated</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter
                  setOpenFilter={setOpenFilter}
                  openFilter={openFilter}
                ></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            {openFilter && <FilterBox></FilterBox>}
            <AdminTable
              data={trading_commission_data}
              colNames={trading_commissioncolNames}
              color="blue"
              color2="green"
              color3="red"
              color4="green"
            ></AdminTable>
            <button>Load More</button>
          </div>

          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Traders Revenue </div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={traders_revenue_data}
              colNames={traders_revenuecolNames}
              color="blue"
              color2=""
              color3="green"
              color4="red"
              color5="green"
            ></AdminTable>
            <button>Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Revenue;
