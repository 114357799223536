import React, { useEffect, useState } from "react";
import settings from "../images/icons/settings.svg";
import hide from "../images/icons/hide-grey.svg";
import copy from "../images/icons/copy.svg";
import deleteIcon from "../images/icons/delete.svg";
import { AiOutlineEye } from "react-icons/ai";
import { CopyToClipboard } from "react-copy-to-clipboard";

// css
import "../pages/Accounts.css";

const LiveAccountBox = ({
  title,
  openAddModal,
  setOpenAddModal,
  openPassModal,
  setOpenPassModal,
  openMaster,
  setOpenMaster,
  accType,
  account,
  setAccountToChange,
  setAccountNumberToDepositTo,
  setArchive,
  Account_ID,
  Archive,
  handleSelection,
  selected,
  showAll,
  openLeverage,
  setOpenLeverage,
  setDemoAccountId,
  setIsModalOpen,
  closeSetting
}) => {
  const {
    balance,
    main_password,
    trading_account_password,
    trading_account_id_numeric,
  } = account;
  const [isActive, setIsActive] = useState(false);

  const options = ["Change Master Password", "Change Investor Password"];

  const [isMasked1, setIsMasked1] = useState(true);
  const [isMasked2, setIsMasked2] = useState(true);

  const toggleMask1 = () => {
    setIsMasked1(!isMasked1);
  };
  const toggleMask2 = () => {
    setIsMasked2(!isMasked2);
  };
  const [copy1, setCopied1] = useState(false);
  const [copy2, setCopied2] = useState(false);
  const [copy3, setCopied3] = useState(false);

  useEffect(() => {
    setIsActive(false);
  }, [closeSetting]);

  return (
    <div
      className={`live-acc-box ${selected ? "border-2 border-[#008ffd]" : ""}`}
    >
      <div className="la-box-title">
        <div className="color-box" style={{ background: "#141416" }}></div>
        <span>{title}</span>
        <div class="live-acc-settings">
          <img
            src={settings}
            alt=""
            onClick={() => {
                            setIsActive(!isActive);
            }}
          />

          {isActive && (
            <div className="df-content">
              <div className="df-content-flex">
                Account Settings
                <img
                  src={deleteIcon}
                  alt=""
                  onClick={() => {
                    setIsActive(!isActive);
                  }}
                />
              </div>
              {options.map((option) => (
                <div
                  onClick={(e) => {
                    debugger;
                    setAccountToChange(account);
                    option === "Change Investor Password"
                      ? setOpenPassModal(!openPassModal)
                      : setOpenMaster(!openMaster);
                    setIsActive(false);
                  }}
                  className="df-item"
                >
                  {option}
                </div>
              ))}
              <div
                onClick={() => {setAccountToChange(account);setOpenLeverage(!openLeverage);}}
                className="df-item"
              >
                Change Leverage
              </div>
              {Archive ? (
                <div
                  onClick={() => setArchive(Account_ID, false)}
                  className="df-item red"
                >
                  Unarchive
                </div>
              ) : (
                <div
                  onClick={() => setArchive(Account_ID, true)}
                  className="df-item red"
                >
                  Archive
                </div>
              )}
              {accType == "APEX MT5 DEMO" && (
                <div
                  className="df-item red"
                  onClick={() =>{
                    setIsModalOpen(true)
                    setDemoAccountId(Account_ID)
                  }}
                >
                  Delete
                </div>
              )}
            </div>
          )}
        </div>
        {!showAll ? (
          <div className="acc-select">
            <input
              type="checkbox"
              checked={selected}
              onChange={handleSelection}
            />
            <span className="label"></span>
          </div>
        ) : null}
      </div>
      <div className="horizontal-line"></div>
      <div className="cb-row1 cb-row">
        <h1 className="dashboard-grid-title">Current Balance</h1>
      </div>
      <div className="cb-row cb-row2">
        <h1>$ {balance}</h1>
        <div className="currency-type">USD</div>
      </div>
      <div className="la-info">
        <div className="la-info-row">
          <p>Server:</p>
          <div className="la-info-details">ApexCapitalMarkets-ECN</div>
        </div>
        <div className="la-info-row">
          <p>Account Type:</p>
          <div className="la-info-details">{accType}</div>
        </div>
        <div className="la-info-row">
          <p>Login ID:</p>
          <div className="la-info-details">
            <div id="text-to-copy">{trading_account_id_numeric}</div>
            <CopyToClipboard
              onCopy={() => setCopied1(true)}
              text={trading_account_id_numeric}
            >
              <div className="hover:bg-[#008ffd]/20 rounded-md">
                <img src={copy} alt="" />
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <div className="la-info-row">
          <p>Master Password:</p>
          <div className="la-info-details">
            {isMasked1 ? <span>*********</span> : <span>{main_password}</span>}
            <span onClick={toggleMask1}>
              {isMasked1 ? (
                <AiOutlineEye className="text-2xl text-gray-600 "></AiOutlineEye>
              ) : (
                <img src={hide} alt="" />
              )}
            </span>

            <CopyToClipboard
              onCopy={() => setCopied2(true)}
              text={main_password}
            >
              <div className="hover:bg-[#008ffd]/20 rounded-md">
                <img src={copy} alt="" />
              </div>
            </CopyToClipboard>
          </div>
        </div>
        <div className="la-info-row">
          <p>Investor Password:</p>
          <div className="la-info-details">
            {isMasked2 ? (
              <span>*********</span>
            ) : (
              <span>{trading_account_password}</span>
            )}
            <span onClick={toggleMask2}>
              {isMasked2 ? (
                <AiOutlineEye className="text-2xl text-gray-600 "></AiOutlineEye>
              ) : (
                <img src={hide} alt="" />
              )}
            </span>

            <CopyToClipboard
              onCopy={() => setCopied3(true)}
              text={trading_account_password}
            >
              <div className="hover:bg-[#008ffd]/20 rounded-md">
                <img src={copy} alt="" />
              </div>
            </CopyToClipboard>
          </div>
        </div>
      </div>
      <button
        onClick={() => {
          setOpenAddModal(!openAddModal);
          setAccountNumberToDepositTo(trading_account_id_numeric);
        }}
      >
        Add Funds
      </button>
    </div>
  );
};

export default LiveAccountBox;
