import React from "react";
import LegalDocumentsSelector from "../components/LegalDocumentsSelector";
import AppBar from "../components/AppBar";

const OrderExecutionPolicy = ({ showNav }) => {
  showNav(true);
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Legal Documents"></AppBar>
        <LegalDocumentsSelector></LegalDocumentsSelector>
        <div className="faq-container">
          <div className="terms-conditions">
            <h4>Purpose</h4>
            <p>
              The purpose of this document is to provide clients of Apex Capital
              Markets with information about our Order Execution arrangements.
              Please note that you provide your consent to this Policy, our
              Order Execution Policy (the Policy) when you sign up and agree to
              our terms. <br />
              We, Apex Markets LLC, have put in place an Execution Policy to
              ensure that, as required by the relevant authorities. We are also
              required to provide appropriate information to Clients about our
              order Execution Policy.
            </p>

            <h4>Scope</h4>
            <p>
              Our Execution Policy applies to where and how orders are executed.
              The policy applies to all the different types of Clients. When the
              orders are placed by users, they are transmitted to an approved
              third party for execution, where we will then ensure at all times
              in your best interests and take all sufficient steps to obtain the
              best possible result for clients, however it must be noted that we
              cannot be liable for defects in any trade after being sent to the
              third party for execution.
            </p>

            <h4>Order Execution</h4>
            <p>
              It is important that we must be able to demonstrate that orders
              are executed in accordance with our Execution Policy, but this
              does not necessarily mean that we will obtain the best possible
              price for every trade. Our commitment to provide you with best
              execution does not mean that we owe you any fiduciary
              responsibilities over and above the specific regulatory
              obligations placed upon us or as may be otherwise contracted
              between us.
            </p>

            <h4>Best Execution </h4>
            <p>
              The Company owes a duty to clients to obtain best execution of
              their transactions. The Company’s policies are modeled after the
              guidelines articulated by the regulators; specifically, it
              believes that, to a significant degree, best execution is a
              qualitative concept. In deciding what constitutes best execution,
              the determinative factor is not the lowest possible commission
              cost, but whether the transaction represents the best qualitative
              execution. In making this determination, the Company's policy is
              to consider the full range of the services offered, including
              without limitation the value of research provided, execution
              capabilities, commission rate, financial responsibility,
              administrative resources and responsiveness.
              <br />
              All trades are electronic, fully regulated and transparent.
            </p>
            <h4>Execution of trades</h4>
            <p>
              All trades are executed immediately to liquidity banks using
              market execution with no re-quotes, no dealing desk or human
              intervention and little to no trading restrictions. <br /> Apex
              Capital Markets takes into account the following Execution
              Factors:
            </p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>&bull;</span>
                <p>price;</p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>costs payable by the client as a result of execution;</p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>speed of execution;</p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>likelihood of execution;</p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>size and nature of the order;</p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>likely market impact; </p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>risks relevant to the execution; </p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>nature of the market for the Financial Instrument, and; </p>
              </div>
              <div className="bullet">
                <span>&bull;</span>
                <p>size and nature of the order;</p>
              </div>
            </div>

            <h4>Execution Venues</h4>
            <p>
              This Best Execution Policy sets out the venues on where your order
              may transact. We have identified those venues on which most orders
              will be executed, as well as venues that offer the best prospects
              for achieving the best possible results, taking into account the
              factors detailed below.
            </p>
            <p>Trades are conducted via the following execution venues:</p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>I.</span>
                <p>Our liquidity providers;</p>
              </div>
              <div className="bullet">
                <span>II.</span>
                <p>Regulated markets;</p>
              </div>
              <div className="bullet">
                <span>III.</span>
                <p>
                  Where appropriate our customer base in the over the counter
                  (OTC) markets;
                </p>
              </div>
              <div className="bullet">
                <span>IV.</span>
                <p>
                  Multilateral trading facilities operated by a third party;
                </p>
              </div>
              <div className="bullet">
                <span>V.</span>
                <p>Systematic internalizers.</p>
              </div>
            </div>
            <p>
              When selecting the venue on which trades are transacted by clients
              we will take reasonable measures to ensure that the selected venue
              obtains the best possible trading result for our clients, subject
              to the following factors:
            </p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>A.</span>
                <p>
                  In the markets, clients have visibility to prices that have
                  been made available;
                </p>
              </div>
              <div className="bullet">
                <span>B.</span>
                <p>
                  We will provide details of all tradable bids and offers (via
                  the platform and subject to the other matters referred to
                  below);
                </p>
              </div>
              <div className="bullet">
                <span>C.</span>
                <p>
                  Time availability of prices – in many markets there are lulls
                  and spikes in trading as negotiations align trading interests
                  at different times and different parts of the curve,
                  accordingly the current started prices may not always be
                  available or act as a reliable indicator of current price;
                </p>
              </div>
              <div className="bullet">
                <span>D.</span>
                <p>
                  Clients are urged to seek professional advice or obtain the
                  necessary skills for trading in the market, therefore if a
                  client is unable to settle a trade, it is their risk for which
                  we hold no responsibility and;
                </p>
              </div>
              <div className="bullet">
                <span>E.</span>
                <p>
                  Fees may vary between clients, based on agreements and levels
                  of activity.
                </p>
              </div>
            </div>
            <h4>Trade Errors</h4>
            <p>
              A trade error occurs when there is a deviation from the general
              trading practices involving transactions and settlements of trades
              for a client’s account. It has been accepted in the industry to
              recognize the following as trade errors:
            </p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>I.</span>
                <p>When a sell is executed as a buy;</p>
              </div>
              <div className="bullet">
                <span>II.</span>
                <p>
                  The over or under allocation of a security i.e., a misplaced
                  comma in the or an additional 0 is added to a figure (100
                  turns into 1000);
                </p>
              </div>
              <div className="bullet">
                <span>III.</span>
                <p>An incorrect ticket symbol</p>
              </div>
              <div className="bullet">
                <span>IV.</span>
                <p>Trade takes place in an incorrect account number;</p>
              </div>
              <div className="bullet">
                <span>V.</span>
                <p>A purchase or sale order fails to be executed</p>
              </div>
              <div className="bullet">
                <span>VI.</span>
                <p>Limit order is executed at market price;</p>
              </div>
              <div className="bullet">
                <span>VII.</span>
                <p>Block trades are allocated inaccurately;</p>
              </div>
              <div className="bullet">
                <span>VIII.</span>
                <p>
                  Client account does not have the funds to settle the
                  transaction;
                </p>
              </div>
              <div className="bullet">
                <span>IX.</span>
                <p>
                  The purchase or sale of securities is transacted in violation
                  of the client’s investment profile or guidelines;
                </p>
              </div>
              <div className="bullet">
                <span>X.</span>
                <p>
                  The purchase or sale of securities for non-discretionary
                  clients are executed prior to or without receiving client
                  consent, or without proper documented authorization.
                </p>
              </div>
            </div>
            <p>
              The following types of errors will not be deemed to be a trade
              error:
            </p>
            <div className="terms-bullets">
              <div className="bullet">
                <span>A.</span>
                <p>
                  An incorrect trade that was caught prior to settlement thereby
                  not having a negative impact on your client;
                </p>
              </div>
              <div className="bullet">
                <span>B.</span>
                <p>A trade that was improperly documented;</p>
              </div>
              <div className="bullet">
                <span>C.</span>
                <p>
                  The rewriting of tickets that describe or correct improperly
                  executed transactions;
                </p>
              </div>
              <div className="bullet">
                <span>D.</span>
                <p>
                  Errors that are made by unaffiliated third parties
                  (broker/dealer, custodian, etc.). Although keep in mind, you
                  are responsible to review the trades and ensure that third
                  party errors are favorably resolved;
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OrderExecutionPolicy;
