import { api } from "./index";
export const depositWireTransfer = async (data) => {
  return await api
    .post("wire-transfer/deposit", {
      json: data,
    })
    .json();
};

export const withdrawWireTransfer = async (data) => {
  return await api
    .post("wire-transfer/withdraw", {
      json: data,
    })
    .json();
};

export const getWireTransfers = async () => {
  return await api.get("wire-transfer/").json();
};

export const getFee = async () => {
  return await api.get("wire-transfer/get-fee").json();
};

export const getWireTransferWithdrawalRequests = async () => {
  return await api.get("wire-transfer/withdrawal-requests").json();
};
export const getWireTransferUrl = (wireTransfer) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/wire-transfer/${wireTransfer.ID}`;
  return url;
};

export const updateWireTransfer = async (data, id) => {
  return await api
    .put(`wire-transfer/${id}`, {
      json: data,
    })
    .json();
};

export const updateWireTransferWithdrawalRequest = async (data, id) => {
  return await api
    .put(`wire-transfer/withdrawal-requests/${id}`, {
      json: data,
    })
    .json();
};
