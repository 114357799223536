import React, { useState } from "react";
import AppBar from "../components/AppBar";
import FaqDropdown from "../components/FaqDropdown";
import {
  faqsdata,
  genfaqsdata,
  kycfaqsdata,
  depositfaqsdata,
  withdrawalfaqsdata,
  ordersfaqsdata,
  rulesfaqsdata,
  metatraderfaqsdata,
  affiliatefaqsdata,
  payoutfaqsdata,
  marketingfaqsdata,
  additionalfaqsdata,
} from "../components/FaqData";

import "../components/FaqSelector.css";

const Faq = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  // state for each category
  const [newto, setnewto] = useState(true);
  const [general, setgeneral] = useState(false);
  const [kyc, setkyc] = useState(false);
  const [deposit, setdeposit] = useState(false);
  const [withdrawal, setwithdrawal] = useState(false);
  const [orders, setorders] = useState(false);
  const [rules, setrules] = useState(false);
  const [metatrader, setmetatrader] = useState(false);
  const [affiliate, setaffiliate] = useState(false);
  const [payout, setpayout] = useState(false);
  const [marketing, setmarketing] = useState(false);
  const [additional, setadditional] = useState(false);

  // window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  const [faqs, setfaqs] = useState(faqsdata);
  // general questions
  const [genfaqs, setGenFaqs] = useState(genfaqsdata);

  // KYC Verification Questions
  const [kycfaqs, setKycFaqs] = useState(kycfaqsdata);

  // DEPOSIT Verification Questions
  const [depositfaqs, setdepositFaqs] = useState(depositfaqsdata);

  // WITHDRAWAL  FAQ
  const [withdrawalfaqs, setwithdrawalfaqs] = useState(withdrawalfaqsdata);
  // Orders FAQ
  const [ordersfaqs, setordersfaqs] = useState(ordersfaqsdata);

  // RULES FAQ
  const [rulesfaqs, setrulesfaqs] = useState(rulesfaqsdata);
  // Matatrader FAQ
  const [metafaqs, setmetafaqs] = useState(metatraderfaqsdata);
  // Affiliate FAQ
  const [affiliatefaqs, setaffiliatefaqs] = useState(affiliatefaqsdata);

  // Payout FAQ
  const [payoutfaqs, setpayoutfaqs] = useState(payoutfaqsdata);

  // marketing FAQ
  const [marketingfaqs, setmarketingfaqs] = useState(marketingfaqsdata);

  // additional FAQ
  const [additionalfaqs, setadditionalfaqs] = useState(additionalfaqsdata);

  const toggleFAQ = (index) => {
    setfaqs(
      faqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleGenFAQ = (index) => {
    setGenFaqs(
      genfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleKycFAQ = (index) => {
    setKycFaqs(
      kycfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleDepositFAQ = (index) => {
    setdepositFaqs(
      depositfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleWithdrawFAQ = (index) => {
    setwithdrawalfaqs(
      withdrawalfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleOrdersFAQ = (index) => {
    setordersfaqs(
      ordersfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleRulesFAQ = (index) => {
    setrulesfaqs(
      rulesfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleMetaFAQ = (index) => {
    setmetafaqs(
      metafaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleAffiliateFAQ = (index) => {
    setaffiliatefaqs(
      affiliatefaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const togglePayoutFAQ = (index) => {
    setpayoutfaqs(
      payoutfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleMarketingFAQ = (index) => {
    setmarketingfaqs(
      marketingfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  const toggleAdditionalFAQ = (index) => {
    setadditionalfaqs(
      additionalfaqs.map((faq, i) => {
        if (i === index) {
          faq.open = !faq.open;
        } else {
          // close
          faq.open = false;
        }

        return faq;
      })
    );
  };
  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Frequently Asked Questions"></AppBar>
        {/* <div className="faq-search">
          <input type="text" placeholder="Search for help" />
          <button>Search</button>
        </div> */}
        <div className="web-faq-container">
          {/* --------------FaqSelector--------------------- */}
          <div class="fs-wrapper faq-selector">
            <input
              type="radio"
              name="select"
              id="option-1"
              checked={newto && true}
              onClick={() => {
                setnewto(true);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-2"
              onClick={() => {
                setnewto(false);
                setgeneral(true);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-3"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(true);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-4"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(true);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-5"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(true);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-6"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(true);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <label
              for="option-1"
              className={newto ? "option option-1 selected" : "option option-1"}
            >
              <span className={newto && "selected"}>New to Apex Markets?</span>
            </label>
            <div className="line"></div>
            <label
              for="option-2"
              className={
                general ? "option option-2 selected" : "option option-2"
              }
            >
              <span className={general && "selected"}>General</span>
            </label>
            <div className="line"></div>
            <label
              for="option-3"
              className={kyc ? "option option-3 selected" : "option option-3"}
            >
              <span className={kyc && "selected"}>KYC Verification</span>
            </label>
            <div className="line"></div>
            <label
              for="option-4"
              className={
                deposit ? "option option-4 selected" : "option option-4"
              }
            >
              <span className={deposit && "selected"}>Deposit</span>
            </label>
            <div className="line"></div>
            <label
              for="option-5"
              className={
                withdrawal ? "option option-5 selected" : "option option-5"
              }
            >
              <span className={withdrawal && "selected"}>Withdrawal</span>
            </label>
            <div className="line"></div>
            <label
              for="option-6"
              className={
                orders ? "option option-6 selected" : "option option-6"
              }
            >
              <span className={orders && "selected"}>Orders & Billing</span>
            </label>
          </div>
          {/* -------------seperator ---------------*/}
          <div class="fs-wrapper faq-selector">
            <input
              type="radio"
              name="select"
              id="option-7"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(true);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-8"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(true);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-9"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(true);
                setpayout(false);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-10"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(true);
                setmarketing(false);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-11"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(true);
                setadditional(false);
              }}
            />
            <input
              type="radio"
              name="select"
              id="option-12"
              onClick={() => {
                setnewto(false);
                setgeneral(false);
                setkyc(false);
                setdeposit(false);
                setwithdrawal(false);
                setorders(false);
                setrules(false);
                setmetatrader(false);
                setaffiliate(false);
                setpayout(false);
                setmarketing(false);
                setadditional(true);
              }}
            />
            <label
              for="option-7"
              className={rules ? "option option-7 selected" : "option option-7"}
            >
              <span className={rules && "selected"}>Rules & Guidelines</span>
            </label>
            <div className="line"></div>
            <label
              for="option-8"
              className={
                metatrader ? "option option-8 selected" : "option option-8"
              }
            >
              <span className={metatrader && "selected"}>MetaTrader (MT5)</span>
            </label>
            <div className="line"></div>
            <label
              for="option-9"
              className={
                affiliate ? "option option-9 selected" : "option option-9"
              }
            >
              <span className={affiliate && "selected"}>Affiliate</span>
            </label>
            <div className="line"></div>
            <label
              for="option-10"
              className={
                payout ? "option option-10 selected" : "option option-10"
              }
            >
              <span className={payout && "selected"}>Affiliate Payout</span>
            </label>
            <div className="line"></div>
            <label
              for="option-11"
              className={
                marketing ? "option option-11 selected" : "option option-11"
              }
            >
              <span className={marketing && "selected"}>Marketing</span>
            </label>
            <div className="line"></div>
            <label
              for="option-12"
              className={
                additional ? "option option-12 selected" : "option option-12"
              }
            >
              <span className={additional && "selected"}>Additional</span>
            </label>
          </div>
          {/* --------------FaqSelector--------------------- */}

          <div className="faq-container">
            {newto && (
              <div className="faqs">
                {faqs.map((faq, i) => (
                  <FaqDropdown faq={faq} index={i} toggleFAQ={toggleFAQ} />
                ))}
              </div>
            )}
            {general && (
              <div className="faqs">
                {genfaqs.map((faq, i) => (
                  <FaqDropdown faq={faq} index={i} toggleFAQ={toggleGenFAQ} />
                ))}
              </div>
            )}

            {kyc && (
              <div className="faqs">
                {kycfaqs.map((faq, i) => (
                  <FaqDropdown faq={faq} index={i} toggleFAQ={toggleKycFAQ} />
                ))}
              </div>
            )}

            {deposit && (
              <div className="faqs">
                {depositfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleDepositFAQ}
                  />
                ))}
              </div>
            )}
            {withdrawal && (
              <div className="faqs">
                {withdrawalfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleWithdrawFAQ}
                  />
                ))}
              </div>
            )}

            {orders && (
              <div className="faqs">
                {ordersfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleOrdersFAQ}
                  />
                ))}
              </div>
            )}

            {rules && (
              <div className="faqs">
                {rulesfaqs.map((faq, i) => (
                  <FaqDropdown faq={faq} index={i} toggleFAQ={toggleRulesFAQ} />
                ))}
              </div>
            )}

            {metatrader && (
              <div className="faqs">
                {metafaqs.map((faq, i) => (
                  <FaqDropdown faq={faq} index={i} toggleFAQ={toggleMetaFAQ} />
                ))}
              </div>
            )}
            {affiliate && (
              <div className="faqs">
                {affiliatefaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleAffiliateFAQ}
                  />
                ))}
              </div>
            )}

            {payout && (
              <div className="faqs">
                {payoutfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={togglePayoutFAQ}
                  />
                ))}
              </div>
            )}

            {marketing && (
              <div className="faqs">
                {marketingfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleMarketingFAQ}
                  />
                ))}
              </div>
            )}
            {additional && (
              <div className="faqs">
                {additionalfaqs.map((faq, i) => (
                  <FaqDropdown
                    faq={faq}
                    index={i}
                    toggleFAQ={toggleAdditionalFAQ}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Faq;
