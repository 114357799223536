import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import moment from "moment";

// css
// HistoryTable.css

const AdminLedgerTable = ({
  refresh,
  data,
  colNames,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {

  const getStatus = (row) => {
    switch (row?.Trans_Type_ID) {
      case 'TRANSFER_TO_TRADING':
        return '';
      case 'TRANSFER_FROM_TRADING':
        return '';
      case 'WIRE_TRANSFER_WITHDRAWAL':
        return row?.Wallet?.Customer?.wire_transfer_requests?.[0]?.Status ? row?.Wallet?.Customer?.wire_transfer_requests?.[0]?.Status : '';
      case 'WIRE_TRANSFER_DEPOSIT':
        return row?.Wallet?.Customer?.wire_transfer_details?.[0]?.Status ? row?.Wallet?.Customer?.wire_transfer_details?.[0]?.Status : '';
      case 'CARD_WITHDRAWAL':
        return row?.Wallet?.Customer?.card_charges?.[0]?.Status ? row?.Wallet?.Customer?.card_charges?.[0]?.Status : '';
      case 'CARD_DEPOSIT':
        return row?.Wallet?.Customer?.card_charges?.[0]?.Status ? row?.Wallet?.Customer?.card_charges?.[0]?.Status : '';
      case 'CRYPTO_WITHDRAWAL':
        return row?.Wallet?.Customer?.crypto_withdrawal_requests?.[0]?.Status ? row?.Wallet?.Customer?.crypto_withdrawal_requests?.[0]?.Status : '';
      case 'M2PAY_DEPOSIT':
        return row?.Wallet?.Customer?.m2p_deposits?.[0]?.Status ? row?.Wallet?.Customer?.m2p_deposits?.[0]?.Status : '';
      default:
        return '';
    }
  }

  const getDateProcessed = (row) => {
    switch (row?.Trans_Type_ID) {
      case 'TRANSFER_TO_TRADING':
        return row?.transfers?.[0]?.Date_Created ? moment(row?.transfers?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A"):'';
      case 'TRANSFER_FROM_TRADING':
        return row?.transfers?.[0]?.Date_Created ? moment(row?.transfers?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A"):'';
      case 'WIRE_TRANSFER_WITHDRAWAL':
        return row?.Wallet?.Customer?.wire_transfer_requests?.[0]?.DateCreated ? moment(row?.Wallet?.Customer?.wire_transfer_requests?.[0]?.DateCreated).format("MMM D, YYYY hh:mm:ss A") : '';
      case 'WIRE_TRANSFER_DEPOSIT':
        return row?.Wallet?.Customer?.wire_transfer_details?.[0]?.Date_Created ? moment(row?.Wallet?.Customer?.wire_transfer_details?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A") : '';
      case 'CARD_WITHDRAWAL':
        return row?.Wallet?.Customer?.card_charges?.[0]?.Date_Created ? moment(row?.Wallet?.Customer?.card_charges?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A") : '';
      case 'CARD_DEPOSIT':
        return row?.Wallet?.Customer?.card_charges?.[0]?.Date_Created ? moment(row?.Wallet?.Customer?.card_charges?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A") : '';
      case 'CRYPTO_WITHDRAWAL':
        return row?.Wallet?.Customer?.crypto_withdrawal_requests?.[0]?.DateCreated ? moment(row?.Wallet?.Customer?.crypto_withdrawal_requests?.[0]?.DateCreated).format("MMM D, YYYY hh:mm:ss A") : '';
      case 'M2PAY_DEPOSIT':
        return row?.Wallet?.Customer?.m2p_deposits?.[0]?.Date_Created ? moment(row?.Wallet?.Customer?.m2p_deposits?.[0]?.Date_Created).format("MMM D, YYYY hh:mm:ss A") : '';
      default:
        return '';
    }
  }

  //const [showManageModal, setShowManageModal] = useState(false);

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={'head-1'}>
            <th>Transaction #</th>
            <th>Date Requested</th>
            <th>Client #</th>
            <th>Name</th>
            <th>Method From</th>
            <th>Method To</th>
            <th>Amount (USD)</th>
            {/* <th>Date Processed</th> */}
            {/* <th>Processing Time</th> */}
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            row.Fee = !row?.Fee ? 0.00 : row?.Fee;
            return (
              <tr key={'data-' + i}>
                <td>{row?.WT_ID}</td>
                <td>{moment(row?.Transaction_Date).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{row?.Wallet?.Customer?.Customer_ID}</td>
                <td>{`${row?.Wallet?.Customer?.Firstname} ${row?.Wallet?.Customer?.Lastname}`}</td>
                <td>{row?.From}</td>
                <td>{row?.To}</td>
                <td>{row?.Amount}</td>
           
                <td>
                  {true && (
                    <button
                      disabled={true}
                      className="plain"
                      onClick={() => {
                        //setShowManageModal(true);
                      }}
                    >
                      View
                    </button>
                  )}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminLedgerTable;
