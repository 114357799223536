import React from "react";

// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import AdminTable from "../../components/admin/AdminTable";
import { data, colNames } from "../../components/admin/AffiliateListData";
import {
  rank_data,
  rank_colNames,
} from "../../components/admin/AffiliateRankData";
import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";

const AffiliateMetrics = () => {
  return (
    <div className="dashboard-page">
      <AdminBar title="Affiliate Metrics"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Invites"
              img={adminboxblue}
              data="120"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Joined"
              img={adminboxorange}
              data="120"
              info="Last 24 Hours -0.21%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Best Affiliate"
              img={adminboxblack}
              data="1000"
              info="Adrito Rafsan"
              info2="By"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Given Amount"
              img={adminboxgreen}
              data="$20,000.00"
              info="Last 24 Hours +3.00%"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Affiliate List</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={data}
              colNames={colNames}
              color="blue"
            ></AdminTable>
            <button>Load More</button>
          </div>
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Top Affiliate Rank</div>
              <div className="os-right">
                <TableSearch classprop="long"></TableSearch>
                <TableFilter></TableFilter>
                <AdminFilter></AdminFilter>
              </div>
            </div>
            <AdminTable
              data={rank_data}
              colNames={rank_colNames}
              color="blue"
            ></AdminTable>
            <button>Load More</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateMetrics;
