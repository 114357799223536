import React, { useEffect, useRef } from "react";

import VerificationInput from "react-verification-input";
import styled from "styled-components";
// import { Link } from "react-router-dom";

// icons
import deleteIcon from "../images/icons/delete.svg";
import eye from "../images/icons/eye.svg";
// hide password
import hidepw from "../images/icons/hidepw.svg";

// css
import "./AccountSettings.css";

const ChangePasswordModal = ({ closeModal }) => {
  // useEffect(() => {
  //   document.addEventListener("click", handleClickOutside, true);
  // });

  // const handleClickOutside = (e) => {
  //   if (!refOne.current.contains(e.target)) {
  //     closeModal(false);
  //   }
  // };
  // const refOne = useRef(null);

  const ModalContainer = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.05);
    z-index: 500;
    border-radius: 20px;
  `;

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container personal-info-form">
            <div className="cpm-top">
              <h2>Change Password</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="form-label-warning">
              <label htmlFor="fullname">Old Password</label>
              <p>Forgot Password?</p>
            </div>
            <div className="hide-pw">
              <input type="text" id="" name="" placeholder="" />
              <img src={eye} alt="" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="">New Password</label>
            </div>
            <div className="hide-pw">
              <input type="text" id="" name="" placeholder="" />
              <img src={eye} alt="" />
            </div>
            <div className="form-label-warning">
              <label htmlFor="">Confirm New Password</label>
            </div>
            <div className="hide-pw">
              <input type="text" id="" name="" placeholder="" />
              <img src={eye} alt="" />
            </div>
            <div className="cpm-info">
              To secure your account, Enter the 6 digit code we sent to
              **********8054/ar**********@gmail.com. <span> Resend Code</span>
            </div>
            <div className="verification-input">
              <VerificationInput
                removeDefaultStyles
                classNames={{
                  container: "code",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
                onChange={console.log}
                inputField={{
                  onChange: (e) => {
                    console.log(e.nativeEvent.target.value);
                  },
                }}
                length={6}
                validChars="0-9"
                placeholder=""
              />
            </div>
            <button>Confirm</button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChangePasswordModal;
