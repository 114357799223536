import React, { useState } from "react";
import DollarSign from "../../images/icons/dollar-sign.svg";
import { DebounceInput } from "react-debounce-input";

const PriceFilter = ({ classprop = null, setValue, value, placeholder = "Enter value" }) => {
    return (
        <div className={`table-search ${classprop}`}>
            <div className="ts-icon">
                <img src={DollarSign} alt="" />
            </div>
            <DebounceInput
                type="text"
                debounceTimeout={300}
                placeholder={placeholder}
                onChange={(e) => setValue(e.target.value)}
                value={value}
            />
        </div>
    );
};

export default PriceFilter;