import React, { useState } from "react";
import moment from "moment";
// css
// HistoryTable.css

const FailedAuditTable = ({
  refresh,
  data,
}) => {

  const getStatus = (isResolved) => {
    switch (isResolved) {
      case 0:
        return 'New';
      case 1:
        return 'Resolved';
      case 2:
        return 'Unresolved';
      default:
        return 'Unknown';
    }
  }

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <th>ID</th>
          <th>Customer ID</th>
          <th>Email</th>
          <th>Date Added</th>
          <th>Status</th>
          <th>Last Login Date</th>
          <th>Failed Automated Fix On</th>
        </thead>
        <tbody>
          {data.map((row) => {
            return (
              <tr key={row?.ID}>
                <td>{row?.ID}</td>
                <td>{row?.Customer_ID}</td>
                <td>{row?.Email}</td>
                <td>{moment(row?.Date_Created).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{(getStatus(row?.Resolved)).toUpperCase()}</td>
                <td>{moment(row?.Last_Login_Date).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{row?.Failed_Automated_Fix_On_Transaction}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default FailedAuditTable;
