import { api } from "./index";
import { clean } from "../util";

export const addPendingCharge = async (
  amount,
  firstSixDigitsOfCard,
  lastFourDigitsOfCard,
  expiryDate
) => {
  const response = await api
    .post("card/add-pending-charge", {
      json: {
        amount,
        firstSixDigitsOfCard,
        lastFourDigitsOfCard,
        expiryDate,
      },
    })
    .json();
  return response;
};

export const confirmPendingCharge = async (
  firstSixDigitsOfCard,
  lastFourDigitsOfCard,
  cardChargeId,
  orderNo
) => {
  const response = await api
    .post("card/confirm-pending-charge", {
      json: {
        firstSixDigitsOfCard,
        lastFourDigitsOfCard,
        cardChargeId,
        orderNo,
      },
    })
    .json();
  return response;
};

export const validateCardDetails = async (data) => {
  const response = await api
    .post("card/validate", {
      json: data,
    })
    .json();
  return response;
};

export const getAvailableCards = async (data) => {
  const response = await api.get("card/available-balances").json();
  if (response?.availableCards != null) {
    return response?.availableCards;
  }
  return [];
};

export const withdrawToCard = async (data) => {
  const response = await api
    .post("card/withdraw", {
      json: data,
    })
    .json();
  return response;
};

export const getCardCharges = async (params) => {
  const response = await api
    .get("card/card-charges", { searchParams: clean(params), timeout: false })
    .json();
  if (response != null) {
    return response;
  }
  return null;
};

export const blockCard = async (values) => {
  const response = await api.post("card/block-card", { json: values }).json();
  return response;
};

export const unblockCard = async (id) => {
  const response = await api.post("card/unblock-card/" + id).json();
  return response;
};
