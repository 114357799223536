import React, { useState } from "react";
import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

import { BsChevronDown } from "react-icons/bs";
import down from "../images/down-arrow.svg";
import deleteIcon from "../images/icons/delete.svg";
import { convertPoints } from "../api/reward";
import { toast } from "react-toastify";
import { useEffect } from "react";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 500;
  border-radius: 20px;
`;
const ConvertRewardsPoints = ({
  closeModal,
  points,
  data,
  accountToOptions,
}) => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const [openTransferTo, setOpenTransferTo] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState("Select Account");

  const [accountList, setAccountList] = useState([]);

  const customer = useSelector(selectCurrentCustomer);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const [pointAmount, setPointAmount] = useState(points ? points : 0);
  const convertRewardsPoints = async () => {
    setLoading(true);
    const data = {
      destination: selectedAccount,
      amount: pointAmount,
    };
    const response = await convertPoints(data);
    if (response.success === true) {
      closeModal(false);
      setTimeout(() => {
        setLoading(true);
        window.location.reload();
      }, 1000);
      toast.success("Successfully converted points!");
    }
  };

  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Convert Rewards Points</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="bank-info">
              <p>
                The amount you converted from your rewards points will be added
                on your wallet account/ trading account.
              </p>
            </div>

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Convertable Points</p>
                <span>Available Points- {data.points}</span>
              </div>
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  // onFocus={(e) => (e.target.placeholder = "")}
                  // onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  // placeholder="30"
                  defaultValue={points}
                  // prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt, name) => setPointAmount(valueAmt)}
                />

                {/* <button>USD</button> */}
              </div>
            </div>
            <div class="dropdown-select">
              <p>Transfer Bonus To</p>
              <div className="select">
                <span onClick={() => setOpenTransferTo(!openTransferTo)}>
                  <p>{selectedAccount}</p>
                  <img src={down} alt="" />
                </span>
                {openTransferTo && (
                  <ul>
                    {accountToOptions.map((option) => (
                      <li
                        key={option.value}
                        onClick={(e) => {
                          setOpenTransferTo(false);
                          setSelectedAccount(option.value);
                        }}
                      >
                        {option.text}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <button
              className="verification-continue-btn complete"
              onClick={convertRewardsPoints}
              disabled={loading}
            >
              Continue
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ConvertRewardsPoints;
