import { React, useState, useRef } from "react";
import styled from "styled-components";
import { getDocumentUrl } from "../api/document";
// react-signature-pad
// https://www.npmjs.com/package/react-signature-pad
import SignaturePad from "react-signature-pad";

// react-signature-canvas
// https://github.com/agilgur5/react-signature-canvas
// import SignatureCanvas from "react-signature-canvas";

import deleteIcon from "../images/icons/delete.svg";
import "./SignatureModal.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const KycModal = ({ closeModal, document }) => {
  const [documentUrl, setDocumentUrl] = useState(getDocumentUrl(document));
  const [height, setHeight] = useState();
  const iframeRef = useRef();
  return (
    document != null && (
      <ModalContainer>
        <div className="cpm">
          <div className="signature-modal big">
            <div className="cpm-container">
              <iframe
                src={documentUrl}
                ref={iframeRef}
                height={900}
                width={900}
              />
              <button
                onClick={() => {
                  closeModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    )
  );
};

export default KycModal;
