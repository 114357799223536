import React from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import bluetick from "../images/icons/bluetick.svg";
import copy from "../images/icons/copy.svg";
import copyblack from "../images/icons/copyblack.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const LiveAccSuccess = ({ closeModal, valueAmt, resetIndex }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="delete-card">
              <img src={bluetick} alt="" />
              <p>Congratulations Zesan!</p>
            </div>
            <div className="delete-card-info">
              <p>
                Your Live account has been successfully created.Your trading
                account details is below. also We are sending this details on
                your email <span className="blue">(ari*******@gmail.com)</span>.
              </p>
            </div>
            <div className="grey-box">
              {" "}
              <div className="la-info">
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Platform</p>
                    <span>How to Log In to MetaTrader 5(MT5) ?</span>
                  </div>
                </div>
                <div className="download-mt5-btn">Download MT5</div>
                <div className="la-info-row bank-info-top">
                  <span>Use below details to log In on MT5 Platfrom</span>
                  <div className="la-info-details">
                    <img src={copyblack} alt="" />
                  </div>
                </div>
                <div className="horizontal-line"></div>

                <div className="la-info-row">
                  <p>Login ID:</p>
                  <div className="la-info-details">
                    104032123 <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Password:</p>
                  <div className="la-info-details">
                    Moneywithhoney
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Investor Password:</p>
                  <div className="la-info-details">
                    Moneywithtrade
                    <img src={copy} alt="" />
                  </div>
                </div>
                <div className="la-info-row">
                  <p>Web Platfrom:</p>
                  <div className="la-info-details">
                    apexmarkets.io
                    <img src={copy} alt="" />
                  </div>
                </div>
              </div>
            </div>

            <button
              className="verification-continue-btn complete"
              onClick={() => {
                closeModal(false);
                resetIndex();
              }}
            >
              Go to Live Accounts
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default LiveAccSuccess;
