import React, { useState } from "react";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { BsChevronDown } from "react-icons/bs";
import { BiSearch } from "react-icons/bi";
import Paginator from "./Paginator";
import { BiFilter } from "react-icons/bi";
// HISTORY TABLE CSS
import "./HistoryTable.css";

import download from "../images/icons/download.svg";

const AffiliateHistoryTable = ({ data = null, hover = true, filterParams,
  setFilterParams, setNumRowsTab2, numPagesTab2, currentPageTab2,
  setCurrentPageTab2}) => {
  const columns = [
    { field: "closedDate", header: "Closed Date" },
    { field: "loginId", header: "Login ID" },
    { field: "lotSize", header: "Volume Traded" },
    { field: "commissionEarned", header: "Commission Earned" },
    { field: "status", header: "Status" },
  ];

  const [searchVal, setSearchVal] = useState("");
  const [rows, setRows] = useState(25);

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All");
  const options = [
    "All",
    "Today",
    "Yesterday",
    "This Week",
    "This Month",
    "This Year",
  ];
  const [filter, setFilter] = useState(false);
  const [selectedfilter, setSelectedfilter] = useState("Sort By");
  const filters = [
    "Closed Date",
    "Login ID",
    "Volume Traded",
    "Commission Earned",
    "Status",
  ];
  const [pages, setPages] = React.useState(25);

  const handleChange = (event) => {
    setRows(event.target.value);
    setNumRowsTab2(event.target.value);
  };

  return (
    <>
      <div className="flex-col justify-between gap-2 deposit-page-box-title lg:flex-row">
        <div className="flex items-center">
          <div className="color-box" style={{ background: "#141416" }}></div>
          <span>Affiliate Trading History</span>
        </div>
        <div className="flex flex-col gap-2 mx-auto ml-auto lg:flex-row lg:mx-0">
          <div className="search-filter">
            <div className="flex items-center justify-between w-full df-btn">
              <input
                type="text"
                placeholder="Search"
                className="bg-transparent outline-none"
                value={searchVal}
                onChange={(e) => {
                  setSearchVal(e.target.value);
                  setFilterParams({ ...filterParams, searchTab2: e.target.value != null ? e.target.value : '' })
                }}
              />
              <BiSearch className="ml-auto"></BiSearch>
            </div>
          </div>

          {/* filter */}
          <div className="deposit-filter blue-border">
            <div
              className="df-btn df-btn-center"
              onClick={(e) => {
                setFilter(!filter);
                setIsActive(false);
              }}
            >
              {selectedfilter} <BiFilter className="text-xl"></BiFilter>
            </div>
            {filter && (
              <div className="df-content">
                {filters.map((option) => (
                  <div
                    onClick={(e) => {
                      setSelectedfilter(option);
                      setFilter(false);
                      setFilterParams({ ...filterParams, sortByTab2: option !== 'Sort By' ? option?.replaceAll(" ","_")?.toUpperCase() : '' })
                    }}
                    className="df-item"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
          {/*--------------- Time filter ---------- */}
          <div className="deposit-filter">
            <div
              className="df-btn"
              onClick={(e) => {
                setIsActive(!isActive);
                setFilter(false);
              }}
            >
              {selected}
              <span>
                <BsChevronDown></BsChevronDown>
              </span>
            </div>
            {isActive && (
              <div className="df-content">
                {options.map((option) => (
                  <div
                    data-option={option.data}
                    onClick={(e) => {
                      setSelected(option);
                      setIsActive(false);
                      setFilterParams({ ...filterParams, rangeTab2: option !== 'All' ? option?.replaceAll(" ","_")?.toUpperCase() : '' })
                    }}
                    className="df-item"
                  >
                    {option}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="flex items-center gap-2">
        <p className="font-light">Show</p>
        <Box>
          <FormControl fullWidth>
            <Select
              value={rows}
              onChange={handleChange}
              sx={{ width: 100, height: 40 }}
            >
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Box>
        <p className="font-light">
          entries of <span>500</span>
        </p>
      </div>
      <div className="table-container aff-table">
        <table>
          <thead>
            <tr>
              {columns && columns.map((head) => <th>{head.header}</th>)}{" "}
              {/* <th>Invoice</th> */}
            </tr>
          </thead>
          <tbody>
            {data &&
              data.map((row) => (
                <tr>
                  {columns.map((col) => (
                    <td className={`${row[col.field]}`}>
                      {parseInt(row[col.field]) == 0 ? "0.00":row[col.field]}
                    </td>
                  ))}
                  {/* <td className="pdf-download">
                  <img src={download} alt="" /> <span>PDF</span>
                </td> */}
                </tr>
              ))}
          </tbody>
        </table>
        {data ? null : <p>No data to show </p>}
      </div>
      <Paginator
        totalPages={numPagesTab2}
        currentPage={currentPageTab2}
        onPageChange={(page) => {
          if (page > numPagesTab2) {
            setCurrentPageTab2(numPagesTab2);
          } else if (page < 1) {
            setCurrentPageTab2(1);
          } else {
            setCurrentPageTab2(page);
          }
        }}
      />
    </>
  );
};

export default AffiliateHistoryTable;
