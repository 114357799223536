import React, { useEffect, useState } from "react";

const Translator = () => {
  const [isLanguage, setIsLanguage] = useState();
  function googleTranslateElementInit() {
    if (!window.google || !window.google.translate) return;
    new window.google.translate.TranslateElement(
      {},
      "google_translate_element"
    );
  }

  function onLangChange() {
    var e = this.getElementsByTagName("select")[0];
    var language = e.options[e.selectedIndex].text;
    setIsLanguage(language);
  }
  useEffect(() => {
    const mainElement = document.querySelector(".main");
    if (mainElement && isLanguage) {
      mainElement.style.marginTop = "-40px";
    }
  }, [isLanguage]);

  useEffect(() => {
    var addScript = document.createElement("script");

    addScript.setAttribute(
      "src",
      "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
    );
    addScript.setAttribute("defer", "true");
    document.body.appendChild(addScript);
    window.googleTranslateElementInit = googleTranslateElementInit;

    document
      .getElementById("google_translate_element")
      .addEventListener("change", onLangChange, false);

    // Clean up event listener on component unmount
    return () => {
      document
        .getElementById("google_translate_element")
        .removeEventListener("change", onLangChange, false);
    };
  }, []);

  return <div id="google_translate_element"></div>;
};

export default Translator;
