import React from "react";
import filter from "../../images/icons/filter.svg";

const TableFilter = ({ setOpenFilter, openFilter }) => {
  return (
    <div className="table-filter" onClick={() => setOpenFilter(!openFilter)}>
      <span>Filter</span>
      <div className="ts-icon">
        <img src={filter} alt="" />
      </div>
    </div>
  );
};

export default TableFilter;
