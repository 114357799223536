import React, { useState } from "react";
import AppBar from "../components/AppBar";
import AccountSettingsLinks from "../components/AccountSettingsLinks";
import ChangePasswordModal from "../components/ChangePasswordModal";
import PasswordChangedSuccess from "../components/PasswordChangedSuccess";
import { useEffect } from "react";
import { getCurrentProfile, save2Fa } from "../api/profile";
import { toast } from "react-toastify";
// css
import "../components/AccountSettings.css";

// Material ui switch
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

const Security = ({ showNav, noNav }) => {
  noNav(false);
  showNav(true);
  // Material ui custom switch
  // https://mui.com/material-ui/react-switch/
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const [openModal, setOpenModal] = useState(false);
  const [twoFaEmailEnabled, setTwoFaEmailEnabled] = useState(false);
  const [twoFaPhoneEnabled, setTwoFaPhoneEnabled] = useState(false);

  const [twoFaEmail, setTwoFaEmail] = useState("");
  const [twoFaPhone, setTwoFaPhone] = useState("");

  const save = async () => {
    const obj = {
      twoFaEmailEnabled,
      twoFaPhoneEnabled,
      twoFaEmail,
      twoFaPhone,
    };
    // debugger;
    const result = await save2Fa(obj);
    if (result != null && result.errors == null) {
      toast("Successfully updated", { type: "success" });
    }
  };

  const loadProfileInfo = async () => {
    const customer = await getCurrentProfile();
    if (customer != null) {
      // debugger;
      setTwoFaEmailEnabled(
        customer.TF_Enabled_Email != null ? customer.TF_Enabled_Email : false
      );
      setTwoFaEmail(customer.Email != null ? customer.Email : "");

      setTwoFaPhoneEnabled(
        customer.TF_Enabled_Phone != null ? customer.TF_Enabled_Phone : false
      );
      setTwoFaPhone(
        customer.Telephone_Cell != null ? customer.Telephone_Cell : ""
      );
    }
  };

  useEffect(() => {
    loadProfileInfo();
  }, []);

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Account Settings"></AppBar>

        <div className="dashboard-container">
          {openModal && (
            <ChangePasswordModal
              closeModal={setOpenModal}
            ></ChangePasswordModal>
          )}
          <AccountSettingsLinks></AccountSettingsLinks>
          <div className="security-line"></div>

          {/* --------------Uncommented for now, may be added back in future --------------*/}
          {/*<div className="personal-info-form">
            <div className="form-label-warning">
              <label htmlFor="fullname">Email</label>
              <p>Add/Remove Email</p>
            </div>
            <input
              type="text"
              id="email"
              name="email"
              placeholder="ar**********@gmail.com"
            />

            <div className="form-label-warning">
              <label htmlFor="fullname">Phone Number</label>
              <p>Add/Remove Phone Number</p>
            </div>
            <div className="phone-inputs">
              <input type="" id="phonenumber" name="phonenumber" />
              <input type="" id="phonenumber" name="phonenumber" />
            </div>
          </div>
          <div className="security-line"></div>*/}
          <div className="personal-info-form">
            <div className="two-fa-text">
              <h1>2-step verification</h1>
              <p>
                2-step verification method is valid across all your Aplex
                Markets accounts,We will send security code to your primary
                email and primary number:
              </p>
            </div>

            <div className="form-label-warning">
              <label htmlFor="fullname">Primary Email</label>
              {/* Material ui switch */}
              {/*<AntSwitch
                checked={twoFaEmailEnabled}
                onChange={() => setTwoFaEmailEnabled(!twoFaEmailEnabled)}
                inputProps={{ "aria-label": "ant design" }}
              />*/}
            </div>
            <input
              disabled={true}
              type="text"
              id="primaryemail"
              name="primaryemail"
              value={twoFaEmail}
              onChange={(e) => setTwoFaEmail(e.target.value)}
            />

            <div className="form-label-warning">
              <label htmlFor="fullname">Primary Phone Number</label>
              {/* Material ui switch */}
              {/*<AntSwitch
                inputProps={{ "aria-label": "ant design" }}
                checked={twoFaPhoneEnabled}
                onChange={() => {
                  setTwoFaPhoneEnabled(!twoFaPhoneEnabled);
                }}
              />*/}
            </div>
            <div className="phone-inputs">
              <input
                type=""
                id="phonenumber"
                name="phonenumber"
                value={twoFaPhone}
                onChange={(e) => setTwoFaPhone(e.target.value)}
              />
            </div>
            <button className="personal-info-save-btn complete" onClick={save}>
              Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Security;
