import React from "react";
import styled from "styled-components";

import CurrencyInput from "react-currency-input-field";

import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";
import edit from "../images/icons/green-edit.svg";
import trash from "../images/icons/trashcan.svg";
import visa from "../images/icons/visa.svg";
// import selectempty from "../images/icons/select-empty.svg";
// import filledselect from "../images/icons/filled-select.svg";

import "./CardSelect.css";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const CardSelect = ({ valueAmt, closeModal }) => {
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>Deposit via Credit/Debit Card</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Amount</p>
              </div>
              <div className="amount-input ">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  defaultValue={valueAmt}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt, name) =>
                    console.log(valueAmt, name)
                  }
                />
                <div className="select-currency-btn currency-type">
                  USD<BsChevronDown></BsChevronDown>
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>
            </div>
            <div className="card-title">
              <p>Saved Card</p>
              <span>Add New Card</span>
            </div>
            <div className="user-card">
              {/* checkbox  */}
              <div className="uc-left">
                <input type="checkbox" />
                <span className="label"></span>
                <img src={visa} alt="" />
                <span>4876 - 4546 - **** - 0032</span>
              </div>
              <div className="uc-right">
                <img src={edit} alt="" />
                <img src={trash} alt="" />
              </div>
            </div>
            <div className="user-card">
              <div className="uc-left">
                {/* checkbox  */}
                <input type="checkbox" />
                <span className="label"></span>
                <img src={visa} alt="" />
                <span>4876 - 4546 - **** - 0032</span>
              </div>
              <div className="uc-right">
                <img src={edit} alt="" />
                <img src={trash} alt="" />
              </div>
            </div>
            <button className="verification-continue-btn">Next</button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CardSelect;
