import React, { useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import TableBtn from "../../components/admin/TableBtn";
import AdminDepositTable from "../../components/admin/AdminDepositTable";
import AdminCryptoDepositTable from "../../components/admin/AdminCryptoDepositTable";
import PriceFilter from "../../components/admin/PriceFilter";
import Paginator from "../../components/Paginator";
import FadeLoader from "react-spinners/FadeLoader";
import {
  deposits_data,
  deposits_datacolNames,
} from "../../components/admin/DepositsData";

import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxblue2 from "../../images/icons/adminboxblue2.svg";
import adminboxbluemoney from "../../images/icons/adminboxbluemoney.svg";
import adminboxbluetrophy from "../../images/icons/adminboxbluetrophy.svg";
import adminboxgreentick from "../../images/icons/adminboxgreentick.svg";
import adminboxgreensuccess from "../../images/icons/adminboxgreensuccess.svg";
import adminboxorangex from "../../images/icons/adminboxorangex.svg";
import adminboxblacktransfer from "../../images/icons/adminboxblacktransfer.svg";
import { getWireTransfers } from "../../api/wireTransfer";
import { getDeposits, getDepositsOverview } from "../../api/data";
import { useEffect } from "react";
import { toast } from "react-toastify";

const Deposits = ({ showAdminNav }) => {
  const [deposits, setDeposits] = useState([]);

  const [filterParams, setFilterParams] = useState({
    search: "",
    deposit_method: "",
  });

  const [filterParamsOverview, setFilterParamsOverview] = useState({
    search: "",
    deposit_method: "",
    dateFilter: "This Week"
  });

  const [option, setOption] = useState();
  const [filter, setFilter] = useState();
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const [overview, setOverview] = useState({});

  const [limit, setLimit] = useState(10);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const [currentTab, setCurrentTab] = useState("card");

  /*
  const loadDeposits = async () => {
    const response = await getWireTransfers();
    if (response.errors == null) {
      const wireTransfers = response.wireTransfers;
      setDeposits(wireTransfers);
    }
  };
  */

  const loadDeposits = async () => {
    setLoading(true);
    try {
      const { records, pages } = await getDeposits({
        ...filterParams,
        tab: currentTab,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      if (records != null) {
        setDeposits(records);
        setNumPages(pages > 0 ? pages : 1);
        if (currentPage > pages) {
          setCurrentPage(1);
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load data successfully", {
        type: "error",
      });
      setDeposits([]);
    }
  };

  const loadDepositsOverview = async () => {
    try {
      const { overview } = await getDepositsOverview({
        ...filterParamsOverview,
      });
      if (overview) {
        setOverview(overview)
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load Overview successfully", {
        type: "error",
      });
      setOverview(null);
    }
  }

  useEffect(() => {
    loadDeposits();
    loadDepositsOverview()
  }, [currentPage, filterParams,currentTab, filterParamsOverview]);
  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Deposits"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter
                setOption={(val) => { setFilterParamsOverview({ ...filterParamsOverview, dateFilter: val }); }}
                defaultValue={filterParamsOverview.dateFilter}
                list={["Today", "Yesterday", "This Week", "This Month", "This Year", "All"]}>
              </AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Deposits"
              img={adminboxblue}
              data={`$ ${overview?.totalDeposits?.toFixed(2) || 0}`}
              info={`${overview?.allCount || 0}`}
              info2="Total Deposits:"
              color="green"
            ></OverviewBox>
            {/* <OverviewBox
              title="Deposits Count"
              img={adminboxgreentick}
              data={`${overview?.depositCount || 0}`}
              info={`$ ${overview?.approved?.toFixed(2) || 0}`}
              info2="Total Deposit:"
              color="green"
            ></OverviewBox> */}
            <OverviewBox
              title="Highest Single Deposit"
              img={adminboxbluetrophy}
              data={`$ ${overview?.bestDeposit?.deposit?.toFixed(2) || 0}`}
              info={`${overview?.bestDeposit?.customer?.Firstname || ""} ${overview?.bestDeposit?.customer?.Lastname || ""}`}
              info2="By"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Most Consistent Deposits"
              img={adminboxbluemoney}
              data={`$ ${overview?.mostDeposit?.deposit?.toFixed(2) || 0}`}
              info={`${overview?.mostDeposit?.customer?.Firstname || ""} ${overview?.mostDeposit?.customer?.Lastname || ""}`}
              info2="By"
              color="blue"
            ></OverviewBox>
            <OverviewBox
              title="Total Approved"
              img={adminboxgreensuccess}
              data={`$ ${overview?.approved?.toFixed(2) || 0}`}
              info={`${overview?.depositCount || 0}`}
              info2="Total Approved:"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Pending"
              img={adminboxblacktransfer}
              data={`$ ${overview?.pending?.toFixed(2) || 0}`}
              info={`${overview?.pendingCount || 0}`}
              info2="Total Pending:"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Rejected"
              img={adminboxorangex}
              data={`$ ${overview?.rejected?.toFixed(2) || 0}`}
              info={`${overview?.rejectedCount || 0}`}
              info2="Total Rejected:"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="No Deposit"
              img={adminboxblue2}
              data={`${overview?.notDeposit || 0}`}
              info={`$ ${((overview?.rejected || 0) + (overview?.pending))?.toFixed(2)}`}
              info2="Total:"
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div class="flex gap-3 mb-3 ">
                <div
                  class={` py-2 px-4 text-sm md:text-lg font-medium  leading-4 text-center border-b-2  cursor-pointer  ${currentTab === "card"
                      ? "text-[#008ffd] border-b-[#008ffd] "
                      : "border-b-gray-200 hover:border-b-black text-gray-400"
                    }`}
                  onClick={() => setCurrentTab("card")}
                >
                  Card Deposits
                </div>

                <div
                  class={` py-2 px-4 text-sm md:text-lg leading-4 font-medium  text-center border-b-2  cursor-pointer  ${currentTab === "crypto"
                      ? "text-[#008ffd] border-b-[#008ffd] "
                      : "border-b-gray-200 hover:border-b-black text-gray-400"
                    }`}
                  onClick={() => setCurrentTab("crypto")}
                >
                  Crypto Deposits
                </div>
              </div>
              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val);
                    setFilterParams({ ...filterParams, search: val });
                  }}
                  filter={filter}
                  placeholder="Search by Name or Client #"
                ></TableSearch>

                <AdminFilter
                  classprop="long"
                  name="status"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      status: val !== "ALL" ? val : "",
                    });
                  }}
                  option={option}
                  list={["ALL", "APPROVED", "DECLINED", "REFUNDED"]}
                  defaultValue="ALL"
                ></AdminFilter>

                <div className="flex-container price-seperator">
                  <PriceFilter
                    setValue={(val) => {
                      setMinValue(val);
                      setFilterParams({ ...filterParams, minAmount: val });
                    }}
                    value={minValue}
                    placeholder="Min. Amount"
                  ></PriceFilter>
                  <div> - </div>
                  <PriceFilter
                    setValue={(val) => {
                      setMaxValue(val);
                      setFilterParams({ ...filterParams, maxAmount: val });
                    }}
                    value={maxValue}
                    placeholder="Max. Amount"
                  ></PriceFilter>
                </div>
                {/* 
                <TableBtn text="Add Manual Deposit"></TableBtn>
                <TableFilter></TableFilter>
                */}
              </div>
            </div>
            {loading ? (
              <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            ) : null}
            {!loading && (
              <>
                {currentTab === "crypto" ? (
                  <AdminCryptoDepositTable
                    data={deposits}
                    colNames={deposits_datacolNames}
                    color="green"
                    color2="blue"
                    color3="green"
                    color4="black"
                  ></AdminCryptoDepositTable>

                ) : (
                  <AdminDepositTable
                    data={deposits}
                    colNames={deposits_datacolNames}
                    color="green"
                    color2="blue"
                    color3="green"
                    color4="black"
                    refresh={loadDeposits}
                  ></AdminDepositTable>
                )}

                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deposits;
