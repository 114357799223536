import React, { useEffect, useState } from "react";

const CountrySelector = ({
  selectedCountry,
  setSelectedCountry,
  allCountries,
}) => {
  const [search, setSearch] = useState("");
  const [currentCountry, setCurrentCountry] = useState();
  const [openCountry, setOpenCountry] = useState(false);

  useEffect(() => {
    if (selectedCountry && allCountries?.length) {
      const country = allCountries.filter(
        (country) =>
          country.name?.toLowerCase() == selectedCountry?.toLowerCase()
      );
      if (country.length) {
        setSelectedCountry(country[0]?.name);
      }
    }
  }, [selectedCountry, allCountries]);

  return (
    <div className="mt-8 mb-4 select-container">
      <div className="custom-select relative">
        <p className="text-[24px]">Select your country</p>
        <div className="relative select">
          <span className="px-2" onClick={() => setOpenCountry(!openCountry)}>
            <input
              className="w-[200px]"
              type="text"
              onChange={(e) => {
                setSelectedCountry("");
                setCurrentCountry("");
                setOpenCountry(true);
                setSearch(e.target.value);
              }}
              placeholder={!selectedCountry != null && "Select your country"}
              value={selectedCountry ? selectedCountry : search}
            />
            {!selectedCountry && !currentCountry ? (
              <div
                onClick={() => setOpenCountry(!openCountry)}
                className="text-sm  text-blue-600 cursor-pointer w-full text-end change-doc-btn"
              >
                Select
              </div>
            ) : (
              <div
                onClick={() => setOpenCountry(!openCountry)}
                className="text-sm  text-blue-600 cursor-pointer w-full text-end change-doc-btn"
              >
                Change
              </div>
            )}
          </span>
          {openCountry && (
            <ul>
              {allCountries
                .filter(
                  (country) =>
                    search === "" ||
                    country.name?.toLowerCase().includes(search?.toLowerCase())
                )
                .map((option) => (
                  <div className="flex ">
                    <div className="rounded-full w-5 h-5 mt-[16.5px] object-cover">
                      {option.flag}
                    </div>
                    <li
                      onClick={(e) => {
                        console.log(e.target.textContent);
                        setSelectedCountry(e.target.textContent);
                        setSearch("");
                        setCurrentCountry("");
                        setOpenCountry(false);
                      }}
                    >
                      {option.name}
                    </li>
                  </div>
                ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default CountrySelector;
