export const lossdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "-2.54%",
  },
];

export const losscolNames = ["No", "Login Id", "Name", "Loss (USD)", "Loss"];
