import _ from "lodash";
import { useState } from "react";
import ManageChargeBackModal from "./ManageChargeBackModal";
import ManageBlockCardModal from "../../ManageBlockCardModal";
import ManageUnblockCardModal from "../../ManageUnblockCardModal";

import KycEditFormModal from "../../KycEditFormModal";
import { toast } from "react-toastify";

import { updateStatus } from "../../../api/customer";
import { formatCardNumber } from "../../../util";

import moment from "moment";

const CustomerChargeBackTable = ({ showManageModal, setShowManageModal, refresh, data }) => {
  
  const [showManageBlockCardModal, setShowManageBlockCardModal] = useState(false);
  const [showManageUnblockCardModal, setShowManageUnblockCardModal] = useState(false);
  const [chargeBackData, setChargeBackData] = useState();

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={"head-1"}>
            <th>Customer</th>
            <th>Email</th>
            <th>Date</th>
            <th>Last 4 Digits</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={"data-" + i}>
                <td>{`${row?.customer?.Firstname} ${row?.customer?.Lastname}`}</td>
                <td>{row?.customer?.Email}</td>
                <td>{moment(row?.Date_Created).format("YYYY-MM-DD")}</td>
                <td>{formatCardNumber(row?.FirstSixDigitsOfCard, row?.LastFourDigitsOfCard)}</td>
                <td>USD${`${row?.Amount}`}</td>
                <td>{`${row?.Status}`}</td>
                <td>
                  <span>
                    <button
                      className="plain"
                      onClick={() => {
                        setChargeBackData(row);
                        setShowManageModal(true);
                      }}
                    >
                      Edit Document
                    </button>
                  </span>

                  {row?.blocked.length === 0 && row?.LastFourDigitsOfCard &&(
                    <span>
                      <button
                        className="plain"
                        onClick={() => {
                          setChargeBackData(row);
                          setShowManageBlockCardModal(true);
                        }}
                      >
                        Block Card
                      </button>
                    </span>
                  )}
                  {row?.blocked.length > 0 && (
                    <span>
                      <button
                        className="plain"
                        onClick={() => {
                          setChargeBackData(row);
                          setShowManageUnblockCardModal(true);
                        }}
                      >
                        Unblock Card
                      </button>
                    </span>
                  )}
                </td>
              </tr>
            );
          })}
          {showManageModal && (
            <>
              <ManageChargeBackModal
                closeModal={setShowManageModal}
                chargeBackData={chargeBackData}
                refresh={refresh}
              />
            </>
          )}
          {showManageBlockCardModal && (
            <>
              <ManageBlockCardModal
                closeModal={setShowManageBlockCardModal}
                chargeBackData={chargeBackData}
                refresh={refresh}
              />
            </>
          )}
          {showManageUnblockCardModal && (
            <>
              <ManageUnblockCardModal
                closeModal={setShowManageUnblockCardModal}
                chargeBackData={chargeBackData}
                refresh={refresh}
              />
            </>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default CustomerChargeBackTable;
