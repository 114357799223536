import { api } from "./index";
export const getConversionData = async () => {
  const response = await api.get("reward").json();
  return response;
};

export const convertPoints = async (data) => {
  const response = await api
    .put("reward", {
      json: data,
    })
    .json();
  return response;
};
