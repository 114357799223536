import React from "react";

// HISTORY TABLE CSS
import "./HistoryTable.css";

import moment from "moment";

const DepositHistoryTable = ({ data = null, hover = true, striped = true }) => {
  const columns = [
    { field: "date", header: "Date" },
    { field: "depositmethod", header: "Deposit Method" },
    { field: "amount", header: "Amount" },
  ];

  return (
    <div className="table-container">
      <table>
        <thead>
          <tr>{columns && columns.map((head) => <th>{head.header}</th>)}</tr>
        </thead>
        <tbody>
          {data &&
            data.map((row, index) => (
              <tr key={index}>
                <td>
                  {row?.Date_Created
                    ? moment(row.Date_Created).format("DD/MM/YY")
                    : "Unknown"}
                </td>
                <td>{row.depositMethod}</td>
                <td>${row.Amount}</td>
                <td>{row.Status}</td>
                {/* <td className="pdf-download">
                  <img src={download} alt="" /> <span>PDF</span>
                </td> */}
              </tr>
            ))}
        </tbody>
      </table>
      {data ? null : <p>No data to show </p>}
    </div>
  );
};

export default DepositHistoryTable;
