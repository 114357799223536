import React, { useState } from "react";
import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";

import { BsChevronDown } from "react-icons/bs";
import down from "../images/down-arrow.svg";
import deleteIcon from "../images/icons/delete.svg";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 500;
  border-radius: 20px;
`;
const TransferAffiliateEarnings = ({ closeModal }) => {
  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  const [openTransferTo, setOpenTransferTo] = useState(false);
  const [selectedToAccount, setSelectedToAccount] = useState("Select Account");
  const accountToOptions = [
    "Trading AC(#47539)",
    "Trading AC(#47539)",
    "Trading AC(#47539)",
  ];
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2>Transfer Affiliate Earnings</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div class="dropdown-select">
              <p>Transfer To</p>
              <div className="select">
                <span onClick={() => setOpenTransferTo(!openTransferTo)}>
                  <p>{selectedToAccount}</p>
                  <img src={down} alt="" />
                </span>
                {openTransferTo && (
                  <ul>
                    {accountToOptions.map((option) => (
                      <li
                        onClick={(e) => {
                          setSelectedToAccount(e.target.textContent);
                          setOpenTransferTo(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <div className="enter-amount">
              <div className="enter-amt-top-row">
                <p>Enter Amount</p>
                <span>Affiliate Earnings: $10.00</span>
              </div>
              <div className="amount-input">
                {/* <input type="text" placeholder="$0.00" /> */}
                <CurrencyInput
                  onFocus={(e) => (e.target.placeholder = "")}
                  onBlur={(e) => (e.target.placeholder = "$0.00")}
                  id="input-example"
                  name="input-name"
                  placeholder="$0.00"
                  // defaultValue={valueAmt}
                  prefix="$"
                  decimalsLimit={2}
                  onValueChange={(valueAmt, name) =>
                    console.log(valueAmt, name)
                  }
                />
                <div className="select-currency-btn currency-type">
                  USD<BsChevronDown></BsChevronDown>
                  <div className="sc-content"></div>
                </div>
                {/* <button>USD</button> */}
              </div>
              {/* <div className="amt-percentages">
                <div className="percentages">
                  <span className="green">25%</span>
                  <span className="green">50%</span>
                  <span className="green">75%</span>
                  <span className="green">100%</span>
                </div>
              </div> */}
            </div>
            {/* <div className="df-save-card">
              <input type="checkbox" />
              <span className="label"></span>
              <p>
                I have read and agree with Apex Market’s{" "}
                <span className="blue">User Agreement</span> and{" "}
                <span className="blue">Privacy Policy</span>.
              </p>
            </div> */}

            <button className="verification-continue-btn">Continue</button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default TransferAffiliateEarnings;
