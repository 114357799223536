import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import moment from "moment";

// css
// HistoryTable.css

const AdminDepositTable = ({
  refresh,
  data,
  colNames,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {
  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <tr key={'head-1'}>
            <th>#</th>
            <th>Order ID</th>
            <th>Date Requested</th>
            <th>Client #</th>
            <th>Name</th>
            <th>Amount (USD)</th>
            <th>Date Processed</th>
            <th>Status</th>
        
          </tr>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={'data-' + i}>
                <td>{i + 1}</td>
                <td>{row?.PaymentProcessorOrderNo}</td>
                <td>{moment(row.Date_Created).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{row?.Customer_ID}</td>
                <td>{`${row?.customer?.Firstname} ${row?.customer?.Lastname}`}</td>
                <td>{row.Amount}</td>
                <td>{moment(row.Date_Created).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{row.Status}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AdminDepositTable;
