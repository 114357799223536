import { useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { FadeLoader } from "react-spinners";
import { useState } from "react";
const OverlayLoader = ({ loading }) => {
  let [color, setColor] = useState("#008ffd");
  useEffect(() => {
    if (loading) {
      window.scrollTo(0, 0);
    }
  }, [loading]);
  if (!loading) {
    return <></>;
  } else {
    return (
      <div
        style={{
          width: "100%",
          height: "100%",
          zIndex: 10000000,
          backgroundColor: "white",
          display: "block",
          position: "absolute",
          top: 0,
          left: 0,
        }}
      >
        <div
          style={{
            display: "block",
            margin: "0px auto",
            position: "absolute",
            width: "200px",
            left: 0,
            right: 0,
            top: 300,
            bottom: 0,
            height: "200px",
          }}
        >
          <FadeLoader color={color} size={80} />
          <br />
        </div>
      </div>
    );
  }
};

export default OverlayLoader;
