export const affiliaterankdata = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    introduced: "#1234567",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    introduced: "#1234567",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    introduced: "#1234567",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    introduced: "#1234567",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    introduced: "#1234567",
  },
];

export const affiliatecolNames = [
  "No",
  "Client Id",
  "Name",
  "Earned (USD)",
  "Introduced Client Id",
];
