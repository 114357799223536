import React, { useState, useRef, useEffect } from "react";
import upload from "../../images/icons/upload.svg";
import "../../pages/Verification.css";
import DocumentPreviewModal from "./DocumentPreviewModal";
import checkmark from "../../images/icons/greencheckmark.svg";
import deleteIcon from "../../images/icons/delete.svg";
import { useStore } from "../../store";
import DocumentUploadBox from "./DocumentUploadBox";
import { azureUploadImage, base64ExtractInformation } from "../../api/document";
import { toast } from "react-toastify";
import CustomQRCode from "../QRCodeGenerator";

const DocumentUpload = ({
  selectedDocument,
  frontPreview,
  setFrontPreview,
  backPreview,
  setBackPreview,
  setProcessing,
  customer,
  documentToResubmit,
  typeOfDocumentToResubmit,
  isMobile,
  documentExtracted,
  setDocumentExtracted,
  submitSingleDoc,
  deleteUploadedDocument,
  documentUploadedId,
}) => {
  const [open, setOpen] = useState(false);
  const baseURL = process.env.REACT_APP_WEBSITE_URL;
  const emailPrefix = customer?.Email?.split("@")[0];
  let { token, Firstname, Lastname } = customer;
  Firstname = Firstname ? Firstname.toLowerCase() : "";
  Lastname = Lastname ? Lastname.toLowerCase() : "";

  const resubmitParam = typeOfDocumentToResubmit
    ? `resubmit=${documentToResubmit.id}`
    : "";

  const QRValue = `${baseURL}/kyc-verification?${resubmitParam}&type=${selectedDocument}&email=${emailPrefix}&Firstname=${Firstname}&Lastname=${Lastname}&token=${token}`;

  const modalRef = useRef(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { setDocFront, setDocBack, docFront, docBack } = useStore();

  const isOnlyLetters = (str) => {
    return /^[a-zA-Z]*$/.test(str);
  };

  const hasSpecialCharacters = (str) => {
    return /[^a-zA-Z0-9]/.test(str);
  };

  const checkFields = (response, field, matchFull) => {
    let matchedFields = {};
    let documentNumber = "";

    for (let i = 0; i < response.length; i++) {
      const item = response[i];
      if (item.fields) {
        Object.entries(item.fields).forEach(([key, value]) => {
          if (field) {
            if (
              matchFull
                ? key.toLowerCase() === field
                : key.toLowerCase().includes(field)
            ) {
              matchedFields[field] = value.value;
            }
          } else {
            if (!isOnlyLetters(value.value) && value.value.length >= 5) {
              if (!hasSpecialCharacters(value.value)) {
                documentNumber = value.value;
              }
            }
          }
        });

        if (Object.keys(matchedFields).length !== 0) break;
      }
    }

    return field ? matchedFields : documentNumber;
  };

  useEffect(() => {
    const extractInformation = async () => {
      if (docFront && (selectedDocument === "Passport" || docBack)) {
        if (documentExtracted) return;

        setProcessing(true);
        const docsToUpload =
          selectedDocument === "Passport" ? [docFront] : [docFront, docBack];

        try {
          const response = await base64ExtractInformation(docsToUpload);

          if (response?.length) {
            let issueDate,
              expirationDate,
              documentNumber,
              documentType,
              firstName,
              lastName,
              rejectedReason;

            if (response[0].fields) {
              const { issue } = checkFields(response, "issue");
              const { expir } = checkFields(response, "expir");
              const { documenttype } = checkFields(response, "documenttype");
              const { givenname } = checkFields(response, "givenname");
              const { givennames } = checkFields(response, "givennames");
              const { familyname } = checkFields(response, "familyname");
              const { name } = checkFields(response, "name", "matchFull");
              const { surname } = checkFields(response, "surname");
              const { documentnumber } = checkFields(
                response,
                "documentnumber"
              );
              const { documentno } = checkFields(response, "documentno");
              const { id } = checkFields(response, "id", "matchFull");
              const { licensenumber } = checkFields(response, "licensenumber");
              const { trn } = checkFields(response, "trn");
              const { identitynumber } = checkFields(
                response,
                "identitynumber"
              );
              const { identificationnumber } = checkFields(
                response,
                " identificationnumber"
              );

              issueDate = issue;
              expirationDate = expir;
              documentType = documenttype;
              documentNumber = documentnumber
                ? documentnumber
                : documentno
                ? documentno
                : licensenumber
                ? licensenumber
                : identitynumber
                ? identitynumber
                : identificationnumber
                ? identificationnumber
                : trn
                ? trn
                : id
                ? id
                : null;
              firstName = givenname
                ? givenname
                : name
                ? name.split(" ")[0]
                : givennames
                ? givennames
                : null;
              lastName = familyname
                ? familyname
                : name
                ? name.split(" ")[1]
                : surname
                ? surname
                : null;

              if (!documentNumber) {
                const docNumber = checkFields(response);
                documentNumber = docNumber;
              }

              firstName = firstName ? firstName.toLowerCase() : "";
              lastName = lastName ? lastName.toLowerCase() : "";

              if (
                issueDate &&
                expirationDate &&
                documentNumber &&
                documentType &&
                ((firstName &&
                  (Firstname == firstName ||
                    (Firstname && firstName.includes(Firstname)))) ||
                  (lastName &&
                    (Lastname == lastName ||
                      (Lastname && lastName.includes(Lastname)))))
              ) {
                if (new Date(expirationDate) < new Date()) {
                  setDocumentExtracted(true);
                  submitSingleDoc(
                    {
                      issueDate,
                      expirationDate,
                      documentNumber,
                      documentType,
                    },
                    "REJECTED",
                    "Your document's expiry date is earlier than today, Please ensure that the document's expiry date is later than today and that all details are clearly visible"
                  );
                  return;
                }
                setDocumentExtracted(true);
                submitSingleDoc(
                  {
                    issueDate,
                    expirationDate,
                    documentNumber,
                    documentType,
                  },
                  "VERIFIED"
                );
              } else {
                let emptyFields = "";
                if (!issueDate) emptyFields += "Issue Date | ";
                if (!expirationDate) emptyFields += "Expiry Date | ";
                if (!documentNumber) emptyFields += "Document Number | ";
                if (!documentType) emptyFields += "Document Type | ";

                if (emptyFields.endsWith(" | ")) {
                  emptyFields = emptyFields.slice(0, -3);
                }

                if (
                  (firstName &&
                    (Firstname == firstName ||
                      (Firstname && firstName.includes(Firstname)))) ||
                  (lastName &&
                    (Lastname == lastName ||
                      (Lastname && lastName.includes(Lastname))))
                ) {
                  rejectedReason = `Attempted automation failed to fetch field(s) ${emptyFields} from the ${selectedDocument}, The document you provided is either invalid or illegible. Please ensure that the document is valid and that all details are clearly visible.`;
                  setDocumentExtracted(true);
                  submitSingleDoc(
                    {
                      issueDate,
                      expirationDate,
                      documentNumber,
                      documentType,
                    },
                    "PENDING",
                    rejectedReason
                  );
                } else {
                  rejectedReason = emptyFields
                    ? `The account's name do not match the document's name, and attempted automation failed to fetch field(s) ${emptyFields} from the ${selectedDocument}`
                    : "The account's name do not match the document's name";
                  setDocumentExtracted(true);
                  submitSingleDoc(
                    {
                      issueDate,
                      expirationDate,
                      documentNumber,
                      documentType,
                    },
                    "PENDING",
                    rejectedReason
                  );
                }
              }
            } else {
              submitSingleDoc(
                {
                  issueDate,
                  expirationDate,
                  documentNumber,
                  documentType,
                },
                "REJECTED"
              );
              setDocumentExtracted(true);
            }
          } else {
            submitSingleDoc({}, "REJECTED");
            setDocumentExtracted(true);
          }
        } catch (error) {
          setDocumentExtracted("");
          setFrontPreview("");
          setDocFront("");
          setDocBack("");
          setDocFront("");
          console.error("Error extracting document information:", error);
        } finally {
          setProcessing(false);
        }
      }
    };

    // extractInformation();

    const handleDocumentSubmission = async () => {
      if (docFront && (selectedDocument === "Passport" || docBack)) {
        await submitSingleDoc({}, "PENDING");
      }
    };
    handleDocumentSubmission();
  }, [docFront, docBack]);

  const handleUploadFront = async (event) => {
    setProcessing(true);
    event.preventDefault();
    const maxSizeInBytes = 4 * 1024 * 1024;
    const files =
      event.type === "drop" ? event.dataTransfer.files : event.target.files;
    if (files && files[0].size > maxSizeInBytes) {
      toast.error(
        "File size exceeds the limit (4MB). Please select a smaller file."
      );
      event.target.value = null; // Clear the file input
      setProcessing(false);
      return;
    }
    if (files.length) {
      const formData = new FormData();
      const filename = `front-${Date.now()}`;
      formData.append("image", files[0], filename);
      setFrontPreview(files[0]);
      try {
        const response = await azureUploadImage(formData);
        if (response.imageUrl) {
          setDocFront(response.imageUrl);
        } else {
          toast.error("something went wrong, please try again later");
          setFrontPreview("");
          setDocFront("");
        }
        setProcessing(false);
      } catch (error) {
        setProcessing(false);
        setFrontPreview("");
        setDocFront("");
        console.error("Error during OCR check:", error);
      }
    }
  };

  const handleUploadBack = async (event) => {
    setProcessing(true);
    event.preventDefault();
    const maxSizeInBytes = 4 * 1024 * 1024;
    const files =
      event.type === "drop" ? event.dataTransfer.files : event.target.files;
    if (files && files[0].size > maxSizeInBytes) {
      toast.error(
        "File size exceeds the limit (4MB). Please select a smaller file."
      );
      event.target.value = null; // Clear the file input
      setProcessing(false);
      return;
    }
    if (files.length) {
      const formData = new FormData();
      const filename = `back-${Date.now()}`;
      formData.append("image", files[0], filename);
      setBackPreview(files[0]);
      try {
        const response = await azureUploadImage(formData);
        if (response.imageUrl) {
          setDocBack(response.imageUrl);
        } else {
          toast.error("something went wrong, please try again later");
          setBackPreview("");
          setDocBack("");
        }
        setProcessing(false);
      } catch (error) {
        setProcessing(false);
        setBackPreview("");
        setDocBack("");
        console.error("Error during OCR check:", error);
      }
    }
  };

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (event.target.closest(".modal-content") === null) {
        handleClose();
      }
    };

    document.addEventListener("mousedown", handleOutsideClick);

    return () => {
      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, []);

  return (
    <div className="mt-6 ">
      {open && (
        <DocumentPreviewModal
          modalRef={modalRef}
          frontPreview={frontPreview}
          backPreview={backPreview}
          onClose={handleClose}
        />
      )}

      {selectedDocument !== "Passport" ? (
        <div>
          {frontPreview ? (
            <div className="flex flex-col items-center justify-center gap-1 p-6 mt-4 border border-blue-200 rounded-md sm:gap-6 sm:flex-row">
              <img src={upload} alt="" className="h-8" />
              <div>
                <p>Upload successful</p>
                <div className="flex justify-center gap-1">
                  <span>200 KB</span> -{" "}
                  <span className="text-green-600">Done</span>
                </div>
              </div>
              <div className="flex items-center justify-between gap-4 ml-0 sm:ml-auto">
                <button onClick={handleOpen}>View</button>
                <img src={checkmark} alt="" />{" "}
                <img
                  src={deleteIcon}
                  alt=""
                  className="h-8 cursor-pointer"
                  onClick={() => {
                    if (documentUploadedId) {
                      deleteUploadedDocument(documentUploadedId);
                    }
                    setFrontPreview(null);
                    setDocFront("");
                    setDocumentExtracted("");
                  }}
                />
              </div>
            </div>
          ) : (
            <DocumentUploadBox
              handleUpload={handleUploadFront}
              uploadText={`Upload front of ${selectedDocument}`}
              preview={frontPreview}
              innerClass={"mt-4 space-y-1 document-upload-box"}
            />
          )}
          {backPreview ? (
            <div className="flex flex-col items-center justify-center gap-1 p-6 mt-4 border border-blue-200 rounded-md sm:gap-6 sm:flex-row">
              <img src={upload} alt="" className="h-8" />
              <div>
                <p>Upload successful</p>
                <div className="flex justify-center gap-1">
                  <span>200 KB</span> -{" "}
                  <span className="text-green-600">Done</span>
                </div>
              </div>
              <div className="flex items-center justify-between gap-4 ml-0 sm:ml-auto">
                <button onClick={handleOpen}>View</button>
                <img src={checkmark} alt="" />{" "}
                <img
                  src={deleteIcon}
                  alt=""
                  className="h-8 cursor-pointer"
                  onClick={() => {
                    setBackPreview(null);
                    setDocBack("");
                  }}
                />
              </div>
            </div>
          ) : (
            <DocumentUploadBox
              handleUpload={handleUploadBack}
              uploadText={`Upload back of ${selectedDocument}`}
              preview={backPreview}
              innerClass={"mt-4 space-y-1 document-upload-box"}
            />
          )}
        </div>
      ) : (
        <>
          {frontPreview ? (
            <div className="flex flex-col items-center justify-center gap-1 p-6 mt-4 border border-blue-200 rounded-md sm:gap-6 sm:flex-row">
              <img src={upload} alt="" className="h-8" />
              <div>
                <p>Upload successful</p>
                <div className="flex justify-center gap-1">
                  <span>200 KB</span> -{" "}
                  <span className="text-green-600">Done</span>
                </div>
              </div>
              <div className="flex items-center justify-between gap-4 ml-0 sm:ml-auto">
                <button onClick={handleOpen}>View</button>
                <img src={checkmark} alt="" />{" "}
                <img
                  src={deleteIcon}
                  alt=""
                  className="h-8 cursor-pointer"
                  onClick={() => {
                    if (documentUploadedId) {
                      deleteUploadedDocument(documentUploadedId);
                    }
                    setFrontPreview(null);
                    setDocFront("");
                    setDocumentExtracted("");
                  }}
                />
              </div>
            </div>
          ) : (
            <DocumentUploadBox
              handleUpload={handleUploadFront}
              uploadText="Click to upload"
              preview={frontPreview}
              innerClass={"mt-4 space-y-1 document-upload-box"}
            />
          )}
        </>
      )}
      <br />
      {!isMobile && (
        <>
          <div className="flex items-center justify-between gap-1">
            <div className="w-full h-[1px] bg-gray-800/20"></div>
            <p>or</p>
            <div className="w-full h-[1px] bg-gray-800/20"></div>
          </div>
          <br />
          <div className="p-4 flex md:flex-row flex-col items-center justify-center rounded-md md:h-[178px] h-auto border-2 border-[#eaecf0]">
            <div className="p-10">
              {/* <CustomQRCode value={QRValue} /> */}
              <CustomQRCode
                value={"This feature is currently under maintenance"}
              />
            </div>
            <div className="flex flex-col space-y-2 text-center text-black md:text-left">
              <p className="text-lg font-bold">Scan the QR code</p>
              <p className="text-sm font-light">
                Open the camera app and scan the QR code on screen.
              </p>
              <p className="text-xs text-blue-600 underline cursor-pointer">
                How to scan QR code
              </p>
            </div>
          </div>{" "}
        </>
      )}
    </div>
  );
};

export default DocumentUpload;
