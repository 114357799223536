export const data = [
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
  {
    id: "01",
    Loginid: "#1234567",
    name: "Abdullah Khaled",
    profit: "$345.05",
    gain: "+2.54%",
  },
];

export const colNames = ["No", "Login Id", "Name", "Profit (USD)", "Gain"];
