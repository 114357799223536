import React, { useState } from "react";
import "./AppBar.css";
import close from "../images/icons/red-x.svg";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
const VerificationBar = ({ personalInformation, kycWarning }) => {
  const customer = useSelector(selectCurrentCustomer);
  const [show, setShow] = useState(true);
  return (
    customer != null &&
    (personalInformation ? (
      kycWarning ? (
        <>
          <div className="verification-bar-container mb-5">
            <p>
              Please complete your remaining personal information to complete
              the KYC verification process.
            </p>

            <Link to="/personal-information">
              <div className="verification-btn">
                <Link to="/personal-information">Go</Link>
              </div>
            </Link>
          </div>
        </>
      ) : (
        <>
          {show && (
            <div className="verification-bar-container mb-5">
              <p>
                Welcome to Apex Markets! Please complete your remaining personal
                information to complete the KYC verification process.
              </p>

              <Link to="/personal-information">
                <div className="verification-btn">
                  <Link to="/personal-information">Go</Link>
                </div>
              </Link>
              <div
                className="close-verification-btn"
                onClick={() => setShow(false)}
              >
                <img src={close} alt="" />
              </div>
            </div>
          )}
        </>
      )
    ) : (
      <>
        {show ? (
          customer.Status.toUpperCase() !== "PENDING" ||
          customer.Ran_Automated_KYC == 1 ? (
            <>
              <div className="verification-bar-container">
                <p>
                  Welcome to Apex Markets, you submitted your documents. Check
                  on your verification status here !
                </p>

                <Link to="/settings-kyc-verification">
                  <div className="verification-btn">
                    <Link to="/settings-kyc-verification">Check</Link>
                  </div>
                </Link>
                <div
                  className="close-verification-btn"
                  onClick={() => setShow(false)}
                >
                  <img src={close} alt="" />
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="verification-bar-container">
                <p>
                  Welcome to Apex Markets, Please complete your verification to
                  get started !
                </p>

                <Link to="/kyc-verification">
                  <div className="verification-btn">
                    <Link to="/kyc-verification">Verify Now</Link>
                  </div>
                </Link>
                <div
                  className="close-verification-btn"
                  onClick={() => setShow(false)}
                >
                  <img src={close} alt="" />
                </div>
              </div>
            </>
          )
        ) : null}
      </>
    ))
  );
};

export default VerificationBar;
