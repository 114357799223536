import React, { useEffect } from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import info from "../images/icons/info2.svg";

// css in
import "./DepositForm.css";
import { depositCoinbaseCrypto, depositM2PCrypto } from "../api/crypto";
import { useState } from "react";
import OverlayLoader from "./OverlayLoader";
import { FadeLoader } from "react-spinners";
import btc from "../images/icons/BTCaddress.svg";
import eth from "../images/icons/ETHaddress.svg";
import usdt from "../images/icons/Tether.svg";
import down from "../images/down-arrow.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const CryptoModal = ({ closeModal, title, valueAmt, deposit }) => {
  const current = new Date();
  const date = `${current.getDate()},${current.getFullYear()}`;
  const [processing, setProcessing] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  let [color, setColor] = useState("#008ffd");

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const monthName = months[d.getMonth()];
  const fullDate = `${monthName},${date}`;
  const depositToCoinbase = async () => {
    const charge = await depositCoinbaseCrypto(valueAmt);
    debugger;
    if (charge != null) {
      const url = `https://commerce.coinbase.com/charges/${charge.Charge_Code}`;
      window.open(url, "_blank");
    }
  };

  const depositToM2P = async () => {
    setIsDisabled(true);
    setProcessing(true);
    const response = await depositM2PCrypto(valueAmt, selectedCrypto);
    if (response != null && response.redirectUrl != null) {
      window.location.href = response.redirectUrl;
    } else {
      setIsDisabled(false);
      setProcessing(false);
    }
  };
  const [agreed, setAgreed] = useState(false);
  const [openCrypto, setOpenCrypto] = useState(false);
  const [selectedCrypto, setSelectedCrypto] = useState("Select Token");

  const handleSelect = (TOKEN) => {
    setSelectedCrypto(TOKEN);
  };

  return (
    <ModalContainer>
      {processing ? (
        <div className="absolute flex justify-center items-center h-[100vh] w-full z-[9999]">
          <FadeLoader color={color} loading={processing} size={80} />
        </div>
      ) : null}
      <div className="cpm" onClick={() => setOpenCrypto(false)}>
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>{title}</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div className="confirm-form">
              <div className="confirm-row">
                <p>Amount</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>
            </div>
            <div className="confirm-form">
              <div className="confirm-row">
                <p>
                  Fee
                  <span>
                    <img src={info} alt="" />
                  </span>
                </p>
                <span>0.00</span>
              </div>
            </div>
            <div className="confirm-form">
              <div className="confirm-row">
                <p>Total</p>
                <span className="confirm-total">${valueAmt} usd</span>
              </div>
            </div>
            <div class="custom-select" onClick={(e) => e.stopPropagation()}>
              <div className="cryptoselect">
                <span onClick={() => setOpenCrypto(!openCrypto)}>
                  <p>{selectedCrypto}</p>
                  <img src={down} alt="" />
                </span>
                {openCrypto && (
                  <ul
                    className="absolute w-[90%] bg-white z-[1000]"
                    onClick={() => setOpenCrypto(false)}
                  >
                    <li
                      onClick={() => handleSelect("BTC")}
                      className="flex items-center w-full gap-1"
                    >
                      Bitcoin <p className="text-gray-400">BTC</p>{" "}
                      <img src={btc} alt="" />
                    </li>
                    <li
                      onClick={() => handleSelect("ETH")}
                      className="flex items-center w-full gap-1"
                    >
                      Ethereum <p className="text-gray-400">ETH</p>{" "}
                      <img src={eth} alt="" />
                    </li>
                    <li
                      onClick={() => handleSelect("USDT ERC20")}
                      className="flex items-center w-full gap-1"
                    >
                      Tether <p className="text-gray-400">USDT</p>{" "}
                      <img src={usdt} alt="" className="w-8 h-8 " />
                    </li>
                  </ul>
                )}
              </div>
            </div>
            <br />
            <div>
              {deposit === "deposit" ? (
                <>
                  {/* <div
                    className="verification-continue-btn complete"
                    onClick={() => depositToCoinbase()}
                  >
                    Pay with Coinbase
                  </div> */}
                  <button
                    disabled={isDisabled || selectedCrypto === "Select Token"}
                    className="verification-continue-btn red"
                    onClick={() => depositToM2P()}
                  >
                    Pay with M2P
                  </button>
                </>
              ) : (
                <div className="verification-continue-btn complete">
                  Proceed with Coinbase
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default CryptoModal;
