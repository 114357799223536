import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { toast } from "react-toastify";
import down from "../images/down-arrow.svg";
import deleteIcon from "../images/icons/delete.svg";
import { changeAccountLeverage, openAccount } from "../api/trading";
// css
import "./DepositForm.css";
import OverlayLoader from "./OverlayLoader";
import FadeLoader from "react-spinners/FadeLoader";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120%;
  background: rgba(0, 0, 0, 0.1);
  z-index: 500;
  border-radius: 20px;
`;

const ChangeLeverage = ({
  closeModal,
  title,
  title2,
  type,
  deposit,
  challengeDeposit,
  continueBtn,
  accountToChange,
  refresh
}) => {
  const [openLeverage, setOpenLeverage] = useState(false);
  const [selectedLev, setSelectedLev] = useState("1:500");
  const levOptions = ["1:500", "1:400", "1:300", "1:200", "1:100"];
  const [processing, setProcessing] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const leverage = levOptions.find((el)=> el ==  `1:${accountToChange.leverage}`)
    setSelectedLev(leverage || "1:500");
  }, [accountToChange]);


  useEffect(() => {
    if (openLeverage && dropdownRef.current) {
      const selectedOption = dropdownRef.current.querySelector(`[data-option="${selectedLev}"]`);
      if (selectedOption) {
        selectedOption.scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });

      }
    }
  });


  const changeLeverage = async () => {
    setProcessing(true);
    const data = {
      leverage: selectedLev.split(":")[1]
    }
    const res = await changeAccountLeverage(accountToChange.trading_account_id_numeric, data)
    if (res) {
      refresh();
      toast.success("Leverage successfuly updated!")
    } else {
      toast.error("Leverage update failed!")
    }
    closeModal(false);
  };

  return (
    <ModalContainer>
      <div className="cpm sticky top-24">
        <div className="change-password-modal">
          <div className="cpm-container deposit-form">
            <div className="cpm-top">
              <h2 className="">Change Leverage</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>

            <div class="dropdown-select">
              <p>Select Leverage</p>
              <div className="select">
                <span onClick={() => setOpenLeverage(!openLeverage)}>
                  <p>{selectedLev}</p>
                  <img src={down} alt="" />
                </span>
                {openLeverage && (
                  <ul ref={dropdownRef}>
                    {levOptions.map((option) => (
                      <li className={option === selectedLev ? 'bg-[#f4f5f6]' : ''}
                      data-option={option}
                        onClick={(e) => {
                          setSelectedLev(e.target.textContent);
                          setOpenLeverage(false);
                        }}
                      >
                        {option}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            </div>

            <button
              className="verification-continue-btn complete"
              disabled={processing || selectedLev == `1:${accountToChange.leverage}`}
              onClick={changeLeverage}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ChangeLeverage;
