import React, { useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import { BsChevronDown } from "react-icons/bs";
import TransferHistoryDownload from "../components/TransferHistoryDownload";

const TransferHistoryPDF = ({ showNav, noNav }) => {
  noNav(true);
  showNav(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".transaction-list");
    let pdf = new jsPDF('p', 'pt', 'a4');
    pdf.html(capture, {
      callback: function (pdf) {
        pdf.save('transfers-report.pdf');
      }
    });
  };

  const styles = {
    page: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      lineHeight: "1.5",
      letterSpacing: "0.01px",
    },

    columnLayout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
    },

    column: {
      display: "flex",
      flexDirection: "column",
    },

    spacer2: {
      height: "2rem",
    },

    fullWidth: {
      width: "100%",
    },

    marginb0: {
      marginBottom: 0,
    },
  };

  const options = [
    { label: "All", data: null },
    { label: "Last Month", data: "1 Months" },
    { label: "Last 2 Month", data: "2 Months" },
    { label: "Last 3 Month", data: "3 Months" },
  ];

  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState(options[0]);


  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="lg:w-[793px]">

        <button
          // disabled={!isFormComplete}
          class={`${true
            ? "bg-[#008ffd] hover:bg-blue-500"
            : "bg-[#008ffd] hover:bg-blue-500"
            }  text-white font-bold py-2 mx-8 px-4 rounded-md mt-2`}
          onClick={downloadPDF}
        >
          Generate PDF
        </button>

        {/*--------------- Dropdown filter ---------- */}
        <div class="deposit-filter">
          <div
            className="df-btn"
            onClick={(e) => {
              setIsActive(!isActive);
            }}
          >
            {selected.label}
            <span>
              <BsChevronDown></BsChevronDown>
            </span>
          </div>
          {isActive && (
            <div className="df-content">
              {options.map((option) => (
                <div
                  onClick={(e) => {
                    setSelected(option);
                    setIsActive(false);
                  }}
                  className="df-item"
                >
                  {option.label}
                </div>
              ))}
            </div>
          )}
        </div>
        {/*--------------- Dropdown filter ---------- */}

        <div>
          <div className="text-base">
            {/* Letter */}
            <div className="px-8 transaction-list">
              <TransferHistoryDownload selected={selected} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransferHistoryPDF;
