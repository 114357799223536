import React, { useState } from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import apexlogo from "../images/igstoryapexlogo.png";
import apexlogofull from "../images/igstoryapexlogofull.png";
import qrcode from "../images/qrcode.png";
import copy from "../images/icons/copy.svg";
import fb from "../images/icons/Facebook.svg";
import ig from "../images/icons/instagram.svg";
import twitter from "../images/icons/twitter.svg";
import whatsapp from "../images/icons/whatsapp.svg";
import telegram from "../images/icons/telegram.svg";
import reddit from "../images/icons/reddit.svg";
import copyinvite from "../images/icons/copyinvite.svg";
import downloadinvite from "../images/icons/downloadinvite.svg";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;
const ShareInvite = ({ closeModal }) => {
  const [story, setStory] = useState(true);
  const [post, setPost] = useState(false);
  return (
    <ModalContainer>
      <div className="cpm">
        <div className="change-password-modal">
          <div className="cpm-container modal-form">
            <div className="cpm-top">
              <h2>Share Invite Link</h2>
              <div className="close-cpm" onClick={() => closeModal(false)}>
                <img src={deleteIcon} alt="" />
              </div>
            </div>
            <div className="bank-info">
              <p>
                <br />
                Share invite link via social media,so they can scan your link
                and you both can earn up to 50% commission and more.
              </p>
              <br />
            </div>

            {story && (
              <div className="qr-container">
                <div className="ig-story">
                  <div className="blue-box">
                    <div className="qr-code">
                      <img src={qrcode} alt="" />
                    </div>
                    <p>Refer a Friends and Earn Up to 50% Commission.</p>
                    <div className="blue-box-logo">
                      <img src={apexlogo} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {post && (
              <div className="qr-container">
                <div className="ig-story">
                  <div className="blue-box full-width">
                    <div className="qr-code">
                      <img src={qrcode} alt="" />
                    </div>
                    <p>Refer a Friends and Earn Up to 50% Commission.</p>
                    <div className="blue-box-logo">
                      <img src={apexlogofull} alt="" />
                    </div>
                  </div>
                </div>
              </div>
            )}

            <br />
            <div class="dm-wrapper">
              <input
                type="radio"
                name="select"
                id="option-1"
                onClick={() => {
                  setStory(true);
                  setPost(false);
                }}
                checked={story && "true"}
              />
              <input
                type="radio"
                name="select"
                id="option-2"
                onClick={() => {
                  setStory(false);
                  setPost(true);
                }}
                checked={post && "true"}
              />

              <label for="option-1" class="option option-1">
                <span>Story</span>
              </label>
              <div className="line"></div>
              <label for="option-2" class="option option-2">
                <span>Post</span>
              </label>
            </div>
            <div className="cinfo-row">
              <div>
                <ul>
                  <li>
                    <img src={fb} alt="" />
                  </li>
                  <li>
                    <img src={ig} alt="" />
                  </li>
                  <li>
                    <img src={twitter} alt="" />
                  </li>
                  <li>
                    <img src={whatsapp} alt="" />
                  </li>
                  <li>
                    <img src={telegram} alt="" />
                  </li>
                  <li>
                    <img src={reddit} alt="" />
                  </li>
                  <li>
                    <img src={copyinvite} alt="" />
                  </li>
                  <li>
                    <img src={downloadinvite} alt="" />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalContainer>
  );
};

export default ShareInvite;
