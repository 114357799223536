import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import QuestionIcon from "../../images/icons/question.svg";
import { convertToBase64 } from "../../api/document";

const KycStatusTable = ({
  documents,
  setAllDocsVerified,
  setSignature,
  setSelfie,
  setGovtId,
  refresh,
}) => {
  const [tooltipContent, setTooltipContent] = useState("");
  const [tooltipIndex, setTooltipIndex] = useState("");

  useEffect(() => {
    if (refresh) window.location.reload();
  }, [refresh]);

  const navigate = useNavigate();
  const formatDate = (dateString) => {
    let date = new Date(dateString);
    if (isNaN(date.getTime())) {
      return "Invalid Date";
    }
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    let hours = date.getHours();
    let minutes = date.getMinutes();
    let seconds = date.getSeconds();

    return `${month}/${day}/${year}, ${hours}:${minutes}:${seconds}`;
  };

  const handleTooltip = (content, index) => {
    setTooltipContent(content);
    setTooltipIndex(index);
  };

  useEffect(() => {
    const processDocuments = async () => {
      let allVerified = true;

      for (let el of documents) {
        const status =
          el?.resubmit_count > 0
            ? el.resubmit_status_id === 1
              ? "PENDING"
              : el.resubmit_status_id === 3
              ? "VERIFIED"
              : "REJECTED"
            : el?.kyc_status?.Status_Name;

        if (status !== "VERIFIED") {
          allVerified = false;
        }

        if (
          el?.kyc_document_type?.Type_Name === "Signed Signature" &&
          status === "VERIFIED"
        ) {
          const { base64 } = await convertToBase64(el.image_path);
          setSignature(base64);
        }

        if (
          el?.kyc_document_type?.Type_Name === "Selfie" &&
          status === "VERIFIED"
        ) {
          const { base64 } = await convertToBase64(el.image_path);
          setSelfie(base64);
        }

        if (el?.name.includes("front") && status === "VERIFIED") {
          const { base64 } = await convertToBase64(el.image_path);
          setGovtId(base64);
        }
      }

      setAllDocsVerified(allVerified);
    };

    processDocuments();
  }, [documents]);

  return (
    <div className="overflow-x-auto shadow-md sm:rounded-lg">
      <div className="inline-block min-w-full align-middle">
        <div className="overflow-hidden">
          <div className="px-6 py-5 ">Submitted documents</div>
          <table className="min-w-full table-fixed divide-y bg-white divide-black border">
            <thead className=" text-white ">
              <tr>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs tracking-wider text-left  uppercase "
                >
                  DOCUMENT TYPE
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs tracking-wider text-left  uppercase "
                >
                  DATE
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs tracking-wider text-left uppercase "
                >
                  STATUS
                </th>
                <th
                  scope="col"
                  className="py-3 px-6 text-xs tracking-wider text-left uppercase "
                >
                  ACTION
                </th>
              </tr>
            </thead>
            <tbody className="text-black divide-y divide-gray-500/50 bg-white ">
              {documents?.map((el, index) => {
                const status =
                  el?.resubmit_count > 0
                    ? el.resubmit_status_id === 1
                      ? "PENDING"
                      : el.resubmit_status_id === 3
                      ? "VERIFIED"
                      : "REJECTED"
                    : el?.kyc_status?.Status_Name;
                return (
                  <tr onMouseLeave={() => handleTooltip("", "")} key={index}>
                    <td className="py-4 px-6 text-sm  whitespace-nowrap  font-light">
                      {el?.kyc_document_type?.Type_Name}
                    </td>
                    <td className="py-4 px-6 text-sm  whitespace-nowrap font-light ">
                      {formatDate(el?.date_created)}
                    </td>
                    <td className="py-4 px-6 text-sm  text-left  whitespace-nowrap ">
                      {tooltipContent &&
                        tooltipIndex === index &&
                        status != "VERIFIED" && (
                          <div className="absolute z-10 -ml-[100px] w-auto whitespace-normal inline-block px-3 py-2 text-sm font-medium text-white transition-opacity duration-300 bg-gray-900 rounded-lg shadow-sm opacity-100 tooltip dark:bg-gray-700">
                            {tooltipContent}
                          </div>
                        )}
                      <div
                        className="flex"
                        onMouseEnter={() =>
                          handleTooltip(el.rejected_reason, index)
                        }
                      >
                        <button
                          className={`flex w-auto px-3 py-1 -ml-3 bg-white rounded-full ${
                            status === "REJECTED"
                              ? "text-red-600"
                              : status === "PENDING"
                              ? "text-yellow-600"
                              : "text-green-600"
                          }`}
                        >
                          {status}
                        </button>
                        {status == "REJECTED" && <img src={QuestionIcon} />}
                      </div>
                    </td>
                    <td className="px-6 py-4 text-sm font-light whitespace-nowrap ">
                      {status === "REJECTED" && !el.name.includes("Form") ? (
                        <button
                          onClick={() => {
                            navigate(`/resubmit/${el?.id}`);
                          }}
                          className="px-3 py-2 rounded-md bg-[#00A4E6] font-semibold"
                        >
                          Re-submit
                        </button>
                      ) : status !== "VERIFIED" &&
                        !el.name.includes("Form") &&
                        (!el.image_path ||
                          el.image_path == null ||
                          el.image_path == "null") ? (
                        <button
                          onClick={() => {
                            navigate(`/resubmit/${el?.id}`);
                          }}
                          className="px-3 py-2 rounded-md bg-[#00A4E6] font-semibold"
                        >
                          Re-submit
                        </button>
                      ) : (
                        "NONE"
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default KycStatusTable;
