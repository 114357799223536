import React, { useState } from "react";
import { Link } from "react-router-dom";

import UserProfileModal from "../../components/UserProfileModal";
import DownloadMetaTrader from "./../DownloadMetaTrader";

// images icons
import { BsChevronDown } from "react-icons/bs";
import bell from "../../images/icons/bell.svg";
import profile from "../../images/profile-icon.png";

const AdminBar = ({ title }) => {
  // const [openModal, setOpenModal] = useState(false);
  // const [openDownload, setOpenDownload] = useState(false);
  return (
    <>
      {/* {openModal && (
        <UserProfileModal closeModal={setOpenModal}></UserProfileModal>
      )} */}

      {/* {openDownload && (
        <DownloadMetaTrader closeModal={setOpenDownload}></DownloadMetaTrader>
      )} */}
      <div className="app-bar-container">
        <div className="app-bar-title">
          <h1>{title}</h1>
        </div>
        <div className="app-bar-right">
          <div className="admin-name">
            <p>User</p>
            <span className="blue">Super Admin</span>
          </div>
          <div
            className="profile-icon"
            // onClick={() => {
            //   setOpenModal(true);
            // }}
          >
            <img src={profile} alt="" />
          </div>
        </div>
      </div>
    </>
  );
};

export default AdminBar;
