import React, { useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import TableBtn from "../../components/admin/TableBtn";
import AdminClientsTable from "../../components/admin/AdminClientsTable";
import Paginator from "../../components/Paginator";
import FadeLoader from "react-spinners/FadeLoader";
import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxblue2 from "../../images/icons/adminboxblue2.svg";
import adminboxbluemoney from "../../images/icons/adminboxbluemoney.svg";
import adminboxbluetrophy from "../../images/icons/adminboxbluetrophy.svg";
import adminboxgreentick from "../../images/icons/adminboxgreentick.svg";
import adminboxgreensuccess from "../../images/icons/adminboxgreensuccess.svg";
import adminboxorangex from "../../images/icons/adminboxorangex.svg";
import adminboxblacktransfer from "../../images/icons/adminboxblacktransfer.svg";
import {
  getClients,
  getAggregateDeposits,
  getAggregateWithdrawals,
  getAggregateTradingAccounts,
  getClientOverview,
} from "../../api/customer";
import { useEffect } from "react";
import { isCompositeComponent } from "react-dom/test-utils";
import { toast } from "react-toastify";

const Clients = ({ showAdminNav }) => {
  const [clients, setClients] = useState([]);

  const [filterParams, setFilterParams] = useState({
    search: "",
    client_status: "",
    kyc_status: "",
  });

  const [option, setOption] = useState();
  const [filter, setFilter] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [clientOverview, setClientOverview] = useState(null);

  let [color, setColor] = useState("#008ffd");
  const [loading, setLoading] = useState(false);
  const [overviewDateFilter, setOverviewDateFilter] = useState('This Week');

  const mergeCustomerDeposits = async (deposits, customers) => {
    customers.forEach((customer) => {
      const total_deposits = deposits.find((e) => {
        return e.Customer_ID == customer.Customer_ID;
      });

      if (total_deposits !== undefined) {
        let card_charges = total_deposits.card_charges?.[0]?.totalAmount
          ? Number(total_deposits.card_charges?.[0]?.totalAmount)
          : 0;
        let m2p_deposits = total_deposits.m2p_deposits?.[0]?.totalAmount
          ? Number(total_deposits.m2p_deposits?.[0]?.totalAmount)
          : 0;
        let wire_transfer_details = total_deposits.wire_transfer_details?.[0]
          ?.totalAmount
          ? Number(total_deposits.wire_transfer_details?.[0]?.totalAmount)
          : 0;
        customer.total_deposits =
          card_charges + m2p_deposits + wire_transfer_details;
      } else {
        customer.total_deposits = 0;
      }
    });
  };

  const mergeCustomerWithdraws = async (withdraws, customers) => {
    customers.forEach((customer) => {
      const total_withdraws = withdraws.find((e) => {
        return e.Customer_ID == customer.Customer_ID;
      });

      if (total_withdraws !== undefined) {
        let totalAmount = total_withdraws.card_charges?.[0]?.totalAmount
          ? Number(total_withdraws.card_charges?.[0]?.totalAmount)
          : 0;
        let totalAvailableAmount = total_withdraws.card_charges?.[0]
          ?.totalAvailableAmount
          ? Number(total_withdraws.card_charges?.[0]?.totalAvailableAmount)
          : 0;
        let card_charges_refunded = totalAmount - totalAvailableAmount;
        let crypto_withdrawals = total_withdraws.crypto_withdrawal_requests?.[0]
          ?.totalAmount
          ? Number(total_withdraws.crypto_withdrawal_requests?.[0]?.totalAmount)
          : 0;
        let wire_transfers_withdrawal = total_withdraws
          .wire_transfer_requests?.[0]?.totalAmount
          ? Number(total_withdraws.wire_transfer_requests?.[0]?.totalAmount)
          : 0;
        customer.total_withdraws =
          card_charges_refunded +
          crypto_withdrawals +
          wire_transfers_withdrawal;
      } else {
        customer.total_withdraws = 0;
      }
    });
  };

  const mergeCustomerTradingAccounts = async (
    customers_trading_accounts,
    customers
  ) => {
    customers.forEach((customer) => {
      const trading_accounts = customers_trading_accounts.find((e) => {
        return e.Customer_ID == customer.Customer_ID;
      });

      if (trading_accounts !== undefined) {
        customer.total_number_of_trading_accounts = trading_accounts
          .tradingAccounts?.[0]?.totalTradingAccounts
          ? Number(trading_accounts.tradingAccounts?.[0]?.totalTradingAccounts)
          : 0;
        customer.total_trading_accounts_equity = trading_accounts
          .tradingAccounts?.[0]?.account?.totalTradingAccountsBalance
          ? Number(
              trading_accounts.tradingAccounts?.[0]?.account
                ?.totalTradingAccountsBalance
            )
          : 0;
      } else {
        customer.total_number_of_trading_accounts = 0;
        customer.total_trading_accounts_equity = 0;
      }
    });
  };

  const loadClients = async () => {
    setLoading(true);
    try {
      const { customers, pages } = await getClients({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      const { customers_deposits } = await getAggregateDeposits({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      const { customers_withdrawals } = await getAggregateWithdrawals({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      const { customer_trading_accounts } = await getAggregateTradingAccounts({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      if (customers != null) {
        await mergeCustomerDeposits(customers_deposits, customers);
        await mergeCustomerWithdraws(customers_withdrawals, customers);
        await mergeCustomerTradingAccounts(
          customer_trading_accounts,
          customers
        );
        console.log(customers);
        setClients(customers);
        setNumPages(pages > 0 ? pages : 1);
        if (currentPage > pages) {
          setCurrentPage(1);
        }
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      setClients([]);
    }
  };

  const overview = async () => {
    try {
      const res = await getClientOverview({dateFilter:overviewDateFilter})
        setClientOverview(res.overview);
        console.log("client", res.overview)
    } catch (error) {
      console.error(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    overview();
  }, [overviewDateFilter])

  useEffect(() => {
    loadClients();
  }, [currentPage, filterParams, filter]);

  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Clients"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter
                setOption={(val) => {
                  setOverviewDateFilter(val);
                }}
                option={overviewDateFilter}
                defaultValue="This Week"
                list={["Today", "Yesterday", "This Week", "This Month", "This Year", "All"]}></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
           
             <OverviewBox
              title="Total Customers"
              img={adminboxblue}
              data={clientOverview?.TotalCustomer}
              info="Last 24 Hours +2.43%"
              color="blue"
            ></OverviewBox>
             <OverviewBox
              title="Total Affiliates Customers"
              img={adminboxblue}
              data={clientOverview?.Reffferal_link}
              info="Last 24 Hours +2.43%"
              color="blue"
            ></OverviewBox>
             <OverviewBox
              title="Total Reffered Customers"
              img={adminboxblue}
              data={clientOverview?.TotalReferred}
              info="Last 24 Hours +2.43%"
              color="blue"
            ></OverviewBox>
             <OverviewBox
              title="Total Blocked Customers"
              img={adminboxblue}
              data={clientOverview?.Blocked}
              info="Last 24 Hours +2.43%"
              color="blue"
            ></OverviewBox>
             <OverviewBox
              title="Total Active Customers"
              img={adminboxblue}
              data={clientOverview?.ActiveUser}
              info="Last 24 Hours +2.43%"
              color="blue"
            ></OverviewBox>
       
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top-as-column">
              <div className="os-left">Clients</div>
              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val?.trim());
                    setFilterParams({ ...filterParams, search: val?.trim() });
                  }}
                  filter={filter}
                  placeholder="Search by name or email"
                ></TableSearch>
                <AdminFilter
                  classprop="admin-filter-ext long"
                  name="status"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      status: val !== "Client Status" ? val : "",
                    });
                  }}
                  option={option}
                  list={[
                    "Client Status",
                    "VERIFIED",
                    "PENDING",
                    "REJECTED",
                    "BLOCKED",
                  ]}
                  defaultValue="Client Status"
                ></AdminFilter>
                <AdminFilter
                  classprop="admin-filter-ext long"
                  name="dateRange"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      dateRange:
                        val !== "Date Joined"
                          ? val?.replace(" ", "_")?.toLocaleUpperCase()
                          : "",
                    });
                  }}
                  option={option}
                  list={["Date Joined", "Last Week", "3 Months"]}
                  defaultValue="Date Joined"
                ></AdminFilter>
              </div>
            </div>
            {!loading && (
              <>
                <AdminClientsTable
                  data={clients}
                  refresh={loadClients}
                ></AdminClientsTable>
                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
          {loading && (
            <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
              <FadeLoader color={color} loading={loading} size={80} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Clients;
