import React, { useState } from "react";
import styled from "styled-components";

import deleteIcon from "../images/icons/delete.svg";
import info from "../images/icons/info2.svg";
// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import down from "../images/down-arrow.svg";
import eth from "../images/icons/ETHaddress.svg";
import bnb from "../images/icons/BNBaddress.svg";
import btc from "../images/icons/BTCaddress.svg";
import edit from "../images/icons/green-edit.svg";
import trash from "../images/icons/trashcan.svg";

import "./CardSelect.css";
// css in
import "./DepositForm.css";
import { add } from "lodash";
import { submitCryptoWithdrawalRequest } from "../api/crypto";
import { toast } from "react-toastify";
import OverlayLoader from "./OverlayLoader";
import { FadeLoader } from "react-spinners";

const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const CryptoWithdrawalModal = ({ closeModal, title, valueAmt, deposit }) => {
  const current = new Date();
  const date = `${current.getDate()},${current.getFullYear()}`;

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const d = new Date();
  const monthName = months[d.getMonth()];
  const fullDate = `${monthName},${date}`;

  const [openNetwork, setOpenNetwork] = useState(false);

  const [selectedNetwork, setSelectedNetwork] = useState();
  const networkOptions = [
    "ETHERIUM STANDARD NETWORK",
    "BITCOIN STANDARD NETWORK",
  ];
  const [openCoin, setOpenCoin] = useState(false);

  const [selectedCoin, setSelectedCoin] = useState("ETH");
  const coinOptions = [
    [<img src={eth} alt="" />, "ETH"],
    [<img src={btc} alt="" />, "BTC"],
  ];

  const [address, setAddress] = useState("");
  const [amount, setAmount] = useState(valueAmt);

  const [processing, setProcessing] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const withdraw = async () => {
    setProcessing(true);
    const data = {
      amount,
      cryptoAddress: address,
      cryptoType:
        selectedNetwork == "ETHERIUM STANDARD NETWORK" ? "ETH" : "BTC",
    };
    const response = await submitCryptoWithdrawalRequest(data);

    if (response.errors == null) {
      toast.success("Successfully submitted withdrawal request");
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    } else {
      setProcessing(false);
    }
  };

  return (
    <>
      {processing ? (
        <div className="absolute flex justify-center items-center h-[100vh] w-full z-[9999]">
          <FadeLoader color={color} loading={processing} size={80} />
        </div>
      ) : null}
      <ModalContainer>
        <div className="cpm">
          <div className="change-password-modal">
            <div className="cpm-container modal-form deposit-form">
              <div className="cpm-top">
                <h2>{title}</h2>
                <div className="close-cpm" onClick={() => closeModal(false)}>
                  <img src={deleteIcon} alt="" />
                </div>
              </div>
              <div className="bank-info">
                <p>
                  To receive a Cryptocurrency Withdrawal from Apex Markets,
                  please enter the amount and provide your Cryptocurrency Wallet
                  details below.
                </p>
                <p>
                  Cryptocurrency Withdrawals can take 10 minutes to a few hours
                  to be added to your Cryptocurrency Wallet.
                </p>
                <p>Thank you for your cooperation!</p>
              </div>
              {/* DEPOSIT AND WITHDRAW CRYPTO MODAL */}

              <>
                {/* WITHDRAW CRYPTO MODAL */}
                <div className="enter-amount">
                  <div className="enter-amt-top-row">
                    <p>Amount of Crypto to Withdraw</p>
                  </div>
                  <div className="amount-input">
                    {/* <input type="text" placeholder="$0.00" /> */}
                    <CurrencyInput
                      onFocus={(e) => (e.target.placeholder = "")}
                      onBlur={(e) => (e.target.placeholder = "$0.00")}
                      id="input-example"
                      name="input-name"
                      placeholder="$0.00"
                      defaultValue={valueAmt}
                      prefix="$"
                      decimalsLimit={2}
                      onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                    />
                    {/* <button>USD</button> */}
                  </div>
                </div>

                {/* ------------ */}

                {/* FIRST TIME CRYPTO WITHDRAW */}
                <div className="form-label-warning">
                  <label>Add Wallet Address</label>
                </div>
                <div className="hide-pw">
                  <input
                    type="text"
                    placeholder="Enter wallet address"
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>
                <div class="custom-select">
                  <p>Network</p>
                  <div className="select">
                    <span onClick={() => setOpenNetwork(!openNetwork)}>
                      <p>{selectedNetwork}</p>
                      <img src={down} alt="" />
                    </span>
                    {openNetwork && (
                      <ul>
                        {networkOptions.map((option) => (
                          <li
                            onClick={(e) => {
                              setSelectedNetwork(e.target.textContent);
                              setOpenNetwork(false);
                            }}
                          >
                            {option}
                          </li>
                        ))}
                      </ul>
                    )}
                  </div>
                </div>
                {/* <div className="form-label-warning">
                  <label>Network</label>
                </div> */}

                {/* <div className="hide-pw">
                  <input type="text" placeholder="" />
                </div> */}
                <div
                  className="verification-continue-btn complete"
                  disabled={processing}
                  onClick={withdraw}
                >
                  Withdraw
                </div>
                {/* FIRST TIME CRYPTO WITHDRAW */}

                {/* ------------ */}

                {/* ---SELECT WALLET ADDRESS--- */}

                {/* <div className="card-title">
                  <p>Wallet Address</p>
                  <span>Add New Address</span>
                </div>
                <div className="user-card">
                  <div className="uc-left">
                    <input type="checkbox" />
                    <span className="label"></span>
                    <img src={eth} alt="" />
                    <span>498458935573hef</span>
                  </div>
                  <div className="uc-right">
                    <img src={edit} alt="" />
                    <img src={trash} alt="" />
                  </div>
                </div>
                <div className="user-card">
                  <div className="uc-left">
                    <input type="checkbox" />
                    <span className="label"></span>
                    <img src={eth} alt="" />
                    <span>498458935573hef</span>
                  </div>
                  <div className="uc-right">
                    <img src={edit} alt="" />
                    <img src={trash} alt="" />
                  </div>
                </div> */}

                {/* ---SELECT WALLET ADDRESS--- */}
              </>
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default CryptoWithdrawalModal;
