import { React, useState, useRef } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import html2pdf from "html2pdf.js";
import { updateDocumentContent } from "../api/document";
import $ from "jquery";
// react-signature-pad
// https://www.npmjs.com/package/react-signature-pad
import SignaturePad from "react-signature-pad";

// react-signature-canvas
// https://github.com/agilgur5/react-signature-canvas
// import SignatureCanvas from "react-signature-canvas";

import deleteIcon from "../images/icons/delete.svg";
import "./SignatureModal.css";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const KycEditFormModal = ({ closeModal, document }) => {
  const [html, setHtml] = useState(document.Form_Html);
  const [height, setHeight] = useState();
  const iframeRef = useRef();
  const editorRef = useRef(null);
  const save = () => {
    if (editorRef.current) {
      const editorHtml = editorRef.current.getContent();
      let transformedHtml = editorHtml;
      html2pdf()
        .set({
          margin: [0, 30, 0, 30],
        })
        .from(transformedHtml)
        .toPdf()
        .output("datauristring")
        .then(function (pdfAsString) {
          // debugger;
          console.log(pdfAsString);
          updateDocumentContent(document.Document_ID, pdfAsString, html)
            .then(() => {
              window.location.reload();
            })
            .catch((err) => {
              console.error(err);
            });
        })
        .catch((err) => {
          console.error(err);
        });
    }
  };
  return (
    document != null && (
      <ModalContainer>
        <div className="cpm">
          <div className="signature-modal big">
            <div className="cpm-container">
              <Editor
                onInit={(evt, editor) => (editorRef.current = editor)}
                initialValue={html}
                init={{
                  height: 500,
                  menubar: false,
                  plugins: [
                    "advlist autolink lists link image charmap print preview anchor",
                    "searchreplace visualblocks code fullscreen",
                    "insertdatetime media table paste code help wordcount",
                  ],
                  toolbar:
                    "undo redo | formatselect | " +
                    "bold italic backcolor | alignleft aligncenter " +
                    "alignright alignjustify | bullist numlist outdent indent | " +
                    "removeformat | help",
                  content_style:
                    "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
                }}
              />
              <button onClick={save}>Save</button>
              <button
                onClick={() => {
                  closeModal(false);
                }}
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    )
  );
};

export default KycEditFormModal;
