import React from "react";
import { NavLink, Link } from "react-router-dom";

import opennav from "../../images/icons/navopen.svg";
import logo from "../../images/apex-logo.svg";

import { AdminSidebarData } from "./AdminSidebarData";

const ClosedAdminNav = ({ active, setActive }) => {
  return (
    <>
      <div className={`closed-sidebar ${active ? "" : "active"}`}>
        <div className="logo-row">
          <div className="logo">
            <Link to="#">
              <img src={logo} alt="Apex Markets Logo" />
            </Link>
          </div>
          <img
            src={opennav}
            alt=""
            className="close-nav"
            onClick={() => setActive(!active)}
          />
        </div>

        <div className="menu-heading">
          <p>Menu</p>
          <div className="menu-heading-underline"></div>
        </div>
        <div className="sidebar-link-container">
          <ul className="sidebar-list">
            {AdminSidebarData.map((val, key) => {
              return (
                <li
                  key={key}
                  className={val.cName}
                  // onClick={() => setActive(!active)}
                >
                  <NavLink to={val.link} className="row">
                    <div id="icon">
                      <img src={val.icon} alt="" />
                    </div>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};

export default ClosedAdminNav;
