import React, { useEffect, useState } from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";

import Paginator from "../../components/Paginator";
import FadeLoader from "react-spinners/FadeLoader";

import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxorangetransfer from "../../images/icons/adminboxorangetransfer.svg";
import adminboxorange3 from "../../images/icons/adminboxorange3.svg";
import adminboxorangex from "../../images/icons/adminboxorangex.svg";

import { getFailedAudits, getFailedAuditsStats } from "../../api/data";
import FailedAuditTable from "../../components/admin/FailedAuditTable";

import { toast } from "react-toastify";

const FailedAudit = ({ showAdminNav }) => {
  showAdminNav(true);
  const [newStat, setNewStat] = useState([]);
  const [resolvedStat, setResolvedStat] = useState([]);
  const [unresolvedStat, setUnresolvedStat] = useState([]);
  const [failedAudits, setFailedAudits] = useState([]);

  const [filterParams, setFilterParams] = useState({
    search: "",
    status: "",
  });

  const [option, setOption] = useState();
  const [filter, setFilter] = useState();

  const [numPages, setNumPages] = useState(1);
  const [numRows, setNumRows] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const [loading, setLoading] = useState(false);
  let [color, setColor] = useState("#008ffd");
  const loadFailedAudits = async () => {
    setLoading(true);
    try {
      const { audits, pages } = await getFailedAudits({
        ...filterParams,
        limit: currentPage * numRows,
        numRows: numRows,
      });

      setFailedAudits(audits);
      setNumPages(pages > 0 ? pages : 1);
      if (currentPage > pages) {
        setCurrentPage(1);
      }
      setLoading(false);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load documents successfully", {
        type: "error",
      });
      setFailedAudits([]);
    }
  };

  const loadStats = async () => {
    setLoading(true);
    try {
      const { New, Resolved, Unresolved } = await getFailedAuditsStats();
      setNewStat(New);
      setResolvedStat(Resolved);
      setUnresolvedStat(Unresolved);
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast("Unable to load stats successfully", {
        type: "error",
      });
    }
  };

  useEffect(() => {
    loadFailedAudits();
    loadStats();
  }, [currentPage, filterParams]);

  return (
    <div className="dashboard-page">
      <AdminBar title="Failed Audit Log"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Resolved"
              img={adminboxorange}
              data={resolvedStat}
              info=""
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Unresolved"
              img={adminboxorangetransfer}
              data={unresolvedStat}
              info=""
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total New"
              img={adminboxorange3}
              data={newStat}
              info=""
              color="green"
            ></OverviewBox>
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <div className="overview-section-top">
              <div className="os-left">Failed Audits</div>
              <div className="os-right">
                <TableSearch
                  classprop="long"
                  setFilter={(val) => {
                    setFilter(val);
                    setFilterParams({ ...filterParams, search: val });
                  }}
                  filter={filter}
                  placeholder="Search by Name or Customer ID"
                ></TableSearch>

                <AdminFilter
                  classprop="long"
                  name="withdrawal_status"
                  setOption={(val) => {
                    setOption(val);
                    setFilterParams({
                      ...filterParams,
                      status: val !== "ALL" ? val : "",
                    });
                  }}
                  option={option}
                  list={["ALL", "NEW", "UNRESOLVED", "RESOLVED"]}
                  defaultValue="ALL"
                ></AdminFilter>
              </div>
            </div>
            {loading && (
              <div className="absolute left-0 right-0 flex items-center justify-center m-auto">
                <FadeLoader color={color} loading={loading} size={80} />
              </div>
            )}
            {!loading && (
              <>
                <FailedAuditTable
                  refresh={loadFailedAudits}
                  data={failedAudits}
                ></FailedAuditTable>
                <Paginator
                  totalPages={numPages}
                  currentPage={currentPage}
                  onPageChange={(page) => {
                    if (page > numPages) {
                      setCurrentPage(numPages);
                    } else if (page < 1) {
                      setCurrentPage(1);
                    } else {
                      setCurrentPage(page);
                    }
                  }}
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FailedAudit;
