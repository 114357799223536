import React from "react";
import { NavLink, Link } from "react-router-dom";

import opennav from "../images/icons/navopen.svg";
import logo from "../images/apex-logo.svg";

import { SidebarData } from "../components/SidebarData";

import "./Navbar.css";
import { useSelector } from "react-redux";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
// ----------------------SIDEBAR / NAVBAR ------------------
const ClosedNav = ({ activeNav, setActiveNav, isMobile, setIsMobile }) => {
  const customer = useSelector(selectCurrentCustomer);
  return (
    customer != null && (
      <>
        <div className={`closed-sidebar ${activeNav ? "" : "active"} `}>
          <div className="logo-row">
            <div className="logo">
              <Link to="#">
                <img src={logo} alt="Apex Markets Logo" />
              </Link>
            </div>
            <img
              src={opennav}
              alt=""
              className="close-nav"
              onClick={() => setActiveNav(!activeNav)}
            />
          </div>

          <div className="menu-heading">
            <p>Menu</p>
            <div className="menu-heading-underline"></div>
          </div>
          <div className="sidebar-link-container">
            <ul className="sidebar-list">
              {SidebarData.filter((item) => {
                const allowed = ["/dashboard"];
                if (
                  customer?.Status != "VERIFIED" &&
                  !allowed.includes(item.link)
                ) {
                  return false;
                }
                return true;
              }).map((val, key) => {
                return (
                  <li
                    key={key}
                    className={val.cName}
                    // onClick={() => setActive(!active)}
                  >
                    <NavLink to={val.link} className="row">
                      <div id="icon">
                        <img src={val.icon} alt="" />
                      </div>
                    </NavLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </>
    )
  );
};

export default ClosedNav;
