import React, { useState } from "react";
import styled from "styled-components";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";
import deleteIcon from "../images/icons/delete.svg";
import FadeLoader from "react-spinners/FadeLoader";

// css
import "./DepositForm.css";
import { toast } from "react-toastify";
import { depositToAccount } from "../api/trading";
import OverlayLoader from "./OverlayLoader";
const ModalContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 500;
  border-radius: 20px;
`;

const AddFunds = ({ closeModal, valueAmt, accountNumber }) => {
  const [amount, setAmount] = useState(0);
  const [processing, setProcessing] = useState(false);

  const depositToLiveAccount = async () => {
    debugger;
    setProcessing(true);
    const result = await depositToAccount(accountNumber, {
      amount,
    });

    if (result != null && result.errors == null) {
      toast("Successfully added funds", {
        type: "success",
      });
      setTimeout(async () => {
        setProcessing(true);
        window.location.reload();
      }, 1000);
    } else {
      setProcessing(false);
    }
  };

  let [color, setColor] = useState("#008ffd");

  return (
    <>
      <ModalContainer>
        {processing ? (
          <div className="bg-white absolute flex justify-center items-center h-[100vh] w-full z-[10000]">
            <FadeLoader color={color} loading={processing} size={80} />
          </div>
        ) : null}
        <div className="cpm">
          <div className="change-password-modal">
            <div className="cpm-container deposit-form">
              <div className="cpm-top">
                <h2>Add Funds</h2>
                <div className="close-cpm" onClick={() => closeModal(false)}>
                  <img src={deleteIcon} alt="" />
                </div>
              </div>

              {/* <div className="hide-pw">
              <input type="text" id="" name="" placeholder="Walet AC(#zesan)" />
            </div> */}

              {/* <div className="hide-pw">
              <input
                type="text"
                id=""
                name=""
                placeholder="Trading AC(#47539)"
              />
            </div> */}

              <div className="enter-amount">
                <div className="enter-amt-top-row">
                  <p>Enter Amount</p>
                </div>
                <div className="amount-input">
                  {/* <input type="text" placeholder="$0.00" /> */}
                  <CurrencyInput
                    onFocus={(e) => (e.target.placeholder = "")}
                    onBlur={(e) => (e.target.placeholder = "$0.00")}
                    id="input-example"
                    name="input-name"
                    placeholder="$0.00"
                    defaultValue={valueAmt}
                    prefix="$"
                    decimalsLimit={2}
                    onValueChange={(valueAmt, name) => setAmount(valueAmt)}
                  />
                  <div className="select-currency-btn currency-type">
                    USD<BsChevronDown></BsChevronDown>
                    <div className="sc-content"></div>
                  </div>
                  {/* <button>USD</button> */}
                </div>
                {/* <div className="amt-percentages">
                <div className="percentages">
                  <span>25%</span>
                  <span>50%</span>
                  <span>75%</span>
                  <span>100%</span>
                </div>
              </div> */}
              </div>
              {/* <div className="df-save-card">
              <input type="checkbox" />
              <span className="label"></span>
              <p>
                I have read and agree with Apex Market’s{" "}
                <span className="blue">User Agreement</span> and{" "}
                <span className="blue">Privacy Policy</span>.
              </p>
            </div> */}

              <button
                className="verification-continue-btn complete"
                onClick={() => depositToLiveAccount()}
                disabled={processing}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </ModalContainer>
    </>
  );
};

export default AddFunds;
