import InputField from "../InputField";

const CustomerIdentificationForm = ({ formData, handleInputChange }) => {
  return (
    <>
      <div className="flex flex-wrap justify-between">
        <InputField
          type="text"
          label="Id Type"
          value={formData.idType}
          onChange={handleInputChange}
          name="idType"
          required
        />
        <InputField
          type="text"
          label="Id Number"
          value={formData.idNumber}
          onChange={handleInputChange}
          name="idNumber"
          required
        />
        <InputField
          type="date"
          label="Issue Date"
          value={formData.issueDate}
          onChange={handleInputChange}
          name="issueDate"
          required
        />
        <InputField
          type="date"
          label="Expiry Date"
          value={formData.expiryDate}
          onChange={handleInputChange}
          name="expiryDate"
          required
        />
      </div>
    </>
  );
};

export default CustomerIdentificationForm;
