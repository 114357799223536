import React, { useState, useEffect } from "react";
import AppBar from "../components/AppBar";
// components
import DepositForm from "../components/DepositForm";
import DeleteCardInfo from "../components/DeleteCardInfo";
import CardSelect from "../components/CardSelect";
import EditCardInfo from "../components/EditCardInfo";
import ConfirmDeposit from "../components/ConfirmDeposit";
import ProcessingTransaction from "../components/ProcessingTransaction";
import DepositSuccess from "../components/DepositSuccess";
import PaymentFailed from "../components/PaymentFailed";
import WireSubmitted from "../components/WireSubmitted";
import DepositDetails from "../components/DepositDetails";

import CryptoModal from "../components/CryptoModal";
import WireTransferModal from "../components/WireTransferModal";

import DepositHistoryTable from "../components/DepositHistoryTable";
import { depositTableData } from "../components/DepositHistoryData";

// currency input field
// https://bestofreactjs.com/repo/cchanxzy-react-currency-input-field-react-masked-input
import CurrencyInput from "react-currency-input-field";

// icons
import { BsChevronDown } from "react-icons/bs";

// CSS
import "./Deposit.css";

const PaymentSuccess = ({ showNav }) => {
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" }); // this will fire only on first render
  }, []);

  const getForm = (url, target, values, method) => {
    function grabValues(x) {
      var path = [];
      var depth = 0;
      var results = [];

      function iterate(x) {
        switch (typeof x) {
          case "function":
          case "undefined":
          case "null":
            break;
          case "object":
            if (Array.isArray(x))
              for (var i = 0; i < x.length; i++) {
                path[depth++] = i;
                iterate(x[i]);
              }
            else
              for (var i in x) {
                path[depth++] = i;
                iterate(x[i]);
              }
            break;
          default:
            results.push({
              path: path.slice(0),
              value: x,
            });
            break;
        }
        path.splice(--depth);
      }
      iterate(x);
      return results;
    }
    var form = document.createElement("form");
    form.method = method;
    form.action = url;
    form.target = target;

    var values = grabValues(values);

    for (var j = 0; j < values.length; j++) {
      var input = document.createElement("input");
      input.type = "hidden";
      input.value = values[j].value;
      input.name = values[j].path[0];
      for (var k = 1; k < values[j].path.length; k++) {
        input.name += "[" + values[j].path[k] + "]";
      }
      form.appendChild(input);
    }
    return form;
  };

  // DEPOSIT FILTER
  const [isActive, setIsActive] = useState(false);
  const [selected, setSelected] = useState("All");
  const options = ["All", "Last 3 Months", "Last Week"];

  const [openModal, setOpenModal] = useState(false);

  const [openCryptoModal, setOpenCryptoModal] = useState(false);

  const [openWireModal, setOpenWireModal] = useState(false);

  // Keep track of amount entered in deposit
  const [valueAmt, setValueAmt] = useState("");

  //  DEPOSIT METHODS
  const [card, setCard] = useState(true);
  const [crypto, setCrypto] = useState(false);
  const [wire, setWire] = useState(false);

  return (
    <>
      <div className="dashboard-page">
        <AppBar title="Deposit"></AppBar>
        <div className="dashboard-container">
          <div class="deposit-page-grid">
            <p>
              Congratulations Zesan! Your payment using the card number{" "}
              <b className="black">(****4080)</b> was successful. Check your{" "}
              <span className="blue">wallet.</span>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaymentSuccess;
