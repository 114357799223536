import { api } from "./index";
export const depositCoinbaseCrypto = async (amount) => {
  const response = await api
    .post("crypto/deposit/coinbase", {
      json: {
        amount,
      },
    })
    .json();
  if (response != null && response.charge != null) {
    return response.charge;
  }
  return null;
};

export const depositM2PCrypto = async (amount, selectedCrypto) => {
  const response = await api
    .post("crypto/deposit/m2p", {
      json: {
        amount,
        selectedCrypto,
      },
    })
    .json();
  return response;
};

export const submitCryptoWithdrawalRequest = async (data) => {
  const response = await api
    .post("crypto/submit-withdrawal-request", {
      json: data,
    })
    .json();
  return response;
};

export const getCryptoWithdrawalRequests = async (data) => {
  const response = await api.get("crypto/withdrawal-requests").json();
  return response;
};

export const updateCryptoWithdrawalRequest = async (data, id) => {
  return await api
    .put(`crypto/withdrawal-requests/${id}`, {
      json: data,
    })
    .json();
};
