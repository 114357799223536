import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getCurrentUser, logout } from "../../api/customer";
export const customerSlice = createSlice({
  name: "customer",
  initialState: {
    customer: null,
  },
  reducers: {
    authenticate: (state, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.customer = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkIfLoggedIn.fulfilled, (state, action) => {
      //// debugger;
      state.customer = action.payload;
    });
  },
});

export const checkIfLoggedIn = createAsyncThunk(
  "customer/getCurrent",
  async () => {
    const user = await getCurrentUser();

    if(user?.Status === 'BLOCKED'){
      await logout(1);
    }

    if (user === null) {
      window.location.href = `${process.env.REACT_APP_WEBSITE_URL}/login`;
    } else {
      window.rollbar.configure({payload: {person: {id: user?.Customer_ID, email: user?.Email}}});
    }

    return user;
  }
);

// Action creators are generated for each case reducer function
export const selectCurrentCustomer = (state) => {
  //// debugger;
  return state?.customerReducer?.customer;
};
export const { authenticate } = customerSlice.actions;

export default customerSlice.reducer;
