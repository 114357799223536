import React from "react";
// components
import AdminBar from "../../components/admin/AdminBar";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";

import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";

const AuditLogs = ({ showAdminNav }) => {
  showAdminNav(true);
  return (
    <div className="dashboard-page">
      <AdminBar title="Audit Logs"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Login"
              img={adminboxblue}
              data="120"
              info="Last 24 Hours +2.43%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Active Now"
              img={adminboxorange}
              data="120"
              info="Last 24 Hours -0.21%"
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total"
              img={adminboxblack}
              data="100"
              info="Last 24 Hours -0.21%"
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Didn’t Apply"
              img={adminboxgreen}
              data="130"
              info="Last 24 Hours +3.00%"
              color="green"
            ></OverviewBox>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditLogs;
