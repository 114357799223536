import React, { useEffect, useRef, useState } from "react";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ReportTemplate from "../components/ReportTemplate";
import { format } from "date-fns";

import { getCurrentProfile, saveProfile } from "../api/profile";

const AffiliateLetter = ({ showNav, noNav }) => {
  noNav(true);
  showNav(false);

  const downloadPDF = () => {
    const capture = document.querySelector(".affiliate-letter");

    const options = { scale: 1 };
    html2canvas(capture, options).then(function (canvas) {
      const imgData = canvas.toDataURL("img/png");
      // canvas.width = document.body.clientWidth;
      const doc = new jsPDF("p", "mm", "a4");
      const componentWidth = doc.internal.pageSize.getWidth();
      const componentHeight = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 0, 0, componentWidth, 0);
      doc.save("affiliate-confirmation-letter.pdf");
    });
  };

  const styles = {
    page: {
      display: "flex",
      justifyContent: "center",
      flexDirection: "column",
      lineHeight: "1.5",
      letterSpacing: "0.01px",
    },

    columnLayout: {
      display: "flex",
      justifyContent: "space-between",
      margin: "3rem 0 5rem 0",
      gap: "2rem",
    },

    column: {
      display: "flex",
      flexDirection: "column",
    },

    spacer2: {
      height: "2rem",
    },

    fullWidth: {
      width: "100%",
    },

    marginb0: {
      marginBottom: 0,
    },
  };

  const currentDate = new Date();
  const month = currentDate.toLocaleString("default", { month: "long" }); // Convert month to string
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  const loadProfileInfo = async () => {
    const customer = await getCurrentProfile();
    const address = customer?.customer_address[0];
    console.log(address)
    if (address != null) {
      setFirstName(customer.Firstname);
      setLastName(customer.Lastname);
      setCountry(address.country);
      setStreet(address.line1);
      setCity(address.city_town_district);
      setState(address.state_parish_pronvince);
      setPostalCode(customer.Postal_Code);
    }
  };

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const [country, setCountry] = useState("");
  const [orgName, setOrgName] = useState("");
  const [orgAddress, setOrgAddress] = useState("");
  const [orgCity, setOrgCity] = useState("");
  const [orgState, setOrgState] = useState("");

  const isFormComplete =
    orgName !== "" && orgCity !== "" && orgAddress !== "" && orgState !== "";

  useEffect(() => {
    loadProfileInfo();
  }, []);
  return (
    <div className="flex flex-col items-center justify-center w-full">
      <div className="lg:w-[793px]">
        {/* <div class="flex flex-col-reverse relative focus group mx-8 text-sm my-2">
          <input
            type="text"
            value={orgName}
            onChange={(e) => setOrgName(e.target.value)}
            required
            class="border border-black rounded-md px-4 py-2"
            placeholder="Enter name of Bank/Organization"
          />

          <span class="">* Name of Bank/Organization</span>
        </div>
        <div class="flex flex-col-reverse relative focus group mx-8 text-sm flex-1">
          <input
            type="text"
            value={orgAddress}
            onChange={(e) => setOrgAddress(e.target.value)}
            required
            class="border border-black rounded-md px-4 py-2"
            placeholder="Enter Bank/Organization Street Address"
          />

          <span class="">* Bank/Organization Street Address</span>
        </div>
        <div className="flex flex-wrap items-center gap-4 mx-8 my-2 text-sm justify-evenly">
          <div class="flex flex-col-reverse relative focus group flex-1">
            <input
              type="text"
              value={orgCity}
              onChange={(e) => setOrgCity(e.target.value)}
              required
              class="border border-black rounded-md px-4 py-2"
              placeholder="Enter Bank/Organization City"
            />

            <span class="">* Bank/Organization City</span>
          </div>
          <div class="flex flex-col-reverse relative focus group flex-1">
            <input
              type="text"
              value={orgState}
              onChange={(e) => setOrgState(e.target.value)}
              required
              class="border border-black rounded-md px-4 py-2"
              placeholder="Enter Bank/Organization State, Parish"
            />

            <span class="">* Bank/Organization State, Parish</span>
          </div>
        </div> */}
        <button
          // disabled={!isFormComplete}
          class={`${isFormComplete
              ? "bg-[#008ffd] hover:bg-blue-500"
              : "bg-[#008ffd] hover:bg-blue-500"
            }  text-white font-bold py-2 mx-8 px-4 rounded-md mt-2`}
          onClick={downloadPDF}
        >
          Generate PDF
        </button>

        <div>
          <div className="text-base">
            {/* Letter */}
            <div className="px-8 affiliate-letter h-[800px]">
              <br />
              <div>
                <p>Apex E-Commerce Services Limited</p>
                <br />
                <p>
                  {month} {day}, {year}
                </p>
                <br />
                <p>
                  {firstName.charAt(0).toUpperCase() + firstName.slice(1)}{" "}
                  {lastName.charAt(0).toUpperCase() + lastName.slice(1)}
                </p>
                <p>{street}</p>
                <p>
                  {city}, {state}, {postalCode}
                </p>
                <p>{country}</p>
              </div>

              <br />
              <p>
                Subject: Confirmation of Affiliate Contractor Engagement and
                Proof of Income
              </p>
              <br />
              <p>
                Dear {firstName.charAt(0).toUpperCase() + firstName.slice(1)}{" "}
                {lastName.charAt(0).toUpperCase() + lastName.slice(1)},
              </p>
              <p>
                We would like to express our appreciation for your engagement as
                an affiliate contractor for Apex E-Commerce Services Limited. We
                affirm that{" "}
                {firstName.charAt(0).toUpperCase() + firstName.slice(1)}{" "}
                {lastName.charAt(0).toUpperCase() + lastName.slice(1)} has
                received income from Apex E-Commerce Services Limited during the
                specified period as an Affiliate Marketer for Apex E-Commerce
                Services Limited.
                <br />
                <br />
                To provide confirmation of our agreement and to support your
                income verification process, we kindly request that you present
                this letter of confirmation to any relevant financial
                institutions involved in processing your payments. This will
                ensure that all payments are recognized as being made directly
                from Apex E-Commerce Services Limited, on behalf of the
                contracted parties, in accordance with applicable laws and
                regulations.
                <br />
                <br />
                Thank you once again for your trust in Apex E-Commerce Services
                Limited. We look forward to continuing our business relationship
                with you and appreciate your trust in us.
                <br />
              </p>
              <br />
              <p>Regards,</p>
              <p>Apex E-Commerce Services Team</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateLetter;
