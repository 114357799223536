import React, { useState, useEffect } from "react";
import {  getAccounts } from "../api/trading";
import { selectCurrentCustomer } from "../redux/slices/customerSlice";
import { useSelector } from "react-redux";
import styled from "styled-components";
import CurrencyInput from "react-currency-input-field";
import { BsChevronDown } from "react-icons/bs";
import down from "../images/down-arrow.svg";
import deleteIcon from "../images/icons/delete.svg";

import FadeLoader from "react-spinners/FadeLoader";
import { convertBonus } from "../api/affiliate";
import { toast } from "react-toastify";

const ConvertBonusPoints = ({
  closeModal,
  points,
  open,
  setOpen,
  handleClose,
}) => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  let [color, setColor] = useState("#008ffd");
  const [openTransferTo, setOpenTransferTo] = useState(false);
  const [selectedToAccount, setSelectedToAccount] = useState("Select Account");
  const [loading, setLoading] = useState(false);
  const [accountToOptions, setAccountToOptions] = useState([]);
  const [pointAmount, setPointAmount] = useState(points ? points : 0);

  const customer = useSelector(selectCurrentCustomer);
  const loadLiveAccounts = async () => {
    setLoading(true);

    try {
      const accounts = await getAccounts();
      const demoAccounts = await getAccounts(null, 0, null, "DEMO");
      const walletBalance = customer?.wallet?.Balance || 0;

      const transferFromList = [
        {
          value: "Wallet",
          text: `Wallet ($${walletBalance})`,
          type: "",
        },
        ...[...accounts,...demoAccounts].map((account) => ({
          value: account.trading_account_id_numeric,
          text: `${account.trading_account_id_numeric} ($${account.balance})`,
          type: account.type,
        })),
      ];

      setAccountToOptions(transferFromList);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong, please try again later");
    } finally {
      setLoading(false);
    }
  };

  const getValueByText = (array, text) => {
    if (array && text) {
      const lst = array.find((arr) => arr.text === text);
      return lst.value;
    } else {
      return false;
    }
  };

  const handleToAccountChange = (e) => {
    const selection = e.target.textContent;
    const getSelectedValue = getValueByText(accountToOptions, selection);

    setSelectedToAccount(getSelectedValue);
    setOpenTransferTo(false);
  };
  useEffect(() => {
    loadLiveAccounts();
  }, []);

  const handleConvert = async () => {
    try {
      if (Number(pointAmount) > Number(points)) {
        toast.error(`You do not have ${pointAmount} points!`);
      } else {
        const res = await convertBonus(pointAmount, selectedToAccount);
        if (res.message) {
          handleClose();
          toast.success("Successfully converted FTD Bonus");
        }
      }
    } catch (error) {
      console.error("An error occurred during bonus conversion:", error);
      toast.error("Failed to convert FTD Bonus. Please try again later.");
    }
  };

  return (
    <div className="relative">
      {loading ? (
        <div className="absolute z-50 flex items-center justify-center w-full h-full">
          <FadeLoader color={color} loading={loading} size={80} />
        </div>
      ) : null}
      <div className="cpm-top">
        <h2>Convert Bonus</h2>
        <div onClick={handleClose} className="cursor-pointer">
          <img src={deleteIcon} alt="" />
        </div>
      </div>
      <div className="bank-info">
        <p>
          Convert Your bonus to cash at 10% value into your main wallet balance
          or transfer the full bonus into your trading account into a trading
          account to place trades.
        </p>
      </div>

      <div className="enter-amount">
        <div className="enter-amt-top-row">
          <p>Convertable Bonus</p>
          <span>Available Bonus - {points}</span>
        </div>
        <div className="amount-input">
          {/* <input type="text" placeholder="$0.00" /> */}
          <CurrencyInput
            // onFocus={(e) => (e.target.placeholder = "")}
            // onBlur={(e) => (e.target.placeholder = "$0.00")}
            id="input-example"
            name="input-name"
            // placeholder="30"
            defaultValue={pointAmount}
            // prefix="$"
            decimalsLimit={2}
            onValueChange={(valueAmt, name) => setPointAmount(valueAmt)}
          />

          {/* <button>USD</button> */}
        </div>
      </div>

      <div class="dropdown-select">
        <p>Transfer Bonus To</p>
        <div className="select">
          <span onClick={() => setOpenTransferTo(!openTransferTo)}>
            <p>{selectedToAccount}</p>
            <img src={down} alt="" />
          </span>
          {openTransferTo && (
            <ul>
              {accountToOptions?.map((option) => (
                <li
                  key={option.value}
                  style={{ color: option.type === "DEMO" ? "gray" : "inherit" }}
                  onClick={(e) => {
                    option.type !== "DEMO" && handleToAccountChange(e);
                  }}
                >
                  <div className="flex justify-between">
                    <div>{option.text}</div>
                    {option.type === "DEMO" && <div>Demo Account</div>}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <button
        onClick={handleConvert}
        className="verification-continue-btn complete"
      >
        Confirm
      </button>
    </div>
  );
};

export default ConvertBonusPoints;
