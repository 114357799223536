import React from "react";
import { Outlet } from "react-router-dom";

// components
import AppBar from "../components/AppBar";
import AccountsSelector from "../components/AccountsSelector";

import "./Accounts.css";

const Accounts = ({ showNav, noNav }) => {
  showNav(true);
  noNav(false);
  return (
    <>
      <>
        <div className="dashboard-page">
          <AppBar title="Accounts"></AppBar>
          <AccountsSelector></AccountsSelector>
          <Outlet></Outlet>
        </div>
      </>
    </>
  );
};

export default Accounts;
