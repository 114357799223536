import { React, useCallback, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Editor } from "@tinymce/tinymce-react";
import html2pdf from "html2pdf.js";
import moment from "moment";
import { getDocumentTemplates } from "../../../api/document";
import { getCardCharges } from "../../../api/card";
import { formatCardNumber } from "../../../util";
import "../../../pages/WireTransferModal.css";
import AdminFilter from "../AdminFilter";

const ModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.05);
  z-index: 1055;
  border-radius: 20px;
  overflow-x: hidden;
  overflow-y: auto;
`;

const ManageChargeBackModal = ({ closeModal, chargeBackData }) => {
  const editorRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [relatedList, setRelatedList] = useState(null);
  const [html, setHtml] = useState(null);
  const [selectedCharges, setSelectedCharges] = useState([]);
  const [selectedSection, setSelectedSection] = useState("charges");
  const [allTemplates, setAllTemplates] = useState([]);
  const [clientImagesData, setClientImagesData] = useState([]);

  const [templateType, setTemplateType] = useState("SELECT AN OPTION");
  const assignedCSS =
    "* { padding: 0; margin: 0; font-family: 'Times New Roman', sans-serif; font-weight: inherit; line-height: 1.5; } html, body { font-size: 14px; } p, span { font-weight: 100 !important; line-height: 1.5; margin: 0; font-size: 14px; } ol, ul, li { font-weight: 100 !important; line-height: 1.5; margin: 0; } ::marker { unicode-bidi: isolate; font-variant-numeric: tabular-nums; text-transform: none; text-indent: 0px !important; text-align: start !important; text-align-last: start !important; } .sections { page-break-inside: avoid; break-inside: avoid; } table:not(#transactions), table:not(#transactions) tr, table:not(#transactions) td, table:not(#transactions) tbody, table:not(#transactions) thead { border: none; font-size: 14px; white-space: normal; } table:not(#transactions) td { padding: 0; } #transactions { border-collapse: collapse; table-layout: fixed; width: 650px; border-spacing: 0 !important; } #transactions td, #transactions th { border: 1px solid #ddd !important; padding: 0.5em 10px !important; font-size: 14px !important; vertical-align: top; white-space: normal; } #transactions tr:nth-child(even) { background-color: #f2f2f2; } #transactions th { text-align: left; background-color: #000; color: white; } strong{ font-weight: bold; }";

  const assignedCSSPrint =
    "#transactions td, #transactions th{ height: 50px !important; } #page-break{ height: 0px; break-before: always !important; page-break-before: always !important; page-break-inside: avoid !important; } #soft-page-break{ height: 0px; break-before: auto !important; page-break-before: auto !important; }";

  const getRelatedCharges = async () => {
    try {
      setLoading(true);
      const { charges, pages } = await getCardCharges({
        customerId: chargeBackData?.Customer_ID,
        status: "APPROVED",
        firstDigits: chargeBackData?.FirstSixDigitsOfCard,
        lastDigits: chargeBackData?.LastFourDigitsOfCard,
        limit: 100,
        numRows: 100,
      });

      const filterCharges = charges;

      if (filterCharges.length > 0) {
        setRelatedList(filterCharges);

        let optCharges = selectedCharges;
        optCharges.push(chargeBackData?.ID);
        setSelectedCharges(optCharges);
      } else {
        setSelectedSection("documents");
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const getAllChargeBackTemplates = async () => {
    try {
      const templates = await getDocumentTemplates();
      if (templates.templates) {
        setAllTemplates(templates.templates);
      }
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  const delay = (ms) => new Promise((res) => setTimeout(res, ms));

  const getChargeBackDocument = async (templateName) => {
    setLoading(true);
    if (templateName && templateName !== "SELECT AN OPTION") {
      //check if customer had saved a document first, if not pull fresh template
      const selectedTemplateName =
        selectedCharges.length > 1 && templateName === "Payment Receipt"
          ? "Payment Receipt Multiple"
          : templateName;

      const selectedTemplateData = allTemplates.filter(
        (temp) => temp.Template_Name === selectedTemplateName
      );

      const converted = await populateCustomerData(
        selectedTemplateData[0]?.Template_Html,
        chargeBackData
      );

      await delay(5000);

      setHtml(converted);
      setLoading(false);
    } else {
      setHtml(null);
      setLoading(false);
    }
  };

  const populateCustomerData = async (template, data) => {
    let html = data.customer?.mostRecentDocs?.FORM?.html_form;
    var parser = new DOMParser();
    var parsedHtml = parser.parseFromString(html, "text/html");
    let customerData = [];

    const addValues = (template, id, value) => {
      let templateHTML = template;
      var templateParser = new DOMParser();
      var templateParsedHtml = templateParser.parseFromString(
        templateHTML,
        "text/html"
      );
      let collection = templateParsedHtml.querySelectorAll(`span#${id}`);
      for (const el of collection) {
        el.innerHTML = value;
      }
      return templateParsedHtml.documentElement.innerHTML;
    };

    const addTransactionTable = (template, id, data) => {
      let templateHTML = template;
      var templateParser = new DOMParser();
      var templateParsedHtml = templateParser.parseFromString(
        templateHTML,
        "text/html"
      );
      let collection = templateParsedHtml.querySelectorAll(`div#${id}`);

      let rows = "";
      data.forEach((d) => {
        rows += '<tr><td height="25px" valign="middle">';
        rows += d.Amount;
        rows += "</td>";
        rows += '<td height="25px" valign="middle">';
        rows += d.PaymentProcessorOrderNo;
        rows += "</td>";
        rows += '<td height="25px" valign="middle">';
        rows += moment(d.Date_Created).format("MMMM Do YYYY, h:mm:ss a");
        rows += "</td></tr>";
      });

      let tableHTML = "<br/>";
      tableHTML +=
        '<table cellspacing="0" cellpadding="0" id="transactions"><thead><tr>';
      tableHTML += '<th width="100px" height="25px">Amount</th>';
      tableHTML += '<th height="25px">Order Number</th>';
      tableHTML += '<th height="25px">Transaction Date</th></tr></thead>';
      tableHTML += "<tbody>" + rows + "</tbody>";
      tableHTML += "</table>";

      for (const el of collection) {
        el.innerHTML = tableHTML;
      }

      return templateParsedHtml.documentElement.innerHTML;
    };

    const extractValue = (data, field) => {
      const arrayValue = data.map((item, i) => {
        if (item.key === field) {
          return item.value;
        }
        return false;
      });

      return arrayValue.filter((r) => r !== false)[0];
    };

    let formAgreementData = [];
    let formAgreement = data.customer.mostRecentDocs.FORM?.html_form;
    if (formAgreement) {
      var formAgreementHtml = parser.parseFromString(
        formAgreement,
        "text/html"
      );
      let formAgreementRows =
        formAgreementHtml.querySelectorAll(`div.agreement-row`);

      for (const dataRows of formAgreementRows) {
        let key = dataRows
          .getElementsByTagName("p")[0]
          .innerHTML.replace(":", "")
          .trim();
        let value = "";
        if (dataRows.querySelectorAll("span.swapped")[0]) {
          value = dataRows.querySelectorAll("span.swapped")[0].innerHTML;
        }

        if (key === "Date Signed") {
          formAgreementData.push({ key, value });
        }
      }
    }

    let divTags = parsedHtml.getElementsByTagName("p");
    for (let div of divTags) {
      const key = div.innerText.replace(":", "").trim();
      const value = div.nextElementSibling?.innerText?.trim();
      if (value) {
        customerData.push({ key, value });
      }
    }

    // Extracting address fields
    const addressFields = [
      "Line 1",
      "Line 2",
      "City",
      "State/parish/providence/region",
      "Postal/Zip Code",
      "Country",
    ];
    let address = addressFields
      .map((field) => {
        return extractValue(customerData, field);
      })
      .join(", ")
      .replace(/, ,/g, ",")
      .trim();

    // change values in template
    let templateString = template;
    if (selectedCharges === 0) {
      templateString = addValues(templateString, "total-amount", data.Amount);
      templateString = addValues(templateString, "total-transactions", 1);
    } else {
      //calculate total
      let totalAmount = 0;
      relatedList.map((rel, i) => {
        if (selectedCharges.includes(rel.ID)) {
          totalAmount += rel.Amount;
        }
      });

      templateString = addValues(templateString, "total-amount", totalAmount);
      templateString = addValues(
        templateString,
        "total-transactions",
        selectedCharges.length
      );
      templateString = addTransactionTable(
        templateString,
        "total-transactions-table",
        relatedList.filter((c, i) => selectedCharges.includes(c.ID))
      );
    }

    templateString = addValues(
      templateString,
      "admin-merchant-id",
      "39461120016"
    );
    templateString = addValues(
      templateString,
      "card-number",
      formatCardNumber(data.FirstSixDigitsOfCard, data.LastFourDigitsOfCard)
    );
    templateString = addValues(
      templateString,
      "full-name",
      data.customer.Firstname + " " + data.customer.Lastname
    );
    templateString = addValues(
      templateString,
      "email-address",
      data.customer.Email
    );
    templateString = addValues(
      templateString,
      "contact-number",
      data.customer.Telephone_Cell
    );
    templateString = addValues(templateString, "gender", data.customer.gender);
    templateString = addValues(
      templateString,
      "date-of-birth",
      extractValue(customerData, "Date of Birth")
    );
    templateString = addValues(
      templateString,
      "identification-type",
      `ID Type: ${
        data.customer.customer_identification?.[0]?.identification_type || ""
      }`
    );
    templateString = addValues(
      templateString,
      "identification-number",
      `ID Number: ${
        data.customer.customer_identification?.[0]?.id_number || ""
      }`
    );
    templateString = addValues(
      templateString,
      "identification-expiration",
      data.customer.customer_identification?.[0]?.id_expiry || ""
    );
    templateString = addValues(templateString, "address", address);
    templateString = addValues(
      templateString,
      "date-signed",
      extractValue(formAgreementData, "Date Signed")
    );
    templateString = addValues(
      templateString,
      "customer-signature",
      extractValue(clientImagesData, "Customer Signature Image")
    );
    templateString = addValues(
      templateString,
      "id-front",
      extractValue(clientImagesData, "Identification Image 0")
    );
    templateString = addValues(
      templateString,
      "id-back",
      extractValue(clientImagesData, "Identification Image 1")
    );
    templateString = addValues(
      templateString,
      "id-holding",
      extractValue(clientImagesData, "Identification Image Holding")
    );

    templateString = addValues(
      templateString,
      "transaction-date",
      moment(data.Date_Created).format("MMMM Do YYYY, h:mm:ss a")
    );
    templateString = addValues(
      templateString,
      "order-number",
      data.PaymentProcessorOrderNo
    );
    templateString = addValues(
      templateString,
      "card-expiration-date",
      data.CardExpirationDate || "MM/YYYY"
    );
    templateString = addValues(
      templateString,
      "client’s-Signature",
      extractValue(formAgreementData, "Client’s Signature")
    );
    return templateString;
  };

  const exportPDF = () => {
    if (editorRef.current) {
      const editorHtml = editorRef.current.getContent();
      let style = "<style>" + assignedCSS + assignedCSSPrint + "</style>";
      let transformedHtml =
        "<!DOCTYPE html><html><head>" +
        style +
        "</head><body>" +
        editorHtml +
        "</body></html>";

      const filename =
        chargeBackData.customer.Firstname +
        " " +
        chargeBackData.customer.Lastname +
        " - " +
        templateType +
        ".pdf";

      var options = {
        jsPDF: { unit: "in", format: "Legal", orientation: "portrait" },
        html2canvas: { scale: 3 },
        image: { type: "jpeg", quality: 1 },
        margin: [0.5, 0.75, 1.44, 0.52],
        filename: filename,
        pagebreak: { mode: "css", after: ".break-here" },
      };
      html2pdf().set(options).from(transformedHtml).save();
    }
  };

  const exportDOCX = () => {
    if (editorRef.current) {
      let fileName =
        chargeBackData.customer.Firstname +
        " " +
        chargeBackData.customer.Lastname +
        " - " +
        templateType;
      const editorHtml = editorRef.current.getContent();
      let style =
        "<style> @page WordSection1{ size: 595.35pt 1008.0pt;mso-page-orientation: portrait; } div.WordSection1 { page: WordSection1; } " +
        assignedCSS +
        assignedCSSPrint +
        "</style>";
      let transformedHtml =
        "<html xmlns:o='urn:schemas-microsoft-com:office:office' xmlns:w='urn:schemas-microsoft-com:office:word' xmlns='http://www.w3.org/TR/REC-html40'><head><meta charset='utf-8'><title>" +
        fileName +
        "</title>" +
        style +
        "</head><body><div class='Section1'>" +
        editorHtml +
        "</div></body></html>";
      var source =
        "data:application/vnd.ms-word;charset=utf-8," +
        encodeURIComponent(transformedHtml);
      fileName = fileName ? fileName + ".doc" : "document.doc";
      // Create download link element
      var downloadLink = document.createElement("a");

      document.body.appendChild(downloadLink);

      // Create a link to the file
      downloadLink.href = source;

      // Setting the file name
      downloadLink.download = fileName;

      //triggering the function
      downloadLink.click();

      document.body.removeChild(downloadLink);
    }
  };

  const onChargesCheck = async (e, item) => {
    const id = item.ID;
    const selectedIndex = selectedCharges.indexOf(id);

    let newSelectedCharges = [];

    if (selectedIndex === -1) {
      newSelectedCharges = newSelectedCharges.concat(selectedCharges, id);
    } else if (selectedIndex === 0) {
      newSelectedCharges = newSelectedCharges.concat(selectedCharges.slice(1));
    } else if (selectedIndex === selectedCharges.length - 1) {
      newSelectedCharges = newSelectedCharges.concat(
        selectedCharges.slice(0, -1)
      );
    } else if (selectedIndex > 0) {
      newSelectedCharges = newSelectedCharges.concat(
        selectedCharges.slice(0, selectedIndex),
        selectedCharges.slice(selectedIndex + 1)
      );
    }

    setSelectedCharges(newSelectedCharges);
  };

  const onChangeSection = async (section) => {
    setSelectedSection(section);
  };

  const getClientImages = useCallback(async (chargeBackData) => {
    try {
      const tempClientImages = [];
      const clientIdentification =
        chargeBackData?.customer?.mostRecentDocs?.ID || null;
      if (clientIdentification) {
        clientIdentification.map(async ({ image_path }, i) => {
          const key = "Identification Image " + i;
          const value =
            '<img src="' +
            image_path +
            '" alt="' +
            key +
            '" style="max-width: 250px; width: 100%" width=250 height="' +
            '" />';
          tempClientImages.push({ key, value });
        });
      }

      const clientImageSelfie =
        chargeBackData?.customer?.mostRecentDocs?.SELFIE || null;
      if (clientImageSelfie) {
        const key = "Identification Image Holding";
        const value =
          '<img src="' +
          clientImageSelfie?.image_path +
          '" alt="' +
          key +
          '" style="max-width: 250px; width: 100%" width=250 height="' +
          '" />';
        tempClientImages.push({ key, value });
      }

      // Handling signature image
      const clientSignature =
        chargeBackData?.customer?.mostRecentDocs?.SIGNED_SIGNATURE || null;
      if (clientSignature) {
        const key = "Customer Signature Image";
        const value =
          '<img src="' +
          clientSignature.image_path +
          '" alt="' +
          key +
          '" style="max-width: 250px; width: 100%" width="250" />';
        tempClientImages.push({ key, value });
      }

      setClientImagesData(tempClientImages);
    } catch (err) {}
  }, []);

  useEffect(() => {
    getRelatedCharges();
    getAllChargeBackTemplates();
  }, []);

  useEffect(() => {
    getClientImages(chargeBackData);
  }, [
    chargeBackData,
    chargeBackData?.customer?.mostRecentDocs?.ID,
    getClientImages,
  ]);

  return (
    <ModalContainer>
      <div className="signature-modal medium-width">
        <div className="cpm-container">
          {selectedSection && selectedSection === "charges" && (
            <>
              <div className="overview-section-top">
                <div className="os-left">Related Charges</div>
                <div className="os-right">
                  <button
                    onClick={(e) => onChangeSection("documents")}
                    disabled={selectedCharges.length === 0}
                  >
                    Documents
                  </button>
                </div>
              </div>
              <div>
                <table>
                  <tbody>
                    {!loading &&
                      relatedList?.map((item, i) => {
                        return (
                          <tr key={"chk-" + i}>
                            <th scope="row">
                              <input
                                type="checkbox"
                                checked={
                                  selectedCharges.indexOf(item.ID) !== -1
                                }
                                className="form-check-input"
                                id={`chrg-chk-${item.ID}`}
                                name={`chrg-chk-${item.ID}`}
                                onChange={(e) => onChargesCheck(e, item)}
                                value={item.ID}
                              />
                            </th>
                            <td>
                              {moment(item?.Date_Created).format(
                                "YYYY-MM-DD hh:mm A"
                              )}
                            </td>
                            <td>
                              {formatCardNumber(
                                item?.FirstSixDigitsOfCard,
                                item?.LastFourDigitsOfCard
                              )}
                            </td>
                            <td>{`${item?.PaymentProcessorOrderNo}`}</td>
                            <td>USD${`${item?.Amount}`}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </table>
              </div>
              <div className="buttons">
                <button
                  className="verification-continue-btn complete"
                  onClick={() => {
                    setSelectedSection("charges");
                    setSelectedCharges([]);
                    closeModal(false);
                  }}
                >
                  Close
                </button>
              </div>
            </>
          )}

          {selectedSection && selectedSection === "documents" && (
            <>
              <div className="overview-section-top">
                <div className="os-left">Chargeback Documents</div>
              </div>
              <div className="overview-section-top">
                <div className="os-left small">
                  {relatedList && relatedList.length > 0 && (
                    <button
                      onClick={(e) => {
                        setTemplateType("SELECT AN OPTION");
                        setHtml(null);
                        onChangeSection("charges");
                      }}
                    >
                      Go Back
                    </button>
                  )}
                </div>
                <div className="os-right">
                  <AdminFilter
                    classprop="long"
                    name="template_type"
                    setOption={(val) => {
                      setTemplateType(val);
                      getChargeBackDocument(val);
                    }}
                    option={templateType}
                    list={[
                      "SELECT AN OPTION",
                      "Charge Back Response",
                      "Customer Agreement",
                      "Payment Receipt",
                      "Proof of Purchase",
                    ]}
                    defaultValue="SELECT AN OPTION"
                  ></AdminFilter>
                </div>
              </div>
              <br />
              {loading && <div>Loading...</div>}
              {!loading && (
                <>
                  <Editor
                    onInit={(evt, editor) => (editorRef.current = editor)}
                    initialValue={html}
                    init={{
                      verify_html: false,
                      entity_encoding: "raw",
                      height: 500,
                      menubar: false,
                      referrer_policy: "origin",
                      editimage_cors_hosts: [
                        "api.apexmarkets.io",
                        "api.apex.localhost.local",
                      ],
                      plugins: [
                        "advlist autolink lists link image charmap print preview anchor",
                        "searchreplace visualblocks code fullscreen",
                        "insertdatetime media table paste code help wordcount",
                        "export pagebreak code",
                      ],
                      toolbar:
                        "export pagebreak code | undo redo | formatselect | " +
                        "bold italic backcolor | alignleft aligncenter " +
                        "alignright alignjustify | bullist numlist outdent indent | " +
                        "removeformat | help ",
                      content_style: assignedCSS,
                    }}
                  />
                  <div className="buttons">
                    <button
                      className="verification-continue-btn complete"
                      disabled={templateType === "SELECT AN OPTION"}
                      onClick={() => {
                        exportPDF();
                      }}
                    >
                      Export as PDF
                    </button>
                    <button
                      className="verification-continue-btn complete"
                      disabled={templateType === "SELECT AN OPTION"}
                      onClick={() => {
                        exportDOCX();
                      }}
                    >
                      Export as DOCX
                    </button>
                    <button
                      className="verification-continue-btn complete"
                      onClick={() => {
                        setSelectedSection("charges");
                        setSelectedCharges([]);
                        closeModal(false);
                      }}
                    >
                      Close
                    </button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </ModalContainer>
  );
};

export default ManageChargeBackModal;
