import React, { useState } from "react";
import { useEffect } from "react";
import { getKycBreakdown, getKycDocuments } from "../../api/document";
// components
import AdminBar from "../../components/admin/AdminBar";
import KycTable from "../../components/admin/KycTable";
import OverviewBox from "../../components/admin/OverviewBox";
import AdminFilter from "../../components/admin/AdminFilter";
import TableSearch from "../../components/admin/TableSearch";
import TableFilter from "../../components/admin/TableFilter";
import AdminTable from "../../components/admin/AdminTable";
import { data, colNames } from "../../components/admin/KYCVerificationData";
import adminboxblue from "../../images/icons/adminboxblue.svg";
import adminboxorange from "../../images/icons/adminboxorange.svg";
import adminboxgreen from "../../images/icons/adminboxgreen.svg";
import adminboxblack from "../../images/icons/adminboxblack.svg";
import AdminKycTable from "../../components/admin/AdminKycTable";

const Documents = ({ showAdminNav }) => {
  const [statistics, setStatistics] = useState();
  const loadStatistics = async () => {
    const breakdown = await getKycBreakdown();
    setStatistics(breakdown);
  };
  showAdminNav(true);
  useEffect(() => {
    loadStatistics();
  }, []);
  return (
    <div className="dashboard-page">
      <AdminBar title="KYC Documents"></AdminBar>
      <div className="admin-container">
        <div className="overview-section">
          <div className="overview-section-top">
            <div className="os-left">Overview</div>
            <div className="os-right">
              <AdminFilter></AdminFilter>
            </div>
          </div>
          <div className="overview-boxes">
            <OverviewBox
              title="Total Approved Customers"
              img={adminboxblue}
              data={statistics?.numVerified}
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Pending Customers"
              img={adminboxorange}
              data={statistics?.numPending}
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total Rejected Customers"
              img={adminboxblack}
              data={statistics?.numRejected}
              color="red"
            ></OverviewBox>
            <OverviewBox
              title="Total Blocked Customers"
              img={adminboxblack}
              data={statistics?.numBlocked}
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Customers Not Submitted"
              img={adminboxblack}
              data={statistics?.numNotSubmitted}
              color="green"
            ></OverviewBox>
            <OverviewBox
              title="Total Verified Documents"
              img={adminboxblue}
              data={statistics?.verifiedDocuments}
              color="green"
            />
            <OverviewBox
              title="Total Pending Documents"
              img={adminboxorange}
              data={statistics?.pendingDocuments}
              color="green"
            />
            <OverviewBox
              title="Total Rejected Documents"
              img={adminboxblack}
              data={statistics?.rejectedDocuments}
              color="green"
            />
          </div>
        </div>
        <div className="overview-tables ot-flex">
          <div className="overview-table">
            <AdminKycTable color="blue"></AdminKycTable>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Documents;
