import React, { useState } from "react";
import ManageWireTransferModal from "../ManageWireTransferModal";
import ManageWithdrawRequestModal from "../ManageWithdrawRequestModal";
import moment from "moment";
// css
// HistoryTable.css

const WithdrawalRequests = ({
  refresh,
  data,
  colNames,
  color,
  color2,
  color3,
  color4,
  color5,
  color6,
}) => {
  const [showManageModal, setShowManageModal] = useState(false);
  const [withdrawRequest, setWithdrawRequest] = useState();

  return (
    <div className="admin-table-container">
      <div className="horizontal-line"></div>
      <table>
        <thead>
          <th>#</th>
          <th>Date Requested</th>
          <th>Client #</th>
          <th>Name</th>
          <th>Email</th>
          <th>Wallet Balance (USD)</th>
          <th>Amount Requested</th>
          <th>Fee (USD)</th>
          <th>Total Payout (USD)</th>
          <th>Date Submitted</th>
          <th>Date Processed</th>
          <th>Processing Time</th>
          <th>Status</th>
          <th>Action</th>
          <th>Processed By</th>
        </thead>
        <tbody>
          {data.map((row, i) => {
            return (
              <tr key={row?.ID}>
                <td>{i + 1}</td>
                <td>{moment(row.Date_Created).format("MMM D, YYYY hh:mm:ss A")}</td>
                <td>{row?.Client_ID}</td>
                <td>{row?.Account_Holder_Name}</td>
                <td>{row?.customer?.Email}</td>
                <td>{row?.customer?.wallet?.Balance}</td>
                <td>{row.Amount + (!isNaN(row.Fee) ? row.Fee:0)}</td>
                <td>{row.Fee}</td>
                <td>{row.Amount}</td>
                <td>{row?.Process_Date?moment(row.Date_Created).format("MMM D, YYYY"):'-'}</td>
                <td>{row?.Process_Date?moment(row.Process_Date).format("MMM D, YYYY"):'-'}</td>
                <td>{row?.Process_Date?moment(row.Process_Date).format("hh:mm:ss A"):'-'}</td>
                <td>{row.Status}</td>
                <td
                  onClick={() => {
                    setWithdrawRequest(row);
                    setShowManageModal(true);
                  }}
                >
                  Manage
                </td>
                <td>{row?.Process_By_admin?.Firstname} {row?.Process_By_admin?.Lastname}</td>
              </tr>
            );
          })}

          {/* {Object.values(data).map((obj, index) => (
            <tr key={index}>
              {Object.values(obj).map((value, index2) => (
                <td
                  className={`${index2 === 0 && color6} || ${
                    index2 === 1 && color
                  } || ${index2 === 3 && color2} || ${
                    index2 === 3 && value === "Verified" && "green"
                  } || ${index2 === 3 && value === "Rejected" && "red"} || ${
                    index2 === 4 && color3
                  } ${index2 === 4 && value === "Rejected" && "red"}  || ${
                    index2 === 4 && value === "Approved" && "green"
                  } || ${index2 === 5 && color4} || ${
                    index2 === 6 && color5
                  } || ${index2 === 6 && value === "Success" && "green"} || ${
                    index2 === 6 && value === "Decline" && "red"
                  } || ${index2 === 6 && value === "Proceeding" && "blue"} || ${
                    index2 === 7 && "admin-flex"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${index2 === 8 && value[0] === "+" && "green"} || ${
                    index2 === 8 && value[0] === "-" && "red"
                  } || ${
                    index2 === 7 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  } ${index2 === 9 && "admin-flex"} || ${
                    index2 === 9 &&
                    (value[0] === "Paid") | (value[0] === "Partially Paid") &&
                    "green"
                  } || ${
                    index2 === 9 &&
                    (value[0] === "Flag") | (value[0] === "Unpaid") &&
                    "red"
                  }`}
                  key={index2}
                >
                  {value}
                </td>
              ))}
            </tr>
          ))} */}
        </tbody>
      </table>
      {showManageModal && (
        <>
          <ManageWithdrawRequestModal
            closeModal={setShowManageModal}
            withdrawRequest={withdrawRequest}
            refresh={refresh}
          />
        </>
      )}
    </div>
  );
};

export default WithdrawalRequests;
