import { api, SECURE_API } from "./index";
import { clean } from "../util";
import axios from "axios";

export const getKycDocuments = async (params) => {
  const response = await api
    .get("document", {
      searchParams: params,
      timeout: false,
    })
    .json();
  if (response != null && response.customers != null) {
    return response;
  }
  return null;
};

export const getDocumentUrl = (document) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/document/${document.Document_ID}`;
  return url;
};

export const getDocument = async (documentID) => {
  const response = await api.get(`document/record/${documentID}`).json();
  return response;
};

export const updateDocumentContent = async (id, base64, html) => {
  const response = await api
    .put(`document/edit-form/${id}`, {
      json: {
        base64,
        html,
      },
    })
    .json();
  return response?.document;
};

export const updateDocumentStatus = async (id, status, identificationForm) => {
  const response = await api
    .put(`document/update-status/${id}`, {
      json: {
        status,
        identificationForm,
      },
    })
    .json();
  return response;
};
export const updateAllDocumentsStatus = async (
  status,
  identificationForm,
  allDocs
) => {
  const response = await api
    .put(`document/bulk-update-status`, {
      json: {
        status,
        identificationForm,
        allDocs,
      },
    })
    .json();
  return response;
};

export const convertToBase64 = async (imageUrl) => {
  const response = await api
    .get(`document/convert-to-base64?url=${encodeURIComponent(imageUrl)}`)
    .json();
  return response;
};

export const updateDocumentNote = async (id, rejectedReason) => {
  const response = await api
    .put(`document/update-rejectedReason/${id}`, {
      json: {
        rejectedReason,
      },
    })
    .json();

  return response;
};

export const getUserDocuments = async () => {
  const response = await api.get(`document/me`).json();
  return response.documents;
};

export const getUserUploadedDocuments = async (params) => {
  const response = await api
    .get("document/docs-me", {
      searchParams: params,
      timeout: false,
    })
    .json();
  return response.documents;
};

export const getSpecificUserDocument = async (id) => {
  const response = await api.get(`document/me/${id}`).json();
  return response.document;
};

export const getKycDocumentTypes = async () => {
  const response = await api.get(`document/kyc-doctypes`).json();
  return response.documentTypes;
};

export const getSpecificUserDocumentStream = async (id) => {
  if (id == null) {
    return null;
  }
  const response = await api.get(`document/stream/me/${id}`).json();
  return response?.file;
};

export const convertSignature = async (obj) => {
  var headers = new Headers();
  headers.append("Content-Type", "application/json");

  var requestOptions = {
    method: "POST",
    headers: headers,
    body: JSON.stringify(obj),
    redirect: "follow",
  };

  const response = (
    await fetch("https://kyc.apexmarkets.io/transform-sign", requestOptions)
  ).json();
  return response;
};

export const getKycBreakdown = async (id) => {
  const response = await api.get(`document/kyc-breakdown`).json();
  return response;
};

export const getDocumentTemplates = async (params) => {
  const response = await api
    .get(`document/document-templates`, {
      searchParams: clean(params),
      timeout: false,
    })
    .json();
  return response;
};

export const azureUploadImage = async (formData) => {
  const response = await axios.post(
    `${process.env.REACT_APP_API_BASE_URL}/document/azure-upload`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
  if (response.data) return response.data; // Return the response data
  return null;
};

export const base64ExtractInformation = async (images) => {
  try {
    const response = await api
      .put("document/base64/document/scan", {
        json: {
          urls: images,
        },
      })
      .json();
    if (response) return response;
    return null;
  } catch (error) {
    console.error("Error occurred:", error.message);
    return null;
  }
};

export const base64FaceMatch = async (document, selfie) => {
  try {
    const requestBody = document
      ? { url: document, queryUrl: selfie }
      : { url: selfie };

    const response = await api
      .put("document/base64/face/scan", { json: requestBody })
      .json();

    return response || null;
  } catch (error) {
    console.error("Error occurred:", error.message);
    return null;
  }
};

export const base64SignatureMatch = async (document, signature) => {
  try {
    const response = await api
      .put("document/base64/signature/scan", {
        json: {
          url: document,
          queryUrl: signature,
        },
      })
      .json();
    if (response) return response;
    return null;
  } catch (error) {
    console.error("Error occurred:", error.message);
    return null;
  }
};

export const deleteDocument = async (id) => {
  const response = await api.post(`document/delete-document/${id}`).json();
  console.log("Called");

  if (response != null) {
    return response;
  }
  return null;
};

export const extractInformationOCR = async (base64IMG) => {
  const response = await api
    .put("document/extract-id", {
      json: {
        base64IMG,
      },
    })
    .json();
  return response;
};

export const compareImages = async (file_base64_1, file_base64_2) => {
  const response = await api
    .put("document/kyc-signature-compare", {
      file_base64_1,
      file_base64_2,
    })
    .json();
  return response;
};

export const matchSignature = async (sign_1, sign_2) => {
  const response = await api
    .put("document/kyc-signature-compare", {
      json: {
        file_base64_1: sign_1,
        file_base64_2: sign_2,
      },
    })
    .json();
  return response;
};

export const matchFace = async (face_1, face_2) => {
  const response = await api
    .put("document/kyc-face-verify", {
      json: {
        file_base64_1: face_1,
        file_base64_2: face_2,
      },
    })
    .json();
  return response;
};
